import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';

import { NavController } from '@ionic/angular';

@Injectable()
export class OfflineModeGuard implements CanLoad, CanActivate {
    constructor(private navCtrl: NavController) {}

    canLoad(): boolean {
        return this.handleOfflineMode();
    }

    canActivate(): boolean {
        return this.handleOfflineMode();
    }

    private handleOfflineMode(): boolean {
        const isOfflineMode: boolean = window.localStorage.getItem('offline_mode') === '1';

        if (isOfflineMode) {
            this.navCtrl.navigateRoot('main/appointments');

            return false;
        }

        return true;
    }
}
