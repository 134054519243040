import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

import { Storage } from '@ionic/storage';

import { AuthGuard } from '@core/guards/auth.guard';
import { ParadigmAuthGuard } from '@core/guards/paradigm-auth.guard';

@Injectable({
    providedIn: 'root'
})
export class GuardHelperService {
    targetRoutePath = 'main';

    constructor(private storage: Storage, private router: Router) {}

    async updateGuards(): Promise<void> {
        const targetRouteIndex: number = this.router.config.findIndex((route) => route.path === this.targetRoutePath);

        if (targetRouteIndex > -1) {
            const isParadigmAuth: boolean = (await this.storage.get('isParadigmAuth')) === 'true';
            const updatedRouteConfig: Route = this.router.config[targetRouteIndex];

            console.log('updateGuards', isParadigmAuth);
            updatedRouteConfig.canActivate = isParadigmAuth ? [ParadigmAuthGuard, MsalGuard] : [AuthGuard]; //todo check
            this.router.config[targetRouteIndex] = updatedRouteConfig;
            this.router.resetConfig(this.router.config);
        }
    }
}
