import { Injectable } from '@angular/core';

import { PickerController } from '@ionic/angular';
import { PickerColumn } from '@ionic/core/dist/types/components/picker/picker-interface';

import { AuthService } from '@core/services/auth.service';
import { UtilsService } from '@core/services/utils.service';
import { UserPreferences } from '@shared/enums/user-preferences.enum';

@Injectable({
    providedIn: 'root'
})
export class WheelSelectorService {
    constructor(private pickerCtrl: PickerController, private utilsService: UtilsService, private auth: AuthService) {}

    openWheelSelector(wheelSelectorSettings: PickerColumn[]): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            const picker = await this.pickerCtrl.create({
                columns: wheelSelectorSettings,
                animated: false,
                cssClass: 'wheel-selector',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => picker.dismiss().then(() => reject())
                    },
                    {
                        text: 'Confirm',
                        handler: (value) =>
                            picker.dismiss().then(() => {
                                let val: string;

                                if (value.fractions) {
                                    if (`${value.fractions.value}`.indexOf('/') > -1) {
                                        val = `${value.numeric.value} ${value.fractions.value}`;
                                    } else {
                                        val = `${parseFloat(value.numeric.value) + parseFloat(value.fractions.value)}`;
                                    }
                                } else {
                                    val = `${value.numeric.value}`;
                                }

                                const result: number = this.utilsService.convertFractionToDecimal(val);

                                resolve(result);
                            })
                    }
                ]
            });

            await picker.present();
        });
    }

    getWheelSelectorSetting(result, userPreferences: any[]): any {
        let wheelSelectorSettings;

        const user = this.auth.getUser();
        const wheelSelectorConfig = user.office.use_organization_other_settings
            ? user.organization.other_settings.wheel_selector
            : user.office.other_settings.wheel_selector;

        if (result) {
            userPreferences.find((preference) => preference.type === result.type).value = result.value;
        }

        const isActiveMeasurementPreference = userPreferences
            .filter((preference) =>
                [UserPreferences.AugmentRealityMeasurement, UserPreferences.BoschMeasurement].includes(preference.type)
            )
            .some((item) => item.value);

        const isShowWheelPicker = wheelSelectorConfig?.is_active && !isActiveMeasurementPreference;

        if (isShowWheelPicker) {
            const numeric = {
                name: 'numeric',
                options: []
            };

            for (let i = wheelSelectorConfig.value_from; i <= wheelSelectorConfig.value_to; i++) {
                numeric.options.push({
                    text: i,
                    value: i
                });
            }

            wheelSelectorSettings = [numeric];

            if (wheelSelectorConfig.fractions.length) {
                const fractions = {
                    name: 'fractions',
                    selectedIndex: wheelSelectorConfig.fractions.findIndex(
                        (fraction) => fraction === wheelSelectorConfig.default_fraction
                    ),
                    options: wheelSelectorConfig.fractions.map((fraction) => {
                        return {
                            text: fraction,
                            value: fraction
                        };
                    })
                };

                wheelSelectorSettings.push(fractions);
            }
        } else {
            wheelSelectorSettings = null;
        }

        return wheelSelectorSettings;
    }
}
