import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-document-view-modal',
    templateUrl: './document-view-modal.component.html',
    styleUrls: ['./document-view-modal.component.scss']
})
export class DocumentViewModalComponent extends BaseModal implements OnInit {
    @Input() document: any;

    documentHtml: SafeHtml;

    constructor(modalController: ModalController, private domSanitizer: DomSanitizer) {
        super(modalController);
    }

    ngOnInit(): void {
        if (this.document.content) {
            this.documentHtml = this.domSanitizer.bypassSecurityTrustHtml(this.document.content);
        } else {
            this.readFromUrl();
        }
    }

    private readFromUrl(): void {
        const request = new XMLHttpRequest();

        request.open('GET', this.document.url.html, true);
        request.onreadystatechange = () => {
            if (request.readyState == 4) {
                this.documentHtml = this.domSanitizer.bypassSecurityTrustHtml(request.responseText);
            }
        };
        request.send();
    }
}
