import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Apollo } from 'apollo-angular';

import { ModalController, Platform } from '@ionic/angular';

import { map } from 'rxjs/operators';

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { FilesService } from '@core/services/files.service';
import { UPLOAD_APPOINTMENTS } from '@core/services/offline/mutations/offline.mutations';
import { OFFLINE_TABLES, OFFLINE_TABLES_VERSION } from '@core/services/offline/offline-tables/tables';
import { LoadingModalComponent } from '@shared';

import { environment } from '../../../../environments/environment';
import { UPLOAD_IMAGE } from '../../../main/appointments/mutations/appointment.mutations';

@Injectable({
    providedIn: 'root'
})
export class OfflineStorageService {
    hasUnsyncData = false;
    isDesktop = (window.location.host && window.location.host.includes('paradigmvendo')) || environment.local;
    private db: SQLiteObject;
    private setupPromise: any;

    constructor(
        private sqlLite: SQLite,
        private auth: AuthService,
        private modalController: ModalController,
        private fileService: FilesService,
        private apollo: Apollo,
        private platform: Platform,
        private rollbarErrorHandler: RollbarErrorHandler
    ) {
        this.platform.ready().then(() => {
            if (this.isDesktop) {
                return;
            }
            this.setupPromise = this.makeQueryablePromise(
                this.sqlLite
                    .create({
                        name: 'offline_db',
                        location: 'default'
                    })
                    .then(async (db) => {
                        this.db = db;
                        const currentOfflineVersion = await this.auth.getStorageItem('offlineTablesVersion');

                        if (!currentOfflineVersion) {
                            await this.auth.setStorageItem('offlineTablesVersion', OFFLINE_TABLES_VERSION);
                        } else if (currentOfflineVersion !== OFFLINE_TABLES_VERSION) {
                            await this.dropTables();
                            await this.auth.setStorageItem('offlineTablesVersion', OFFLINE_TABLES_VERSION);
                        }

                        await this.refreshTables();
                    })
            );
        });
    }

    async insert(tableName: string, values: any[]): Promise<any> {
        if (!this.setupPromise.isFulfilled()) {
            await this.setupPromise;
        }

        const table = OFFLINE_TABLES.find((table) => table.table_name === tableName);

        if (!table) {
            return;
        }

        let query = `INSERT OR REPLACE INTO ${tableName} (${table.fields.join(', ')}) VALUES `;

        values.forEach((value, index) => {
            query += `${index ? ', ' : ''}(${table.fields
                .map(
                    (field) =>
                        `'${
                            isArray(value[field]) || isObject(value[field])
                                ? this.prepareJSON(value[field])
                                : value[field]
                        }'`
                )
                .join(', ')}) `;
        });

        return this.db.sqlBatch([query]);
    }

    async updatedEntities(user): Promise<any> {
        const updatedAppointments = await this.read(`
            SELECT * FROM Appointments
              WHERE seller_id='${user.id}'
              AND office_id='${user.office.id}'
              AND (updated = 1
              OR created = 1)
        `);
        const updatedOpeningAppointments = await this.read(`
            SELECT DISTINCT Appointments.id, openings FROM Appointments
              WHERE seller_id='${user.id}'
              AND office_id='${user.office.id}'
              AND (openings LIKE '%\"created\":\"1\"%'
              OR openings LIKE '%\"is_deleted\":\"1\"%'
              OR openings LIKE '%\"updated\":\"1\"%')
        `);

        const updatedOpenings = updatedOpeningAppointments.reduce((acc, appointment) => {
            return acc.concat(
                appointment.openings.filter(
                    (opening) => opening.updated === '1' && opening.created !== '1' && opening.is_deleted !== '1'
                )
            );
        }, []);

        const createdOpenings = updatedOpeningAppointments.reduce((acc, appointment) => {
            return acc.concat(appointment.openings.filter((opening) => opening.created === '1'));
        }, []);

        const deletedOpenings = updatedOpeningAppointments.reduce((acc, appointment) => {
            return acc.concat(appointment.openings.filter((opening) => opening.is_deleted === '1'));
        }, []);

        updatedAppointments.forEach((appointment) => {
            delete appointment.openings;
        });

        return {
            updatedAppointments,
            updatedOpeningAppointments,
            updatedOpenings,
            createdOpenings,
            deletedOpenings
        };
    }

    async syncData(updatedEntities: any): Promise<boolean> {
        const modal = await this.modalController.create({
            component: LoadingModalComponent,
            showBackdrop: true,
            backdropDismiss: false,
            cssClass: 'loading-modal',
            componentProps: {
                title: 'Syncing items',
                message: 'Your appointments are being updated with the most recent changes.'
            }
        });

        await modal.present();

        const allModifiedOpenings = updatedEntities.updatedOpenings.concat(updatedEntities.createdOpenings);
        const filesToDelete = [];

        for (const openingIndex in allModifiedOpenings) {
            const opening: any = allModifiedOpenings[openingIndex];

            if (opening.images?.length) {
                for (const imageIndex in opening.images) {
                    const imageSrc = opening.images[imageIndex];

                    if (imageSrc.__typename) {
                        delete opening.images[imageIndex].__typename;
                    }

                    if (imageSrc.appointment_type) {
                        delete opening.images[imageIndex].appointment_type;
                    }

                    if (imageSrc.opening_id) {
                        delete opening.images[imageIndex].opening_id;
                    }

                    if (!imageSrc.url || imageSrc.url.includes('https://')) {
                        continue;
                    }

                    const indexOfFilePath: number = imageSrc.url.indexOf('openingimages');

                    if (indexOfFilePath === -1) {
                        continue;
                    }

                    const localUrl: string = imageSrc.url.slice(indexOfFilePath);
                    let tempUrl: string;
                    const file: Blob = await this.fileService.getBlobFile(localUrl);

                    try {
                        tempUrl = await this.apollo
                            .mutate({
                                mutation: UPLOAD_IMAGE,
                                variables: {
                                    file
                                },
                                context: {
                                    useMultipart: true,
                                    extensions: {
                                        background: true
                                    }
                                }
                            })
                            .pipe(map((res: ApolloQueryResult<any>) => res.data.uploadImage))
                            .toPromise();
                    } catch (e) {}

                    if (tempUrl) {
                        imageSrc.url = imageSrc.original_url = tempUrl;
                        filesToDelete.push(localUrl);
                    }
                }
            }
        }

        const syncData = {
            updated: {
                appointments: updatedEntities.updatedAppointments,
                openings: updatedEntities.updatedOpenings
            },
            created: {
                openings: updatedEntities.createdOpenings
            },
            deleted: {
                openings: updatedEntities.deletedOpenings.map((opening) => opening.id)
            }
        };

        let result;

        try {
            result = await this.apollo
                .mutate({
                    mutation: UPLOAD_APPOINTMENTS,
                    variables: syncData,
                    context: {
                        extensions: {
                            background: true
                        }
                    }
                })
                .pipe(map((res: ApolloQueryResult<any>) => res?.data?.synchronizeAppointments))
                .toPromise();

            if (result?.status) {
                this.rollbarErrorHandler.handleInfo(
                    `SynchronizeAppointments - ${result?.status} - ${JSON.stringify(result)}`
                );
            }
        } catch (e) {
            this.rollbarErrorHandler.handleInfo(`Failed SynchronizeAppointments - ${JSON.stringify(e)}`);
        }

        if (filesToDelete?.length) {
            filesToDelete.forEach((file) => {
                this.fileService.deleteFile(file);
            });
        }

        modal.dismiss();

        return (
            !!updatedEntities.updatedAppointments?.length ||
            !!updatedEntities.updatedOpenings?.length ||
            !!updatedEntities.createdOpenings?.length ||
            !!updatedEntities.deletedOpenings?.length
        );
    }

    /**
     * TODO: Change promise handler after https://github.com/ionic-team/ionic-native/issues/3408 resolved
     *
     * @param query
     */
    async read(query: string): Promise<any[]> {
        if (!this.setupPromise.isFulfilled()) {
            await this.setupPromise;
        }

        return new Promise((resolve, reject) => {
            this.db.executeSql(query).then(
                (error) => {
                    reject(error);
                },
                (res) => {
                    const data = [];

                    for (let i = res.rows.length - 1; i >= 0; i--) {
                        data.push(this.convertJsonFields(res.rows.item(i)));
                    }

                    resolve(data);
                }
            );
        });
    }

    async findOne(query: string): Promise<any> {
        if (!this.setupPromise.isFulfilled()) {
            await this.setupPromise;
        }

        return new Promise((resolve, reject) => {
            this.db.executeSql(query).then(
                (error) => {
                    reject(error);
                },
                (res) => {
                    const data = this.convertJsonFields(res.rows.item(0));

                    resolve(data);
                }
            );
        });
    }

    async clearTable(tableName: string): Promise<any> {
        if (!this.setupPromise.isFulfilled()) {
            await this.setupPromise;
        }

        return this.db.sqlBatch([`DELETE FROM ${tableName}`]);
    }

    async deleteRecords(query: string): Promise<any> {
        if (!this.setupPromise.isFulfilled()) {
            await this.setupPromise;
        }

        return this.db.sqlBatch([query]);
    }

    async dropTables(): Promise<void> {
        const queries: string[] = OFFLINE_TABLES.map((table) => `DROP TABLE IF EXISTS ${table.table_name}`);

        await this.db.sqlBatch(queries);
    }

    async refreshTables(): Promise<any> {
        const tablesCreateSql = OFFLINE_TABLES.map((table) => {
            const fields = table.fields.map((field) => {
                if (table.column_types && table.column_types[field]) {
                    field += ` ${table.column_types[field]}`;
                }
                if (table.primary_key === field) {
                    return `${field} PRIMARY KEY`;
                }

                return field;
            });

            return `CREATE TABLE IF NOT EXISTS ${table.table_name} (${fields.join(', ')})`;
        });

        return this.db.sqlBatch(tablesCreateSql).then(
            // eslint-disable-next-line no-console
            (succ) => console.log('success create', succ),
            // eslint-disable-next-line no-console
            (error) => console.log('error create', error)
        );
    }

    private prepareJSON(json: any, stringify = true): any {
        const escape = (element): void => {
            if (isObject(element)) {
                for (const key in element) {
                    if (!element.hasOwnProperty(key)) {
                        continue;
                    }

                    if (isObject(element[key]) || isArray(element[key])) {
                        this.prepareJSON(element[key], false);
                        continue;
                    }

                    if (!isString(element[key])) {
                        continue;
                    }

                    element[key] = element[key].replace("'", "''");
                }
            }
        };

        if (isArray(json)) {
            json.forEach((element, index) => {
                if (isString(element)) {
                    json[index] = element.replace("'", "''");

                    return;
                }

                if (isArray(element) || isObject(element)) {
                    escape(element);
                }
            });
        } else if (isObject(json)) {
            escape(json);
        }

        return stringify ? JSON.stringify(json) : json;
    }

    private convertJsonFields(object: any): any {
        for (const key in object) {
            try {
                const convertedField = JSON.parse(object[key]);

                object[key] = convertedField;
            } catch (e) {}
        }

        return object;
    }

    private makeQueryablePromise(promise): any {
        // Don't modify any promise that has been already modified.
        if (promise.isFulfilled && promise.isFulfilled()) {
            return promise;
        }

        // Set initial state
        let isPending = true;
        let isRejected = false;
        let isFulfilled = false;

        // Observe the promise, saving the fulfillment in a closure scope.
        const result = promise.then(
            function (v) {
                isFulfilled = true;
                isPending = false;

                return v;
            },
            function (e) {
                isRejected = true;
                isPending = false;
                throw e;
            }
        );

        result.isFulfilled = function () {
            return isFulfilled;
        };
        result.isPending = function () {
            return isPending;
        };
        result.isRejected = function () {
            return isRejected;
        };

        return result;
    }
}
