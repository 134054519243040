<div class="line-item" [class.excluded]="lineItem.excluded">
    <div class="images-section">
        <div class="image">
            <img class="drawing-image" [src]="activeDrawing?.url"/>
        </div>
        <div class="custom-segment" *ngIf="lineItem.opening_drawings?.length > 1">
            <ion-label *ngFor="let drawing of lineItem.opening_drawings"
                       class="ion-text-center"
                       [ngClass]="{'active-segment': drawing.name === activeDrawing.name}"
                       (click)="setActiveDrawing(drawing)">
                {{getDrawingDisplayName(drawing)}}
            </ion-label>
        </div>
    </div>

    <div class="details-section">
        <div class="header-section">
            <div>
                <div class="title">
                    {{ lineItem.opening_name }}
                </div>
                <div class="description">
                    {{ lineItem.description }}
                </div>
            </div>

            <div class="amount-section">
                <ion-text>Qty</ion-text>
                <div *ngIf="!isAllowQuantityChange" class="readonly-quantity">{{ lineItem.quantity }}</div>
                <ion-input *ngIf="isAllowQuantityChange"
                           type="number"
                           inputmode="numeric"
                           tqMIntegerInput
                           class="quantity-select"
                           [disabled]="lineItem.excluded || lineItem.is_provia"
                           [(ngModel)]="lineItem.quantity"
                           (ionBlur)="quantityChanges()"
                           min="1"
                           required
                           autocomplete="off"></ion-input>
                <vendo-price-details *ngIf="showPrice"
                                     [isShowDiscount]="!!lineItem.discounts_combined?.length && displayPrice !== totalPrice"
                                     [finalPrice]="displayPrice"
                                     [totalPrice]="totalPrice"></vendo-price-details>
            </div>
        </div>
        <div class="details">
            <div class="details-action">
                <ion-button class="toggle-btn"
                            [disabled]="lineItem.excluded"
                            (click)="toggleDetailsSection()">
                    <ion-text slot="start">{{ isShowDetails ? 'Hide' : 'Show' }} Details</ion-text>
                    <ion-icon slot="end" [name]="isShowDetails ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
                </ion-button>

                <ion-text *ngIf="!isNotAllowExclude" class="exclude-btn" (click)="itemExcluded.emit(lineItem)">
                    {{ lineItem.excluded ? 'Include' : 'Exclude' }}
                </ion-text>

                <div *ngIf="(!isFirst || !isLast) && isAllowReordering" class="reorder-controls">
                    <ion-button class="icon-only nav-btn"
                                size="small"
                                [disabled]="isDisableReordering || isFirst"
                                (click)="moveItem.emit(-1)"
                                expand="full">
                        <ion-icon name="chevron-up" color="primary"></ion-icon>
                    </ion-button>

                    <ion-button class="icon-only nav-btn"
                                size="small"
                                [disabled]="isDisableReordering || isLast"
                                (click)="moveItem.emit(1)"
                                expand="full">
                        <ion-icon name="chevron-down" color="primary"></ion-icon>
                    </ion-button>
                </div>
            </div>

            <ng-container *ngIf="isShowDetails">
                <ng-container *ngFor="let detail of lineItem.details; let i = index">
                    <div #detailElement
                         *ngIf="detail.answers.length"
                         class="detail"
                         [ngClass]="{'expanded': isExpanded[i]}"
                         (click)="toggleDetail(i)">
                        <ion-button class="icon-only" color="primary">
                            <ion-icon [name]="isExpanded[i] ? 'remove-outline' : 'add-outline'"></ion-icon>
                        </ion-button>
                        <ion-text>{{ detail.label }}</ion-text>
                    </div>

                    <ion-grid *ngIf="isExpanded[i]" class="options">
                        <ng-container [ngSwitch]="detail.label">
                            <ng-container *ngSwitchDefault>
                                <ion-row *ngFor="let answer of detail.answers">
                                    <ion-col size="5">
                                        {{ answer.question }}
                                    </ion-col>
                                    <ion-col size="7">
                                        {{ answer.answer }}
                                    </ion-col>
                                </ion-row>
                            </ng-container>

                            <ng-container *ngSwitchCase="adderNameSetting?.plural">
                                <ion-row>
                                    <ion-col size="4">
                                        Name
                                    </ion-col>
                                    <ion-col>
                                        Short description
                                    </ion-col>
                                    <ion-col size="1" class="adder-quantity">
                                        Qty
                                    </ion-col>
                                    <ion-col size="2.5" class="ion-justify-content-end" *ngIf="showPrice">
                                        Price
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngFor="let adder of lineItem.adders">
                                    <ion-col size="4">
                                        {{ adder.name }}
                                        <ion-icon *ngIf="adder.description"
                                                  class="info-icon"
                                                  name="information-circle-outline"
                                                  (click)="showFullDescriptionPopover($event, adder.description)"></ion-icon>
                                    </ion-col>
                                    <ion-col [innerHTML]="adder.short_description | lineBreaks"></ion-col>
                                    <ion-col size="1" class="adder-quantity">
                                        {{ adder.quantity }}
                                    </ion-col>
                                    <ion-col size="2.5" class="adder-amount" *ngIf="showPrice">
                                        <div class="value" [class.value-with-discount]="adder.display_price !== adder.total_price">
                                            <ion-icon *ngIf="isPromotionApplied && !adder.discountable" name="md-custom-no-discount-icon"></ion-icon>
                                            {{ adder.display_price | customCurrency: true }}
                                        </div>
                                        <div *ngIf="adder.display_price !== adder.total_price" class="value">
                                            {{ adder.total_price | customCurrency: true }}
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ng-container>

                            <ng-container *ngSwitchCase="'Accessories'">
                                <ion-row *ngFor="let accessory of lineItem.accessories">
                                    <ion-col size="4">
                                        {{ accessory.name }}
                                    </ion-col>
                                    <ion-col size="5">
                                        {{ accessory.description }}
                                    </ion-col>
                                    <ion-col size="3" class="adder-amount" *ngIf="showPrice">
                                        <div class="value" [class.value-with-discount]="accessory.display_price !== accessory.total_price">
                                            {{ accessory.display_price | customCurrency: true }}
                                        </div>
                                        <div *ngIf="accessory.display_price !== accessory.total_price" class="value">
                                            {{ accessory.total_price | customCurrency: true }}
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ng-container>


                            <ng-container *ngSwitchCase="'Change Details'">
                                <ng-container *ngIf="lineItem.changes">
                                    <ng-container [ngTemplateOutlet]="changesHeaderTemplate"
                                                  [ngTemplateOutletContext]="{ title: '' }"></ng-container>
                                    <ng-container [ngTemplateOutlet]="changesBodyTemplate"
                                                  [ngTemplateOutletContext]="{ changes: lineItem.changes }"></ng-container>
                                </ng-container>

                                <ng-container *ngIf="isExistAddersChanges">
                                    <ng-container *ngIf="!isExpandedAddersChanges"
                                                  [ngTemplateOutlet]="changesHeaderTemplate"
                                                  [ngTemplateOutletContext]="{ title: adderNameSetting?.plural }"></ng-container>
                                    <ng-container *ngIf="isExpandedAddersChanges">
                                        <ng-container *ngFor="let adder of lineItem.adders">
                                            <ng-container *ngIf="adder?.changes?.length">
                                                <ng-container [ngTemplateOutlet]="changesHeaderTemplate"
                                                              [ngTemplateOutletContext]="{ title: adder.name }"></ng-container>
                                                <ng-container [ngTemplateOutlet]="changesBodyTemplate"
                                                              [ngTemplateOutletContext]="{ changes: adder.changes }"></ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="lineItem?.custom_changes?.length">
                                    <ng-container [ngTemplateOutlet]="changesHeaderTemplate"
                                                  [ngTemplateOutletContext]="{ title: 'Additional Work Info' }"></ng-container>
                                    <ng-container [ngTemplateOutlet]="changesBodyTemplate"
                                                  [ngTemplateOutletContext]="{ changes: lineItem.custom_changes }"></ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ion-grid>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #changesHeaderTemplate let-title="title">
    <ion-row [class.font-bold]="!!title">
        <ion-col size="4">{{ title }}</ion-col>
        <ng-container *ngIf="title !== adderNameSetting?.plural || isExpandedAddersChanges; else addersChangesButton">
            <ion-col size="4">{{ title ? '' : 'Original' }}</ion-col>
            <ion-col size="4">{{ title ? '' : 'New' }}</ion-col>
        </ng-container>
    </ion-row>
</ng-template>

<ng-template #addersChangesButton>
    <ion-col size="8"
             class="adders-changes"
             (click)="isExpandedAddersChanges = !isExpandedAddersChanges">
        View Changes
    </ion-col>
</ng-template>

<ng-template #changesBodyTemplate let-changes="changes">
    <ion-row *ngFor="let change of changes">
        <ion-col size="4" class="pl-10">
            {{ change.label }}
        </ion-col>
        <ion-col size="4">
            {{ change.old }}
        </ion-col>
        <ion-col size="4">
            {{ change.new }}
        </ion-col>
    </ion-row>
</ng-template>
