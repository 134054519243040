import { gql } from 'apollo-angular';

/**
 * input OpeningFile {
 *   file: Upload,
 *   img_url: String
 * }
 */

const CREATE_OPENING = gql`
    mutation CreateOpening(
        $categoryId: ID!
        $catalogId: ID!
        $appointmentId: ID!
        $opening: SaveOpening!
        $geolocation: JSON
        $images: [AppointmentImageInput!]
    ) {
        createOpening(
            category_id: $categoryId
            catalog_id: $catalogId
            appointment_id: $appointmentId
            opening: $opening
            geolocation: $geolocation
            images: $images
        ) {
            id
        }
    }
`;

const COPY_OPENING = gql`
    mutation CopyOpening($openingId: ID!, $copyCount: Int!) {
        copyOpening(opening_id: $openingId, copy_count: $copyCount)
    }
`;

const CREATE_HOUSE_AREA = gql`
    mutation CreateHouseArea($appointment_id: ID!, $title: String!, $category_type: String!) {
        createHouseArea(appointment_id: $appointment_id, title: $title, category_type: $category_type) {
            id
            title
            hash
            question_hash
        }
    }
`;

const DELETE_HOUSE_AREA = gql`
    mutation DeleteHouseArea($areaId: ID!) {
        deleteHouseArea(id: $areaId)
    }
`;

const RENAME_HOUSE_AREA = gql`
    mutation RenameHouseArea($id: ID!, $title: String!) {
        renameHouseArea(id: $id, title: $title)
    }
`;

const UPDATE_OPENING = gql`
    mutation UpdateOpening(
        $categoryId: ID!
        $catalogId: ID!
        $appointmentId: ID!
        $opening: SaveOpening!
        $images: [AppointmentImageInput!]
    ) {
        updateOpening(
            category_id: $categoryId
            catalog_id: $catalogId
            appointment_id: $appointmentId
            opening: $opening
            images: $images
        ) {
            id
        }
    }
`;

const SAVE_OPENING_PHOTOS = gql`
    mutation SaveOpeningPhotos($appointmentId: ID!, $openingId: ID!, $files: [OpeningImageDetails]!) {
        saveOpeningPhotos(appointment_id: $appointmentId, opening_id: $openingId, files: $files) {
            id
            images
            image_details
        }
    }
`;

const UPDATE_OPENING_NAME = gql`
    mutation UpdateOpeningName($categoryId: ID!, $appointmentId: ID!, $opening: SaveOpeningName!) {
        updateOpeningName(category_id: $categoryId, appointment_id: $appointmentId, opening: $opening) {
            id
            name
        }
    }
`;

const DELETE_OPENING = gql`
    mutation DeleteOpening($openingId: ID!) {
        deleteOpening(opening_id: $openingId)
    }
`;

const DELETE_TAKEOFF_DETAILS = gql`
    mutation DeleteTakeoffDetails($catalogId: ID!, $appointmentId: ID!) {
        deleteAnnotation(catalog_id: $catalogId, appointment_id: $appointmentId)
    }
`;

const SAVE_TAKEOFF_DETAILS = gql`
    mutation SaveTakeoffDetails(
        $catalog_id: ID!
        $appointment_id: ID!
        $takeoff_details: InputTakeoffDetails!
        $openings: [InputAnnotatedOpening]!
    ) {
        openingAnnotations(
            catalog_id: $catalog_id
            appointment_id: $appointment_id
            takeoff_details: $takeoff_details
            openings: $openings
        )
    }
`;

export {
    CREATE_OPENING,
    UPDATE_OPENING,
    DELETE_OPENING,
    COPY_OPENING,
    UPDATE_OPENING_NAME,
    CREATE_HOUSE_AREA,
    RENAME_HOUSE_AREA,
    DELETE_HOUSE_AREA,
    SAVE_TAKEOFF_DETAILS,
    DELETE_TAKEOFF_DETAILS,
    SAVE_OPENING_PHOTOS
};
