import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { forkJoin } from 'rxjs';

import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import some from 'lodash/some';

import { FeaturesService } from '@core/services/features.service';
import { PriceConfigurationService } from '@core/services/price-configuration.service';
import { StepsService } from '@core/services/steps.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { BaseModal } from '@shared/components/base-modal';
import { USER_PREFERENCES_GROUPS } from '@shared/constants/user-preferences';
import { AppointmentType } from '@shared/enums/appointment-type';
import { PriceConfigurationDisplayStatus } from '@shared/enums/price-configuration-display-status';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { UserPreferences } from '@shared/enums/user-preferences.enum';

@Component({
    selector: 'vendo-user-preferences-modal',
    templateUrl: './user-preferences-modal.component.html',
    styleUrls: ['./user-preferences-modal.component.scss']
})
export class UserPreferencesModalComponent extends BaseModal implements OnInit {
    availablePreferences: any[] = [];
    userPreferences: any = {};

    constructor(
        private featureService: FeaturesService,
        public modalCtrl: ModalController,
        private priceConfigurationService: PriceConfigurationService,
        private stepsService: StepsService,
        private userPreferenceService: UserPreferencesService
    ) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        forkJoin([
            this.userPreferenceService.getPreferences([
                UserPreferences.ShowPricing,
                UserPreferences.DefaultDepositAsPercent,
                UserPreferences.AugmentRealityMeasurement,
                UserPreferences.BoschMeasurement,
                UserPreferences.QuickTakeoff,
                UserPreferences.CustomProductPromotion,
                UserPreferences.ShowPriceModifiers
            ]),
            this.priceConfigurationService.allowDisplayListPriceStatus(),
            this.priceConfigurationService.allowDisplayPriceModifiersStatus()
        ]).subscribe(([preferences, allowDisplayListPriceStatus, allowDisplayPriceModifiersStatus]) => {
            this.userPreferences = keyBy(preferences, 'type');

            const userPreferencesGroups = cloneDeep(USER_PREFERENCES_GROUPS);

            this.availablePreferences = userPreferencesGroups.filter((group: any) => {
                group.values = group.values.filter(
                    (value: any) => !value.features || this.featureService.hasFeature(value.features)
                );

                group.values.forEach((value) => {
                    if (value.hash === 'show_pricing') {
                        this.setPricePreferenceValue(value.hash, allowDisplayListPriceStatus);

                        value.isShow = PriceConfigurationDisplayStatus.AlwaysOff !== allowDisplayListPriceStatus;
                    }

                    if (value.hash === 'show_price_modifiers') {
                        this.setPricePreferenceValue(value.hash, allowDisplayPriceModifiersStatus);

                        value.isShow = PriceConfigurationDisplayStatus.AlwaysOff !== allowDisplayPriceModifiersStatus;
                    }
                });

                if (group.label.startsWith('Window')) {
                    this.replaceTakeOffLabels(group);
                }

                return (
                    (!group.features || this.featureService.hasFeature(group.features, false)) &&
                    some(group.values, (value) => value.isShow)
                );
            });
        });
    }

    preferenceChanged(type: string): void {
        this.userPreferenceService.saveUserPreference(type, this.userPreferences[type].value).subscribe(
            () => {},
            () => (this.userPreferences[type].value = !this.userPreferences[type].value)
        );
    }

    done(): void {
        this.closeModal();
    }

    private setPricePreferenceValue(hash: string, status: PriceConfigurationDisplayStatus): void {
        if (!this.userPreferences[hash].__typename) {
            this.userPreferences[hash].value = [
                PriceConfigurationDisplayStatus.DefaultOn,
                PriceConfigurationDisplayStatus.AlwaysOffInConfigurator
            ].includes(status);
        }
    }

    private replaceTakeOffLabels(group: any): void {
        const value = group.values.find((value) => (value.hash = 'quick_takeoff'));
        const takeoffStepName: string = this.stepsService.getStepName(AppointmentType.Sales, SaleStepType.TAKE_OFF);

        group.label = `Window ${takeoffStepName}`;
        value.label = `Quick ${takeoffStepName}`;
    }
}
