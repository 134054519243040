export enum CategoryType {
    Windows = 'windows',
    Doors = 'doors',
    PatioDoors = 'patio_doors',
    Siding = 'siding',
    CustomRoofs = 'custom_roofs',
    Roofs = 'roofs',
    Hvac = 'hvac',
    Kitchens = 'kitchens',
    Bathrooms = 'bathrooms',
    Flooring = 'flooring',
    GarageDoors = 'garage_doors',
    Decks = 'decks',
    Porches = 'porches',
    Sunrooms = 'sunrooms',
    Awnings = 'awnings',
    Solar = 'solar',
    Fencing = 'fencing',
    Other = 'other',
    CustomWindows = 'custom_windows',
    CustomDoors = 'custom_doors',
    CustomPatioDoors = 'custom_patio_doors',
    Provia = 'provia'
}
