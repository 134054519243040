import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'vendo-side-expand-area',
    templateUrl: './side-expand-area.component.html',
    styleUrls: ['./side-expand-area.component.scss']
})
export class SideExpandAreaComponent {
    @Input() side = 'right'; //Only right is supported for now
    @Input() @HostBinding('class.expanded') expanded = false;

    public setExpanded(isExpanded: boolean): void {
        this.expanded = isExpanded;
    }
}
