import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import get from 'lodash/get';

import {
    IMPORT_MEASUREMENT_SYSTEM_REPORT,
    MODIFY_MEASUREMENT_SYSTEM_REPORT,
    ORDER_MEASUREMENT_SYSTEM_REPORT,
    REMOVE_MEASUREMENT_SYSTEM_REPORT
} from '@core/mutations/measurement-system.mutations';
import {
    GET_MEASUREMENT_SYSTEM,
    GET_MEASUREMENT_SYSTEM_REPORT_DETAILS,
    GET_MEASUREMENT_SYSTEM_USER_DETAILS,
    GET_USER_REMEMBER,
    SEARCH_MEASUREMENT_SYSTEM_REPORTS
} from '@core/queries/measurement-system.queries';
import { GeolocationService } from '@core/services/geolocation.service';
import { CategoryType } from '@shared/enums/category-type';
import { MeasurementSystemType } from '@shared/enums/measurement-system-type';
import {
    MeasurementSystem,
    MeasurementSystemReport,
    MeasurementSystemReportDetails,
    MeasurementSystemUserDetails
} from '@shared/interfaces/measurement-system';
import { PaginationItems } from '@shared/interfaces/pagination-items';

@Injectable({
    providedIn: 'root'
})
export class MeasurementSystemService {
    constructor(private apollo: Apollo, private geolocationService: GeolocationService) {}

    getAvailableSystems(appointmentId: number): Observable<MeasurementSystem[]> {
        return this.apollo
            .query({
                query: GET_MEASUREMENT_SYSTEM,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myMeasurementSystems));
    }

    getRemember(type: MeasurementSystemType): Observable<any> {
        return this.apollo
            .query({
                query: GET_USER_REMEMBER,
                variables: {
                    type
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => get(res, `data.publicRememberProperties.${type}`, {})));
    }

    searchReport(
        type: MeasurementSystemType,
        appointment_id: number,
        search: string,
        credentials: any,
        remember?: boolean,
        used_remember?: boolean,
        offset?: number,
        limit?: number
    ): Observable<PaginationItems<MeasurementSystemReport>> {
        return this.apollo
            .query({
                query: SEARCH_MEASUREMENT_SYSTEM_REPORTS,
                variables: {
                    type,
                    appointment_id,
                    search,
                    credentials,
                    remember,
                    used_remember,
                    offset,
                    limit
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.searchMeasurementSystemReports));
    }

    importReport(
        type: MeasurementSystemType,
        appointment_id: string,
        credentials: any,
        report: MeasurementSystemReport,
        category_types: CategoryType[],
        takeoff_details?: any,
        remember?: boolean,
        used_remember?: boolean
    ): Observable<boolean> {
        return from(this.geolocationService.getGeolocation()).pipe(
            mergeMap((geolocation: any) =>
                this.apollo
                    .mutate({
                        mutation: IMPORT_MEASUREMENT_SYSTEM_REPORT,
                        variables: {
                            type,
                            geolocation,
                            appointment_id,
                            credentials,
                            ...(report.id ? { id: report.id } : { report_id: report.report_id }),
                            category_types,
                            takeoff_details,
                            remember,
                            used_remember
                        }
                    })
                    .pipe(map((res: ApolloQueryResult<any>) => res.data.importMeasurementSystemReport))
            )
        );
    }

    modifyReport(
        id: string,
        type: MeasurementSystemType,
        appointment_id: string,
        category_types: CategoryType[],
        takeoff_details: any
    ): Observable<boolean> {
        return from(this.geolocationService.getGeolocation()).pipe(
            mergeMap((geolocation: any) =>
                this.apollo
                    .mutate({
                        mutation: MODIFY_MEASUREMENT_SYSTEM_REPORT,
                        variables: {
                            id,
                            type,
                            appointment_id,
                            category_types,
                            takeoff_details,
                            geolocation
                        }
                    })
                    .pipe(map((res: ApolloQueryResult<any>) => res.data.modifyMeasurementSystemReport))
            )
        );
    }

    orderReport(
        type: MeasurementSystemType,
        appointment_id: number,
        credentials: any,
        owner_details: any,
        address_details: any
    ): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: ORDER_MEASUREMENT_SYSTEM_REPORT,
                variables: {
                    type,
                    appointment_id,
                    credentials,
                    owner_details,
                    address_details
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.orderMeasurementSystemReport));
    }

    removeReport(id: string, appointmentId: number): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: REMOVE_MEASUREMENT_SYSTEM_REPORT,
                variables: {
                    id,
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.removeMeasurementSystemReport));
    }

    getMeasurementSystemReportDetails(
        type: MeasurementSystemType,
        appointment_id: number,
        report: MeasurementSystemReport,
        credentials: any,
        remember?: boolean,
        used_remember?: boolean
    ): Observable<MeasurementSystemReportDetails> {
        return this.apollo
            .query({
                query: GET_MEASUREMENT_SYSTEM_REPORT_DETAILS,
                variables: {
                    type,
                    appointment_id,
                    ...(report.id ? { id: report.id } : { report_id: report.report_id }),
                    credentials,
                    remember,
                    used_remember
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.getMeasurementSystemReportDetails));
    }

    getMeasurementSystemUserDetails(
        type: MeasurementSystemType,
        appointment_id: string,
        credentials?: any,
        remember?: boolean,
        used_remember?: boolean
    ): Observable<MeasurementSystemUserDetails> {
        return this.apollo
            .query({
                query: GET_MEASUREMENT_SYSTEM_USER_DETAILS,
                variables: {
                    type,
                    appointment_id,
                    credentials,
                    remember,
                    used_remember
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.getMeasurementSystemUserDetails));
    }
}
