import { gql } from 'apollo-angular';

const FETCH_PRODUCT_SELECTION_OPTIONS = gql`
    mutation FetchProductSelection(
        $quoteId: ID!
        $openingId: ID!
        $update: Boolean!
        $answers: [ProductSelectionAnswer]!
        $pagination: ProductSelectionPagination
        $search: String
    ) {
        fetchProductSelection(
            quote_id: $quoteId
            opening_id: $openingId
            update: $update
            answers: $answers
            pagination: $pagination
            search: $search
        )
    }
`;

const CREATE_CONFIGURATOR_SESSION = gql`
    mutation CreateConfiguratorSession(
        $quoteId: ID!
        $openingId: ID!
        $productListSelections: ProductListSelections!
        $productImageUrl: String
    ) {
        configurator(
            quote_id: $quoteId
            opening_id: $openingId
            product_list_selections: $productListSelections
            product_image_url: $productImageUrl
        )
    }
`;

const EDIT_LINE_ITEM = gql`
    mutation EditLineItem($quoteId: ID!, $openingId: ID!) {
        editConfigurator(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const EDIT_OPENING_QUANTITY = gql`
    mutation EditOpeningQuantity($appointmentId: ID!, $openingId: ID!, $quantity: Int!) {
        updateLineItemQuantity(appointment_id: $appointmentId, opening_id: $openingId, quantity: $quantity)
    }
`;

const BULK_EDIT_OPENING_QUANTITY = gql`
    mutation EditOpeningQuantity($appointmentId: ID!, $inputs: [LineItemQuantityInput!]!) {
        bulkUpdateLineItemQuantity(appointment_id: $appointmentId, inputs: $inputs)
    }
`;

const EDIT_ACCESSORY_QUANTITY = gql`
    mutation EditAccessoryQuantity($quoteId: ID!, $openingId: ID!, $accessoryId: ID!, $quantity: Int!) {
        updateAccessoryQuantity(id: $accessoryId, quote_id: $quoteId, opening_id: $openingId, quantity: $quantity) {
            id
        }
    }
`;

const ANSWER_CONFIGURATOR_QUESTION = gql`
    mutation AnswerQuestion($quoteId: ID!, $openingId: ID!, $question: UpdateQuestion!) {
        updateQuestion(quote_id: $quoteId, opening_id: $openingId, question: $question)
    }
`;

const SAVE_CONFIGURATIONS_NOTES = gql`
    mutation configurationNotes($quoteId: ID!, $openingId: ID!, $notes: [String]!) {
        configurationNotes(quote_id: $quoteId, opening_id: $openingId, notes: $notes)
    }
`;

const SAVE_CONFIGURATIONS_ACCESSORIES = gql`
    mutation configurationAccessories($quoteId: ID!, $openingId: ID!, $accessories: [ID]!) {
        configurationAccessories(quote_id: $quoteId, opening_id: $openingId, accessories: $accessories)
    }
`;

const CREATE_ADDER = gql`
    mutation CreateCustomAdder(
        $name: String!
        $applies_to: String!
        $taxable: Boolean!
        $amount: Float!
        $amount_type: String!
        $quantity: Int
        $short_description: String
        $description: String
    ) {
        createCustomAdder(
            name: $name
            applies_to: $applies_to
            taxable: $taxable
            amount: $amount
            amount_type: $amount_type
            quantity: $quantity
            short_description: $short_description
            description: $description
        ) {
            id
            name
            adder_type_id
            applies_to
            taxable
            default
            amount
            amount_type
            quantity
            short_description
            description
            type
        }
    }
`;

const EDIT_ADDER = gql`
    mutation UpdateCustomAdder(
        $id: ID!
        $adder_type_id: ID!
        $name: String!
        $applies_to: String!
        $taxable: Boolean!
        $amount: Float!
        $amount_type: String!
        $quantity: Int
        $short_description: String
        $description: String
        $opening_id: ID
        $quote_id: ID
    ) {
        updateCustomAdder(
            id: $id
            adder_type_id: $adder_type_id
            name: $name
            applies_to: $applies_to
            taxable: $taxable
            amount: $amount
            amount_type: $amount_type
            quantity: $quantity
            short_description: $short_description
            description: $description
            opening_id: $opening_id
            quote_id: $quote_id
        ) {
            id
            name
            adder_type_id
            applies_to
            taxable
            default
            amount
            amount_type
            quantity
            short_description
            description
            type
        }
    }
`;

const SAVE_CONFIGURATIONS_ADDERS = gql`
    mutation configurationAdders(
        $quoteId: ID!
        $openingId: ID
        $adders: [ID]!
        $addersConfigs: JSON
        $appliesTo: String!
    ) {
        configurationAdders(
            quote_id: $quoteId
            opening_id: $openingId
            adders: $adders
            adders_config: $addersConfigs
            applies_to: $appliesTo
        )
    }
`;

const MULTIPLE_CONFIGURATION_ADDERS = gql`
    mutation MultipleApplyConfigurationAdders($input: [ConfigurationAdderInput]!) {
        multipleApplyConfigurationAdders(input: $input)
    }
`;

const CHANGE_PROJECT_ADDER_QUANTITY = gql`
    mutation projectAdderQuantity($appointmentId: ID!, $adderId: ID!, $quantity: Int!) {
        projectAdderQuantity(appointment_id: $appointmentId, adder_id: $adderId, quantity: $quantity)
    }
`;

const CHANGE_LINE_ADDER_QUANTITY = gql`
    mutation lineAdderQuantity($appointmentId: ID!, $quoteId: ID!, $openingId: ID!, $adderId: ID!, $quantity: Int!) {
        lineAdderQuantity(
            appointment_id: $appointmentId
            quote_id: $quoteId
            opening_id: $openingId
            adder_id: $adderId
            quantity: $quantity
        )
    }
`;

const FINISH_CONFIGURATION = gql`
    mutation FinishConfiguration($quoteId: ID!, $openingId: ID!, $answers: [JSON!]!) {
        finishConfiguration(quote_id: $quoteId, opening_id: $openingId, answers: $answers)
    }
`;

const DELETE_LINE_ITEM = gql`
    mutation DeleteLineItem($quoteId: ID!, $openingId: ID!) {
        deleteLineItem(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const COMPLETE_CONFIGURATION_STEP = gql`
    mutation CompleteConfigurationStep($appointmentId: ID!, $presentationType: String!) {
        finishConfigureStep(appointment_id: $appointmentId, presentation_type: $presentationType)
    }
`;

const FILL_TAKEOFF_ANSWERS = gql`
    mutation FillOpeningQuestions($quoteId: ID!, $openingId: ID!, $answers: [ProductSelectionAnswer]!) {
        fillOpeningQuestions(quote_id: $quoteId, opening_id: $openingId, answers: $answers)
    }
`;

const CREATE_EMPTY_OPENING = gql`
    mutation CreateEmptyOpening($appointmentId: ID!, $presentationType: String!, $categoryId: ID!, $answers: JSON) {
        emptyOpening(
            appointment_id: $appointmentId
            presentation_type: $presentationType
            catalog_id: $categoryId
            answers: $answers
        ) {
            opening {
                id
            }
            quote {
                id
                quote_id
            }
        }
    }
`;

const COPY_OPENING_WITHIN_QUOTE = gql`
    mutation CopyOpeningConfigurations(
        $quoteId: ID!
        $openingId: ID!
        $copyToOpeningIds: [ID]
        $newOpeningsCount: Int
        $includeAdders: Boolean
        $includeAccessories: Boolean
    ) {
        copyOpeningConfigurations(
            quote_id: $quoteId
            opening_id: $openingId
            copy_to_opening_ids: $copyToOpeningIds
            new_openings_count: $newOpeningsCount
            include_adders: $includeAdders
            include_accessories: $includeAccessories
        )
    }
`;

export {
    FETCH_PRODUCT_SELECTION_OPTIONS,
    CREATE_CONFIGURATOR_SESSION,
    ANSWER_CONFIGURATOR_QUESTION,
    FINISH_CONFIGURATION,
    EDIT_LINE_ITEM,
    COMPLETE_CONFIGURATION_STEP,
    SAVE_CONFIGURATIONS_NOTES,
    SAVE_CONFIGURATIONS_ADDERS,
    SAVE_CONFIGURATIONS_ACCESSORIES,
    CREATE_ADDER,
    EDIT_ADDER,
    MULTIPLE_CONFIGURATION_ADDERS,
    CREATE_EMPTY_OPENING,
    FILL_TAKEOFF_ANSWERS,
    COPY_OPENING_WITHIN_QUOTE,
    DELETE_LINE_ITEM,
    EDIT_OPENING_QUANTITY,
    BULK_EDIT_OPENING_QUANTITY,
    EDIT_ACCESSORY_QUANTITY,
    CHANGE_PROJECT_ADDER_QUANTITY,
    CHANGE_LINE_ADDER_QUANTITY
};
