import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import isEmpty from 'lodash/isEmpty';

import { FormHelperService } from '@core/services/form-helper.service';
import { MeasurementReportService } from '@core/services/measurement-report.service';
import { MeasurementSystemService } from '@core/services/measurement-system.service';
import { BaseModal } from '@shared/components/base-modal';
import { CategoryType } from '@shared/enums/category-type';
import { MeasurementSystemStatus } from '@shared/enums/measurement-system-status';
import { MeasurementSystem, MeasurementSystemReportDetails } from '@shared/interfaces/measurement-system';
import { ValidateMatchPattern } from '@shared/validators/match-pattern.validator';
import { RequiredOneTrueValidator } from '@shared/validators/required-one-true.validator';

@Component({
    selector: 'vendo-measurement-report-details-modal',
    templateUrl: './measurement-report-details-modal.component.html',
    styleUrls: ['./measurement-report-details-modal.component.scss']
})
export class MeasurementReportDetailsModalComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() appointment: any;
    @Input() measurementSystem: MeasurementSystem;
    @Input() credentials: any;
    @Input() report: any;
    @Input() remember: boolean;
    @Input() used_remember: boolean;
    @Input() details: MeasurementSystemReportDetails;
    @Input() categories: string[];
    availableCategoryTypes: CategoryType[] = [];
    measurementDetailsForm: UntypedFormGroup;
    readonly categoryTypes: typeof CategoryType = CategoryType;
    readonly measurementSystemStatuses: typeof MeasurementSystemStatus = MeasurementSystemStatus;

    get itemsFormArray(): UntypedFormArray {
        return this.measurementDetailsForm.get('items') as UntypedFormArray;
    }

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private formHelperService: FormHelperService,
        private measurementReportService: MeasurementReportService,
        private measurementSystemService: MeasurementSystemService,
        public modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.availableCategoryTypes = this.categories
            ? this.details.category_types.filter((categoryType: CategoryType) => this.categories.includes(categoryType))
            : this.details.category_types;
        this.initForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    import(): void {
        if (this.measurementDetailsForm.invalid) {
            this.measurementDetailsForm.markAllAsTouched();

            return;
        }

        const data: any[] = this.itemsFormArray.getRawValue();
        const categoryTypes: CategoryType[] = [];
        const takeoffDetailsData = {};

        data.forEach(({ active, categoryType, takeoffDetails }) => {
            if (active) {
                categoryTypes.push(categoryType);

                if (!isEmpty(takeoffDetails)) {
                    takeoffDetailsData[categoryType] = takeoffDetails;
                }
            }
        });

        if (this.report.status === MeasurementSystemStatus.Imported) {
            this.measurementSystemService
                .modifyReport(
                    this.report.id,
                    this.measurementSystem.hash,
                    this.appointment.id,
                    categoryTypes,
                    takeoffDetailsData
                )
                .subscribe({ next: (res: boolean) => this.dismiss(res) });
        } else {
            this.measurementSystemService
                .importReport(
                    this.measurementSystem.hash,
                    this.appointment.id,
                    this.credentials,
                    this.report,
                    categoryTypes,
                    takeoffDetailsData,
                    this.remember,
                    this.used_remember
                )
                .subscribe({ next: (res: boolean) => this.dismiss(res) });
        }
    }

    remove(): void {
        this.measurementReportService
            .removeReport(this.report.id, this.appointment.id)
            .then((result) => result && this.dismiss(result));
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    private initForm(): void {
        this.measurementDetailsForm = this.formBuilder.group({
            items: this.formBuilder.array(
                this.availableCategoryTypes.map((categoryType: CategoryType) =>
                    this.getCategoryFormGroup(categoryType)
                ),
                RequiredOneTrueValidator('active')
            )
        });
    }

    private getCategoryFormGroup(categoryType: CategoryType): UntypedFormGroup {
        const takeoffDetails: UntypedFormGroup = this.formBuilder.group({});
        const takeoffDetailsData = this.details.takeoff_details[categoryType];
        const active: boolean =
            this.report.id && this.report.category_types !== undefined
                ? this.report.category_types.includes(categoryType)
                : true;

        if (takeoffDetailsData && !isEmpty(takeoffDetailsData)) {
            if ([CategoryType.Roofs, CategoryType.Siding].includes(categoryType)) {
                takeoffDetails.addControl(
                    'waste_factor',
                    this.formBuilder.control(
                        takeoffDetailsData.waste_factor,
                        active
                            ? [
                                  Validators.required,
                                  Validators.min(0),
                                  Validators.max(100),
                                  ValidateMatchPattern(/^[0-9]*$/, 'Only digit numbers')
                              ]
                            : null
                    )
                );
            }

            if (CategoryType.Siding === categoryType) {
                takeoffDetails.addControl(
                    'exclude_openings',
                    this.formBuilder.control(takeoffDetailsData.exclude_openings || false)
                );

                takeoffDetails.addControl(
                    'siding_type',
                    this.formBuilder.control(
                        takeoffDetailsData.siding_type || null,
                        active ? Validators.required : null
                    )
                );
            }
        }

        const formGroup: UntypedFormGroup = this.formBuilder.group({
            active,
            categoryType,
            takeoffDetails
        });

        if ([CategoryType.Roofs, CategoryType.Siding].includes(categoryType)) {
            formGroup
                .get('active')
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value: boolean) => {
                    if ([CategoryType.Roofs, CategoryType.Siding].includes(categoryType)) {
                        const wasteFactorControl: AbstractControl = takeoffDetails.get('waste_factor');

                        wasteFactorControl.setValidators(
                            value
                                ? [
                                      Validators.required,
                                      Validators.min(0),
                                      Validators.max(100),
                                      ValidateMatchPattern(/^[0-9]*$/, 'Only digit numbers')
                                  ]
                                : null
                        );
                        wasteFactorControl.updateValueAndValidity();

                        if (CategoryType.Siding === categoryType) {
                            const sidingTypeControl: AbstractControl = takeoffDetails.get('siding_type');

                            sidingTypeControl.setValidators(value ? Validators.required : null);
                            sidingTypeControl.updateValueAndValidity();
                        }
                    }
                });
        }

        return formGroup;
    }
}
