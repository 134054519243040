import { Component, Input } from '@angular/core';

import { Adder } from '@shared/interfaces/adder';

@Component({
    selector: 'vendo-adders-list-popover',
    templateUrl: './adders-list-popover.component.html',
    styleUrls: ['./adders-list-popover.component.scss']
})
export class AddersListPopoverComponent {
    @Input() adders: Adder[] = [];
    @Input() lineItemQuantity;
}
