<div class="modal-header">
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body" [formGroup]="form">
    <ion-radio-group [value]="selectedOption"
                     (ionChange)="changeOption($event.detail.value)">
        <div class="radio-with-input">
            <ion-radio mode="md" [value]="'email'"></ion-radio>
            <div class="field-block">
                <ion-label>Email</ion-label>
                <ion-input type="text"
                           name="email"
                           placeholder="Email"
                           formControlName="email"
                           (ionChange)="$event.stopPropagation()"></ion-input>
                <div class="errors-wrapper" *ngIf="form.get('email').touched">
                    <ion-text color="danger" class="error">
                        {{form.get('email').errors && 'Email is required. Please enter valid email'}}
                    </ion-text>
                </div>
            </div>
        </div>

        <div class="separator">- OR -</div>

        <div class="radio-with-input">
            <ion-radio mode="md" [value]="'phone'"></ion-radio>
            <div class="field-block">
                <ion-label>Phone Number</ion-label>
                <ion-input type="text"
                           inputmode="tel"
                           name="phone"
                           placeholder="Phone Number"
                           formControlName="phone"
                           (ionChange)="$event.stopPropagation()"></ion-input>
                <div class="errors-wrapper" *ngIf="form.get('phone').touched">
                    <ion-text color="danger" class="error">
                        {{form.get('phone').errors && 'Phone Number format is invalid'}}
                    </ion-text>
                </div>
            </div>
        </div>
    </ion-radio-group>
</div>

<div class="modal-footer">
    <ion-button class="classic-width outline"
                (click)="closeModal()">
        Cancel
    </ion-button>
    <ion-button class="classic-width"
                [disabled]="form.invalid"
                (click)="save()">
        Apply
    </ion-button>
</div>
