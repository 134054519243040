import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastService } from '@core/services/toast.service';
import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent extends BaseModal implements OnInit {
    @Input() title: string;
    @Input() form: UntypedFormGroup;
    @Input() inputLabels: any;
    @Input() buttons: any[];
    @Input() avoidZeroControls: string[];

    controlNames: string[];

    private destroy$ = new Subject<void>();
    constructor(public modalCtrl: ModalController, private toastService: ToastService) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.controlNames = Object.keys(this.form.controls);

        if (this.avoidZeroControls.length) {
            this.avoidZeroControls.forEach((control) => {
                this.form
                    .get(control)
                    .valueChanges.pipe(takeUntil(this.destroy$))
                    .subscribe((value) => {
                        if (value.length > 1 && value.startsWith('0') && !value.includes('.')) {
                            this.form.get(control).patchValue(value.replace('0', ''));
                        }
                    });
            });
        }
    }

    async performAction(action: string): Promise<void> {
        if (action === 'done' && this.form.invalid) {
            await this.toastService.showMessage('Please fix errors in the form!');

            return;
        }
        this.dismiss(action);
    }
}
