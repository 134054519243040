<div class="modal-header">
    <ion-text class="title">Photo Annotation</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <div class="image-container" #imageContainer>
        <div class="svg-wrap">
            <input *ngIf="allowTextEditing"
                   [(ngModel)]="activeTextElement.title"
                   (change)="updateMeasurements($event)"
                   [style]="textEditPosition"/>
            <svg width="100%" height="100%" [attr.viewBox]="viewBox" *ngIf="viewBox"
                 xmlns="http://www.w3.org/2000/svg" (mousemove)="emulateLine($event)"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter id="dropshadow" height="130%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                        <feOffset dx="2" dy="2" result="offsetblur"/>
                        <feComponentTransfer>
                            <feFuncA type="linear" slope="0.5"/>
                        </feComponentTransfer>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <image #image
                       [attr.xlink:href]="imgUrl"
                       x="0"
                       y="0"
                       height="100%"
                       width="100%"
                       [class.transparent]="hideBackground"
                       (click)="startDrawing($event)"/>
                <ng-container *ngFor="let segment of segments">
                    <g *ngFor="let line of segment.lines"
                       [attr.stroke-width]="circleStrokeWidth">
                        <line [attr.x1]="line.from.x"
                              [attr.y1]="line.from.y"
                              [attr.x2]="line.to.x"
                              [attr.y2]="line.to.y"
                              [attr.stroke]="line.type.color"
                              (click)="startDrawing($event)"
                        />
                        <text *ngIf="line.distance?.title && activeTextElement?.x !== line.distance.x"
                              text-anchor="middle"
                              class="distance-label"
                              [attr.x]="line.distance.x"
                              [attr.y]="line.distance.y"
                              (click)="textElementClicked(line.distance, $event, 'measurements')">
                            {{line.distance.title}}'
                        </text>
                    </g>
                    <line *ngIf="drawingInProgress"
                          [attr.x1]="emulatedLine?.from?.x"
                          [attr.y1]="emulatedLine?.from?.y"
                          [attr.x2]="emulatedLine?.to?.x"
                          [attr.y2]="emulatedLine?.to?.y"
                          [attr.stroke]="selectedTool?.type?.color"
                          [attr.stroke-width]="lineStrokeWidth"
                          (click)="startDrawing($event)"
                    />
                    <circle *ngFor="let point of segment.points"
                            [attr.cx]="point.x + 'px'"
                            [attr.cy]="point.y + 'px'"
                            [attr.r]="radius"
                            [attr.stroke]="point.color"
                            [attr.stroke-width]="circleStrokeWidth"
                            fill="#ffffff"
                            fill-opacity="0.2"
                            (click)="pointClicked(segment, point)"
                    />
                </ng-container>
                <text *ngFor="let text of textItems"
                      class="text-element"
                      [attr.font-size]="fontSize + 'px'"
                      [attr.visibility]="activeTextElement?.x === text.x ? 'hidden' : 'visible'"
                      [attr.x]="text.x"
                      [attr.y]="text.y"
                      (click)="textElementClicked(text, $event)">
                    {{text.title}}
                </text>

                <!--     LEGEND       -->
                <ng-container *ngIf="legendItems.length">
                    <rect class="legend"
                          [attr.width]="legendPosition.width"
                          [attr.height]="legendPosition.height"
                          [attr.x]="legendPosition.x"
                          [attr.y]="legendPosition.y"
                          fill="#ffffff"
                          fill-opacity="0.6"
                          (click)="startDrawing($event)"></rect>
                    <g *ngFor="let item of legendItems"
                       [attr.stroke-width]="circleStrokeWidth"
                       fill="black">
                        <line [attr.x1]="item.x1"
                              [attr.y1]="item.y1"
                              [attr.x2]="item.x2"
                              [attr.y2]="item.y2"
                              [attr.stroke]="item.color"
                              [attr.stroke-width]="circleStrokeWidth"
                        />
                        <text class="legend-item"
                              [attr.font-size]="fontSize + 'px'"
                              [attr.x]="item.x"
                              [attr.y]="item.y">
                            {{item.name}}
                        </text>
                    </g>
                </ng-container>
                <!--    END LEGEND      -->
            </svg>
        </div>
    </div>
    <div class="tools-container">
        <ng-container *ngFor="let tool of tools">
            <div class="tool dropdown-actions"
                 *ngIf="tool.types?.length"
                 (click)="showDropdownPopover($event, tool)">
                <i class="material-icons" [class.selected]="selectedTool?.hash === tool.hash">{{tool.icon}}</i>
            </div>
            <div class="tool"
                 *ngIf="!tool.types?.length"
                 (click)="changeTool(tool)">
                <i class="material-icons" [class.selected]="selectedTool?.hash === tool.hash">{{tool.icon}}</i>
            </div>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <ion-button class="classic-width outline" [disabled]="!actionsHistory.length" (click)="undo()">Undo</ion-button>
    <ion-button class="classic-width outline" (click)="toggleBackground()">
        {{hideBackground ? 'Show' : 'Hide'}} Background
    </ion-button>
    <ion-button class="classic-width" (click)="save()">Done</ion-button>
</div>

