import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { DictionaryService } from '@core/services/dictionary.service';
import { NotificationService } from '@core/services/notification.service';
import { RequestFinancingApplicationModalComponent } from '@shared/components';
import { ApprovalRequestStatus } from '@shared/enums/approval-request-status';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { Appointment } from '@shared/interfaces/appointment';
import { ApprovalRequest } from '@shared/interfaces/approval-request';
import { Dictionary } from '@shared/interfaces/dictionary';
import { Promotion } from '@shared/interfaces/promotion';
import { Quote } from '@shared/interfaces/quote';
import { QuotePricePresentationPackageViewSetting } from '@shared/interfaces/quote-price-presentation-package-view-setting';

import { PricingDetailsComponent } from '../../../main/appointments/quote/components/pricing-details/pricing-details.component';
import { RequestApprovalModalComponent } from '../../../main/appointments/quote/components/request-approval-modal/request-approval-modal.component';

@Component({
    selector: 'vendo-quote-actions',
    templateUrl: './quote-actions.component.html',
    styleUrls: ['./quote-actions.component.scss']
})
export class QuoteActionsComponent implements OnInit {
    @Input() appointment: Appointment;
    @Input() quote: Quote;
    @Input() isChangeOrderEnabled: boolean;
    @Input() selectedPromotions: Promotion[] = [];
    @Input() selectedOfferCode: any;
    @Input() isOverPromotionLimit: boolean;
    @Input() pricePresentationSettings: QuotePricePresentationPackageViewSetting[] = [];
    @Output() proceedClicked: EventEmitter<void> = new EventEmitter<void>();
    readonly approvalRequestStatuses = ApprovalRequestStatus;
    proceedButtonSetting: Dictionary;

    constructor(
        private dictionaryService: DictionaryService,
        private modalController: ModalController,
        private notificationService: NotificationService
    ) {}

    async ngOnInit(): Promise<void> {
        this.proceedButtonSetting = await this.dictionaryService.getDictionaryName(DictionaryType.ProceedButton);
    }

    get existingNotification(): ApprovalRequest {
        return this.notificationService.existingNotification(String(this.quote.quote_id));
    }

    async openRequestApplicationModal(): Promise<any> {
        const modal = await this.modalController.create({
            component: RequestFinancingApplicationModalComponent,
            cssClass: 'request-application-modal',
            backdropDismiss: false,
            componentProps: {
                quoteId: this.quote.quote_id,
                offerCodeName: this.selectedOfferCode.offerCodeName
            }
        });

        await modal.present();

        await modal.onDidDismiss();
    }

    async openPricingDetails(): Promise<void> {
        const modal = await this.modalController.create({
            component: PricingDetailsComponent,
            cssClass: 'pricing-details-modal',
            componentProps: {
                quote: this.quote
            }
        });

        await modal.present();
    }

    async requestApproval(): Promise<void> {
        const modal = await this.modalController.create({
            component: RequestApprovalModalComponent,
            cssClass: 'request-approval-modal',
            componentProps: {
                promotions: this.selectedPromotions,
                quote: this.quote,
                pricePresentationSettings: this.pricePresentationSettings
            }
        });

        await modal.present();
    }
}
