import { Injectable } from '@angular/core';

import some from 'lodash/some';

import { QuestionType } from '@shared/enums/question-type.enum';
import { OriginalQuestion } from '@shared/interfaces/question';

@Injectable({
    providedIn: 'root'
})
export class ConfigureHelperService {
    constructor() {}

    setQuestionType(question: OriginalQuestion): void {
        if (question.QuestionType === 'FreeEntry') {
            const isTextarea: boolean = some(
                question.ExtraFields,
                (field) => field.Key === 'VendoAnswerStyle' && field.Value === 'Textarea'
            );

            question.questionType = isTextarea ? QuestionType.Textarea : QuestionType.Input;
        } else if (
            some(
                question.ExtraFields,
                (field) =>
                    field.Key === 'WebCPAnswerStyle' &&
                    ['TextOnlyRadioButtons', 'TextOnlyRadioButtonsVertical'].includes(field.Value)
            )
        ) {
            question.questionType = QuestionType.RadioButtons;
        } else if (
            some(
                question.ExtraFields,
                (field) => field.Key === 'WebCPAnswerStyle' && field.Value === 'TextOnlyDropDown'
            )
        ) {
            question.questionType = QuestionType.Dropdown;
        } else {
            question.questionType = QuestionType.MultiChoice;
        }
    }
}
