import { Component, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'vendo-selection-popover',
    templateUrl: './selection-popover.component.html',
    styleUrls: ['./selection-popover.component.scss']
})
export class SelectionPopoverComponent {
    @Input() items = [];
    title = 'Please select the import source';

    constructor(private popoverController: PopoverController) {}

    async selectItem(result): Promise<void> {
        await this.popoverController.dismiss(result);
    }
}
