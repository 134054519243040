import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SAVE_CONFIGURATION_GROUP } from '@core/mutations/configuration-groups.mutations';
import {
    GET_CONFIGURATION_GROUPS,
    GET_GROUP_OPENINGS,
    GET_NO_CONFIGURED_OPENINGS
} from '@core/queries/configuration-groups.queries';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationGroupsService {
    constructor(private apollo: Apollo) {}

    getGroups(appointmentId: number, catalogId?: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_CONFIGURATION_GROUPS,
                variables: {
                    appointmentId,
                    catalogId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.openingGroups));
    }

    getGroupOpenings(opening_id: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_GROUP_OPENINGS,
                variables: {
                    opening_id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.opening));
    }

    getNotConfiguredOpenings(appointment_id: number, catalog_id: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_NO_CONFIGURED_OPENINGS,
                variables: {
                    appointment_id,
                    catalog_id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.notConfiguredOpenings));
    }

    saveGroup(name: string, opening_ids: string[], group_id?: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_CONFIGURATION_GROUP,
                variables: {
                    name,
                    opening_ids,
                    group_id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.saveOpeningGroup));
    }
}
