<div class="warnings"
     *ngIf="configuratorData.LimitationWarningsCount || configuratorData.LimitationErrorsCount">
    <div class="warnings-header">
        <ion-icon name="warning"></ion-icon>
        <ion-label>
            Warning
            <ng-container *ngIf="configuratorData.Limitations.length > 1">
                ({{ activeWarningSlideIndex + 1 }} of {{ configuratorData.Limitations.length }})
            </ng-container>
        </ion-label>
    </div>

    <div class="warning-slides" [ngClass]="{'p-10': configuratorData.Limitations.length === 1}">
        <ion-button class="icon-only"
                    size="small"
                    shape="round"
                    fill="outline"
                    *ngIf="configuratorData.Limitations.length > 1"
                    [disabled]="activeWarningSlideIndex === 0"
                    (click)="warningSlides.slidePrev()"
                    expand="full">
            <ion-icon name="chevron-back"></ion-icon>
        </ion-button>

        <ion-slides #warningSlides
                    pager="true"
                    [options]="{speed: 400}"
                    (ionSlideDidChange)="warningSlideChanged()">
            <ion-slide *ngFor="let limitation of configuratorData.Limitations"
                       class="ion-align-self-center warning-button"
                       (click)="warmingClicked.emit(limitation)">
                <ion-text>{{ limitation.Message }}</ion-text>
            </ion-slide>
        </ion-slides>

        <ion-button class="icon-only"
                    size="small"
                    shape="round"
                    fill="outline"
                    *ngIf="configuratorData.Limitations.length > 1"
                    [disabled]="activeWarningSlideIndex === (configuratorData.Limitations.length - 1)"
                    (click)="warningSlides.slideNext()"
                    expand="full">
            <ion-icon name="chevron-forward"></ion-icon>
        </ion-button>
    </div>
</div>
