import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SIGN_E_DOCUMENT } from '../mutations/e-document.queries';
import { GET_DOCUMENT, GET_DOCUMENT_PDF } from '../queries/e-document.queries';
import { CustomerDocumentDownload } from '@shared/interfaces/document';

@Injectable({
    providedIn: 'root'
})
export class EDocumentService {
    constructor(private apollo: Apollo) {}

    getDocument(hash: string, authToken?: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_DOCUMENT,
                variables: {
                    hash,
                    auth_token: authToken
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerESignatureDocument));
    }

    getDocumentPdf(hash: string, authToken?: string): Observable<CustomerDocumentDownload> {
        return this.apollo
            .query({
                query: GET_DOCUMENT_PDF,
                variables: {
                    hash,
                    auth_token: authToken
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerESignatureDocumentDownload));
    }

    signDocument(
        hash: string,
        signature: string,
        initials: string,
        appLink: string,
        updated_at?: string
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SIGN_E_DOCUMENT,
                variables: {
                    hash,
                    customer_signature: signature,
                    customer_initials: initials,
                    application_url: appLink,
                    updated_at
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.customerSignDocument));
    }
}
