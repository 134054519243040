import { UntypedFormGroup } from '@angular/forms';

export function ValidateMustMatch(attribute: any, compareAttribute: any) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[attribute.name];
        const matchingControl = formGroup.controls[compareAttribute.name];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({
                mustMatch: {
                    attribute: attribute.label,
                    compareAttribute: compareAttribute.label
                }
            });
        } else {
            matchingControl.setErrors(null);
        }
    };
}
