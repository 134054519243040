import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ModalController, NavController } from '@ionic/angular';

import { PromotionsSummaryModalComponent } from '@shared/components';
import { paradigmFinanceOfferId } from '@shared/constants/paradigm-finance-offer-id';
import { QuotePricePresentationFieldType } from '@shared/enums/quote-price-presentation-field-type.enum';
import { FinancingOption } from '@shared/interfaces/financing-option';
import { Quote } from '@shared/interfaces/quote';
import { QuotePricePresentationPackageViewSetting } from '@shared/interfaces/quote-price-presentation-package-view-setting';

@Component({
    selector: 'vendo-quote-summary',
    templateUrl: './quote-summary.component.html',
    styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit, OnChanges {
    @Input() appointmentId: number;
    @Input() quote: Quote;
    @Input() energyDetailCategories: any = [];
    @Input() isChangeOrderEnabled: boolean;
    @Input() pricePresentationSettings: QuotePricePresentationPackageViewSetting[] = [];
    @Input() selectedPromotionIds: string[] = [];
    @Input() selectedFinancingOption: FinancingOption;
    @Input() monthlyPayment: number;
    @Input() balance: number;
    @Input() totalPromotion: number;
    isDisplayPromosInModal = true;
    isHideFinancingOptions = false;
    readonly paradigmFinanceOfferId = paradigmFinanceOfferId;
    readonly quotePricePresentationFieldTypes = QuotePricePresentationFieldType;

    constructor(
        private modalController: ModalController,
        private navCtrl: NavController,
        private route: ActivatedRoute
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quote?.currentValue) {
            this.setEnergyDetails();
        }
    }

    ngOnInit(): void {
        this.pricePresentationSettings.forEach((setting: QuotePricePresentationPackageViewSetting) => {
            switch (setting.hash) {
                case QuotePricePresentationFieldType.Balance:
                    this.isHideFinancingOptions = !setting.fields.is_hide_with_financing;
                    break;
                case QuotePricePresentationFieldType.DiscountAmount:
                    this.isDisplayPromosInModal = setting.fields.display_promos_in_modal;
                    break;
            }
        });
    }

    navigateToEnergyDetailsPage(): void {
        if (!this.energyDetailCategories.length || !this.energyDetailCategories[0]) {
            return;
        }

        this.navCtrl.navigateRoot(
            `/main/appointments/${this.appointmentId}/quote/${this.quote.quote_id}/energy-details/${this.energyDetailCategories[0]}`
        );
    }

    async openPromotionsSummary(totalPromotionLabel: string): Promise<void> {
        if (!this.isDisplayPromosInModal) {
            return;
        }

        const modal: HTMLIonModalElement = await this.modalController.create({
            component: PromotionsSummaryModalComponent,
            cssClass: 'promotions-summary-modal',
            componentProps: {
                discounts: this.quote.discount_details,
                totalPromotionLabel,
                totalPromotion: this.totalPromotion
            }
        });

        await modal.present();
    }

    setEnergyDetails(): void {
        /**
         * Example:
         * priceConditioning = {"categories": [
         *      {
         *          "id": 7,
         *          "category_type": "windows"
         *      }
         *  ]}
         */
        const priceConditioning = this.route.snapshot.data.priceConditioning;
        const energyDetailCategories = [];

        if (!priceConditioning) {
            return;
        }

        if (
            !this.quote ||
            !this.quote.line_items ||
            !this.quote.line_items.length ||
            !priceConditioning ||
            !priceConditioning.categories ||
            !priceConditioning.categories.length
        ) {
            this.energyDetailCategories = energyDetailCategories;

            return;
        }

        const lineItemCategoryInfo = {};

        this.quote.line_items.forEach((lineItem) => {
            if (lineItem.excluded) {
                return;
            }

            if (!Object.keys(lineItemCategoryInfo).includes(`${lineItem.category_id}`)) {
                lineItemCategoryInfo[lineItem.category_id] = 0;
            }

            lineItemCategoryInfo[lineItem.category_id] +=
                lineItem.final_price + lineItem.adders_final_price + lineItem.accessories_final_price;
        });

        Object.entries(lineItemCategoryInfo).forEach(([categoryId, finalPrice]: [string, number]) => {
            if (!finalPrice) {
                return;
            }

            const matchedPriceConditioning = priceConditioning.categories.find(
                (category) => `${category.id}` === categoryId
            );

            if (!matchedPriceConditioning) {
                return;
            }

            energyDetailCategories.push(matchedPriceConditioning.category_type);
        });

        this.energyDetailCategories = energyDetailCategories;
    }
}
