import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { FormHelperService } from '@core/services/form-helper.service';
import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-set-monthly-investment-modal',
    templateUrl: './set-monthly-investment-modal.component.html',
    styleUrls: ['./set-monthly-investment-modal.component.scss']
})
export class SetMonthlyInvestmentModalComponent extends BaseModal implements OnInit {
    @Input() minValue: number;
    @Input() maxValue: number;
    control: UntypedFormControl;

    constructor(
        modalController: ModalController,
        private formBuilder: UntypedFormBuilder,
        private formHelperService: FormHelperService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.control = this.formBuilder.control(null, [
            Validators.required,
            Validators.min(this.minValue),
            Validators.max(this.maxValue)
        ]);
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    apply(): void {
        if (this.control.invalid) {
            return;
        }
        this.dismiss(this.control.value);
    }
}
