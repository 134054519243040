import { gql } from 'apollo-angular';

const SEND_QUICK_INVOICE = gql`
    mutation SendQuickInvoice(
        $appointmentId: ID!
        $email: String!
        $amount: Float!
        $title: String!
        $products_and_services: String!
    ) {
        sendMyQuickInvoice(
            appointment_id: $appointmentId
            email: $email
            amount: $amount
            title: $title
            products_and_services: $products_and_services
        ) {
            id
            title
            email
            due_date
            payment_status
        }
    }
`;

const PROCESS_CHECK = gql`
    mutation ProcessCheck($quoteId: ID!, $front: String!, $back: String!) {
        processCheck(quote_id: $quoteId, image_front: $front, image_back: $back)
    }
`;

export { SEND_QUICK_INVOICE, PROCESS_CHECK };
