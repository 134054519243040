import { Component, Input, OnInit } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';

import { ProviaService } from '@core/services/provia.service';
import { ToastService } from '@core/services/toast.service';
import { BaseModal } from '@shared/components/base-modal';
import { MultiSelectPopoverComponent } from '@shared/components/multiselect-popover/multiselect-popover.component';
import { ImportOrderData, UserData } from '@shared/components/provia-order-modal/provia-order-modal.interfaces';

@Component({
    selector: 'vendo-provia-order-modal',
    templateUrl: './provia-order-modal.component.html',
    styleUrls: ['./provia-order-modal.component.scss']
})
export class ProviaOrderModalComponent extends BaseModal implements OnInit {
    @Input() quotes: any[];
    @Input() packageNames: string[];
    @Input() rememberData: any;
    @Input() appointmentId: number;
    @Input() usePortalLogin: boolean;

    orders: any[];
    userData: UserData;

    constructor(
        public modalCtrl: ModalController,
        private proviaService: ProviaService,
        private popoverController: PopoverController,
        private toastService: ToastService
    ) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        if (this.rememberData?.user_name) {
            this.userData = {
                used_remember: true,
                remember: true,
                user_name: this.rememberData.user_name,
                password: this.rememberData.user_name // prefill password by user name
            };
        }
    }

    getOrders(data: UserData): void {
        this.userData = data;

        this.proviaService.getOrders(data).subscribe((res) => {
            if (!res?.length) {
                this.toastService.showMessage('No orders found');

                return;
            }
            this.orders = res;
        });
    }

    async importOrder(data: ImportOrderData): Promise<any> {
        if (data.userData) {
            this.userData = data.userData;
        }

        let selectedQuoteIds;

        if (this.quotes?.length > 1) {
            selectedQuoteIds = await this.showPickPackagePopover(data.event);

            if (!selectedQuoteIds?.length) {
                return;
            }
        } else {
            selectedQuoteIds = [this.quotes[0].partial_quote_id];
        }

        this.proviaService
            .importOrder(this.userData, selectedQuoteIds, this.appointmentId, data.orderNumber)
            .subscribe(() => {
                this.dismiss(true);
            });
    }

    private async showPickPackagePopover(event: Event): Promise<any> {
        const popover = await this.popoverController.create({
            component: MultiSelectPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass: `multi-select-popover ${this.quotes.length > 3 ? 'wider' : ''}`,
            componentProps: {
                items: this.quotes,
                names: this.packageNames,
                applyButtonText: 'Apply'
            }
        });

        await popover.present();

        const { data } = await popover.onDidDismiss();

        return data;
    }
}
