import { gql } from 'apollo-angular';

const CREATE_QUOTE = gql`
    mutation CreateQuote1($appointmentId: ID!, $presentationType: String!, $geolocation: JSON, $expectedCount: Int) {
        createQuote(
            appointment_id: $appointmentId
            presentation_type: $presentationType
            geolocation: $geolocation
            expected_count: $expectedCount
        ) {
            id
            name
            temporary
            quantity
            is_group
            group_id
            category {
                name
                category_type
                catalog_type
                id
                allow_configure_questions
            }
            opening_details {
                question_id
                answer
                question {
                    title
                    show_if
                    hash
                    column_width
                }
            }
            opening_configurations {
                wcp_config_id
                wcp_item_id
                opening_id
                parent_quote_id
                wcp_quote_id
                quote_id
                status
                summary_details {
                    product_name
                    details {
                        question_id
                        answer
                    }
                }
            }
        }
    }
`;
const CREATE_OPENING_QUOTE = gql`
    mutation CreateOpeningQuote($appointmentId: ID!, $catalogId: ID!, $clientId: String, $geolocation: JSON) {
        openingQuote(
            appointment_id: $appointmentId
            catalog_id: $catalogId
            client_id: $clientId
            geolocation: $geolocation
        )
    }
`;
const CREATE_WCP_QUOTE = gql`
    mutation CreateWcpQuote($quote_id: ID!, $client_id: String!) {
        createWcpQuote(quote_id: $quote_id, client_id: $client_id)
    }
`;
const DELETE_QUOTE = gql`
    mutation DeleteQuote($appointment_id: ID!, $quote_id: ID!) {
        deleteQuote(appointment_id: $appointment_id, quote_id: $quote_id) {
            appointment_id
        }
    }
`;
const EXCLUDE_LINE_ITEM = gql`
    mutation ExcludeLineItem($quoteId: ID!, $openingId: ID!, $isExcluded: Boolean!, $returnPrices: Boolean) {
        excludeQuoteOpening(
            quote_id: $quoteId
            opening_id: $openingId
            exclude: $isExcluded
            return_prices: $returnPrices
        )
    }
`;

const ORDER_QUOTE = gql`
    mutation OrderQuote1($quoteId: ID!) {
        order(quote_id: $quoteId)
    }
`;

const MAKE_PAYMENT = gql`
    mutation MakePayment($quote_id: ID!, $payment_data: JSON!) {
        payment(quote_id: $quote_id, payment_data: $payment_data)
    }
`;

const SYNC_PAYMENT = gql`
    mutation SyncPaymentMutation($quote_id: ID!, $payment_id: ID!) {
        syncPayment(quote_id: $quote_id, payment_id: $payment_id)
    }
`;

const SET_FINANCING_OPTION = gql`
    mutation FinancingOptionMutation($quote_id: ID!, $financing_option_id: ID) {
        financingOption(quote_id: $quote_id, financing_option_id: $financing_option_id)
    }
`;

const SAVE_DEPOSIT = gql`
    mutation saveDeposit($quote_id: ID!, $deposit_type: DepositTypeEnum, $deposit_amount: Float) {
        saveDeposit(quote_id: $quote_id, deposit_type: $deposit_type, deposit_amount: $deposit_amount)
    }
`;

const COPY_PRICE_ATTRIBUTES = gql`
    mutation copyPriceAttributes($appointment_id: ID!, $quote_id: ID!, $price_attributes: [PackagePriceAttributes!]!) {
        copyPriceAttributes(appointment_id: $appointment_id, quote_id: $quote_id, price_attributes: $price_attributes)
    }
`;

const MOVE_LINE_ITEM = gql`
    mutation moveLineItem($quote_id: ID!, $origin_index: Int!, $target_index: Int!) {
        moveLineItem(quote_id: $quote_id, origin_index: $origin_index, target_index: $target_index)
    }
`;

const SAVE_PRICING_CARDS = gql`
    mutation SavePricingCards(
        $appointmentId: ID!
        $quoteId: ID!
        $discountIds: [ID]
        $financingOptionId: ID
        $projectDescription: String
    ) {
        savePriceCards(
            appointment_id: $appointmentId
            quote_id: $quoteId
            discount_ids: $discountIds
            financing_option_id: $financingOptionId
        )
        projectDescription(id: $appointmentId, project_description: $projectDescription)
    }
`;

const REQUEST_FINANCING_APPLICATION = gql`
    mutation RequestFinancingApplication($quoteId: ID!, $consumer: JSON!, $financingType: String!) {
        financingApplication(quote_id: $quoteId, consumer: $consumer, financing_type: $financingType)
    }
`;

export {
    CREATE_QUOTE,
    ORDER_QUOTE,
    MAKE_PAYMENT,
    EXCLUDE_LINE_ITEM,
    CREATE_OPENING_QUOTE,
    SET_FINANCING_OPTION,
    SYNC_PAYMENT,
    SAVE_DEPOSIT,
    COPY_PRICE_ATTRIBUTES,
    MOVE_LINE_ITEM,
    CREATE_WCP_QUOTE,
    SAVE_PRICING_CARDS,
    REQUEST_FINANCING_APPLICATION,
    DELETE_QUOTE
};
