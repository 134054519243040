import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production || environment.stage) {
  enableProdMode();
}
/* eslint-disable no-console */
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
