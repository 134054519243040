<div class="modal-header">
    <ion-text class="title">
        {{ configurator?.opening_configurations?.wcp_config_details?.WindowsetName || configurator?.WindowsetName }} {{ configureTitle }}
    </ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <vendo-tabs [tabs]="tabs"
                [activeTabId]="activeTab?.hash"
                (tabSelected)="setActiveTab($event)"></vendo-tabs>
    <div class="drawing-wrap" *ngIf="activeTab.hash === 'drawing'">
        <div class="drawing">
            <ion-img class="drawing-image"
                     [src]="configurator.url ? ('https://' + configurator.url + '/config' + currentDrawing.image.url) : currentDrawing.image.url"
                     *ngIf="currentDrawing && currentDrawing.image?.url && currentDrawing.DrawingID !== 'view'"></ion-img>
            <vendo-masked-image class="mask-adjust" *ngIf="currentDrawing.DrawingID === 'view'"
                                [openings]="[openingData]"
                                [imageUrl]="openingData.view_photo?.img_url"
                                [disableEdit]="true"
                                [activeOpening]="openingData"></vendo-masked-image>
        </div>

        <div class="custom-segment">
            <ion-label *ngFor="let drawing of availableDrawings"
                       class="ion-text-center" [ngClass]="{'active-segment': drawing.DrawingID === currentDrawing.DrawingID}"
                       (click)="setActiveDrawing(drawing)">
                {{ drawing.DisplayName }}
            </ion-label>
        </div>
    </div>

    <div class="answers" *ngIf="activeTab.hash === 'details' && activeDetailsStep">
        <ion-row class="answers__title ion-align-items-center">
            {{ activeDetailsStep.stepName }}
            <ion-button fill="outline" color="primary" class="icon-only" (click)="nextDetailsStep()">
                <ion-icon name="chevron-forward" color="primary"></ion-icon>
            </ion-button>
        </ion-row>

        <ion-row class="answers__details">
            <ion-grid class="options">
                <ion-row *ngFor="let item of activeDetailsStep.answers">
                    <ion-col size="6">
                        {{ item.question }}
                    </ion-col>
                    <ion-col size="6">
                        <span class="value">{{ item.answer }}</span>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-row>
    </div>
</div>
