<div class="modal-header">
  <ion-text class="title">{{openingData?.Name}} Details</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <vendo-tabs [tabs]="tabs"
             [activeTabId]="activeTab?.hash"
             (tabSelected)="setActiveTab($event)"></vendo-tabs>

  <div class="drawing-wrap" *ngIf="activeTab?.hash === 'photos'">
    <div class="drawing">
      <ion-content>
        <ion-slides #sliderRef
                    *ngIf="countImages"
                    [pager]="true"
                    [options]="{initialSlide: activeSlideIndex ? (this.activeSlideIndex - 1) : 0}"
                    (ionSlidesDidLoad)="refreshSlides()">
          <ion-slide *ngFor="let image of openingData.images">
            <img class="drawing-image" [src]="image.url">
          </ion-slide>
        </ion-slides>
      </ion-content>
    </div>
  </div>

  <div class="answers" *ngIf="activeTab?.hash === 'details'">
    <ion-row class="answers__title ion-align-items-center">
      {{ takeoffDetailsTitle }}
    </ion-row>

    <ion-row class="answers__details">
      <ion-grid class="options">
        <ion-row *ngFor="let item of openingData.opening_details">
          <ion-col size="6">
            {{item.question.title}}
          </ion-col>
          <ion-col size="6">
            <span class="value">{{item.answer}}</span>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-row>

    <ion-row class="answers__notes">
      <ion-chip *ngFor="let note of openingData.notes">
        <ion-label>{{note}}</ion-label>
      </ion-chip>
    </ion-row>
  </div>
</div>
