import { gql } from 'apollo-angular';

const GET_CONFIGURATION_GROUPS = gql`
    query OpeningGroups($appointmentId: ID!, $catalogId: ID) {
        openingGroups(appointment_id: $appointmentId, catalog_id: $catalogId) {
            id
            name
        }
    }
`;

const GET_GROUP_OPENINGS = gql`
    query Opening($opening_id: ID!) {
        opening(opening_id: $opening_id) {
            group_openings {
                id
                name
                group_opening_details {
                    question {
                        hash
                    }
                    question_id
                    answer
                }
            }
        }
    }
`;

const GET_NO_CONFIGURED_OPENINGS = gql`
    query NotConfiguredOpenings($appointment_id: ID!, $catalog_id: ID) {
        notConfiguredOpenings(appointment_id: $appointment_id, catalog_id: $catalog_id) {
            id
            name
            quantity
            group_id
            is_group
            opening_details {
                question_id
                answer
            }
        }
    }
`;

export { GET_CONFIGURATION_GROUPS, GET_NO_CONFIGURED_OPENINGS, GET_GROUP_OPENINGS };
