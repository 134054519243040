import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-clickable-image',
    templateUrl: './clickable-image.component.html',
    styleUrls: ['./clickable-image.component.scss']
})
export class ClickableImageComponent {
    @Input() imgSrc;
    @Input() areas: any[] = [];

    @Output() areaClicked = new EventEmitter<any>();

    constructor() {}

    selectArea(area: any): void {
        this.areaClicked.emit(area);
    }
}
