import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export function ValidateMatchPattern(pattern: any, msg: string): ValidatorFn {
    return (control: UntypedFormControl) => {
        if (control.value && !`${control.value}`.match(pattern)) {
            return {
                invalidMsg: msg,
                matchPattern: {
                    msg
                }
            };
        }

        return null;
    };
}
