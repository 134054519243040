import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import get from 'lodash/get';

import {
    CHANGE_CUSTOM_TAX,
    REOPEN_APPOINTMENT,
    SAVE_APPOINTMENT,
    SAVE_APPOINTMENT_DOCUMENT_DETAILS,
    PULL_QUOTES,
    START_APPOINTMENT,
    OPEN_APPOINTMENT_DEEPLINK,
    UPLOAD_IMAGE,
    SAVE_APPOINTMENT_IMAGES
} from '../mutations/appointment.mutations';
import {
    GET_APPOINTMENT,
    GET_APPOINTMENT_CUSTOM_TAX,
    GET_APPOINTMENT_DOCUMENT,
    GET_APPOINTMENT_MAIL_CONFIGURATION,
    GET_APPOINTMENT_PRODUCTS_INTERESTS,
    GET_APPOINTMENT_REMAINING_BALANCE,
    GET_APPOINTMENT_RESULTS,
    GET_APPOINTMENT_REVIEW,
    GET_APPOINTMENTS_LIST,
    GET_APPOINTMENTS_SEARCH,
    GET_PHOTOS_GLOSSERY,
    GET_PROJECT_DESCRIPTION,
    GET_PULL_QUOTES_APPOINTMENTS,
    GET_PULL_QUOTES_MIGRATIONS,
    GET_APPOINTMENT_MAIL,
    GET_APPOINTMENT_FOR_EXTERNAL_RESOURCE,
    GET_APPOINTMENT_REQUIRED_IMAGES,
    GET_APPOINTMENT_IMAGES,
    GET_APPOINTMENT_ORDER_REQUIREMENTS
} from '../queries/appointments.query';
import { GET_VIEW_PHOTOS_AND_OPENINGS } from '../takeoff/view-takeoff/queries/view-takeoff.queries';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { OfflineSyncService } from '@core/services/offline/offline-sync.service';
import { OfflineService } from '@core/services/offline/offline.service';
import { AppointmentImageType } from '@shared/enums/appointment-image-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { DeeplinkEntity } from '@shared/enums/deeplink-entity';
import { DocumentType } from '@shared/enums/document-type';
import { MailType } from '@shared/enums/mail-type.enum';
import { MeasurementSystemType } from '@shared/enums/measurement-system-type';
import { OrderDirection } from '@shared/enums/order-direction.enum';
import { Appointment, AppointmentMailSettings } from '@shared/interfaces/appointment';
import { AppointmentImage } from '@shared/interfaces/appointment-image';
import { AppointmentOrderRequirements } from '@shared/interfaces/appointment-order-requirements';
import { AppointmentPossibleResults } from '@shared/interfaces/appointment-possible-results';
import { AppointmentRequiredImages } from '@shared/interfaces/appointment-required-images';
import { PaginationItems } from '@shared/interfaces/pagination-items';
import { PhotoGlossary } from '@shared/interfaces/photo-glossery';
import { SaveAppointmentImages } from '@shared/interfaces/save-appointment-images';

import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppointmentsService {
    private isSynchronizing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isSynchronizing$: Observable<boolean> = this.isSynchronizing.asObservable();
    private isDesktop = (window.location.host && window.location.host.includes('paradigmvendo')) || environment.local;

    constructor(
        private apollo: Apollo,
        private deviceHelperService: DeviceHelperService,
        private offlineService: OfflineService,
        private offlineSyncService: OfflineSyncService
    ) {}

    async getAppointments(date?: string): Promise<Appointment[]> {
        if (!this.isDesktop && !this.deviceHelperService.isPhone) {
            this.setIsSynchronizing(true);

            const isExistUpdatedData: boolean = await this.offlineService.uploadOfflineChanges();

            if (isExistUpdatedData) {
                await this.offlineSyncService.syncAppointments(false).then(() => this.setIsSynchronizing(false));
            } else {
                this.offlineSyncService.syncAppointments().then(() => this.setIsSynchronizing(false));
            }
        }

        return this.apollo
            .query({
                query: GET_APPOINTMENTS_LIST,
                variables: {
                    date
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointments))
            .toPromise();
    }

    searchAppointments(params: any = {}): Observable<PaginationItems<Appointment>> {
        params.offset = params.offset * params.limit;
        params.orderDirection = OrderDirection.Desc;

        return this.apollo
            .query({
                query: GET_APPOINTMENTS_SEARCH,
                variables: {
                    ...params
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myAppointments));
    }

    getAppointment(id: string | number, silentRequest = false): Observable<Appointment> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT,
                variables: {
                    id
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointment as Appointment));
    }

    getAppointmentCustomTax(id: number | string, silentRequest = false): Observable<Appointment> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_CUSTOM_TAX,
                variables: {
                    id
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointment as Appointment));
    }

    getAppointmentReviewInfo(id: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_REVIEW,
                variables: {
                    id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentReview));
    }

    getAppointmentRemainingBalance(id: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_REMAINING_BALANCE,
                variables: {
                    id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myAppointmentRemainingBalance));
    }

    getAppointmentPossibleResults(appointmentId: string | number): Observable<AppointmentPossibleResults> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_RESULTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) =>
                    get(res, 'data.appointmentResults', { catalogs: [], opportunities_results: [], results: [] })
                )
            );
    }

    getAppointmentDocument(appointmentId: string, documentType: DocumentType): Observable<any> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_DOCUMENT,
                variables: {
                    appointmentId,
                    documentType
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myAppointmentDocument));
    }

    startAppointment(appointmentId: number): Observable<Appointment> {
        return this.apollo
            .mutate({
                mutation: START_APPOINTMENT,
                variables: {
                    appointment_id: appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.startAppointment as Appointment));
    }

    reopenAppointment(appointmentId: number): Observable<Appointment> {
        return this.apollo
            .mutate({
                mutation: REOPEN_APPOINTMENT,
                variables: {
                    appointment_id: appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.reopenAppointment as Appointment));
    }

    changeCustomTax(appointmentId: number, customTax: number): Observable<any> {
        return this.apollo.mutate({
            mutation: CHANGE_CUSTOM_TAX,
            variables: {
                id: appointmentId,
                customTax
            }
        });
    }

    saveAppointment(
        scheduleTime: string,
        scheduledTo: string,
        customer: any,
        secondCustomer: any,
        house: any,
        secondHouse: any,
        notes: string,
        productsInterests: string[],
        appointmentId?: number
    ): Observable<any> {
        if (house && house.additional_info) {
            delete house.additional_info;
        }

        return this.apollo
            .mutate({
                mutation: SAVE_APPOINTMENT,
                variables: {
                    appointmentId,
                    schedule_time: scheduleTime,
                    scheduled_to: scheduledTo,
                    customer,
                    second_customer: secondCustomer,
                    house,
                    second_house: secondHouse,
                    notes,
                    products_interests: productsInterests
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.mobileCreateAppointment));
    }

    getAppointmentMail(
        appointmentId: number,
        mailType: MailType,
        isSkipPartOfData = false,
        customerId?: number
    ): Observable<AppointmentMailSettings> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_MAIL,
                variables: {
                    appointmentId,
                    mailType,
                    isSkipPartOfData,
                    customerId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => get(res, 'data.appointmentMail', '')));
    }

    getAppointmentMailConfiguration(appointmentId: number, mailType: MailType, customerId?: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_MAIL_CONFIGURATION,
                variables: {
                    appointmentId,
                    mailType,
                    customerId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data));
    }

    getProductsInterests(): Observable<string[]> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_PRODUCTS_INTERESTS
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentProductsInterests));
    }

    getProjectDescription(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_PROJECT_DESCRIPTION,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => get(res, 'data.projectDescription', '')));
    }

    getPhotoGlossary(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_PHOTOS_GLOSSERY,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => get(res, 'data.photosGlossary', '')));
    }

    saveAppointmentDocumentDetails({
        id,
        photos_glossary,
        project_description
    }: {
        id: number;
        photos_glossary?: PhotoGlossary;
        project_description?: string;
    }): Observable<any> {
        return this.apollo.mutate({
            mutation: SAVE_APPOINTMENT_DOCUMENT_DETAILS,
            variables: {
                id,
                photos_glossary,
                project_description
            }
        });
    }

    getPullQuotesAppointments(appointmentId: string, appointmentType: AppointmentType): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_PULL_QUOTES_APPOINTMENTS,
                variables: {
                    appointmentId,
                    appointmentType
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.pullQuotesAppointments));
    }

    pullQuotesAppointments(
        appointmentId: string,
        appointmentType: AppointmentType,
        quoteIds: string[],
        silentRequest = false
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: PULL_QUOTES,
                variables: {
                    appointmentId,
                    appointmentType,
                    quoteIds
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.pullQuotes));
    }

    getChangeOrderQuoteMigrations(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_PULL_QUOTES_MIGRATIONS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.pullQuotesMigrations));
    }

    openAppointmentDeeplink(id: number, hash: DeeplinkEntity): Observable<any> {
        return this.apollo
            .query({
                query: OPEN_APPOINTMENT_DEEPLINK,
                variables: {
                    id,
                    hash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.openAppointmentDeeplink));
    }

    getAppointmentForExternalResource(id: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_FOR_EXTERNAL_RESOURCE,
                variables: {
                    id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentForExternalResource));
    }

    setIsSynchronizing(value: boolean): void {
        this.isSynchronizing.next(value);
    }

    uploadImage(file: File): Observable<string> {
        return this.apollo
            .mutate({
                mutation: UPLOAD_IMAGE,
                variables: {
                    file
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.uploadImage));
    }

    getAppointmentRequiredImages(
        appointment_id: number,
        opening_id?: number,
        check_only_configured_openings?: boolean
    ): Observable<AppointmentRequiredImages> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_REQUIRED_IMAGES,
                variables: {
                    appointment_id,
                    opening_id,
                    check_only_configured_openings
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentRequiredImages));
    }

    getAppointmentOrderRequirements(id: number, isSkipFailedConfigurations): Observable<AppointmentOrderRequirements> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_ORDER_REQUIREMENTS,
                variables: {
                    id,
                    isSkipFailedConfigurations
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentOrderRequirements));
    }

    getAppointmentImages(
        appointment_id: number,
        opening_id: number,
        types: AppointmentImageType[] | MeasurementSystemType[],
        appointment_type?: AppointmentType
    ): Observable<AppointmentImage[]> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_IMAGES,
                variables: {
                    appointment_id,
                    opening_id,
                    types,
                    appointment_type
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentImages));
    }

    getOpeningsList(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_VIEW_PHOTOS_AND_OPENINGS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentOpenings));
    }

    saveAppointmentImages(
        appointment_id: number,
        type: AppointmentImageType,
        images_data: any[]
    ): Observable<SaveAppointmentImages> {
        return this.apollo
            .mutate({
                mutation: SAVE_APPOINTMENT_IMAGES,
                variables: {
                    appointment_id,
                    type,
                    images_data
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.saveAppointmentImages));
    }
}
