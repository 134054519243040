import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment } from '@angular/router';

import { Storage } from '@ionic/storage';

import { ApplicationRole } from '@shared/enums/application-role.enum';

@Injectable({ providedIn: 'root' })
export class CapturePaymentGuard implements CanLoad, CanActivate {
    constructor(private storage: Storage) {}

    canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        return this.storage.get('user').then((user) => {
            const parsedUser = JSON.parse(user);

            return (
                parsedUser &&
                [ApplicationRole.Standard, ApplicationRole.CapturePayment].includes(parsedUser.application_role_hash)
            );
        });
    }

    canActivate(): Promise<boolean> {
        return this.storage.get('user').then((user) => {
            const parsedUser = JSON.parse(user);

            return (
                parsedUser &&
                [ApplicationRole.Standard, ApplicationRole.CapturePayment].includes(parsedUser.application_role_hash)
            );
        });
    }
}
