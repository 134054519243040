import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { SubscriptionsService } from '@core/services/websocket/subscriptions.service';
import { DISCOUNT_NOTIFICATIONS } from '@shared/constants/discount-notifications';
import { TRANSACTION_NOTIFICATIONS } from '@shared/constants/transaction-notifications';
import { ApprovalRequestStatus } from '@shared/enums/approval-request-status';
import { NotificationType } from '@shared/enums/notification-type';
import { TransactionStatus } from '@shared/enums/transaction-status.enum';
import { ApprovalRequest } from '@shared/interfaces/approval-request';
import { Notification } from '@shared/interfaces/notification';
import { WebsocketSubscriptions } from '@shared/interfaces/websocket-subscriptions';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private transactionStatusNotifications: Subject<Notification> = new Subject();
    transactionStatusNotifications$: Observable<Notification> = this.transactionStatusNotifications.asObservable();
    private requestApprovalNotifications: Subject<Notification> = new Subject();
    requestApprovalNotifications$: Observable<Notification> = this.requestApprovalNotifications.asObservable();
    private autoCloseNotification: Subject<string> = new Subject();
    autoCloseNotification$: Observable<string> = this.autoCloseNotification.asObservable();
    private subscriptions: WebsocketSubscriptions = {
        [NotificationType.approvalRequest]: {},
        [NotificationType.transactionStatus]: {}
    };
    private approvalRequests = {};

    constructor(private subscriptionsService: SubscriptionsService) {}

    startSubscription(data: any, type: NotificationType): void {
        switch (type) {
            case NotificationType.approvalRequest:
                this.startApprovalRequestSubscription(data);
                break;
            case NotificationType.transactionStatus:
                this.startTransactionStatusSubscription(data);
                break;
        }
    }

    private startApprovalRequestSubscription(initialApprovalRequest: ApprovalRequest): void {
        this.subscriptions[NotificationType.approvalRequest][initialApprovalRequest.id] = this.subscriptionsService
            .requestApprovalSubscription(initialApprovalRequest.id)
            .pipe(
                startWith(initialApprovalRequest),
                filter((approvalRequest: ApprovalRequest) => approvalRequest.status !== ApprovalRequestStatus.Closed)
            )
            .subscribe((approvalRequest: ApprovalRequest) => {
                this.emitNotification({
                    id: approvalRequest.id,
                    relation_id: approvalRequest.relation_id,
                    status: approvalRequest.status,
                    message: DISCOUNT_NOTIFICATIONS[approvalRequest.status],
                    type: NotificationType.approvalRequest
                });
                this.approvalRequests[approvalRequest.relation_id] = approvalRequest;
            });
    }

    private startTransactionStatusSubscription(quoteId: string): void {
        this.subscriptions[NotificationType.transactionStatus][quoteId] = this.subscriptionsService
            .transactionStatusSubscription(quoteId)
            .subscribe((status: TransactionStatus) => {
                this.emitNotification({
                    id: '0',
                    relation_id: quoteId,
                    status,
                    message: TRANSACTION_NOTIFICATIONS[status],
                    type: NotificationType.transactionStatus
                });
            });
    }

    closeSubscription(id: string, type: NotificationType): void {
        if (!this.subscriptions[type][id]) {
            return;
        }

        this.subscriptions[type][id].unsubscribe();
        delete this.subscriptions[type][id];

        switch (type) {
            case NotificationType.approvalRequest:
                const notificationsIds: string[] = this.getNotificationIds();
                const index: number = notificationsIds.findIndex((notificationsId: string) => notificationsId === id);

                if (index > -1) {
                    const relationIds: string[] = Object.keys(this.approvalRequests);
                    const relationId: string = relationIds[index];

                    delete this.approvalRequests[relationId];
                }
                break;
            case NotificationType.transactionStatus:
                break;
        }
    }

    emitNotification(notification: Notification): void {
        switch (notification.type) {
            case NotificationType.approvalRequest:
                this.requestApprovalNotifications.next(notification);
                break;
            case NotificationType.transactionStatus:
                this.transactionStatusNotifications.next(notification);
                break;
        }
    }

    emitAutoCloseNotification(id: string): void {
        this.autoCloseNotification.next(id);
    }

    existingNotification(relationId: string): ApprovalRequest {
        return this.approvalRequests[relationId];
    }

    getNotificationIds(): string[] {
        return Object.values<any>(this.approvalRequests).map(({ id }) => id);
    }
}
