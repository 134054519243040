import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';

import { IonicModule } from '@ionic/angular';

import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { InchSignPipe } from './pipes/inch-sign.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { LineBreaksPipe } from './pipes/line-breaks.pipe';
import { OfflineAndroidPipe } from './pipes/offline-android.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SidingMeasurementTypePipe } from './pipes/siding-measurement-type.pipe';
import { NewQuoteComparisonComponent } from '../main/appointments/quote/components/new-quote-comparison/new-quote-comparison.component';
import {
    AccessoriesModalComponent,
    AddersDetailsComponent,
    AddersListPopoverComponent,
    AddersModalComponent,
    AppUpdateComponent,
    BluetoothDevicesModalComponent,
    CenterMapControlComponent,
    ChangeCustomTaxComponent,
    ClickableImageComponent,
    ConfigurationInfoModalComponent,
    ConfiguratorWarningsComponent,
    ConfirmationPopoverComponent,
    CopyOpeningModalComponent,
    CopyOpeningWithinQuoteModalComponent,
    CopyPackageModalComponent,
    CreateAdderModalComponent,
    CustomerSignatureModalComponent,
    CustomizeQuestionComponent,
    CustomPromotionModalComponent,
    DiscountsDropdownComponent,
    DocumentViewModalComponent,
    DropdownPopoverComponent,
    EmailResendModalComponent,
    ErrorsFormComponent,
    ExpiresDropdownComponent,
    FabButtonComponent,
    FileDropComponent,
    FilterComponent,
    FinancingComponent,
    FormModalComponent,
    FreeTextPopoverComponent,
    GroupConfigurationModalComponent,
    HouseOnMapComponent,
    ImageAnnotationModalComponent,
    ImageAnnotationToolComponent,
    InstallationNotesComponent,
    ItemsListPopoverComponent,
    LineItemAddersComponent,
    LineItemDetailsComponent,
    LineItemMeasureDetailsComponent,
    ListItemsModalComponent,
    LoadingModalComponent,
    LoginModalComponent,
    MapComponent,
    MaskedImageComponent,
    MeasurementReportDetailsModalComponent,
    MeasurementReportsModalComponent,
    MultiSelectPopoverComponent,
    NotesModalComponent,
    NotificationsComponent,
    NumberPickerComponent,
    OpeningDrawingModalComponent,
    PackageSelectorComponent,
    PageHeaderComponent,
    PaymentErrorModalComponent,
    PreferenceTypeModalComponent,
    PriceDetailsComponent,
    ProductPreviewComponent,
    ProductSelectionComponent,
    ProjectAddersComponent,
    ProjectAnnotationModalComponent,
    PromotionsSummaryModalComponent,
    ProviaFormComponent,
    ProviaOrderModalComponent,
    ProviaOrdersListComponent,
    QuickInvoiceFormComponent,
    QuickInvoiceModalComponent,
    QuoteActionsComponent,
    QuoteNumbersComponent,
    QuotePricingFieldsComponent,
    QuoteSummaryComponent,
    RadioGroupListModalComponent,
    RenamePackageComponent,
    RequestFinancingApplicationModalComponent,
    ResultingModalComponent,
    RoofingDimensionsModalComponent,
    SegmentizeImageComponent,
    SelectActionModalComponent,
    SelectDocumentsInputComponent,
    SelectDocumentsModalComponent,
    SelectItemComponent,
    SelectPriceBookComponent,
    SelectPricingProfileModalComponent,
    SeriesChangesModalComponent,
    SetMonthlyInvestmentModalComponent,
    SetQuoteHeadersModalComponent,
    SideExpandAreaComponent,
    SignatureConfirmationModalComponent,
    SignatureModalComponent,
    StatusBarModalComponent,
    StepperProgressBarComponent,
    StepsNavComponent,
    TabsComponent,
    TagsModalComponent,
    TakeoffInfoModalComponent,
    ToolbarComponent,
    TooltipPopoverComponent,
    UserPreferencesModalComponent,
    ViewMeasurementReportComponent,
    YesNoRadioComponent,
    ZoomPanPinchComponent
} from '@shared/components';
import { FinancingDropdownPopoverComponent } from '@shared/components/financing-dropdown-popover/financing-dropdown-popover.component';
import { ListPromotionItemsComponent } from '@shared/components/list-promotion-items/list-promotion-items.component';
import { ListSelectModalComponent } from '@shared/components/list-select-modal/list-select-modal.component';
import { PdfDocumentViewerModule } from '@shared/components/pdf-document-viewer/pdf-document-viewer.module';
import { PriceModifiersModalComponent } from '@shared/components/price-modifiers-modal/price-modifiers-modal.component';
import { SelectionPopoverComponent } from '@shared/components/selection-popover/selection-popover.component';
import { UploadPhotosComponent } from '@shared/components/upload-photos/upload-photos.component';
import { UploadPhotosModalComponent } from '@shared/components/upload-photos-modal/upload-photos-modal.component';
import {
    ContentEditableDirective,
    HorizontalScrollDirective,
    IntegerInputDirective,
    PhoneInputMaskDirective,
    ScrollToMeDirective,
    SelectAllTextDirective
} from '@shared/directives';
import { AppointmentStatusPipe } from '@shared/pipes/appointment-status.pipe';
import { FilterPipe } from '@shared/pipes/filter';
import { HashToLabelPipe } from '@shared/pipes/hash-to-label.pipe';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { OrdinaryNumberPipe } from '@shared/pipes/ordinary.number.pipe';
import { SortItemsByValuesPipe } from '@shared/pipes/sort-items-by-values.pipe';
import { StartCasePipe } from '@shared/pipes/start-case.pipe';
import { SumPipe } from '@shared/pipes/sum.pipe';

const components = [
    FabButtonComponent,
    PageHeaderComponent,
    TabsComponent,
    ToolbarComponent,
    FinancingComponent,
    MapComponent,
    YesNoRadioComponent,
    ClickableImageComponent,
    NumberPickerComponent,
    ExpiresDropdownComponent,
    CenterMapControlComponent,
    FileDropComponent,
    MaskedImageComponent,
    AppUpdateComponent,
    ImageAnnotationToolComponent,
    SideExpandAreaComponent,
    NotificationsComponent,
    ZoomPanPinchComponent,
    ProviaOrdersListComponent,
    ProviaFormComponent,
    QuickInvoiceFormComponent,
    StatusBarModalComponent,
    NewQuoteComparisonComponent,
    FinancingDropdownPopoverComponent,
    UploadPhotosComponent,
    StepperProgressBarComponent,
    ProjectAddersComponent,
    StepsNavComponent,
    CustomizeQuestionComponent,
    ProductPreviewComponent,
    LineItemAddersComponent,
    ConfiguratorWarningsComponent,
    ProductSelectionComponent,
    LineItemMeasureDetailsComponent,
    QuoteNumbersComponent,
    LineItemDetailsComponent,
    PriceDetailsComponent,
    AddersDetailsComponent,
    QuoteActionsComponent,
    QuotePricingFieldsComponent,
    DiscountsDropdownComponent,
    QuoteSummaryComponent,
    InstallationNotesComponent
];

const modals = [
    NotesModalComponent,
    OpeningDrawingModalComponent,
    AddersModalComponent,
    ResultingModalComponent,
    SignatureModalComponent,
    RadioGroupListModalComponent,
    DocumentViewModalComponent,
    SignatureConfirmationModalComponent,
    PaymentErrorModalComponent,
    TagsModalComponent,
    ConfigurationInfoModalComponent,
    TakeoffInfoModalComponent,
    CopyOpeningModalComponent,
    CopyOpeningWithinQuoteModalComponent,
    CopyPackageModalComponent,
    RenamePackageComponent,
    ChangeCustomTaxComponent,
    ImageAnnotationModalComponent,
    CreateAdderModalComponent,
    AccessoriesModalComponent,
    HouseOnMapComponent,
    SegmentizeImageComponent,
    CustomerSignatureModalComponent,
    CustomPromotionModalComponent,
    SelectDocumentsInputComponent,
    SelectDocumentsModalComponent,
    UserPreferencesModalComponent,
    ProviaOrderModalComponent,
    GroupConfigurationModalComponent,
    SelectPricingProfileModalComponent,
    BluetoothDevicesModalComponent,
    PreferenceTypeModalComponent,
    QuickInvoiceModalComponent,
    SeriesChangesModalComponent,
    PromotionsSummaryModalComponent,
    LoadingModalComponent,
    ProjectAnnotationModalComponent,
    FormModalComponent,
    EmailResendModalComponent,
    LoginModalComponent,
    MeasurementReportsModalComponent,
    RoofingDimensionsModalComponent,
    ErrorsFormComponent,
    PackageSelectorComponent,
    SetMonthlyInvestmentModalComponent,
    ListItemsModalComponent,
    RequestFinancingApplicationModalComponent,
    SelectPriceBookComponent,
    SelectItemComponent,
    FilterComponent,
    SetQuoteHeadersModalComponent,
    SelectActionModalComponent,
    ListSelectModalComponent,
    PriceModifiersModalComponent,
    UploadPhotosModalComponent,
    MeasurementReportDetailsModalComponent,
    ViewMeasurementReportComponent,
    ListPromotionItemsComponent
];

const popovers = [
    AddersListPopoverComponent,
    DropdownPopoverComponent,
    FreeTextPopoverComponent,
    ConfirmationPopoverComponent,
    TooltipPopoverComponent,
    MultiSelectPopoverComponent,
    ItemsListPopoverComponent,
    SelectionPopoverComponent
];

const pipes = [
    FilterPipe,
    InchSignPipe,
    LocalizedDatePipe,
    OrdinaryNumberPipe,
    ReplacePipe,
    SortItemsByValuesPipe,
    SumPipe,
    PhonePipe,
    OfflineAndroidPipe,
    JoinPipe,
    StartCasePipe,
    SidingMeasurementTypePipe,
    LineBreaksPipe,
    CapitalizePipe,
    HashToLabelPipe,
    AppointmentStatusPipe,
    CustomCurrencyPipe
];

const directives = [
    ContentEditableDirective,
    HorizontalScrollDirective,
    IntegerInputDirective,
    PhoneInputMaskDirective,
    ScrollToMeDirective,
    SelectAllTextDirective
];

@NgModule({
    declarations: [...components, ...directives, ...modals, ...pipes, ...popovers],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        AngularSignaturePadModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule,
        SortablejsModule,
        ColorPickerModule,
        PdfDocumentViewerModule
    ],
    exports: [
        CommonModule,
        IonicModule,
        FormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule,
        ReactiveFormsModule,
        SortablejsModule,
        PdfDocumentViewerModule,
        ...components,
        ...directives,
        ...modals,
        ...popovers,
        ...pipes
    ],
    providers: [...pipes]
})
export class SharedModule {}
