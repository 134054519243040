<ion-content>
    <vendo-page-header title="Financing Offers"
                       doneButtonName="Done"
                       (doneButtonClicked)="done()"></vendo-page-header>

    <vendo-tabs *ngIf="tabs.length > 1"
                [tabs]="tabs"
                [activeTabId]="activeTabId"
                (tabSelected)="selectTab($event)"></vendo-tabs>

    <div *ngIf="activeTabId === paradigmFinanceTab.hash"
         class="tabs"
         #tabs="tqMHorizontalScroll"
         tqMHorizontalScroll
         (overflowed)="isShowNavButtons = $event">
        <ng-container *ngFor="let item of offerCodesList">
            <ion-button *ngIf="item.id === null || item?.rate_sheet_merchant_product_prices?.length"
                        [class.outline]="item.id !== selectedOfferCode?.id"
                        (click)="selectOfferCode(item)">
                {{item.name}}
            </ion-button>
        </ng-container>
    </div>

    <ion-row class="tab-content {{ tabs.length > 1 ? activeTabId : ('only-' + activeTabId) }}">
        <ng-container [ngSwitch]="activeTabId">
            <ng-container *ngSwitchCase="paradigmFinanceTab.hash">
                <div class="rate-sheets">
                    <ng-container *ngIf="financingUsers.length && approvedOffers.length && !selectedOfferCode?.id">
                        <div class="name">
                            Approved Offers
                        </div>
                        <div class="list">
                            <ng-container *ngFor="let approvedOffer of approvedOffers">
                                <div class="list--sheet"
                                     [ngClass]="{'selected': approvedOffer.id === selectedOption?.id}"
                                     (click)="selectFinancing(approvedOffer)">
                                    <ion-img class="list--sheet--logo"
                                             [src]="paradigmFinanceImagePath"></ion-img>
                                    <div class="list--sheet--description">
                                        {{ approvedOffer.name }}
                                    </div>
                                    <div class="list--sheet--apr-range">
                                        {{ approvedOffer.interest_rate }}% Interest
                                    </div>
                                    <div class="list--sheet--term-range">
                                        Full Term: {{ approvedOffer.months }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngFor="let itemList of rateSheetsList">
                        <ng-container *ngIf="itemList.value.length">
                            <div class="name">
                                {{itemList.name}}
                            </div>
                            <div class="list">
                                <ng-container *ngFor="let item of itemList.value">
                                    <div class="list--sheet"
                                         [ngClass]="{'selected': item.id === selectedRateSheet?.id}"
                                         (click)="selectRateSheet(item)">
                                        <ion-img class="list--sheet--logo"
                                                 [src]="paradigmFinanceImagePath"></ion-img>
                                        <div class="list--sheet--description">
                                            {{item.short_description}}
                                        </div>
                                        <div class="list--sheet--apr-range">
                                            {{item.apr}} Interest
                                        </div>
                                        <div class="list--sheet--term-range">
                                            Full Term: {{item.full_term}}
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="standardOffersTab.hash">
                <ion-grid class="page">
                    <ion-row>
                        <ion-col>
                            <ion-content class="financing">
                                <ion-grid>
                                    <ion-row>
                                        <ion-col *ngFor="let option of financingOptions"
                                                 size="6"
                                                 class="ion-justify-content-center financing--card">
                                            <div #financingElement
                                                 class="financing--card--body"
                                                 [ngClass]="{'financing--card--body_selected': option.id === selectedOption?.id}"
                                                 (click)="selectFinancing(option)">
                                                <ion-text *ngIf="option.id === selectedOption?.id"
                                                          class="financing--card--pick__label">Your Pick
                                                </ion-text>
                                                <ion-img class="financing--card--logo"
                                                         [src]="option.provider?.logo_url"></ion-img>
                                                <ion-text class="financing--card--title">{{ option.name }}</ion-text>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-content>
                        </ion-col>

                        <ion-col size="6" class="offer">
                            <ng-container *ngIf="selectedOption && !selectedOption.quote_id">
                                <ion-row class="offer--header">
                                    <ion-col size="9">
                                        <ion-text class="offer--title">{{ selectedOption.provider.name }}</ion-text>
                                        <ion-text class="offer--sub_title">{{ selectedOption.name }}</ion-text>
                                    </ion-col>
                                    <ion-col size="3">
                                        <ion-img class="offer--logo" [src]="selectedOption.provider.logo_url"></ion-img>
                                    </ion-col>
                                </ion-row>

                                <ion-row class="offer--body">
                                    <ion-col size="12">
                                        <div class="payment">
                                            <div class="label">Total Amount Financed</div>
                                            <div class="value">
                                                {{ amountFinancing | customCurrency: true }}
                                            </div>
                                        </div>

                                        <div class="payment monthly">
                                            <div class="label">
                                                Est. Monthly Payment
                                            </div>
                                            <div class="value">
                                                {{ monthlyPayment | customCurrency: true }}/mo
                                            </div>
                                        </div>

                                        <div class="flex-center ion-justify-content-between payment-terms-block">
                                            <div>
                                                <div class="label">
                                                    Payment Term
                                                </div>
                                                <div class="value">
                                                    {{ selectedOption.months }} Months
                                                </div>
                                            </div>

                                            <div>
                                                <div class="label">
                                                    Interest Rate
                                                </div>
                                                <div class="value">
                                                    {{ selectedOption.interest_rate | number:'1.2' }}%
                                                </div>
                                            </div>
                                        </div>
                                    </ion-col>

                                    <ion-col class="details">
                                        <div class="offers" *ngIf="selectedOption?.details?.length">
                                            Details:
                                            <ul>
                                                <ng-container *ngFor="let detail of selectedOption.details">
                                                    <li>{{ detail }}</li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </ion-col>
                                </ion-row>

                                <ion-row class="offer--footer" *ngIf="selectedOption?.url">
                                    <ion-button color="primary"
                                                class="classic-width"
                                                (click)="doAction()">
                                        Go to Application
                                    </ion-button>
                                </ion-row>
                            </ng-container>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ng-container>
        </ng-container>
    </ion-row>
</ion-content>

<div *ngIf="activeTabId === paradigmFinanceTab.hash"
     class="select-offer"
     [ngClass]="{ 'selected-offer' : selectedRateSheet || selectedOption?.quote_id}">
    <div>
        <ng-container *ngIf="selectedRateSheet">
            Payment as low as {{ monthlyPayment | customCurrency: true }}/mo
        </ng-container>
        <ng-container *ngIf="!selectedRateSheet && selectedOption?.quote_id">
            Est. Monthly Payment {{ monthlyPayment | customCurrency: true }}/mo
        </ng-container>
        <ng-container *ngIf="!selectedRateSheet && !selectedOption?.quote_id">
            Select An Offer
        </ng-container>
    </div>

    <ion-button *ngIf="selectedRateSheet"
                class="classic-width"
                color="primary"
                (click)="doAction()">
        Start Application
        <i class="material-icons">open_in_new</i>
    </ion-button>

    <ion-button *ngIf="selectedOption?.quote_id"
                class="classic-width"
                color="primary"
                (click)="done()">
        Continue
    </ion-button>
</div>

