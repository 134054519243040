import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { IonSlides, ModalController } from '@ionic/angular';

import get from 'lodash/get';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-takeoff-info-modal',
    templateUrl: './takeoff-info-modal.component.html',
    styleUrls: ['./takeoff-info-modal.component.scss']
})
export class TakeoffInfoModalComponent extends BaseModal implements OnInit {
    @Input() openingData: any;
    @Input() activeSlideIndex: number;
    @Input() takeoffDetailsTitle: string;
    @ViewChild('sliderRef') slides: IonSlides;
    tabs = [];
    activeTab = this.tabs[0];
    countImages: number;

    constructor(modalCtrl: ModalController) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.countImages = get(this.openingData, 'images.length') || 0;
        this.tabs = [
            {
                label: 'Photos',
                hash: 'photos',
                hidden: !this.countImages
            }
        ];

        if (this.takeoffDetailsTitle) {
            this.tabs.push({
                label: this.takeoffDetailsTitle,
                hash: 'details'
            });
        }

        const tabIndex = Number(!this.activeSlideIndex);

        this.activeTab = this.tabs[this.takeoffDetailsTitle ? tabIndex : 0];
    }

    setActiveTab(tab: any): void {
        this.activeTab = tab;
    }

    refreshSlides(): void {
        //fix https://github.com/ionic-team/ionic-framework/issues/19638
        if (this.countImages) {
            this.slides.getSwiper().then((swiper) => swiper.update());
        }
    }
}
