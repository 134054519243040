import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Device } from '@awesome-cordova-plugins/device/ngx';
import { BIOMETRIC_ERRORS, BIOMETRIC_TYPE, FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';

import { ModalController, NavController, Platform } from '@ionic/angular';

import { from, of, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil } from 'rxjs/operators';

import { PrivacyPolicyService } from './services/privacy-policy.service';
import { GuardHelperService } from '@core/guards/guard-helper.service';
import { ThemeService } from '@core/modules/theme/services/theme.service';
import { AppService } from '@core/services/app.service';
import { AuthService } from '@core/services/auth.service';
import { Auth0Service } from '@core/services/auth0.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { SpinnerService } from '@core/services/spinner.service';
import { ToastService } from '@core/services/toast.service';
import { ListSelectModalComponent } from '@shared/components/list-select-modal/list-select-modal.component';
import { FEATURES } from '@shared/constants/features';
import { ApplicationRole } from '@shared/enums/application-role.enum';
import { DomainHelper } from '@shared/helpers/domain-helper';

import { environment } from '../../../environments/environment';
import { loginRequest } from '../../auth-config';

@Component({
    selector: 'vendo-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit, OnDestroy {
    @HostBinding('class.is-phone') isPhone = this.deviceHelperService.isPhone;
    form = new UntypedFormGroup({
        userName: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [])
    });
    currentYear = new Date().getFullYear();
    biometricAvailable = false;
    isTablet = this.platform.is('ios') || this.platform.is('android');
    isHigherImage: boolean = this.appService.checkIsAndersen() || this.appService.checkIsGenesis();
    deviceUUID;
    logoUrl: string;
    backgroundUrl: any;
    loginConfirmation: any;
    isDisableCheckEmailButton = false;
    private subDomain: string;
    private lastUsedLogin: any = {};
    private destroy$ = new Subject<void>();

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private navCtrl: NavController,
        private themeService: ThemeService,
        private faio: FingerprintAIO,
        private platform: Platform,
        private device: Device,
        private termsAndConditionsModalService: PrivacyPolicyService,
        private featureService: FeaturesService,
        public cdr: ChangeDetectorRef,
        private deviceHelperService: DeviceHelperService,
        private toastService: ToastService,
        private cookieService: CookieService,
        private modalController: ModalController,
        private auth0Service: Auth0Service,
        private msalService: MsalService,
        private guardHelperService: GuardHelperService,
        private spinnerService: SpinnerService
    ) {}

    ngOnInit(): void {
        this.deviceUUID = this.device.uuid;
        this.themeService.setTheme('light');
        this.authService.signedIn.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value && !this.authService.isLoginViaDeeplink) {
                const user = this.authService.getUser();

                if (user.application_role_hash === ApplicationRole.CapturePayment) {
                    this.navCtrl.navigateRoot(['payment']);

                    return;
                }

                const returnUrl = this.route.snapshot.queryParams.returnUrl;
                const returnUrlParts = returnUrl ? returnUrl.split('?') : null;
                const queryParams = {};

                if (returnUrl?.includes('https')) {
                    window.location.href = returnUrl;
                }

                if (returnUrlParts && returnUrlParts[1]) {
                    const queryParts = returnUrlParts[1].split('=');

                    queryParams[queryParts[0]] = queryParts[1];
                }

                this.navCtrl
                    .navigateRoot([returnUrlParts && returnUrlParts[0] ? returnUrlParts[0] : 'main'], { queryParams })
                    .then(() => {
                        if (
                            !this.featureService.hasFeature(FEATURES.OFFLINE_MODE) &&
                            window.localStorage.getItem('offline_mode') === '1'
                        ) {
                            window.localStorage.setItem('offline_mode', '0');
                            (window as any).location.reload();
                        }
                    })
                    .catch(() => {
                        if (returnUrlParts && returnUrlParts[0]) {
                            this.navCtrl.navigateRoot(['main']);
                        }
                    });
            }
        });

        [this.subDomain] = window.location.hostname.split('.');

        this.themeService.getOrganizationThemeOptions(this.subDomain).subscribe(async (res) => {
            if (res) {
                this.appService.setLogoUrl(res.logo_url);
            }

            this.logoUrl = await this.appService.getLogoUrl();
        });

        this.authService.getStorageItem('background_url').then((backgroundUrl) => (this.backgroundUrl = backgroundUrl));

        this.authService.getLastUsedLogin().then((login) => {
            this.lastUsedLogin = login;
            this.form.patchValue(login);

            if (this.isTablet) {
                this.faio
                    .isAvailable()
                    .then((result: BIOMETRIC_TYPE) => {
                        if (result) {
                            this.biometricAvailable = result && login.t_id_hash;
                            this.form
                                .get('userName')
                                .valueChanges.pipe(takeUntil(this.destroy$))
                                .subscribe((value: string) => this.emailFieldChanged(value));
                        }
                    })
                    .catch(() => (this.biometricAvailable = false));
            }
        });

        this.isAvailableUpdate();
        this.appService.isAvailableUpdate();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkEmail(): void {
        if (this.form.invalid) {
            return;
        }

        this.isDisableCheckEmailButton = true;
        const organizationData = [];
        const trimmedUsername: string = this.form.getRawValue().userName.trim();

        this.authService
            .checkEmail(trimmedUsername)
            .pipe(
                switchMap((res) => {
                    organizationData.push(...res);

                    if (res.length > 1) {
                        return from(this.openSelectOrganizationModal(res.map((org) => org.organization))).pipe(
                            map((res) => res.data)
                        );
                    } else {
                        return of(...res);
                    }
                }),
                filter((res) => res),
                finalize(() => (this.isDisableCheckEmailButton = false))
            )
            .subscribe(async (res) => {
                await this.authService.setOrgHash(res.hash);
                this.loginConfirmation =
                    organizationData.length > 1
                        ? organizationData.find((data) => data.organization.hash === res.hash)
                        : res;

                const isParadigmAuth: boolean =
                    !!this.loginConfirmation.external && !this.loginConfirmation.connection_name;

                this.authService.setStorageItem('isParadigmAuth', `${isParadigmAuth}`);
                await this.guardHelperService.updateGuards();

                if (this.loginConfirmation.external) {
                    if (!this.loginConfirmation.connection_name) {
                        // if (this.deviceHelperService.isWeb) {
                        this.spinnerService.showSpinner();
                        this.msalService
                            .loginRedirect({ ...loginRequest, loginHint: this.loginConfirmation.login_hint })
                            .pipe(finalize(() => this.spinnerService.hideSpinner()))
                            .subscribe();
                        // } else {
                        // }

                        return;
                    }

                    this.auth0Service.login(
                        this.loginConfirmation,
                        organizationData.length > 1 ? `?orgHash=${res.hash}` : ''
                    );
                } else {
                    this.form.get('password').setValidators(Validators.required);
                    this.form.get('userName').disable();
                }
            });
    }

    handlePressEnter(event: any): void {
        if (event.keyCode !== 13) {
            return;
        }

        this.loginConfirmation && !this.loginConfirmation.external ? this.login() : this.checkEmail();
    }
    private async openSelectOrganizationModal(organizationList: { hash: string; name: string }[]): Promise<any> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            backdropDismiss: false,
            component: ListSelectModalComponent,
            componentProps: {
                resources: organizationList,
                propToShow: 'name',
                title: 'Choose Your Organization',
                notice: 'We noticed this email is connected to multiple organizations. Which organization would you like to use?'
            },
            cssClass: 'select-organization-modal'
        });

        await modal.present();

        return await modal.onWillDismiss();
    }

    login(): void {
        if (this.form.invalid) {
            return;
        }

        const platformName = this.device.platform || 'browser';

        this.authService.login(this.form.getRawValue(), this.deviceUUID, platformName).subscribe((res) => {
            this.setCookie();
            if (res.reset_password) {
                this.navCtrl.navigateRoot(`reset-password/${res.reset_password}`);
            }
        });
    }

    loginWithBiometric(): void {
        this.faio
            .show({ disableBackup: true, confirmationRequired: false })
            .then(() => {
                this.authService.loginWithBiometric(this.form.getRawValue(), this.deviceUUID).then(() => {});
            })
            .catch((error) => {
                if (error.code === BIOMETRIC_ERRORS.BIOMETRIC_LOCKED_OUT) {
                    this.biometricAvailable = false;
                    this.toastService.showMessage('Too much attempts. Please use your credentials to login!');
                }
            });
    }

    backToEmailVerification(): void {
        this.loginConfirmation = null;
        this.authService.setOrgHash(null);

        this.form.get('password').setValidators([]);
        this.form.get('userName').enable();

        this.form.get('password').updateValueAndValidity();
    }

    emailFieldChanged(userName: string): void {
        this.biometricAvailable = this.lastUsedLogin.userName === userName;
    }

    async openTermsAndConditionsModal(): Promise<void> {
        await this.termsAndConditionsModalService.openTermsAndConditionsModal();
    }

    private isAvailableUpdate(): void {
        this.appService.isAvailableUpdateObservable().pipe(takeUntil(this.destroy$)).subscribe();
    }

    private setCookie(): void {
        if (this.deviceHelperService.isWeb) {
            this.cookieService.set(
                'isVendoMobileUser',
                'yes',
                30,
                '/',
                DomainHelper.getDomainWithoutSubdomain(environment.siteUrl)
            );
        }
    }
}
