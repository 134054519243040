<ion-button *ngIf="isChangeOrderEnabled && quote.changes"
            class="outline"
            (click)="openPricingDetails()">
    Pricing Details
</ion-button>

<ion-button *ngIf="isOverPromotionLimit"
            color="primary"
            [disabled]="existingNotification?.status === approvalRequestStatuses.Sent"
            (click)="requestApproval()">
    Request Approval
</ion-button>

<ion-button *ngIf="selectedOfferCode"
            color="primary"
            (click)="openRequestApplicationModal()">
    Start Application
</ion-button>

<ion-button *ngIf="!isOverPromotionLimit && !selectedOfferCode"
            color="primary"
            (click)="proceedClicked.emit()">
    {{ proceedButtonSetting?.single }}
</ion-button>
