import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MsalService } from '@azure/msal-angular';

import { NavController } from '@ionic/angular';

import { AuthService } from '@core/services/auth.service';
import { Auth0Service } from '@core/services/auth0.service';

@Component({
    selector: 'vendo-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private auth0Service: Auth0Service,
        private navCtrl: NavController,
        private msalService: MsalService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        const isParadigmAuth: boolean = (await this.authService.getStorageItem('isParadigmAuth')) === 'true';
        const isSSOLogin: boolean = (await this.authService.getStorageItem('isSSOLogin')) === 'true';

        console.log(
            'LogoutComponent',
            this.msalService.instance.getActiveAccount(),
            '/',
            isParadigmAuth,
            '/',
            isSSOLogin
        );

        if (this.msalService.instance.getActiveAccount() || isParadigmAuth) {
            await this.authService.removeStorageItem('isParadigmAuth');
            await this.authService.logout();

            // if (this.isDesktop) {
            this.msalService.logoutRedirect();
            // } else {
            // }
        } else if (isSSOLogin) {
            await this.authService.removeStorageItem('isSSOLogin');
            this.authService.logout();
            this.auth0Service.logout();
        } else {
            await this.authService.logout();
            const returnUrl = this.route.snapshot.queryParams.returnUrl;

            this.navCtrl.navigateRoot('/login', {
                queryParams: {
                    ...(returnUrl && { returnUrl })
                }
            });
        }
    }
}
