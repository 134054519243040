import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { IonInput, ModalController } from '@ionic/angular';

import { QuoteService } from '@core/services/quote.service';
import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-rename-package',
    templateUrl: './rename-package.component.html',
    styleUrls: ['./rename-package.component.scss']
})
export class RenamePackageComponent extends BaseModal implements OnInit, AfterViewInit {
    @Input() quote: any;
    @ViewChild('inputField') inputField: IonInput;
    inputText = '';
    charLimit = 30;

    constructor(modalCtrl: ModalController, private quoteService: QuoteService) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.inputText = this.quote.package_name;
    }

    async ngAfterViewInit(): Promise<void> {
        setTimeout(async () => {
            await this.inputField.setFocus();
            (await this.inputField.getInputElement()).select();
        }, 300);
    }

    save(): void {
        if (!this.inputText.length) {
            this.inputText = this.quote.package_name;
        }

        if (this.inputText.length > this.charLimit) {
            return;
        }

        this.quoteService
            .updateQuotePackage(this.quote.id, this.inputText)
            .subscribe(() => this.dismiss(this.inputText));
    }
}
