import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Device } from '@awesome-cordova-plugins/device/ngx';

import { NavController, Platform } from '@ionic/angular';

import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';

import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'vendo-redirect-callback',
    templateUrl: './redirect-callback.component.html',
    styleUrls: ['./redirect-callback.component.scss']
})
export class RedirectCallbackComponent implements OnInit {
    private isDesktop = this.platform.is('desktop') || this.platform.is('mobileweb');

    constructor(
        private route: ActivatedRoute,
        private auth: AuthService,
        private platform: Platform,
        private device: Device,
        private navCtrl: NavController,
        private toastService: ToastService
    ) {}

    ngOnInit(): void {
        const queryParams = this.route.snapshot.queryParams;

        if (queryParams.code) {
            const auth0Params = JSON.parse(
                window.sessionStorage.getItem(`a0.spajs.txs.${environment.auth0.client_id}`)
            );

            const platformName = this.device.platform || 'browser';
            const redirectCallback = this.isDesktop
                ? `${window.location.protocol}//${window.location.host}/redirect-callback`
                : `${environment.siteUrl}/redirect-callback`;

            this.auth
                .ssoSignIn(
                    queryParams.code,
                    auth0Params.code_verifier,
                    this.device.uuid,
                    platformName,
                    redirectCallback,
                    queryParams.orgHash
                )
                .subscribe(
                    (res) => {
                        if (res?.token) {
                            this.navCtrl.navigateRoot(queryParams.returnUrl || ['main']).catch(() => {
                                if (queryParams.returnUrl) {
                                    this.navCtrl.navigateRoot(['main']);
                                }
                            });
                        } else {
                            this.navCtrl.navigateRoot('/login', {
                                queryParams: {
                                    ...(queryParams.returnUrl && { returnUrl: queryParams.returnUrl })
                                }
                            });
                        }
                    },
                    () => {
                        this.navCtrl.navigateRoot('/login');
                    }
                );
        } else if (queryParams.error && queryParams.error_description) {
            let description = queryParams.error_description;

            description = `${description[0].toUpperCase()}${description.substring(1)}`;
            this.toastService.showMessage(description, 50000).then(() => this.navCtrl.navigateRoot('/login'));
        }
    }
}
