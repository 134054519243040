<ion-content class="login-page forgot-password">
    <div slot="fixed" class="background-box">
        <form [formGroup]="form" (ngSubmit)="sendForgotPasswordEmail()">
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col class="top-logo ion-align-self-center ion-justify-content-center" [ngClass]="{'is-higher-image': isHigherImage}" size-xl="4" size-md="7" size="10">
                        <div class="ion-align-self-center">
                            <ion-img class="main-logo ion-align-self-center"
                                     [src]="logoUrl"
                            ></ion-img>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-justify-content-center" *ngIf="emailSent !== true">
                    <ion-col class="login-card ion-align-self-center" size-xl="4" size-md="7" size="10">
                        <div>
                            <ion-text class="help-text">
                                Enter your email address and we'll send you a link to reset your password
                            </ion-text>
                            <ion-item>
                                <ion-label position="stacked">Email</ion-label>
                                <ion-input formControlName="email" type="email" required></ion-input>
                            </ion-item>
                        </div>
                        <div class="button-section ion-text-center">
                            <ion-button class="login-button"
                                        color="dark"
                                        size="large"
                                        type="submit"
                                        [disabled]="form.invalid"
                                        expand="full">
                                Continue
                            </ion-button>
                            <a class="ion-margin-top underline link inline-block"
                               routerDirection="root"
                               routerLink="/login">
                                Go To Login
                            </a>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-justify-content-center" *ngIf="emailSent === true">
                    <ion-col class="login-card email-sent ion-align-self-center" size-xl="4" size-md="7" size="10">
                        <ion-text color="dark-success">
                            <ion-icon name="checkmark"></ion-icon>
                        </ion-text>
                        <ion-text>
                            Reset password email sent. Please check your mail box.
                        </ion-text>
                        <div class="button-section ion-text-center">
                            <a class="ion-margin-top underline link inline-block"
                               routerDirection="root"
                               routerLink="/login">
                                Go To Login
                            </a>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-justify-content-center">
                    <ion-col class="bottom-section ion-text-center" size-xl="4" size-md="7" size="10">
                        <ion-text>&#9400; 2001-{{currentYear}} All Rights Reserved.</ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>
</ion-content>
