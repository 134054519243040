import { Component, Inject } from '@angular/core';

@Component({
    selector: 'vendo-center-map-control',
    templateUrl: './center-map-control.component.html',
    styleUrls: ['./center-map-control.component.scss']
})
export class CenterMapControlComponent {
    _className: string;
    _title: string;
    _eventHandler: any;
    _btn: any;
    _container: any;
    _map: any;

    constructor(@Inject(Object) { className = '', title = '', eventHandler = () => {} }) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
    }

    onAdd(map): any {
        this._btn = document.createElement('button');
        this._btn.className = 'mapbox-gl-draw_ctrl-draw-btn ' + this._className;
        this._btn.type = 'button';
        this._btn.title = this._title;
        this._btn.onclick = this._eventHandler;

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove(): void {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
