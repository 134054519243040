import { gql } from 'apollo-angular';

const GET_CATEGORIES_LIST = gql`
    query CategoriesList($appointmentId: ID!) {
        myCategories(appointment_id: $appointmentId) {
            category {
                id
                category_type
                allow_configure_questions
                questions {
                    title
                    id
                    hash
                    show_if
                    column_width
                }
                all_questions {
                    id
                    title
                    hash
                    show_if
                    available_answers
                }
                openings {
                    id
                    view_photo_id
                }
                house_areas {
                    title
                    hash
                    question_hash
                }
                allow_takeoff
                catalog_type
            }
            allow_series_changes
            allow_price_books
            allow_pricing_profiles
            id
            name
            use_provia_portal_login
        }
    }
`;

const GET_APPOINTMENT_CATEGORIES_LIST = gql`
    query AppointmentCategoriesList($appointmentId: ID!) {
        myAppointmentCategories(appointment_id: $appointmentId) {
            category {
                id
                category_type
                questions {
                    title
                    id
                    show_if
                }
                openings {
                    id
                }
                allow_takeoff
            }
            id
            name
        }
    }
`;

const GET_APPOINTMENT_CATEGORIES_WITHOUT_OPENINGS = gql`
    query AppointmentCategoriesList($appointmentId: ID!) {
        myAppointmentCategories(appointment_id: $appointmentId) {
            category {
                id
                category_type
                allow_takeoff
            }
            id
            name
        }
    }
`;

const GET_CATEGORY_WITH_QUESTIONS_AND_OPENINGS = gql`
    query CategoryWithQuestionsAndOpenings1($categoryId: ID!, $appointmentId: ID!) {
        category(category_id: $categoryId, appointment_id: $appointmentId) {
            id
            name
            category_type
            allow_configure_questions
            questions {
                id
                title
                hash
                show_if
                available_answers
                column_width
            }
            all_questions {
                id
                title
                hash
                show_if
                available_answers
            }
            house_areas {
                id
                title
                hash
                question_hash
            }
            openings {
                id
                name
                notes
                temporary
                opening_details {
                    question_id
                    answer
                }
                is_linked
                quantity
                images {
                    appointment_id
                    opening_id
                    type
                    name
                    note
                    url
                    position
                    appointment_type
                }
                autoconfigured
            }
        }
    }
`;

const GET_CATEGORY_WITH_QUESTIONS = gql`
    query CategoryWithQuestions($categoryId: ID!, $appointmentId: ID!, $openingCountBy: String) {
        categoryWithQuestions(
            category_id: $categoryId
            appointment_id: $appointmentId
            opening_count_by: $openingCountBy
        ) {
            id
            name
            openings_count
            openings_detailed_count
            openings_ids
            category_type
            openings {
                category_id
            }
            attributes {
                title
                hash
                attribute_values
            }
            questions {
                id
                title
                available_answers
                field_type
                allow_measurement
                hash
                depends_on
                required
                order
                default_answer
                show_if
            }
            all_questions {
                id
                available_answers
                hash
                used_for_grouping
            }
            allow_configure_questions
            inclusions {
                type
                label
                mapping
            }
            accessories {
                name
                coverage
                measurement_type
                maps_to
            }
        }
    }
`;

const GET_CATALOG_QUOTES = gql`
    query CatalogQuotes($appointmentId: ID!, $catalogId: ID) {
        catalogQuotes(appointment_id: $appointmentId, catalog_id: $catalogId) {
            id
            category_type
            name
            display_mull_toggle
            allow_pricing_profiles
            allow_autoconfigure
            partial_quotes {
                id
                parent_quote_id
                wcp_url_id
                quote_id
                last_changed_pricing_profile_at
            }
        }
    }
`;

const GET_CATALOG_IMAGE = gql`
    query GetCatalogImage($appointmentId: ID!, $catalogId: ID!) {
        getCatalogImage(appointment_id: $appointmentId, catalog_id: $catalogId) {
            appointment_type
            category_type
            predefined_images {
                label
                is_required
            }
        }
    }
`;

const GET_APPOINTMENT_CATALOGS_BY_PRODUCT_INTERESTS = gql`
    query appointmentCatalogsByProductsInterests($appointmentId: ID!) {
        appointmentCatalogsByProductsInterests(appointment_id: $appointmentId) {
            category_type
        }
    }
`;

export {
    GET_CATEGORIES_LIST,
    GET_CATEGORY_WITH_QUESTIONS_AND_OPENINGS,
    GET_CATEGORY_WITH_QUESTIONS,
    GET_APPOINTMENT_CATEGORIES_LIST,
    GET_APPOINTMENT_CATEGORIES_WITHOUT_OPENINGS,
    GET_CATALOG_QUOTES,
    GET_CATALOG_IMAGE,
    GET_APPOINTMENT_CATALOGS_BY_PRODUCT_INTERESTS
};
