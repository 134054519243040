import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './components/auth/auth.component';
import { ForgotPasswordComponent } from './components/auth/components/forgot-password/forgot-password.component';
import { LogoutComponent } from './components/auth/components/logout/logout.component';
import { RedirectCallbackComponent } from './components/auth/components/redirect-callback/redirect-callback.component';
import { ResetPasswordComponent } from './components/auth/components/reset-password/reset-password.component';
import { CheckResetPasswordResolver } from './components/auth/resolvers/check-reset-password.resolver';
import { HomePageComponent } from './components/home-page/home-page.component';
import { CapturePaymentGuard } from '@core/guards/capture-payment.guard';
import { LoginGuard } from '@core/guards/login.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: HomePageComponent
    },
    {
        path: 'main',
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule)
    },
    {
        path: 'e-document',
        loadChildren: () => import('./e-document/e-document.module').then((m) => m.EDocumentModule)
    },
    {
        path: 'e-document/v2',
        loadChildren: () => import('./e-document-v2/e-document.module').then((m) => m.EDocumentModule)
    },
    {
        path: 'offer-request',
        loadChildren: () => import('./offer-request/offer-request.module').then((m) => m.OfferRequestModule)
    },
    {
        path: 'deeplink',
        loadChildren: () => import('./deeplink/deeplink.module').then((m) => m.DeeplinkModule)
    },
    {
        path: 'login',
        component: AuthComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'redirect-callback',
        component: RedirectCallbackComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        resolve: {
            resetPasswordHash: CheckResetPasswordResolver
        }
    },
    {
        path: 'payment',
        canActivate: [CapturePaymentGuard],
        canLoad: [CapturePaymentGuard],
        loadChildren: () => import('./capture-payment/capture-payment.module').then((m) => m.CapturePaymentModule)
    },
    {
        path: '**',
        redirectTo: 'main'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking'
        })
    ],
    exports: [RouterModule],
    providers: [CheckResetPasswordResolver]
})
export class AppRoutingModule {}
