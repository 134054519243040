import { Injectable } from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { AppointmentImageType } from '@shared/enums/appointment-image-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { PhotoModalTabHash } from '@shared/enums/photo-modal-tab-hash.enum';
import { AppointmentImage } from '@shared/interfaces/appointment-image';

@Injectable({
    providedIn: 'root'
})
export class AppointmentImagesService {
    remapPhotos(
        openingId,
        catalogId,
        images,
        predefinedImages,
        isOpening = false,
        appointmentType?: AppointmentType
    ): AppointmentImage[] {
        const openingImages = images.filter((image) => image.opening_id === openingId);

        const openingPredefinedImages = isOpening
            ? predefinedImages.filter((image) => image.catalog_id == catalogId)
            : [];

        let catalogPredefinedImages;

        if (appointmentType) {
            if (isOpening) {
                catalogPredefinedImages = openingPredefinedImages.length
                    ? this.setAppointmentType(openingPredefinedImages[0].catalog.predefined_images, appointmentType)
                    : [];
            } else {
                catalogPredefinedImages = predefinedImages.length
                    ? this.setAppointmentType(predefinedImages[0].predefined_images, appointmentType)
                    : [];
            }
        } else {
            catalogPredefinedImages = predefinedImages.predefined_images.map((image) => {
                image.appointment_type = appointmentType;

                return image;
            });
        }

        const customArray = [];
        const predefinedOpeningImages = [];

        openingImages.forEach((image) => {
            switch (image.type) {
                case AppointmentImageType.Custom:
                    customArray.push(image);
                    break;
                case AppointmentImageType.Predefined:
                    predefinedOpeningImages.push(image);
                    break;
            }
        });

        let photos = [];

        if (catalogPredefinedImages.length) {
            const catalogPredefinedImagesTemp = cloneDeep(catalogPredefinedImages);

            catalogPredefinedImages.forEach((preImage) => {
                const preImgInArr = predefinedOpeningImages.find(
                    (pre) => pre.name === preImage.label && pre.type === preImage.type
                );

                if (preImgInArr) {
                    remove(
                        catalogPredefinedImagesTemp,
                        (pre) => pre.label === preImgInArr.name && pre.type === preImgInArr.type
                    );

                    photos.push(preImgInArr);
                }
            });

            if (catalogPredefinedImagesTemp.length) {
                photos = [...photos, ...catalogPredefinedImagesTemp];
            }

            photos = [...photos, ...customArray];
        } else {
            photos = openingImages;
        }

        return photos;
    }

    remapOpenings(openings, isOpening = true, appointmentType: AppointmentType): any[] {
        return openings.map((opening) => {
            const predefinedImages = isOpening
                ? opening.catalog.predefined_images.find((catalog) => catalog.appointment_type === appointmentType)
                      .predefined_images || []
                : opening.predefined_images.find((catalog) => catalog.appointment_type === appointmentType)
                      .predefined_images || [];

            if (predefinedImages.length) {
                predefinedImages.map((image) => (image.type = AppointmentImageType.Predefined));
            }

            return opening;
        });
    }

    calculateRequiredImages(requiredImages, opening): any[] {
        const image = requiredImages.opening_required_images.find((image) => image.opening_id === opening.id);

        if (image) {
            opening.addedCount = image.addedCount;
            opening.requireCount = image.requireCount;
        }

        return opening;
    }

    remapImagesToCheckIsEqual(images): { name: string; url: string; note: string } {
        return images.map((image) => {
            return {
                name: image.name || image.label,
                url: image.url ? new URL(image.url).pathname.split('/').pop() : null,
                note: image.note || null
            };
        });
    }

    getPropertiesWithValues(
        sortedOpeningsByType,
        requiredTab: PhotoModalTabHash,
        tabToExclude?: AppointmentType
    ): any[] {
        const propertiesWithValues = [];

        for (const key in sortedOpeningsByType) {
            if (
                sortedOpeningsByType.hasOwnProperty(key) &&
                sortedOpeningsByType[key].length > 0 &&
                key !== tabToExclude
            ) {
                propertiesWithValues.push(key);
            }
        }

        if (requiredTab && !propertiesWithValues.includes(requiredTab)) {
            propertiesWithValues.push(requiredTab);
        }

        return propertiesWithValues;
    }

    getTabs(propertiesWithValues, howToSort: number[] = [1, 2, 3]): any[] {
        const tabs = [];

        propertiesWithValues.forEach((prop) => {
            switch (prop) {
                case PhotoModalTabHash.ProjectPhotos:
                    tabs.push({
                        label: 'Project Photos',
                        hash: PhotoModalTabHash.ProjectPhotos,
                        index: 1
                    });
                    break;
                case PhotoModalTabHash.Sales:
                    tabs.push({
                        label: 'Sales Added',
                        hash: PhotoModalTabHash.Sales,
                        index: 2
                    });
                    break;
                case PhotoModalTabHash.SecondMeasure:
                    tabs.push({
                        label: 'Measure Added',
                        hash: PhotoModalTabHash.SecondMeasure,
                        index: 3
                    });
                    break;
            }
        });

        tabs.sort((a, b) => {
            return howToSort.indexOf(a.index) - howToSort.indexOf(b.index);
        });

        return tabs;
    }

    getRequiredImagesCounter(images: any[]): number {
        let counter = 0;

        images.forEach((image) => {
            if (
                image.hasOwnProperty('is_required') &&
                image.is_required &&
                !image.url &&
                image.type === AppointmentImageType.Predefined
            ) {
                counter++;
            }
        });

        return counter;
    }

    private setAppointmentType(predefinedArray, appointmentType): any[] {
        return (
            predefinedArray
                .find((catalog) => catalog.appointment_type === appointmentType)
                .predefined_images.map((image) => {
                    image.appointment_type = appointmentType;

                    return image;
                }) || []
        );
    }
}
