import { Pipe, PipeTransform } from '@angular/core';

import startCase from 'lodash/startCase';

@Pipe({
    name: 'startCase'
})
export class StartCasePipe implements PipeTransform {
    transform(value: string): string {
        return value ? startCase(value) : value;
    }
}
