<div id="house-drawing" [class.edit-mode]="editMode" [class.disabled]="disableEdit"
     [style.width]="width">
    <app-zoom-pan-pinch>
        <img id="house-img" #houseImg
             [class.hidden]="!displayImage"
             (window:resize)="calcRatio()"
             (load)="onImageLoaded()"
             [src]="imageUrl"/>
        <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                class="view-drawing"
                id="house-svg"
                style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
        >
            <defs>
                <filter id="shadow">
                    <feMorphology operator="dilate" radius="1" in="SourceAlpha" result="thicken"/>
                    <feGaussianBlur in="thicken" stdDeviation="10" result="blurred"/>
                    <feFlood result="glowColor"/>
                    <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored"/>
                    <feMerge>
                        <feMergeNode in="softGlow_colored"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <radialGradient id="Gradient1" fx="50%" fy="50%" cx="0.4" cy="0.5" r="0.7">
                    <stop offset="0%" stop-color="#474747"/>
                    <stop offset="100%" stop-color="#7a8185" stop-opacity="0.8"/>
                </radialGradient>
            </defs>
            <ng-container *ngFor="let opening of openings; let i = index">
                <g
                        [id]="'view-drawing-edit-' + i"
                        class="drawing-wrap"
                        [class.valid]="opening.opening_details?.length"
                        [class.disabled]="disableEdit"
                        *ngIf="(!editMode || opening.id === activeOpening?.id) && displayImage"
                >
                    <polygon
                            (touchstart)="$event.stopImmediatePropagation(); $event.preventDefault()"
                            (mousedown)="$event.stopImmediatePropagation(); $event.preventDefault()"
                            (click)="$event.stopImmediatePropagation(); $event.preventDefault(); editOpening(opening, i)"
                            id="opening-polygon"
                            class="mask"
                            [class.editing]="editMode"
                            [attr.points]="getMaskCoordinates(i)"
                            *ngIf="!opening.configurationDrawingUrl && !activePoint"
                    />
                    <ng-container *ngIf="!disableEdit">
                        <text [attr.x]="opening.center?.x" [attr.y]="opening.center?.y"
                              [style.fontSize]="16"
                              class="edit-icon"
                              *ngIf="!activeOpening"
                              (touchstart)="$event.stopImmediatePropagation()"
                              (click)="$event.stopImmediatePropagation(); editOpening(opening, i)">create
                        </text>
                        <text [attr.x]="opening.center?.x" [attr.y]="opening.center?.y"
                              class="valid-icon"
                              [style.fontSize]="16"
                              (touchstart)="$event.stopImmediatePropagation()"
                              (click)="$event.stopImmediatePropagation(); editOpening(opening, i)"
                              *ngIf="!activeOpening && opening.opening_details?.length">check_circle
                        </text>
                        <text [attr.x]="opening.center?.x" [attr.y]="opening.center?.y"
                              class="invalid-icon"
                              [style.fontSize]="16"
                              [style.width]="16"
                              (touchstart)="$event.stopImmediatePropagation()"
                              (click)="$event.stopImmediatePropagation(); editOpening(opening, i)"
                              *ngIf="!activeOpening && !opening.opening_details?.length">build_circle
                        </text>
                    </ng-container>
                    <ng-container *ngIf="editMode && !disableEdit">
                        <ng-container *ngFor="let handle of handles; let j = index">
                            <rect
                                    [id]="'drag-handle-' + j"
                                    class="drag-handle panzoom-exclude {{getHandleClass(j)}}"
                                    [attr.width]="handleSize"
                                    [attr.height]="handleSize"
                                    *ngIf="!activePoint"
                                    (mousedown)="$event.stopImmediatePropagation()"
                                    (mousemove)="$event.stopImmediatePropagation()"
                                    [attr.x]="handle.x"
                                    [attr.y]="handle.y"
                            />
                            <svg [attr.x]="handle.x" [attr.y]="handle.y"
                                 class="point-mask"
                                 *ngIf="(activePointIndex || activePointIndex === 0) && (activePointIndex * 2) === j">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
                                <svg
                                        (mousedown)="$event.stopImmediatePropagation()"
                                        (mousemove)="$event.stopImmediatePropagation()" [attr.x]="-4" [attr.y]="0"
                                        width="24px" height="24px" viewBox="0 0 24 24"
                                        (click)="movePoint(handle, -1, 0)"
                                        class="drag-handle panzoom-exclude"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <g>
                                            <rect width="24" height="24" opacity="0"/>
                                            <path d="M13.54 18a2.06 2.06 0 0 1-1.3-.46l-5.1-4.21a1.7 1.7 0 0 1 0-2.66l5.1-4.21a2.1 2.1 0 0 1 2.21-.26 1.76 1.76 0 0 1 1.05 1.59v8.42a1.76 1.76 0 0 1-1.05 1.59 2.23 2.23 0 0 1-.91.2z"/>
                                        </g>
                                    </g>
                                </svg>
                                <svg
                                        (mousedown)="$event.stopImmediatePropagation()"
                                        (mousemove)="$event.stopImmediatePropagation()" [attr.x]="0" [attr.y]="-4"
                                        width="24px" height="24px" viewBox="0 0 24 24"
                                        (click)="movePoint(handle, 0, -1)"
                                        class="drag-handle panzoom-exclude"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <g>
                                            <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                                            <path d="M16.21 16H7.79a1.76 1.76 0 0 1-1.59-1 2.1 2.1 0 0 1 .26-2.21l4.21-5.1a1.76 1.76 0 0 1 2.66 0l4.21 5.1A2.1 2.1 0 0 1 17.8 15a1.76 1.76 0 0 1-1.59 1z"/>
                                        </g>
                                    </g>
                                </svg>
                                <svg
                                        (mousedown)="$event.stopImmediatePropagation()"
                                        (mousemove)="$event.stopImmediatePropagation()" [attr.x]="4" [attr.y]="0"
                                        width="24px" height="24px" viewBox="0 0 24 24"
                                        (click)="movePoint(handle, 1, 0)"
                                        class="drag-handle panzoom-exclude"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <g>
                                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                                            <path d="M10.46 18a2.23 2.23 0 0 1-.91-.2 1.76 1.76 0 0 1-1.05-1.59V7.79A1.76 1.76 0 0 1 9.55 6.2a2.1 2.1 0 0 1 2.21.26l5.1 4.21a1.7 1.7 0 0 1 0 2.66l-5.1 4.21a2.06 2.06 0 0 1-1.3.46z"/>
                                        </g>
                                    </g>
                                </svg>
                                <svg
                                        (mousedown)="$event.stopImmediatePropagation()"
                                        (mousemove)="$event.stopImmediatePropagation()" [attr.x]="0" [attr.y]="4"
                                        width="24px" height="24px" viewBox="0 0 24 24"
                                        (click)="movePoint(handle, 0, 1)"
                                        class="drag-handle panzoom-exclude"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <g>
                                            <rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"/>
                                            <path d="M12 17a1.72 1.72 0 0 1-1.33-.64l-4.21-5.1a2.1 2.1 0 0 1-.26-2.21A1.76 1.76 0 0 1 7.79 8h8.42a1.76 1.76 0 0 1 1.59 1.05 2.1 2.1 0 0 1-.26 2.21l-4.21 5.1A1.72 1.72 0 0 1 12 17z"/>
                                        </g>
                                    </g>
                                </svg>
                            </svg>
                        </ng-container>
                    </ng-container>
                </g>

                <polygon
                        *ngIf="opening.configurationDrawingUrl && opening.category?.type !== categoryTypes.Doors"
                        [class.editing]="editMode"
                        fill="url(#Gradient1)"
                        [attr.points]="getMaskCoordinates(i)"
                />

                <image *ngIf="opening.configurationDrawingUrl"
                       class="product-preview"
                       [class.disabled]="disableEdit"
                       [attr.href]="opening.configurationDrawingUrl"
                       [attr.x]="opening.minX" [attr.y]="opening.minY"
                       [attr.width]="opening.pixelWidth"
                       (touchstart)="$event.stopImmediatePropagation()"
                       (click)="$event.stopImmediatePropagation(); editOpening(opening, i)"
                ></image>
            </ng-container>
        </svg>
    </app-zoom-pan-pinch>
</div>
