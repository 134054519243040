import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
    FINISH_MEASURE_CONFIGURATOR,
    SAVE_MEASURE_NOTES,
    UPDATE_MEASURE_CONFIGURATOR
} from '../mutations/measure.mutations';
import {
    GET_MEASURE_CONFIGURATOR_WITH_OPENING,
    GET_MEASURE_QUOTE,
    GET_MEASURE_QUOTE_CHANGES
} from '../queries/measure.queries';
import { MeasureConfigurationType } from '@shared/enums/measure-configuration-type';
import { MeasureQuote, MeasureQuoteChanges } from '@shared/interfaces/measure-quote';

@Injectable({
    providedIn: 'root'
})
export class MeasureService {
    productListSelections: any;
    private type: MeasureConfigurationType;

    constructor(private apollo: Apollo) {}

    setMeasureConfigurationType(type?: MeasureConfigurationType): void {
        this.type = type;
    }

    getMeasureQuoteWithAdders(appointmentId: number): Observable<MeasureQuote> {
        return this.apollo
            .query({
                query: GET_MEASURE_QUOTE,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    res.data.measureQuote.config_adders = res.data.measureQuote.config_adders.map((config) => {
                        const data = config.adder;

                        if (!isNaN(parseFloat(config.amount))) {
                            data.amount = config.amount;
                        }

                        data.quantity = config.quantity || 1;
                        data.position = config.position;
                        data.created_from = config.created_from;
                        data.applied_type = config.applied_type;
                        data.type = config.type;

                        return data;
                    });

                    return res.data.measureQuote;
                })
            );
    }

    getMeasureQuoteChanges(appointmentId: number): Observable<MeasureQuoteChanges> {
        return this.apollo
            .query({
                query: GET_MEASURE_QUOTE_CHANGES,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.measureQuoteChanges));
    }

    getConfigurator(quoteId: string, openingId: string, type: MeasureConfigurationType = this.type): Observable<any> {
        return this.apollo
            .query({
                query: GET_MEASURE_CONFIGURATOR_WITH_OPENING,
                variables: {
                    quoteId,
                    openingId,
                    type,
                    productListSelections: this.productListSelections
                }
            })
            .pipe(
                tap(() => (this.productListSelections = undefined)),
                map((res: ApolloQueryResult<any>) => res.data)
            );
    }

    finishConfiguration(quoteId: string, openingId: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: FINISH_MEASURE_CONFIGURATOR,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.finishMeasureConfigurator));
    }

    updateQuestion(quoteId: string, openingId: string, stepId: string, question: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: UPDATE_MEASURE_CONFIGURATOR,
                variables: {
                    quoteId,
                    openingId,
                    stepId,
                    question
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.updateMeasureQuestion));
    }

    saveNotes(appointmentId: string, measureNotes: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SAVE_MEASURE_NOTES,
                variables: {
                    appointmentId,
                    measureNotes
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.saveMeasureNotes));
    }
}
