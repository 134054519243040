import { gql } from 'apollo-angular';

const GET_ORGANIZATION_THEME_OPTIONS = gql`
    query OrganizationThemeOptions($subDomain: String!) {
        themeOptions(sub_domain: $subDomain) {
            logo_url
            background_url
            colors
        }
    }
`;

export { GET_ORGANIZATION_THEME_OPTIONS };
