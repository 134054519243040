import { gql } from 'apollo-angular';

const SaveAppointmentAssessmentAnswers = gql`
    mutation saveAppointmentAssessmentAnswers($appointmentId: ID!, $answers: [AppointmentAssessmentAnswerInput!]!) {
        saveAppointmentAssessmentAnswers(appointment_id: $appointmentId, answers: $answers)
    }
`;

const SetQuoteFieldsByAssessmentAnswers = gql`
    mutation setQuoteFieldsByAssessmentAnswers($appointmentId: ID!, $quoteIds: [ID!]!) {
        setQuoteFieldsByAssessmentAnswers(appointment_id: $appointmentId, quote_ids: $quoteIds)
    }
`;

export { SaveAppointmentAssessmentAnswers, SetQuoteFieldsByAssessmentAnswers };
