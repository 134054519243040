import { MenuItems } from '@shared/interfaces/menu';

const isOffline = window.localStorage.getItem('offline_mode') === '1';

export const DEFAULT_MENU_ITEMS: MenuItems = {
    hamburger: {
        top: [
            {
                icon: 'date_range',
                label: 'Appointments',
                link: '/main/appointments'
            },
            {
                icon: 'create',
                label: 'Edit Appointment',
                isHidden: true
            },
            {
                icon: 'account_balance_wallet',
                label: 'Change Tax',
                isHidden: true
            },
            {
                icon: 'insert_comment',
                label: 'Feedback',
                isHidden: isOffline
            },
            {
                icon: 'done_all',
                label: 'Final Steps',
                isHidden: true,
                link: '/main/appointments/${appointment_id}/final'
            },
            {
                icon: 'payments',
                label: 'Capture Payment',
                link: 'payment',
                isHidden: true
            },
            {
                icon: 'settings',
                label: 'User Preferences',
                isHidden: isOffline
            }
        ],
        bottom: [
            {
                icon: 'payments',
                label: 'Capture Payment',
                link: 'payment',
                isHidden: true
            },
            {
                icon: 'help',
                label: 'Need help?'
            },
            {
                icon: 'wifi_off',
                label: 'Offline Mode',
                hash: 'offline_mode',
                isHidden: true
            },
            {
                icon: 'exit_to_app',
                label: 'Logout',
                link: '/logout'
            }
        ]
    }
};
