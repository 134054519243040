import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-tags-modal',
    templateUrl: './tags-modal.component.html',
    styleUrls: ['./tags-modal.component.scss']
})
export class TagsModalComponent extends BaseModal implements OnInit {
    @Input() items: string[] = [];
    @Input() selected: string[] = [];
    @Input() isReadonly = false;
    headerText: string;
    customTag: string;
    options: any[] = [];
    isAddingCustomTag = false;

    constructor(public modalCtrl: ModalController) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.headerText = `${this.isReadonly ? '' : 'Select'} Conditions`;
        this.selected = cloneDeep(this.selected);
        const options: any[] = uniq(this.items.concat(this.selected)).map((value: string) => ({
            value,
            checked: this.selected.includes(value)
        }));

        this.options = this.isReadonly ? options.filter(({ checked }) => checked) : options;
    }

    selectionChanged(event): void {
        if (this.isAddingCustomTag) {
            this.isAddingCustomTag = false;

            return;
        }

        if (event.detail.checked) {
            this.selected.push(event.detail.value);
        } else {
            this.selected = this.selected.filter((item) => item !== event.detail.value);
        }
    }

    addCustom(): void {
        const tagIndex = this.options.findIndex((option) => option.value === this.customTag);

        if (tagIndex > -1 && this.options[tagIndex].checked) {
            this.customTag = '';

            return;
        }

        const value: string = this.customTag.trim();

        if (tagIndex === -1) {
            this.options.push({ value, checked: true });
        } else {
            this.options[tagIndex].checked = true;
            this.isAddingCustomTag = true;
        }
        this.selected.push(value);
        this.customTag = '';
    }

    save(): void {
        this.dismiss(this.selected);
    }
}
