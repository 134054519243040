import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Memoize } from 'lodash-decorators';

import { AsyncSubject, from, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import {
    COPY_PRICE_ATTRIBUTES,
    CREATE_OPENING_QUOTE,
    CREATE_QUOTE,
    CREATE_WCP_QUOTE,
    DELETE_QUOTE,
    EXCLUDE_LINE_ITEM,
    MAKE_PAYMENT,
    MOVE_LINE_ITEM,
    ORDER_QUOTE,
    REQUEST_FINANCING_APPLICATION,
    SAVE_DEPOSIT,
    SAVE_PRICING_CARDS,
    SET_FINANCING_OPTION,
    SYNC_PAYMENT
} from '@core/mutations/quote.mutations';
import { DictionaryService } from '@core/services/dictionary.service';
import { FeaturesService } from '@core/services/features.service';
import { GeolocationService } from '@core/services/geolocation.service';
import { RoundingService } from '@core/services/rounding.service';
import { FEATURES } from '@shared/constants/features';
import { CategoryType } from '@shared/enums/category-type';
import { ConfigurationType } from '@shared/enums/configuration-type.enum';
import { DepositType } from '@shared/enums/deposit-type.enum';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { PricePresentationType } from '@shared/enums/price-presentation-type.enum';
import { PromotionAmountType } from '@shared/enums/promotion.enum';
import { QuoteClientApplyToTypeEnum } from '@shared/enums/quote-client-apply-to-type.enum';
import { QuotePricePresentationFieldType } from '@shared/enums/quote-price-presentation-field-type.enum';
import { QuotePricingProfileApplyToType } from '@shared/enums/quote-pricing-profile-apply-to-type.enum';
import { Dictionary } from '@shared/interfaces/dictionary';
import {
    DepositPriceFields,
    ExtraPriceFields,
    FinancingOptionPriceFields
} from '@shared/interfaces/extra-price-fields';
import { FinancingOption } from '@shared/interfaces/financing-option';
import { PackagePriceAttribute } from '@shared/interfaces/package-price-attribute';
import { BatchExtraPriceFieldInputs, QuotePriceInfo } from '@shared/interfaces/quote';
import { QuoteClient } from '@shared/interfaces/quote-client';
import { QuoteDiscountLimitation } from '@shared/interfaces/quote-discount-limitation';
import { QuotePriceBookListItem } from '@shared/interfaces/quote-price-book';
import { QuotePricePresentationPackageViewSetting } from '@shared/interfaces/quote-price-presentation-package-view-setting';
import { QuotePricingProfileListItem } from '@shared/interfaces/quote-pricing-profile';
import { QuoteUpdatePkResult } from '@shared/interfaces/quote-update-pk-result';

import {
    AUTO_CONFIGURATOR,
    COPY_PACKAGE,
    QUOTES_UPDATE_PK,
    RELEASE_DOCUMENTS,
    SET_CLIENT,
    SET_PRICE_BOOK,
    SET_PRICING_PROFILE,
    SET_QUOTES_FIELDS,
    UPDATE_PACKAGE
} from '../../main/appointments/quote/mutations/quote.mutations';
import {
    APPLY_CUSTOM_PROMOTION,
    CHECK_OUT_OF_DATA_QUOTES,
    GET_ACTIVE_QUOTE_PRICE_PRESENTATION_TYPE,
    GET_ALL_FINANCING_OPTIONS,
    GET_APPOINTMENT_QUOTES,
    GET_AVAILABLE_PROMOTIONS,
    GET_BATCH_EXTRA_PRICE_FIELDS,
    GET_EXTRA_PRICE_FIELDS,
    GET_FINANCING_OFFER_CODES,
    GET_FINANCING_OPTIONS,
    GET_FINANCING_RATE_SHEETS,
    GET_PACKAGE,
    GET_PAYMENT_INFO,
    GET_PRICE_CARDS,
    GET_QUOTE_CLIENTS,
    GET_QUOTE_DISCOUNT_LIMITATIONS,
    GET_QUOTE_PACKAGE_NAMES,
    GET_QUOTE_PRICE_BOOKS,
    GET_QUOTE_PRICE_PRESENTATION_COMPARISON_SETTINGS,
    GET_QUOTE_PRICE_PRESENTATION_VIEW_SETTINGS,
    GET_QUOTE_PRICING_INFO,
    GET_QUOTE_PRICING_PROFILES,
    GET_QUOTES_FIELDS,
    GET_QUOTES_PRICING,
    GET_QUOTES_WITH_ADDERS,
    SHOW_CARD_PRESENTATION
} from '../../main/appointments/quote/queries/quotes.queries';
import { GET_CATALOG_QUOTES } from '../../main/appointments/takeoff/queries/category.queries';

@Injectable({
    providedIn: 'root'
})
export class QuoteService {
    get financingType(): string {
        return this.featureService.hasFeature(FEATURES.PARADIGM_FINANCE_SANDBOX) ? 'momnt_sandbox' : 'momnt';
    }

    constructor(
        private apollo: Apollo,
        private dictionaryService: DictionaryService,
        private geolocationService: GeolocationService,
        private featureService: FeaturesService,
        private roundingService: RoundingService
    ) {}

    createQuote(
        appointmentId: number,
        presentationType: ConfigurationType,
        geolocation = {},
        expectedCount?: number
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CREATE_QUOTE,
                variables: {
                    appointmentId,
                    presentationType,
                    geolocation,
                    expectedCount
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const hideQuestions = ['location'];

                    res.data.createQuote.forEach((item) => {
                        if (item.category.allow_configure_questions) {
                            return;
                        }

                        const loopOpeningDetails = [];

                        item.opening_details.forEach((details) => {
                            if (hideQuestions.indexOf(details.question.title.toLowerCase()) !== -1) {
                                return;
                            }

                            if (!isEmpty(details.question.show_if)) {
                                const parentQuestion = find(item.opening_details, (detail) => {
                                    return detail.question.hash === Object.keys(details.question.show_if)[0];
                                });

                                if (
                                    parentQuestion &&
                                    parentQuestion.answer === details.question.show_if[parentQuestion.question.hash]
                                ) {
                                    parentQuestion.answer = details.answer;
                                }
                            }

                            loopOpeningDetails.push(details);
                        });

                        item.opening_details = loopOpeningDetails.filter((detail) => isEmpty(detail.question.show_if));
                    });

                    const categories = res.data.createQuote.map((opening) => {
                        if (opening.category.allow_configure_questions) {
                            return {
                                ...opening.category,
                                questions: opening.opening_details
                            };
                        }

                        const questionNames = {
                            width: 'w',
                            height: 'h',
                            'product type': 'Style',
                            'door style': 'Style'
                        };

                        const questions = [];

                        opening.opening_details.forEach((q) => {
                            if (hideQuestions.indexOf(q.question.title.toLowerCase()) !== -1) {
                                return;
                            }

                            const loopQuestion = q;

                            loopQuestion.cssClass = q.question.title.toLowerCase().replace(' ', '');

                            if (
                                questionNames.hasOwnProperty(q.question.title.toLowerCase()) &&
                                opening.category.category_type !== CategoryType.Siding
                            ) {
                                loopQuestion.question.title = questionNames[q.question.title.toLowerCase()];
                            }

                            questions.push(loopQuestion);
                        });

                        return {
                            ...opening.category,
                            questions
                        };
                    });

                    return {
                        openings: res.data.createQuote.filter((opening) => !opening.temporary),
                        categories: orderBy(
                            uniqBy(categories, (category) => category.id),
                            ['name'],
                            ['desc']
                        )
                    };
                })
            );
    }

    deleteQuote(appointmentId: number, quoteId: string): Observable<any> {
        return this.apollo.mutate({
            mutation: DELETE_QUOTE,
            variables: {
                appointment_id: appointmentId,
                quote_id: quoteId
            }
        });
    }

    createWcpQuote(quoteId: string, clientId: string): Observable<any> {
        return this.apollo.mutate({
            mutation: CREATE_WCP_QUOTE,
            variables: {
                quote_id: quoteId,
                client_id: clientId
            }
        });
    }

    createOpeningQuote(appointmentId: number, catalogId: number, geolocation: any, clientId?: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CREATE_OPENING_QUOTE,
                variables: {
                    appointmentId,
                    catalogId,
                    clientId,
                    geolocation
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.openingQuote));
    }

    getCatalogQuotes(appointmentId: number, catalogId?: number): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_CATALOG_QUOTES,
                variables: {
                    appointmentId,
                    catalogId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.catalogQuotes));
    }

    getQuotesWithAdders(appointmentId: number, presentationType: ConfigurationType): Observable<any> {
        return this.apollo
            .query({
                query: GET_QUOTES_WITH_ADDERS,
                variables: {
                    appointmentId,
                    presentationType
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.quotesWithAdders.map((quote) => {
                        quote.config_adders = quote.config_adders.map((config) => {
                            const data = config.adder;

                            if (!isNaN(parseFloat(config.amount))) {
                                data.amount = config.amount;
                            }

                            data.quantity = config.quantity || 1;
                            data.position = config.position;
                            data.created_from = config.created_from;
                            data.applied_type = config.applied_type;
                            data.type = config.type;

                            return data;
                        });

                        return quote;
                    });
                })
            );
    }

    getQuoteClients(quoteId?: string): Observable<QuoteClient[]> {
        return this.apollo
            .query({
                query: GET_QUOTE_CLIENTS,
                ...(quoteId && {
                    variables: {
                        quote_id: quoteId
                    }
                })
            })
            .pipe(map((res: ApolloQueryResult<{ quoteClients: QuoteClient[] }>) => res.data.quoteClients));
    }

    getQuotePricingProfiles(quoteIds: string[]): Observable<QuotePricingProfileListItem[]> {
        return this.apollo
            .query({
                query: GET_QUOTE_PRICING_PROFILES,
                variables: {
                    quote_ids: quoteIds
                }
            })
            .pipe(
                map(
                    (res: ApolloQueryResult<{ quotePricingProfiles: QuotePricingProfileListItem[] }>) =>
                        res.data.quotePricingProfiles
                )
            );
    }

    getQuotePriceBooks(quoteId: string): Observable<QuotePriceBookListItem[]> {
        return this.apollo
            .query({
                query: GET_QUOTE_PRICE_BOOKS,
                variables: {
                    quote_ids: [quoteId]
                }
            })
            .pipe(
                map((res: ApolloQueryResult<{ quotePriceBooks: QuotePriceBookListItem[] }>) => res.data.quotePriceBooks)
            );
    }

    getAppointmentQuotes(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_QUOTES,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentQuotes));
    }

    getQuotePackageNames(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_QUOTE_PACKAGE_NAMES,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.appointmentQuotes));
    }

    orderQuote(quoteId: number): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: ORDER_QUOTE,
                variables: {
                    quoteId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data?.order));
    }

    applyCustomPromotion(
        appointmentId: number,
        quoteId: number,
        name: string,
        amount: number,
        amount_type: PromotionAmountType,
        discountIds?: string[]
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: APPLY_CUSTOM_PROMOTION,
                variables: {
                    appointmentId,
                    name,
                    amount,
                    amount_type,
                    quoteId,
                    discountIds
                }
            })
            .pipe(
                mergeMap((res: ApolloQueryResult<any>) =>
                    from(this.dictionaryService.getDictionaryName(DictionaryType.Adder)).pipe(
                        map((adderNameSetting: Dictionary) =>
                            this.remapPriceResponse(res.data.applyCustomPromotion, adderNameSetting)
                        )
                    )
                )
            );
    }

    getQuotesPricing(
        appointmentId: number,
        quoteId?: number,
        discountIds?: string[],
        silentRequest = false
    ): Observable<any> {
        return from(this.geolocationService.getGeolocation()).pipe(
            mergeMap((coordinates: any) => {
                return this.apollo
                    .query({
                        query: GET_QUOTES_PRICING,
                        variables: {
                            appointmentId,
                            quoteId,
                            discountIds,
                            geolocation: coordinates
                        },
                        context: {
                            extensions: {
                                background: silentRequest
                            }
                        }
                    })
                    .pipe(
                        mergeMap((res: ApolloQueryResult<any>) =>
                            from(this.dictionaryService.getDictionaryName(DictionaryType.Adder)).pipe(
                                map((adderNameSetting: Dictionary) =>
                                    this.remapPriceResponse(res.data.price, adderNameSetting)
                                )
                            )
                        )
                    );
            })
        );
    }

    getQuotePriceInfo(quoteId: number, silentRequest = false): Observable<QuotePriceInfo> {
        return this.apollo
            .query({
                query: GET_QUOTE_PRICING_INFO,
                variables: {
                    quoteId
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.priceInfo));
    }

    excludeLineItem(openingId: number, quoteId: number, isExcluded: boolean): Observable<any> {
        return this.apollo
            .mutate({
                mutation: EXCLUDE_LINE_ITEM,
                variables: {
                    quoteId,
                    openingId,
                    isExcluded,
                    returnPrices: true
                }
            })
            .pipe(
                mergeMap((res: ApolloQueryResult<any>) =>
                    from(this.dictionaryService.getDictionaryName(DictionaryType.Adder)).pipe(
                        map((adderNameSetting: Dictionary) =>
                            this.remapPriceResponse(res.data.excludeQuoteOpening, adderNameSetting)
                        )
                    )
                )
            );
    }

    getAvailablePromotions(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_AVAILABLE_PROMOTIONS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myPromotions));
    }

    getFinancingOptions(
        appointmentId?: number,
        quoteId?: number,
        silentRequest = false
    ): Observable<{ financingOptions: FinancingOption[]; financingUsers: any[] }> {
        const hasParadigmFinanceFeature = this.featureService.hasFeature(
            [FEATURES.PARADIGM_FINANCE, FEATURES.PARADIGM_FINANCE_SANDBOX],
            false
        );

        return this.apollo
            .query({
                query: hasParadigmFinanceFeature ? GET_ALL_FINANCING_OPTIONS : GET_FINANCING_OPTIONS,
                variables: {
                    appointmentId,
                    quoteId,
                    financingType: this.financingType
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    let financingUsers: any[] = [];

                    if (!hasParadigmFinanceFeature || !res.data.myFinanceUsers?.length) {
                        financingUsers = [];
                    } else {
                        financingUsers = res.data.myFinanceUsers.filter(
                            (item) =>
                                !res.data.myFinancingOptions.find(
                                    (option) =>
                                        option.appointment_id && option.provider.type === item.financing_merchant.type
                                )
                        );
                    }

                    return {
                        financingOptions: res.data.myFinancingOptions,
                        financingUsers
                    };
                })
            );
    }

    getFinancingOfferCodes(): Observable<any[]> {
        if (!this.featureService.hasFeature([FEATURES.PARADIGM_FINANCE, FEATURES.PARADIGM_FINANCE_SANDBOX], false)) {
            return of([]);
        }

        return this.apollo
            .query({
                query: GET_FINANCING_OFFER_CODES,
                variables: {
                    financingType: this.financingType
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myFinancingOfferCodes));
    }

    getFinancingRateSheets(): Observable<any[]> {
        if (!this.featureService.hasFeature([FEATURES.PARADIGM_FINANCE, FEATURES.PARADIGM_FINANCE_SANDBOX], false)) {
            return of([]);
        }

        return this.apollo
            .query({
                query: GET_FINANCING_RATE_SHEETS,
                variables: {
                    financingType: this.financingType,
                    isActive: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myFinancingRateSheets));
    }

    requestFinancingApplication(quoteId: number, consumerInfo: any): Observable<any> {
        return this.apollo.mutate({
            mutation: REQUEST_FINANCING_APPLICATION,
            variables: {
                quoteId,
                consumer: consumerInfo,
                financingType: this.financingType
            }
        });
    }

    getPaymentInfo(quoteId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_PAYMENT_INFO,
                variables: {
                    quoteId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.paymentInfo));
    }

    getQuotePackage(appointmentId: number, packageIndex: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_PACKAGE,
                variables: {
                    appointmentId,
                    packageIndex
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.quotePackage));
    }

    updateQuotePackage(quoteId: number, packageName: string): Observable<any> {
        return this.apollo
            .query({
                query: UPDATE_PACKAGE,
                variables: {
                    quoteId,
                    packageName
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data));
    }

    copyQuotePackage(quoteId: number, copyToQuoteIds: any): Observable<any> {
        return this.apollo
            .query({
                query: COPY_PACKAGE,
                variables: {
                    quoteId,
                    copyToQuoteIds
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data));
    }

    getQuoteFields(quoteIds: string[]): Observable<any> {
        return this.apollo
            .query({
                query: GET_QUOTES_FIELDS,
                variables: {
                    quoteIds
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.quoteFields));
    }

    setQuoteFields(quoteId: string, quoteFields: any): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SET_QUOTES_FIELDS,
                variables: {
                    quoteId,
                    quoteFields
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.setQuoteFields));
    }

    setQuoteClient(quoteId: string, clientId: string, applyTo: QuoteClientApplyToTypeEnum): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SET_CLIENT,
                variables: {
                    quoteId,
                    clientId,
                    applyTo
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data));
    }

    setQuotePricingProfile(
        quoteId: string,
        applyTo: QuotePricingProfileApplyToType,
        isUpdateExistingLines: boolean,
        pricingProfileId?: string
    ): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SET_PRICING_PROFILE,
                variables: {
                    quoteId,
                    applyTo,
                    pricingProfileId,
                    isUpdateExistingLines
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.setQuotePricingProfile));
    }

    setQuotePriceBook(quoteId: string, priceBookId: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SET_PRICE_BOOK,
                variables: {
                    quoteId,
                    priceBookId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.setQuotePriceBook));
    }

    makePayment(quoteId: string, paymentData: any): Observable<any> {
        return this.apollo
            .query({
                query: MAKE_PAYMENT,
                variables: {
                    quote_id: quoteId,
                    payment_data: paymentData
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.payment));
    }

    syncPayment(quoteId: number, paymentId: number): Observable<any> {
        return this.apollo
            .query({
                query: SYNC_PAYMENT,
                variables: {
                    quote_id: quoteId,
                    payment_id: paymentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.syncPayment));
    }

    setFinancingOption(quote_id: number, financing_option_id: number | string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SET_FINANCING_OPTION,
                variables: {
                    quote_id,
                    financing_option_id
                },
                context: {
                    extensions: {
                        background: true
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.financingOption));
    }

    saveDeposit(quote_id: number, isAmountDeposit: boolean, deposit_amount: number): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SAVE_DEPOSIT,
                variables: {
                    quote_id,
                    deposit_type: isAmountDeposit ? DepositType.STATIC : DepositType.PERCENT,
                    deposit_amount
                },
                context: {
                    extensions: {
                        background: true
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.saveDeposit));
    }

    copyPriceAttributes(
        appointment_id: number,
        quote_id: number,
        price_attributes: PackagePriceAttribute[]
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: COPY_PRICE_ATTRIBUTES,
                variables: {
                    appointment_id,
                    quote_id,
                    price_attributes
                }
            })
            .pipe(
                mergeMap((res: ApolloQueryResult<any>) =>
                    from(this.dictionaryService.getDictionaryName(DictionaryType.Adder)).pipe(
                        map((adderNameSetting: Dictionary) =>
                            this.remapPriceResponse(res.data.copyPriceAttributes, adderNameSetting)
                        )
                    )
                )
            );
    }

    @Memoize((finalPrice: number, deposit?: DepositPriceFields, financingOption?: FinancingOptionPriceFields) =>
        JSON.stringify({ finalPrice, deposit, financingOption })
    )
    getExtraPriceFields(
        finalPrice: number,
        deposit?: DepositPriceFields,
        financingOption?: FinancingOptionPriceFields
    ): Observable<ExtraPriceFields> {
        const subject = new AsyncSubject<ExtraPriceFields>();

        this.apollo
            .mutate({
                mutation: GET_EXTRA_PRICE_FIELDS,
                variables: {
                    finalPrice,
                    deposit,
                    financingOption
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.extraPriceFields))
            .subscribe(subject);

        return subject.asObservable().pipe(first());
    }

    getQuotePricePresentationViewSettings(): Observable<QuotePricePresentationPackageViewSetting[]> {
        return this.apollo
            .query({
                query: GET_QUOTE_PRICE_PRESENTATION_VIEW_SETTINGS
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const index = res.data.quotePricePresentationViewSettings.findIndex(
                        ({ hash }) => hash === QuotePricePresentationFieldType.Balance
                    );
                    const boldField = res.data.quotePricePresentationViewSettings[index].fields.find(
                        ({ hash }) => hash === 'is_bold'
                    );

                    res.data.quotePricePresentationViewSettings.splice(index + 1, 0, {
                        hash: QuotePricePresentationFieldType.BalanceWithFinancingOption,
                        fields: [
                            {
                                hash: QuotePricePresentationFieldType.BalanceWithFinancingOption,
                                value: res.data.quotePricePresentationViewSettings[index].fields.find(
                                    ({ hash }) => hash === QuotePricePresentationFieldType.BalanceWithFinancingOption
                                ).value
                            },
                            {
                                hash: 'is_bold',
                                value: boldField.value
                            }
                        ],
                        is_active: res.data.quotePricePresentationViewSettings[index].is_active
                    });
                    res.data.quotePricePresentationViewSettings.forEach((setting) => {
                        setting.fields = setting.fields.reduce((acc, { hash, value }) => {
                            acc[hash] = value;

                            return acc;
                        }, {});
                    });

                    return res.data.quotePricePresentationViewSettings;
                })
            );
    }

    showCardPresentation(appointmentId: number): Observable<boolean> {
        return this.apollo
            .query({
                query: SHOW_CARD_PRESENTATION,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.showCardPresentation));
    }

    getPriceCards(appointmentId: number, quoteId: number, discountIds: any[] = []): Observable<any> {
        return this.apollo
            .query({
                query: GET_PRICE_CARDS,
                variables: {
                    appointmentId,
                    quoteId,
                    discountIds
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    res.data.priceCards.view_settings.forEach(({ hash, fields }) => {
                        if (
                            ![
                                QuotePricePresentationFieldType.PackagePrice,
                                QuotePricePresentationFieldType.SubTotal
                            ].includes(hash)
                        ) {
                            return;
                        }

                        const field = fields.find((item) => item.hash === hash);

                        if (!field) {
                            return;
                        }

                        if (hash === QuotePricePresentationFieldType.PackagePrice) {
                            res.data.priceCards.cards[0].priceLabel = field.value;
                        } else {
                            res.data.priceCards.cards[1].priceLabel = field.value;
                            res.data.priceCards.cards[2].priceLabel = field.value;
                        }
                    });

                    return res.data.priceCards;
                })
            );
    }

    savePriceCards(
        appointmentId: number,
        quoteId: number,
        discountIds: any[] = [],
        financingOptionId,
        projectDescription: string
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_PRICING_CARDS,
                variables: {
                    appointmentId,
                    quoteId,
                    discountIds,
                    financingOptionId,
                    projectDescription
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.savePriceCards));
    }

    moveLineItem(quote_id: number, origin_index: number, target_index: number): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: MOVE_LINE_ITEM,
                variables: {
                    quote_id,
                    origin_index,
                    target_index
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.moveLineItem));
    }

    getDiscountLimitations(appointmentId: number, quoteId?: number): Observable<QuoteDiscountLimitation[]> {
        return this.apollo
            .mutate({
                mutation: GET_QUOTE_DISCOUNT_LIMITATIONS,
                variables: {
                    appointmentId,
                    quoteId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.quoteDiscountLimitations));
    }

    checkOutOfDateQuotes(appointmentId: number): Observable<boolean> {
        return this.apollo
            .query({
                query: CHECK_OUT_OF_DATA_QUOTES,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.checkOutOfDateQuotes));
    }

    quotesUpdatePk(appointmentId: number, silentRequest = false): Observable<QuoteUpdatePkResult[]> {
        return this.apollo
            .query({
                query: QUOTES_UPDATE_PK,
                variables: {
                    appointmentId,
                    isBackground: true
                },
                context: {
                    extensions: {
                        background: silentRequest
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.quotesUpdatePk));
    }

    applyAutoConfigurator(appointmentId: number, catalogId?: number, openingId?: number): Observable<boolean> {
        return this.apollo
            .query({
                query: AUTO_CONFIGURATOR,
                variables: {
                    appointmentId,
                    catalogId,
                    openingId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.autoConfigurator));
    }

    private remapPriceResponse(price: any, adderNameSetting: Dictionary): any {
        if (!price) {
            return [];
        }

        price.forEach((quote) => {
            quote.discount_percentage = ((quote.total_price - quote.final_price) / quote.total_price) * 100;
            quote.final_discount_amount = quote.total_price - quote.final_price;

            quote.line_items.forEach((item) => {
                item.discounts_combined = uniqBy(item.adders_discount_details.concat(item.discount_details), 'id');

                if (!item.answers?.length) {
                    return;
                }

                const designDetails = item.answers.filter(
                    (step) => step.stepName !== 'Advanced' && step.stepName !== 'Hidden'
                );

                item.details = [
                    {
                        label: 'Design Details',
                        answers:
                            designDetails.length > 1
                                ? designDetails.reduce((acc, val) => (acc = acc.concat(val.answers)), [])
                                : designDetails[0]?.answers || [],
                        defaultDrawing: 'exterior'
                    },
                    {
                        label: 'Technical Details',
                        answers:
                            get(
                                item.answers.filter((step) => step.stepName === 'Advanced'),
                                [0, 'answers'],
                                []
                            ) || [],
                        defaultDrawing: 'elevation'
                    },
                    {
                        label: 'Technical Details',
                        answers:
                            item.attributes?.map((attribute) => {
                                return {
                                    question: attribute.question,
                                    answer: attribute.values.join(', ')
                                };
                            }) || [],
                        defaultDrawing: 'exterior'
                    },
                    {
                        label: adderNameSetting.plural,
                        answers: item.adders,
                        defaultDrawing: 'exterior'
                    },
                    {
                        label: 'Accessories',
                        answers: item.accessories,
                        defaultDrawing: 'exterior'
                    },
                    {
                        label: 'Change Details',
                        answers: [
                            ...(item.changes || []),
                            ...item.adders.reduce(
                                (acc, adder) => (adder.changes ? acc.concat(adder.changes) : acc),
                                []
                            ),
                            ...(item.custom_changes || [])
                        ],
                        defaultDrawing: 'exterior'
                    }
                ];

                item.details = item.details.filter((detail) => detail.answers?.length);
            });

            quote.excluded_items = quote.line_items.filter((item) => item.excluded);
            quote.line_items = quote.line_items.filter((item) => !item.excluded);
        });

        return price;
    }

    calculateMonthlyPayment(financingOption: FinancingOption, balance: number): number {
        let balanceWithDiscountFactor: number;

        if (financingOption.payment_factor) {
            balanceWithDiscountFactor = balance * financingOption.payment_factor;
        } else {
            const periodicInterestRate: number = financingOption.interest_rate / 100 / 12;
            const monthsFinanced: number = financingOption.months;
            let discountFactor = monthsFinanced;

            // Monthly Payment = Balance * ((Periodic Interest Rate/12) / (1 - (1+(Periodic Interest Rate/12)^(-months financed)
            if (periodicInterestRate !== 0) {
                discountFactor = periodicInterestRate / (1 - Math.pow(1 + periodicInterestRate, -monthsFinanced));
                balanceWithDiscountFactor = discountFactor * balance;
            } else {
                balanceWithDiscountFactor = balance / discountFactor;
            }
        }

        return this.roundingService.round(balanceWithDiscountFactor + Number.EPSILON);
    }

    getActiveQuotePricePresentationType(): Observable<PricePresentationType> {
        return this.apollo
            .query({
                query: GET_ACTIVE_QUOTE_PRICE_PRESENTATION_TYPE
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.activeQuotePricePresentationType));
    }

    getQuotePricePresentationComparisonSettings(): Observable<any> {
        return this.apollo
            .query({
                query: GET_QUOTE_PRICE_PRESENTATION_COMPARISON_SETTINGS
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.quotePricePresentationComparisonSettings));
    }

    getBatchExtraPriceFields(inputs: BatchExtraPriceFieldInputs[]): Observable<any> {
        return this.apollo
            .query({
                query: GET_BATCH_EXTRA_PRICE_FIELDS,
                variables: {
                    inputs
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.batchExtraPriceFields));
    }

    releaseDocuments(appointmentId: string, quoteIds: string[], cancellationReason: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: RELEASE_DOCUMENTS,
                variables: {
                    appointmentId,
                    quoteIds,
                    cancellationReason
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.releaseDocuments));
    }
}
