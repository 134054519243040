<div class="modal-header">
  <ion-text class="title">Electronic Signature Consent</ion-text>
</div>
<div class="modal-body">
  {{ activeStep.text }}
</div>
<div class="modal-footer">
  <ion-button class="classic-width" fill="outline" (click)="goBack()">{{activeStep.cancel_button_name}}</ion-button>
  <ion-button class="classic-width" (click)="nextStep()">{{activeStep.next_button_name}}</ion-button>
</div>
