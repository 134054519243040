export enum PromotionType {
    Smart = 'smart',
    Standard = 'standard',
    Hybrid = 'hybrid'
}

export enum PromotionAmountType {
    EachFollowingFree = 'each_following_free',
    Percentage = 'percentage',
    Static = 'static',
    QuestionMultiplier = 'question_multiplier'
}

export enum PromotionAppliesToHash {
    Adder = 'adder',
    Category = 'category',
    Project = 'project',
    Accessory = 'accessory'
}

export enum PromotionStackableWithType {
    Product = 'product',
    Adder = 'adder',
    Project = 'project'
}

export const PromotionAppliesToStackableMapping = {
    [PromotionAppliesToHash.Adder]: PromotionStackableWithType.Adder,
    [PromotionAppliesToHash.Category]: PromotionStackableWithType.Product,
    [PromotionAppliesToHash.Accessory]: PromotionStackableWithType.Product,
    [PromotionAppliesToHash.Project]: PromotionStackableWithType.Project
};
