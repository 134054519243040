import { Component, OnDestroy, OnInit } from '@angular/core';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { Subject, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';

@Component({
    selector: 'vendo-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private storage: Storage,
        private platform: Platform,
        private navCtrl: NavController,
        private splashScreen: SplashScreen,
        private deviceHelper: DeviceHelperService,
        private authService: AuthService,
        private rollbarErrorHandler: RollbarErrorHandler,
        private msalBroadcastService: MsalBroadcastService
    ) {}

    async ngOnInit(): Promise<void> {
        if ((this.deviceHelper.isAndroidPlatform || this.deviceHelper.isIOSPlatform) && !this.deviceHelper.isWeb) {
            this.splashScreen.show();
        }

        await this.platform.ready();

        const isParadigmAuth: boolean = (await this.storage.get('isParadigmAuth')) === 'true';

        console.log('HomePageComponent', isParadigmAuth);

        if (isParadigmAuth) {
            this.msalBroadcastService.inProgress$
                .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
                .subscribe(() => this.handleToken());
        } else {
            timer(1000).subscribe(() => this.handleToken());
        }
    }

    ngOnDestroy(): void {
        this.splashScreen.hide();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private handleToken(): void {
        this.storage.get('token').then((token) => {
            console.log('handleToken', !token && !this.authService.getToken());
            if (!token && !this.authService.getToken()) {
                this.rollbarErrorHandler.handleInfo('Logout - Token not found in HomePageComponent');
                const returnUrl: string = this.authService.getReturnUrl();

                this.navCtrl.navigateRoot(['logout'], returnUrl ? { queryParams: { returnUrl } } : undefined);
            } else {
                this.navCtrl.navigateRoot(['main']);
            }
        });
    }
}
