import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import panzoom from 'panzoom';

import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

@Component({
    selector: 'app-zoom-pan-pinch',
    templateUrl: './zoom-pan-pinch.component.html',
    styleUrls: ['./zoom-pan-pinch.component.scss']
})
export class ZoomPanPinchComponent implements AfterViewInit, OnDestroy {
    @ViewChild('scene', { static: false }) scene: ElementRef;

    private instance: any;

    ngAfterViewInit(): void {
        this.instance = panzoom(this.scene.nativeElement, {
            minZoom: 1,
            maxZoom: 7,
            bounds: true,
            boundsPadding: 1,
            beforeMouseDown: (e) => {
                return (
                    (e.target as any).classList.contains('drag-handle') || (e.target as any).classList.contains('mask')
                );
            }
        });
    }

    setPosition(x: number, y: number, zoomLevel): void {
        if (!x || !y) {
            return;
        }

        this.instance.zoomAbs(
            x, // initial x position
            y, // initial y position
            zoomLevel // initial zoom
        );
    }

    showRect(rect: { right: number; left: number; top: number; bottom: number }): void {
        this.instance.showRectangle(rect);
    }

    public disableZooming(): void {
        this.instance.pause();
    }

    public getZoomLevel(): number {
        return this.instance.getTransform().scale;
    }

    public enableZooming(): void {
        this.instance.resume();
    }

    zoomToFit(
        coordinates: { x: number; y: number }[],
        imageWidth: number,
        imageHeight: number,
        zoomLevel?: number
    ): void {
        if (!coordinates?.length) {
            return;
        }
        const minX = minBy(coordinates, (coordinate) => coordinate.x).x - 20;
        const minY = minBy(coordinates, (coordinate) => coordinate.y).y - 20;
        const maxX = maxBy(coordinates, (coordinate) => coordinate.x).x + 20;
        const maxY = maxBy(coordinates, (coordinate) => coordinate.y).y + 20;
        const zoomByX = imageWidth / (maxX - minX);
        const zoomByY = imageHeight / (maxY - minY);

        if (!zoomLevel) {
            zoomLevel = zoomByX > zoomByY ? zoomByY : zoomByX;
        }

        const centerX = (minX - maxX) / 2 + maxX;
        const centerY = (minY - maxY) / 2 + maxY;

        this.setPosition(centerX, centerY, zoomLevel);

        if (zoomLevel !== 1) {
            this.showRect({ right: maxX, left: minX, top: minY, bottom: maxY });
        }
    }

    ngOnDestroy(): void {
        this.instance.dispose();
    }
}
