import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[tqMIntegerInput]'
})
export class IntegerInputDirective {
    @Input('tqMIntegerInput') pattern: string;

    @HostListener('keypress', ['$event'])
    onInput(event: any): boolean {
        const pattern = this.pattern ? new RegExp(this.pattern) : /[0-9]/; // without ., for integer only
        const inputChar = String.fromCharCode(event.which || event.keyCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
            event.stopPropagation();

            return false;
        }

        return true;
    }
}
