import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { ERROR_MESSAGES } from '@shared/constants/error-messages';

@Injectable({
    providedIn: 'root'
})
export class FormHelperService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    getMessages(errors): string[] {
        const messages = [];

        forEach(errors, (hasError, key) => {
            if (hasError) {
                let message = get(ERROR_MESSAGES, key);

                if (!message) {
                    return;
                }

                if (isObject(hasError)) {
                    forEach(hasError, (value, errorProperty) => {
                        const replacement = isArray(value) ? value.join(' or ') : value;

                        message = message.replace(`{${errorProperty}}`, replacement);
                    });
                }

                messages.push(message);
            }
        });

        return messages;
    }

    setPopoverWidth(event?: any): void {
        this.document.documentElement.style.setProperty(
            '--select-popover-width',
            `${event ? event.target.clientWidth : 300}px`
        );
    }

    uuidv4(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c == 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    }
}
