import { Injectable } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { Appointment } from '@shared/interfaces/appointment';

@Injectable({
    providedIn: 'root'
})
export class CategoryHelperService {
    constructor(private authService: AuthService) {}

    filterCategories(
        appointment: Appointment,
        categories: any[],
        isOnlyAllowTakeoff: boolean,
        requiredCategoryTypes?: any[]
    ): any[] {
        return categories.filter(
            (category) =>
                (requiredCategoryTypes?.length && requiredCategoryTypes.includes(category.category.category_type)) ||
                ((!isOnlyAllowTakeoff || category.category.allow_takeoff) &&
                    (!this.isFilterByProductInterest(appointment.products_interests) ||
                        appointment.products_interests.includes(category.name)))
        );
    }

    isFilterByProductInterest(productsInterests: string[]): boolean {
        const user = this.authService.getUser();

        return user.office.product_filtering_settings?.filter_catalogs_by_interest && !!productsInterests.length;
    }
}
