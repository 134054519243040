import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppointmentReloadService {
    private reloadAppointmentList: Subject<void> = new Subject<void>();
    reloadAppointmentList$: Observable<void> = this.reloadAppointmentList.asObservable();

    constructor() {}

    emitReloadAppointmentList(): void {
        this.reloadAppointmentList.next();
    }
}
