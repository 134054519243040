import { forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';

export class DemoResourcesOffline {
    constructor(private offlineStorageService: OfflineStorageService, private auth: AuthService) {}

    getDemoResources(): Observable<any> {
        const user = this.auth.getUser();

        const resourcesPromise = this.offlineStorageService.read(`
            SELECT * FROM DemoResources
            WHERE user_id='${user.id}' AND office_id='${user.office.id}'
        `);
        const userPreferencesPromise = this.offlineStorageService.findOne(`
            SELECT * FROM UserPreferences
            WHERE user_id='${user.id}'
        `);

        return forkJoin([from(resourcesPromise), from(userPreferencesPromise)]).pipe(
            map(([resources, userPreferences]) => {
                const demoResourcesOrder = userPreferences.preferences.find(
                    ({ type }) => type === 'demo_resources_order'
                );

                if (demoResourcesOrder?.value?.length) {
                    resources.sort((option1, option2) => {
                        const option1Index = demoResourcesOrder.value.indexOf(option1.id.toString());
                        const option2Index = demoResourcesOrder.value.indexOf(option2.id.toString());

                        if (option2Index === -1 || option1Index > option2Index) {
                            return 1;
                        }

                        if (option1Index < option2Index) {
                            return -1;
                        }

                        return 0;
                    });
                } else {
                    resources.sort((a, b) => {
                        if (a.id > b.id) {
                            return 1;
                        }

                        if (a.id < b.id) {
                            return -1;
                        }

                        return 0;
                    });
                }

                return resources;
            })
        );
    }

    getDemoResource(id: number, appointment_id: number, geolocation = {}): Observable<any> {
        const user = this.auth.getUser();
        const resource = this.offlineStorageService.findOne(`
            SELECT * FROM DemoResources
            WHERE id='${id}'
            AND user_id='${user.id}'
    `);

        return from(resource);
    }
}
