import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'vendo-yes-no-radio',
    templateUrl: './yes-no-radio.component.html',
    styleUrls: ['./yes-no-radio.component.scss']
})
export class YesNoRadioComponent {
    answer: boolean;

    @Output() answerSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

    setAnswer(value: boolean): void {
        this.answer = value;
        this.answerSelected.emit(value);
    }
}
