import { gql } from 'apollo-angular';

const SAVE_USER_PREFERENCE = gql`
    mutation SaveUserPreference($type: String!, $value: JSON!) {
        saveUserPreference(type: $type, value: $value) {
            type
            value
        }
    }
`;

export { SAVE_USER_PREFERENCE };
