import { Injectable } from '@angular/core';

import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppUpdatesService {
    updatingProcess = new BehaviorSubject<any>({
        in_progress: false
    });

    constructor(private deploy: Deploy) {}

    async performLiveUpdates(): Promise<void> {
        await this.defineChannel();

        const update = await this.deploy.checkForUpdate();

        if (update.available) {
            await this.deploy.downloadUpdate((progress) => {
                if (progress < 100) {
                    this.updatingProcess.next({
                        in_progress: true,
                        progress,
                        action: 'Downloading'
                    });
                } else {
                    this.updatingProcess.next({
                        in_progress: false
                    });
                }
            });
            await this.deploy.extractUpdate((progress) => {
                if (progress < 100) {
                    this.updatingProcess.next({
                        in_progress: true,
                        progress,
                        action: 'Installing'
                    });
                } else {
                    this.updatingProcess.next({
                        in_progress: false
                    });
                }
            });
            await this.deploy.reloadApp();
        }
    }

    private async defineChannel(): Promise<void> {
        return this.deploy.configure({
            channel: environment.ionic.channel_name,
            appId: environment.ionic.app_id
        });
    }
}
