import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

import versions from '../environments/app-versions';
import { environment } from '../environments/environment';

const azureAdB2C = {
    // todo env
    instance: 'https://auth.myparadigmcloud.com',
    clientId: '4e84f603-d638-49a2-8bbb-646e2e27e269',
    domain: 'eb53bf10-ee6f-4517-bd67-f3f32fab2a90',
    signUpSignInPolicy: 'B2C_1A_SIGNINSIGNUP_OTP'
};
const authorityBaseAddress = `${azureAdB2C.instance}/${azureAdB2C.domain}`;

export const b2cPolicies = {
    names: {
        signUpSignIn: azureAdB2C.signUpSignInPolicy
    },
    authorities: {
        signUpSignIn: {
            authority: `${authorityBaseAddress}/${azureAdB2C.signUpSignInPolicy}`
        }
    },
    authorityDomain: azureAdB2C.domain,
    authorityBaseAddress
};

export const msalConfig: Configuration = {
    auth: {
        clientId: azureAdB2C.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain, b2cPolicies.authorityBaseAddress],
        redirectUri: environment.siteUrl,
        // redirectUri: window.location.origin, // local development
        postLogoutRedirectUri: `${environment.siteUrl}/login`,
        // postLogoutRedirectUri: `${window.location.origin}/login`, // local development
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // maybe use sessionStorage
        storeAuthStateInCookie: false
    },
    telemetry: {
        application: {
            appName: 'Vendo App',
            appVersion: versions.appVersion
        }
    },
    system: {
        loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
            },
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
        }
    }
};

export const protectedResources = {
    webApi: {
        endpoint: `${environment.apiUrl}/graphql`,
        scopes: ['https://autheastusprodb2c.onmicrosoft.com/vendo_lt_service/user'] // todo from env
    }
};
export const loginRequest = {
    scopes: [...protectedResources.webApi.scopes],
    redirectStartPage: `${window.location.origin}/main`
};
