import { gql } from 'apollo-angular';

const GET_MEASUREMENT_SYSTEM = gql`
    query MyMeasurementSystems($appointmentId: ID!) {
        myMeasurementSystems(appointment_id: $appointmentId) {
            hash
            is_enabled
            allow_skip_credentials
            allow_multiple_reports
            category_types
            reports {
                id
                type
                status
                category_types
                appointment_id
                report_id
                report_link
                address_details {
                    address1
                    address2
                    city
                    state
                    postal_code
                    country
                }
            }
        }
    }
`;

const GET_USER_REMEMBER = gql`
    query GetUserRemember($type: String!) {
        publicRememberProperties(type: $type)
    }
`;

const SEARCH_MEASUREMENT_SYSTEM_REPORTS = gql`
    query searchMeasurementSystemReports(
        $type: MeasurementSystemTypeEnum!
        $appointment_id: ID!
        $search: String
        $offset: Int
        $limit: Int
        $credentials: JSON
        $remember: Boolean
        $used_remember: Boolean
    ) {
        searchMeasurementSystemReports(
            type: $type
            appointment_id: $appointment_id
            search: $search
            offset: $offset
            limit: $limit
            credentials: $credentials
            remember: $remember
            used_remember: $used_remember
        ) {
            totalCount
            entities {
                status
                report_id
                report_link
                address_details {
                    address1
                    address2
                    city
                    state
                    postal_code
                    country
                }
                placed_at
                completed_at
            }
        }
    }
`;

const GET_MEASUREMENT_SYSTEM_REPORT_DETAILS = gql`
    query GetMeasurementSystemReportDetails(
        $type: MeasurementSystemTypeEnum!
        $appointment_id: ID!
        $id: ID
        $report_id: ID
        $credentials: JSON
        $remember: Boolean
        $used_remember: Boolean
    ) {
        getMeasurementSystemReportDetails(
            type: $type
            appointment_id: $appointment_id
            id: $id
            report_id: $report_id
            credentials: $credentials
            remember: $remember
            used_remember: $used_remember
        ) {
            category_types
            takeoff_details
            siding_types
        }
    }
`;

const GET_MEASUREMENT_SYSTEM_USER_DETAILS = gql`
    query GetMeasurementSystemUserDetails(
        $type: MeasurementSystemTypeEnum!
        $appointment_id: ID!
        $credentials: JSON
        $remember: Boolean
        $used_remember: Boolean
    ) {
        getMeasurementSystemUserDetails(
            type: $type
            appointment_id: $appointment_id
            credentials: $credentials
            remember: $remember
            used_remember: $used_remember
        ) {
            email
        }
    }
`;

export {
    GET_MEASUREMENT_SYSTEM,
    GET_USER_REMEMBER,
    SEARCH_MEASUREMENT_SYSTEM_REPORTS,
    GET_MEASUREMENT_SYSTEM_REPORT_DETAILS,
    GET_MEASUREMENT_SYSTEM_USER_DETAILS
};
