import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

import get from 'lodash/get';

@Injectable({
    providedIn: 'root'
})
export class ParadigmFinanceRateSheetSelectedService {
    readonly storageKey = 'rate-sheet-selected';

    constructor(private storage: Storage) {}

    async setRateSheet(appointmentId: number, quoteId: number, rateSheetId: string): Promise<void> {
        let currentStorageValue = await this.getCurrentStorageValue(appointmentId, quoteId);

        if (!currentStorageValue) {
            currentStorageValue = {};
        }

        if (!currentStorageValue.hasOwnProperty(appointmentId)) {
            currentStorageValue[appointmentId] = {};
        }

        currentStorageValue[appointmentId][quoteId] = rateSheetId;

        await this.setStorageValue(currentStorageValue);
    }

    async getRateSheet(appointmentId: number, quoteId: number): Promise<string> {
        const currentStorageValue = await this.getCurrentStorageValue(appointmentId, quoteId);

        return get(currentStorageValue, [appointmentId, quoteId], null);
    }

    async removeRateSheet(appointmentId: number, quoteId: number): Promise<void> {
        const currentStorageValue = await this.getCurrentStorageValue(appointmentId, quoteId);

        if (currentStorageValue && currentStorageValue[appointmentId].hasOwnProperty(quoteId)) {
            delete currentStorageValue[appointmentId][quoteId];
        }

        await this.setStorageValue(currentStorageValue);
    }

    private async setStorageValue(value: any): Promise<void> {
        await this.storage.set(this.storageKey, value);
    }

    private async getCurrentStorageValue(appointmentId: number, quoteId: number): Promise<any> {
        const currentStorageValue = await this.storage.get(this.storageKey);

        if (
            !currentStorageValue?.hasOwnProperty(appointmentId) ||
            !currentStorageValue[appointmentId].hasOwnProperty(quoteId)
        ) {
            return null;
        }

        return currentStorageValue;
    }
}
