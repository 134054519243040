import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import cloneDeep from 'lodash/cloneDeep';

import { DataCompareHelperService } from '@core/services/data-compare-helper.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FormHelperService } from '@core/services/form-helper.service';
import { UtilsService } from '@core/services/utils.service';
import { WheelSelectorService } from '@core/services/wheel-selector.service';
import { DropdownPopoverComponent } from '@shared';
import { QuestionType } from '@shared/enums/question-type.enum';
import { WcpPreferenceOptionType } from '@shared/enums/wcp-preference-option-type.enum';
import { OriginalAnswer } from '@shared/interfaces/answer';
import { OriginalQuestion } from '@shared/interfaces/question';

import { ConfigureHelperService } from '../../../main/appointments/configure/services/configure-helper.service';

@Component({
    selector: 'vendo-customize-question',
    templateUrl: './customize-question.component.html',
    styleUrls: ['../../../main/appointments/configure/components/question/question.component.scss']
})
export class CustomizeQuestionComponent {
    @Input() set question(question: OriginalQuestion) {
        const questionDeep = cloneDeep(question);

        if (questionDeep.Answers && questionDeep.Answers.length) {
            questionDeep.Answers.forEach((answer: OriginalAnswer) => {
                this.modifyAnswer(answer);

                if (answer.ID === question.SelectedAnswerID) {
                    this.selectedAnswer = answer;
                }
            });
        }
        this.configureHelperService.setQuestionType(questionDeep);

        if (questionDeep.questions && questionDeep.questions.length) {
            questionDeep.questions.forEach((subQuestion: OriginalQuestion) => {
                this.configureHelperService.setQuestionType(subQuestion);
                this.selectedSubAnswers = [];
                subQuestion.Answers.forEach((answer: OriginalAnswer) => {
                    this.modifyAnswer(answer);

                    if (answer.ID === subQuestion.SelectedAnswerID) {
                        this.selectedSubAnswers.push(answer);
                    }
                });
            });
        }

        this._question = questionDeep;
        this.initialQuestion = question;
    }

    get question(): OriginalQuestion {
        return this._question;
    }

    @Input() isSecondMeasureValidate = false;
    @Input() imgBaseUrl: any;
    @Input() wheelSelectorSettings = null;
    @Input() availableWcpPreferenceOptions: WcpPreferenceOptionType[] = [];
    @Input() isWcpPreferencesFeatureEnabled = false;
    @Input() hardStopErrors = null;

    @Output() answerModified = new EventEmitter<{ question: OriginalQuestion; text: string; isTextField: boolean }>();
    @Output() preferenceClicked = new EventEmitter<{ question: OriginalQuestion; answer: OriginalAnswer }>();
    @Output() focusedInputId = new EventEmitter<string>();

    selectedAnswer: OriginalAnswer;
    selectedSubAnswers: OriginalAnswer[] = [];
    readonly questionTypes: typeof QuestionType = QuestionType;
    initialQuestion: OriginalQuestion;
    private _question: OriginalQuestion;

    constructor(
        private configureHelperService: ConfigureHelperService,
        private dataCompareHelperService: DataCompareHelperService,
        private deviceHelperService: DeviceHelperService,
        private formHelperService: FormHelperService,
        private popoverController: PopoverController,
        private utilsService: UtilsService,
        private wheelSelectorService: WheelSelectorService
    ) {}

    toggleAllowsDifferentAnswers(question: OriginalQuestion): void {
        let text: string;

        if (this.isSecondMeasureValidate) {
            text = question.questionType === QuestionType.Input ? this.selectedAnswer.ID : this.selectedAnswer.Text;
        }

        this.emitUpdate(question, text);
    }

    setAnswer(question: OriginalQuestion, answerId: string, index?: number): void {
        this.setPopoverWidth();

        if (question.Locked || question.SelectedAnswerID === answerId) {
            return;
        }

        question.SelectedAnswerID = answerId;
        if (index !== undefined) {
            this.selectedSubAnswers[index] = question.Answers.find(({ ID }) => ID === answerId);
        } else if (question.Answers.length) {
            this.selectedAnswer = question.Answers.find(({ ID }) => ID === answerId);
        }

        this.emitUpdate(question, index !== undefined ? this.selectedSubAnswers[index].Text : this.selectedAnswer.Text);
    }

    setPopoverWidth(event?: any, isLocked?: boolean): void {
        if (isLocked) {
            return;
        }

        this.formHelperService.setPopoverWidth(event);
    }

    saveTextAnswer(question, answerId: string): void {
        if (question.SelectedAnswerID !== answerId) {
            if (question.IsNumeric && question.SelectedAnswerID !== '') {
                question.SelectedAnswerID = this.utilsService
                    .convertFractionToDecimal(question.SelectedAnswerID)
                    .toString();
            }
            this.emitUpdate(question, question.SelectedAnswerID);
        }
    }

    openPicker(question, answerId: string): void {
        if (this.wheelSelectorSettings && question.IsNumeric && !question.Locked) {
            this.wheelSelectorService
                .openWheelSelector(this.wheelSelectorSettings)
                .then((wheelSelectorValue) => {
                    question.SelectedAnswerID = wheelSelectorValue;

                    this.saveTextAnswer(question, answerId);
                })
                .catch(() => {});
        }
    }

    emitUpdate(question: OriginalQuestion, text?: string): void {
        const isTextField = [QuestionType.Input, QuestionType.Textarea].includes(question.questionType);

        if (
            !isTextField &&
            this.dataCompareHelperService.isEmpty(question.SelectedAnswerID) &&
            !question.AllowsDifferentAnswers
        ) {
            return;
        }

        this.answerModified.emit({ question, text, isTextField });
    }

    setPreferenceType(question: OriginalQuestion, answerId, event?: Event): void {
        if (event) {
            event.stopPropagation();
        }

        const answer = question.Answers.find(({ ID }) => ID === answerId);

        this.preferenceClicked.emit({ question, answer });
    }

    openLink(url: string): void {
        let urlForOpen: string = url;

        if (this.imgBaseUrl && !url.startsWith('http')) {
            urlForOpen = `https://${this.imgBaseUrl}/config${url}`;
        }

        this.deviceHelperService.openUrl(urlForOpen, '_blank');
    }

    async infoIconOnClick(event: Event, text: string, link?: string): Promise<void> {
        event.stopPropagation();

        const popover = await this.popoverController.create({
            component: DropdownPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass: 'auto-width',
            componentProps: {
                isIconSlotEnd: false,
                items: [
                    ...(link
                        ? [
                              {
                                  name: 'Click for more information',
                                  hash: 'more_info',
                                  icon: 'launch'
                              }
                          ]
                        : []),
                    {
                        name: text,
                        cssClass: 'no-cursor'
                    }
                ]
            }
        });

        await popover.present();

        const { data } = await popover.onDidDismiss();

        if (data === 'more_info') {
            this.openLink(link);
        }
    }

    private modifyAnswer(answer: OriginalAnswer): void {
        if (answer.ImageURL) {
            answer.ImageURL = answer.ImageURL.split(String.fromCharCode(92)).join('/');
        }

        if (answer.IsPreferenceAllowed) {
            answer.IsPreference =
                answer.IsPreference &&
                answer.PreferenceType.some((value: WcpPreferenceOptionType) =>
                    this.availableWcpPreferenceOptions.includes(value)
                );
        }
    }
}
