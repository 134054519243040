import { Component, Input } from '@angular/core';

@Component({
    selector: 'vendo-tooltip-popover',
    templateUrl: './tooltip-popover.component.html',
    styleUrls: ['./tooltip-popover.component.scss']
})
export class TooltipPopoverComponent {
    @Input() message = '';
}
