import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { PdfDocumentViewerComponent } from './pdf-document-viewer.component';

@NgModule({
    declarations: [PdfDocumentViewerComponent],
    imports: [CommonModule, PdfViewerModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [PdfDocumentViewerComponent]
})
export class PdfDocumentViewerModule {}
