import { Observable, of } from 'rxjs';

export class QuoteOffline {
    getAppointmentQuotes(appointmentId: number): Observable<any> {
        return of([]);
    }

    getCatalogQuotes(appointmentId: number, catalogId?: number): Observable<any[]> {
        return of([
            {
                partial_quotes: [],
                allow_pricing_profiles: false,
                allow_autoconfigure: false
            }
        ]);
    }
}
