import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import some from 'lodash/some';

import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { DEFAULT_MENU_ITEMS } from '@shared/constants/default-menu-items';
import { AppointmentLockType } from '@shared/enums/appointment-lock-type';
import { AppointmentStatus } from '@shared/enums/appointment-status';
import { PaymentSystem } from '@shared/enums/payment-system';
import { QuoteStatus } from '@shared/enums/quote-status';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { Appointment } from '@shared/interfaces/appointment';
import { AppointmentStep, AppointmentSteps } from '@shared/interfaces/appointment-step';
import { MenuItem, MenuItems } from '@shared/interfaces/menu';

import { AppointmentsService } from '../../main/appointments/services/appointments.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private showLeftMenu: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    showLeftMenu$: Observable<boolean> = this.showLeftMenu.asObservable();
    menuItems: BehaviorSubject<MenuItems> = new BehaviorSubject<MenuItems>(DEFAULT_MENU_ITEMS);
    setInProgress = false;
    private taxChanged: Subject<void> = new Subject<void>();
    taxChanged$: Observable<void> = this.taxChanged.asObservable();
    isOffline = window.localStorage.getItem('offline_mode') === '1';
    private saleSteps: Subject<AppointmentStep[]> = new ReplaySubject<AppointmentStep[]>(1);
    saleSteps$: Observable<AppointmentStep[]> = this.saleSteps.asObservable();

    constructor(
        private auth: AuthService,
        private appointmentService: AppointmentsService,
        private featuresService: FeaturesService,
        private deviceHelperService: DeviceHelperService
    ) {
        this.auth.getStorageItem('menuItems').then((menuItems: MenuItems) => {
            if (!menuItems) {
                return;
            }

            this.handleVisibilityOfflineModeItem(menuItems);

            if (!this.setInProgress) {
                this.menuItems.next(menuItems);
            }
        });
    }

    initSteps(appointment: Appointment): void {
        if (appointment.status === AppointmentStatus.CLOSED) {
            this.saleSteps.next([]);

            return;
        }

        const user = this.auth.getUser();
        const isLockedByAutoconfigure: boolean = appointment.lock_type === AppointmentLockType.LockedByAutoconfigure;
        const appointmentSteps: AppointmentSteps = user.office.steps.find(
            ({ appointment_type }) => appointment_type === appointment.type
        );
        let steps: AppointmentStep[] = cloneDeep(appointmentSteps.items);

        if (this.isOffline) {
            steps = steps.filter(({ hash }) =>
                [SaleStepType.NEEDS_ASSESSMENT.replace('-', '_'), SaleStepType.TAKE_OFF, SaleStepType.DEMO].includes(
                    hash
                )
            );
        }

        steps.forEach((step: AppointmentStep, index: number) => {
            const previousStep = steps[index - 1];
            const activity = find(appointment.activities, { hash: step.hash });

            step.link = `/main/appointments/${appointment.id}/${step.hash}`;
            step.completed = activity?.status === 'completed';
            step.active = !step.completed && (!previousStep || previousStep.completed);
            step.icon = isLockedByAutoconfigure && step.hash === SaleStepType.CONFIGURE ? 'autorenew' : step.icon;
            step.disabled =
                (isLockedByAutoconfigure && [SaleStepType.CONFIGURE, SaleStepType.QUOTE].includes(step.hash)) ||
                some(steps, ({ hash, completed }) => !completed && step.dependencies.includes(hash));
        });

        this.saleSteps.next(steps);
    }

    setMenuItems(items?: MenuItems): void {
        this.setInProgress = true;
        if (!items) {
            items = cloneDeep(DEFAULT_MENU_ITEMS);
            this.handleVisibilityOfflineModeItem(items);
        }
        this.auth.setStorageItem('menuItems', items).then(() => (this.setInProgress = false));
        this.menuItems.next(items);
    }

    emitTaxWasChanged(): void {
        this.taxChanged.next();
    }

    initMenuItems(appointment: any, isAvailableCustomTax: boolean): void {
        const menuItems: MenuItems = cloneDeep(DEFAULT_MENU_ITEMS);

        const selectedQuote = appointment.quotes?.find((quote) => quote.status === QuoteStatus.Selected);

        menuItems.hamburger.top.forEach((item: MenuItem) => {
            switch (item.label) {
                case 'Edit Appointment':
                case 'Change Tax':
                    item.isHidden = !isAvailableCustomTax;
                    break;
                case 'Capture Payment':
                    item.isHidden =
                        this.auth.getUser()?.office.payment_system !== PaymentSystem.ParadigmPayments || this.isOffline;
                    break;
                case 'Final Steps':
                    item.isHidden =
                        appointment.status === 'closed' ||
                        appointment.lock_type !== AppointmentLockType.Locked ||
                        !selectedQuote ||
                        this.isOffline;

                    if (selectedQuote) {
                        item.link = `${item.link.replace('${appointment_id}', appointment.id)}/${selectedQuote.id}`;
                    }
                    break;
            }
        });

        menuItems.hamburger.bottom.forEach((item: MenuItem) => {
            switch (item.label) {
                case 'Offline Mode':
                    item.isHidden = !this.featuresService.isOfflineModeAvailable();
                    break;
                case 'Capture Payment':
                    item.isHidden =
                        this.auth.getUser()?.office.payment_system !== PaymentSystem.ParadigmPayments ||
                        !this.deviceHelperService.isPhone;
                    break;
            }
        });

        menuItems.back = {
            icon: 'arrow_back',
            link: '/main/appointments'
        };

        this.setMenuItems(menuItems);
    }

    async resetStepsAndMenuItemsByAppointment(appointmentId: number | string): Promise<Appointment> {
        const [appointment, isAvailableCustomTax] = await Promise.all([
            this.appointmentService.getAppointment(appointmentId, true).toPromise(),
            this.appointmentService
                .getAppointmentCustomTax(appointmentId)
                .pipe(
                    map(({ accessibility }) => accessibility.change_tax),
                    tap((data) => this.auth.setStorageItem('isAvailableCustomTax', data))
                )
                .toPromise()
        ]);

        this.initSteps(appointment);
        this.initMenuItems(appointment, isAvailableCustomTax);

        return appointment;
    }

    resetStepsByAutoConfigure(appointment: Appointment): void {
        this.initSteps(appointment);
    }

    toggleLeftMenu(value: boolean): void {
        this.showLeftMenu.next(value);
    }

    private handleVisibilityOfflineModeItem(menuItems: MenuItems): void {
        menuItems.hamburger.bottom.forEach((item: MenuItem) => {
            switch (item.label) {
                case 'Offline Mode':
                    item.isHidden = !this.featuresService.isOfflineModeAvailable();
                    break;
            }
        });
        menuItems.hamburger.top.forEach((item: MenuItem) => {
            if (item.label === 'Capture Payment') {
                item.isHidden =
                    this.auth.getUser()?.office.payment_system !== PaymentSystem.ParadigmPayments || this.isOffline;
            }
        });
    }
}
