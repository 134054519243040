import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { merge, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-accessories-modal',
    templateUrl: './accessories-modal.component.html',
    styleUrls: ['./accessories-modal.component.scss']
})
export class AccessoriesModalComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() accessoriesCategories = [];
    @Input() selectedAccessories = [];
    @Input() countOfColumns = 1;
    packages: string[] = [];
    activeTab: any;
    form: UntypedFormGroup;
    activeTabIndex = 0;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(private formBuilder: UntypedFormBuilder, modalCtrl: ModalController) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.setActiveTab(this.accessoriesCategories[0], 0);
        this.packages = new Array(this.countOfColumns).fill('Pkg');
        this.initForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get accessoriesCategoriesForm(): UntypedFormArray {
        return this.form.get('accessoriesCategories') as UntypedFormArray;
    }

    changeTab(count: number): void {
        const tabIndex = this.getActiveTabIndex() + count;

        if (!this.accessoriesCategories[tabIndex]) {
            return;
        }

        this.activeTab = this.accessoriesCategories[tabIndex];
        this.activeTabIndex = tabIndex;
    }

    setActiveTab(tab: any, index: number): void {
        this.activeTab = tab;
        this.activeTabIndex = index;
    }

    save(): void {
        const result: any[] = this.packages.map(() => []);
        const formValues: any[] = this.accessoriesCategoriesForm.getRawValue();

        formValues.forEach((category) => {
            category.accessories.forEach((accessory) => {
                if (this.countOfColumns > 1) {
                    accessory.packages.slice(1).forEach((item: any, index: number) => {
                        if (item) {
                            result[index].push(accessory.id);
                        }
                    });
                } else {
                    if (accessory.packages[1]) {
                        result[0].push(accessory.id);
                    }
                }
            });
        });
        this.dismiss(result);
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            accessoriesCategories: this.formBuilder.array(
                this.accessoriesCategories.map((category) =>
                    this.formBuilder.group({
                        accessories: this.initAccessoriesFormArray(category.accessories)
                    })
                )
            )
        });

        merge(
            ...this.accessoriesCategoriesForm.controls.map((group: AbstractControl) =>
                this.handleAccessoryTypeForm(group as UntypedFormGroup)
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ rowIndex, colIndex }) => {
                const packagesForm: UntypedFormArray = (
                    this.accessoriesCategoriesForm.at(this.activeTabIndex).get('accessories') as UntypedFormArray
                )
                    .at(rowIndex)
                    .get('packages') as UntypedFormArray;
                const packages: boolean[] = packagesForm.getRawValue();

                if (colIndex === 0) {
                    packagesForm.setValue(
                        new Array(this.countOfColumns > 1 ? 4 : 2).fill(packagesForm.at(colIndex).value),
                        { emitEvent: false }
                    );
                } else {
                    const isAllSelected: boolean = packages.slice(1).every((value: boolean) => value);

                    packagesForm.at(0).setValue(isAllSelected, { emitEvent: false });
                }
            });
    }

    private handleAccessoryTypeForm(group: UntypedFormGroup): Observable<any> {
        return merge(
            ...(group.get('accessories') as UntypedFormArray).controls.map(
                (nestedGroup: AbstractControl, index: number) =>
                    this.handleAccessoryForm(nestedGroup as UntypedFormGroup, index)
            )
        );
    }

    private handleAccessoryForm(group: UntypedFormGroup, rowIndex: number): Observable<any> {
        return merge(
            ...(group.get('packages') as UntypedFormArray).controls.map((control: AbstractControl, colIndex: number) =>
                control.valueChanges.pipe(map(() => ({ rowIndex, colIndex })))
            )
        );
    }

    private initAccessoriesFormArray(accessories): UntypedFormArray {
        if (!accessories || !accessories.length) {
            return this.formBuilder.array([]);
        }

        return this.formBuilder.array(
            accessories.map((accessory) => {
                const selectedAccessory = this.selectedAccessories.find((selected) => +selected.id === +accessory.id);

                if (selectedAccessory) {
                    return this.initAccessoryFormGroup(accessory.id, [
                        !selectedAccessory.packages.some((item) => item === false),
                        ...selectedAccessory.packages.map((checked) => checked)
                    ]);
                }

                return this.initAccessoryFormGroup(
                    accessory.id,
                    new Array(this.countOfColumns > 1 ? 4 : 2).fill(false)
                );
            })
        );
    }

    private initAccessoryFormGroup(id: string, packages: any[]): UntypedFormGroup {
        return this.formBuilder.group({
            id,
            packages: this.formBuilder.array(packages)
        });
    }

    private getActiveTabIndex(): number {
        return this.accessoriesCategories.indexOf(this.activeTab);
    }
}
