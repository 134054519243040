import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';

import { AppInitializerService } from '@core/services/app-initializer.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { ScriptService } from '@core/services/script.service';

import { environment } from '../../../environments/environment';

declare var pendo: any;

@Injectable({
    providedIn: 'root'
})
export class PendoService {
    private isInitializedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isInitialized$: Observable<boolean> = this.isInitializedSubject.asObservable();
    readonly isOffline = window.localStorage.getItem('offline_mode') === '1';

    get isDisallow(): boolean {
        return (
            this.isOffline ||
            !this.appInitializerService.globalSettings?.pendo ||
            !environment.pendoApiKeys.web ||
            (!environment.stage && !environment.production)
        );
    }

    constructor(
        private appInitializerService: AppInitializerService,
        private deviceHelperService: DeviceHelperService,
        private scriptService: ScriptService
    ) {}

    init(): void {
        if (this.isDisallow) {
            return;
        }

        this.load(
            () => {
                this.isInitializedSubject.next(true);
            },
            () => {
                this.isInitializedSubject.next(false);
            }
        );
    }

    initUser(user): void {
        if (this.isDisallow) {
            return;
        }

        if (this.isInitializedSubject.getValue()) {
            this.initializeUser(user);
        } else {
            this.isInitialized$.pipe(skip(1), take(1)).subscribe((value: boolean) => {
                if (value) {
                    this.initializeUser(user);
                }
            });
        }
    }

    clearSession(): void {
        if (this.isDisallow) {
            return;
        }

        if (this.isInitializedSubject.getValue()) {
            pendo.clearSession();
        } else {
            this.isInitialized$.pipe(skip(1), take(1)).subscribe((value: boolean) => {
                if (value) {
                    pendo.clearSession();
                }
            });
        }
    }

    private initializeUser(user): void {
        pendo.initialize({
            visitor: {
                id: this.getValueByEnvironment(user.email),
                name: `${user.first_name} ${user.last_name}`,
                email: user.email
            },
            account: {
                id: this.getValueByEnvironment(user.office.name),
                name: `${user.office.name} (${user.organization.name})`
            }
        });
    }

    private getValueByEnvironment(value): string {
        return environment.production ? value : `${environment.stage ? 'stage' : 'dev'}_${value}`;
    }

    private load(successLoadCallback: (ev?: Event) => any, errorLoadCallback: (ev?: Event) => any): void {
        if (typeof pendo === 'function') {
            successLoadCallback();
        } else {
            this.scriptService.loadJsScript(
                `https://cdn.pendo.io/agent/static/${this.getPendoApiKey()}/pendo.js`,
                successLoadCallback,
                errorLoadCallback
            );
        }
    }

    private getPendoApiKey(): string {
        switch (true) {
            case this.deviceHelperService.isWeb:
                return environment.pendoApiKeys.web;
            case this.deviceHelperService.isIOSPlatform:
                return environment.pendoApiKeys.ios;
            case this.deviceHelperService.isAndroidPlatform:
                return environment.pendoApiKeys.android;
        }
    }
}
