import { gql } from 'apollo-angular';

const GetCommonItems = gql`
    query CommonItems($types: [String]) {
        commonItems(types: $types)
    }
`;

const GetCommonItem = gql`
    query CommonItem($type: String) {
        commonItem(type: $type) {
            type
            values
        }
    }
`;

const GetOptionList = gql`
    query optionList($type: OptionListTypeEnum!, $filter: JSON) {
        optionList(type: $type, filter: $filter) {
            label
            value
        }
    }
`;

export { GetCommonItem, GetCommonItems, GetOptionList };
