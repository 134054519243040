export enum AdderType {
    Smart = 'smart',
    Standard = 'standard',
    Hybrid = 'hybrid'
}

export enum AdderAppliedType {
    Manual = 'manual',
    Auto = 'auto'
}
