import { Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { FeedbackModalComponent } from './feedback-modal.component';

@Injectable()
export class FeedbackModalService {
    constructor(private modalController: ModalController) {}

    async openFeedbackModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: FeedbackModalComponent,
            cssClass: 'feedback-modal',
            backdropDismiss: false
        });

        await modal.present();

        await modal.onDidDismiss();
    }
}
