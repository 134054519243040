import { gql } from 'apollo-angular';

const SendApprovalRequest = gql`
    mutation SendApprovalRequest(
        $type: ApprovalRequestTypeEnum!
        $application_url: String!
        $description: String
        $relation_id: ID
    ) {
        sendApprovalRequest(
            type: $type
            application_url: $application_url
            description: $description
            relation_id: $relation_id
        ) {
            id
            status
            relation_id
        }
    }
`;

const ApproveApprovalRequest = gql`
    mutation ApproveApprovalRequest($hash: String!) {
        approveApprovalRequest(hash: $hash)
    }
`;

const DeclineApprovalRequest = gql`
    mutation DeclineApprovalRequest($hash: String!) {
        declineApprovalRequest(hash: $hash)
    }
`;

const CancelApprovalRequest = gql`
    mutation CancelApprovalRequest($id: ID!) {
        cancelApprovalRequest(id: $id)
    }
`;

const CloseApprovalRequest = gql`
    mutation CloseApprovalRequest($id: ID!) {
        closeApprovalRequest(id: $id)
    }
`;

export {
    SendApprovalRequest,
    ApproveApprovalRequest,
    DeclineApprovalRequest,
    CancelApprovalRequest,
    CloseApprovalRequest
};
