import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { IonTextarea, ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-notes-modal',
    templateUrl: './notes-modal.component.html',
    styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent extends BaseModal implements AfterViewInit {
    @Input() headerText = 'Add Notes';
    @Input() inputText = '';
    @Input() charLimit = 700;
    @ViewChild('inputField') inputField: IonTextarea;

    constructor(modalCtrl: ModalController) {
        super(modalCtrl);
    }

    ngAfterViewInit(): void {
        setTimeout(async () => {
            await this.inputField.setFocus();
        }, 300);
    }

    save(): Promise<any> {
        if (!this.inputText.length || this.inputText.length > this.charLimit) {
            return;
        }
        this.dismiss(this.inputText);
    }
}
