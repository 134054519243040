import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AbstractForm } from '@shared/helpers/abstract-form';
import { ValidateMatchPattern } from '@shared/validators/match-pattern.validator';

@Component({
    selector: 'vendo-errors-form',
    templateUrl: './errors-form.component.html',
    styleUrls: ['./errors-form.component.scss']
})
export class ErrorsFormComponent extends AbstractForm implements OnInit {
    @Input() fields: any;
    @Output() formSubmitted = new EventEmitter<any>();

    form: UntypedFormGroup = new UntypedFormGroup({
        billing_street: new UntypedFormControl('', [Validators.maxLength(32)]),
        billing_zip: new UntypedFormControl('', [Validators.maxLength(12), Validators.minLength(4)]),
        billing_city: new UntypedFormControl('', [Validators.maxLength(36)]),
        billing_state: new UntypedFormControl('', [Validators.maxLength(24)]),
        notification_email_address: new UntypedFormControl('', [
            ValidateMatchPattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$', 'Email provided is not valid')
        ]),
        billing_phone: new UntypedFormControl('', [Validators.maxLength(10)]),
        account_holder_name: new UntypedFormControl('', [Validators.maxLength(32)])
    });

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.form.patchValue(this.fields);
        this.setSubmitted(true, this.form);
    }

    confirm(): void {
        this.setSubmitted(true, this.form);
        if (!this.form.valid) {
            return;
        }

        this.formSubmitted.emit(this.form.getRawValue());
    }
}
