import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { IonInput } from '@ionic/angular';

import { combineLatest, fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, first, map, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

import { ImportOrderData, UserData } from '@shared/components/provia-order-modal/provia-order-modal.interfaces';
import { AbstractForm } from '@shared/helpers/abstract-form';

@Component({
    selector: 'vendo-provia-form',
    templateUrl: './provia-form.component.html',
    styleUrls: ['./provia-form.component.scss']
})
export class ProviaFormComponent extends AbstractForm implements OnInit {
    @Input() userData: UserData;
    @Input() usePortalLogin: boolean;

    form: any;
    isShowSearchButton = true;
    usedRemember = false;
    userNameLabel: string;

    @Output() searchOrders = new EventEmitter<UserData>();
    @Output() orderSelected = new EventEmitter<ImportOrderData>();

    @ViewChild('orderNumberInput') inputOrderNumber: IonInput;

    constructor(private formBuilder: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.userNameLabel = this.usePortalLogin ? 'Portal ID' : 'User Name';
        this.usedRemember = !!this.userData?.remember;
        this.form = this.formBuilder.group({
            user_name: [this.userData?.user_name || '', Validators.required],
            password: [this.userData?.password || '', Validators.required],
            po_number: null,
            job_name: null,
            order_number: null,
            remember: this.usedRemember
        });
        this.initSearchFieldsRelation();
    }

    submit(): void {
        if (this.form.invalid) {
            this.setSubmitted(true, this.form);

            return;
        }

        const formValue = this.form.getRawValue();

        this.searchOrders.emit({ ...formValue, used_remember: this.usedRemember });
    }

    async pickOrder(): Promise<void> {
        if (this.form.invalid) {
            this.setSubmitted(true, this.form);

            return;
        }

        const inputOrderNumberEl = await this.inputOrderNumber.getInputElement();
        const itemOrderNumberEl = inputOrderNumberEl?.parentElement?.parentElement;

        if (!itemOrderNumberEl) {
            return;
        }

        fromEvent(itemOrderNumberEl, 'click')
            .pipe(first())
            .subscribe((event: Event) => {
                const formValue = this.form.getRawValue();

                const orderNumber = formValue.order_number;

                delete formValue.order_number;

                this.orderSelected.emit({
                    userData: { ...formValue, used_remember: this.usedRemember },
                    orderNumber,
                    event
                });
            });

        itemOrderNumberEl.click();
    }

    /**
     * - resetting and disabling `order_number` when filled `po_number` or `job_name`
     * - resetting and disabling `po_number` and `job_name` when filled `order_number`
     * @private
     */
    private initSearchFieldsRelation(): void {
        combineLatest([
            this.getFieldValueAsBooleanObservable('po_number'),
            this.getFieldValueAsBooleanObservable('job_name')
        ])
            .pipe(
                debounceTime(100),
                pairwise(),
                filter(([prevValues, currValues]: boolean[][]): boolean => {
                    const prevValue = prevValues.reduce((acc, val) => acc || val, false);
                    const currValue = currValues.reduce((acc, val) => acc || val, false);

                    return prevValue !== currValue;
                }),
                map(([, value]: boolean[][]): boolean[] => value),
                takeUntil(this.destroy$)
            )
            .subscribe(([isPoNumberFilled, isJobNameFilled]: boolean[]): void => {
                const orderNumberControl: AbstractControl = this.form.get('order_number');

                if (isPoNumberFilled || isJobNameFilled) {
                    orderNumberControl.disable();
                } else {
                    orderNumberControl.enable();
                }
            });

        this.getFieldValueAsBooleanObservable('order_number')
            .pipe(
                debounceTime(100),
                pairwise(),
                filter(([prevValue, currValue]: boolean[]): boolean => prevValue !== currValue),
                map(([, value]: boolean[]): boolean => value),
                tap((isShowImportButton: boolean): void => {
                    this.isShowSearchButton = !isShowImportButton;
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((isOrderNumberFilled: boolean): void => {
                const poNumberControl: AbstractControl = this.form.get('po_number');
                const jobNameControl: AbstractControl = this.form.get('job_name');

                if (isOrderNumberFilled) {
                    poNumberControl.disable();
                    jobNameControl.disable();
                } else {
                    poNumberControl.enable();
                    jobNameControl.enable();
                }
            });
    }

    /**
     * Getting Observable for valueChanges for special field by field_name
     * - applied startWith and map<convert to boolean>
     *
     * @param {string} fieldName
     * @return {Observable<boolean>}
     * @private
     */
    private getFieldValueAsBooleanObservable(fieldName: string): Observable<boolean> {
        return this.form.get(fieldName).valueChanges.pipe(
            startWith(null),
            map((value: string | null): boolean => Boolean(value))
        );
    }

    useOtherAccount(): void {
        this.form.patchValue({
            user_name: '',
            password: '',
            remember: false
        });
        this.usedRemember = false;
    }
}
