import { Directive, HostListener, Input, Injectable, Self, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[phoneMask]'
})
@Injectable()
export class PhoneInputMaskDirective {
    @Input() phoneMask: string;

    constructor(private renderer: Renderer2, @Self() public ngControl?: NgControl) {}

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent): void {
        const el: HTMLInputElement = event.target as HTMLInputElement;

        const value = this.formatField(el.value);

        this.writeValue(value, event.target);
    }

    private writeValue(value: string | null, target: any): void {
        target.value = value;
        this.renderer.setProperty(target, 'value', value);
        if (this.ngControl && this.ngControl.control) {
            this.ngControl.control.setValue(value);
            this.ngControl.control.markAsDirty();
            this.ngControl.control.updateValueAndValidity();
        }
    }

    private formatField(value: string): string {
        const valueChars = value.split('');
        const maskChars = this.phoneMask.split('');

        const nextNumber = (): string | any => {
            if (!valueChars.length) {
                return '';
            }

            const charNumber = parseInt(valueChars.shift(), 10);

            return isNaN(charNumber) ? nextNumber() : charNumber;
        };

        const newValueChars = [];

        for (const maskChar of maskChars) {
            if (!valueChars.length) {
                break;
            }

            if (isNaN(parseInt(maskChar, 10))) {
                newValueChars.push(maskChar);
            } else {
                newValueChars.push(nextNumber());
            }
        }

        return newValueChars.join('');
    }
}
