import { gql } from 'apollo-angular';

const IMPORT_PROVIA_ORDER = gql`
    mutation ImportProviaOrder(
        $quote_ids: [ID!]!
        $appointment_id: ID!
        $order_number: String!
        $user_name: String!
        $password: String!
        $remember: Boolean
        $used_remember: Boolean
    ) {
        importProviaOrder(
            quote_ids: $quote_ids
            appointment_id: $appointment_id
            order_number: $order_number
            user_name: $user_name
            password: $password
            remember: $remember
            used_remember: $used_remember
        )
    }
`;

const REMOVE_PROVIA_ORDER = gql`
    mutation RemoveProviaOrder($appointment_id: ID!) {
        removeProviaOrder(appointment_id: $appointment_id)
    }
`;

export { IMPORT_PROVIA_ORDER, REMOVE_PROVIA_ORDER };
