import { Component, Input } from '@angular/core';

import { MeasureChange } from '@shared/interfaces/measure-quote';

@Component({
    selector: 'vendo-line-item-measure-details',
    templateUrl: './line-item-measure-details.component.html',
    styleUrls: ['./line-item-measure-details.component.scss']
})
export class LineItemMeasureDetailsComponent {
    @Input() title: string;
    @Input() questions: MeasureChange[] = [];
    @Input() isShowOldAnswerCol = true;
    @Input() isShowColumnTitles = true;
    @Input()
    set isExpandedParent(isExpanded: boolean) {
        if (!isExpanded) {
            this.isExpanded = false;
        }
    }
    isExpanded = false;
}
