import { Injectable } from '@angular/core';

import { BLE } from '@awesome-cordova-plugins/ble/ngx';

import { Platform } from '@ionic/angular';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';

declare let JSPack: any;

@Injectable({
    providedIn: 'root'
})
export class BluetoothService {
    private measureServiceUUID = '00005301-0000-0041-5253-534f46540000';
    private measureCharacteristicUUID = '00004301-0000-0041-5253-534F46540000';
    private MEASURE_COMMAND = [0xc0, 85, 1, 1, 96];

    constructor(
        private ble: BLE,
        private platform: Platform,
        private auth: AuthService,
        private toastService: ToastService
    ) {}

    async isBluetoothOn(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.ble
                .isEnabled()
                .then(
                    () => {
                        resolve(true);
                    },
                    () => {
                        resolve(false);
                    }
                )
                .catch(() => {
                    resolve(false);
                });
        });
    }

    async isConnected(deviceId: string): Promise<boolean> {
        try {
            return this.ble.isConnected(deviceId).then(
                () => true,
                () => false
            );
        } catch (e) {
            return of(false).toPromise();
        }
    }

    connect(deviceId: string): Observable<any> {
        return this.ble.connect(deviceId);
    }

    disconnect(deviceId: string): Promise<any> {
        return this.ble.disconnect(deviceId);
    }

    subscribeToNotifications(deviceId: string): Observable<any> {
        return this.ble.startNotification(deviceId, this.measureServiceUUID, this.measureCharacteristicUUID).pipe(
            map((notification: any[]) => {
                return this.handleNotifications(notification[0]);
            })
        );
    }

    private handleNotifications(value): number | false {
        const arr8 = new Uint8Array(value);
        const commandType = arr8[0];
        const deviceStatus = arr8[3];
        const executionCommandNumber = 192;

        if (commandType !== executionCommandNumber || deviceStatus <= 3) {
            return false;
        }

        const withoutStatus = arr8.slice(2);
        const resultOffset = 5;
        const floatBytesSize = 4;
        const inchesInMeter = 39.37;
        const resultArray = withoutStatus.slice(resultOffset, resultOffset + floatBytesSize).reverse();
        const jsPack = new JSPack();

        const result = jsPack.Unpack('f', resultArray, 0)[0];

        return result * inchesInMeter;
    }

    async measureCommand(deviceId: string): Promise<any> {
        const buffer = new Uint8Array(this.MEASURE_COMMAND);

        return this.ble
            .write(deviceId, this.measureServiceUUID, this.measureCharacteristicUUID, buffer.buffer)
            .catch(async (e) => {
                await this.toastService.showMessage('Not able to connect. Please verify your device and try again.');
            });
    }

    scanDevices(): Observable<any> {
        return this.ble.scan([], 25);
    }

    stopScan(): Promise<any> {
        return this.ble.stopScan();
    }

    async findPairedDevice(): Promise<any> {
        const lastConnectedDevice = await this.auth.getStorageItem('lastConnectedMeasurementTool');

        if (lastConnectedDevice) {
            return lastConnectedDevice;
        }

        let devices = [];

        if (this.platform.is('android')) {
            devices = await this.ble.bondedDevices();
        } else if (this.platform.is('ios')) {
            devices = await this.ble.connectedPeripheralsWithServices([]);
        }

        return devices.find((device) => device.name?.includes('Bosch'));
    }
}
