import { gql } from 'apollo-angular';

const SAVE_CONFIGURATION_GROUP = gql`
    mutation SaveConfigurationGroup($name: String!, $opening_ids: [ID!]!, $group_id: ID) {
        saveOpeningGroup(name: $name, opening_ids: $opening_ids, group_id: $group_id) {
            id
        }
    }
`;

export { SAVE_CONFIGURATION_GROUP };
