import { Component, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';

import { AppUpdatesService } from '@core/services/app-updates.service';

@Component({
    selector: 'vendo-app-update',
    templateUrl: './app-update.component.html',
    styleUrls: ['./app-update.component.scss']
})
export class AppUpdateComponent implements OnInit {
    update: any;

    constructor(private appUpdatesService: AppUpdatesService) {}

    ngOnInit(): void {
        this.update = this.appUpdatesService.updatingProcess.pipe(
            map((update) => {
                update.progress_value = update.progress / 100;

                return update;
            })
        );
    }
}
