import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertController, ModalController } from '@ionic/angular';

import { ImageService } from '@core/services/image.service';
import { UtilsService } from '@core/services/utils.service';
import { BaseModal } from '@shared/components/base-modal';
import { FormModalComponent } from '@shared/components/form-modal/form-modal.component';
import { ImageAnnotationToolComponent } from '@shared/components/image-annotation-tool/image-annotation-tool.component';

import { TakeoffService } from '../../../main/appointments/takeoff/services/takeoff.service';

@Component({
    selector: 'vendo-project-annotation-modal',
    templateUrl: './project-annotation-modal.component.html',
    styleUrls: ['./project-annotation-modal.component.scss']
})
export class ProjectAnnotationModalComponent extends BaseModal implements OnInit {
    @Input() catalogId: number;
    @Input() appointmentId: number;
    @Input() imageData: {
        imgUrl: string | Blob;
        feet_per_pixel: number;
        predominant_pitch?: number;
        waste_factor?: number;
    };
    @Input() segments: any[];
    @Input() existingOpeningsCount: any;

    @ViewChild(ImageAnnotationToolComponent) imageAnnotation: ImageAnnotationToolComponent;

    lineTypes: any[];
    imageFile: Blob;
    hideBackground = false;
    private categoryQuestions: any[];

    constructor(
        public modalCtrl: ModalController,
        private takeoffService: TakeoffService,
        private utilsService: UtilsService,
        private alertController: AlertController,
        private imageService: ImageService
    ) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        if (typeof this.imageData.imgUrl === 'object') {
            this.imageFile = this.imageData.imgUrl;
            this.imageData.imgUrl = this.imageService.createObjectURL(this.imageData.imgUrl);
        }

        this.takeoffService.getCategoryWithOpenings(this.catalogId, this.appointmentId).subscribe((category) => {
            this.categoryQuestions = category.all_questions;
            const typeQuestion = category.all_questions.find((question) => question.hash === 'type');

            if (typeQuestion) {
                this.lineTypes = typeQuestion.available_answers.filter((answer) => answer !== 'Facet');
            }
        });
    }

    toggleBackground(): void {
        this.hideBackground = !this.hideBackground;
    }

    async done(): Promise<any> {
        const detailsForm = new UntypedFormGroup({
            predominant_pitch: new UntypedFormControl(
                this.imageData.predominant_pitch ? Math.round(this.imageData.predominant_pitch * 12 * 10) / 10 : 0,
                [Validators.required, Validators.pattern(/^(\d{1,2})([.,]\d{1})?$/)]
            ),
            waste_factor: new UntypedFormControl(this.imageData.waste_factor || 0, [
                Validators.required,
                Validators.min(0),
                Validators.max(100)
            ])
        });

        const modal = await this.modalCtrl.create({
            component: FormModalComponent,
            componentProps: {
                form: detailsForm,
                inputLabels: {
                    predominant_pitch: 'Predominant Pitch',
                    waste_factor: 'Waste Factor (%)'
                },
                buttons: [
                    {
                        label: 'Cancel',
                        fill: 'outline',
                        action: 'cancel'
                    },
                    {
                        label: 'Done',
                        action: 'done'
                    }
                ],
                title: 'Would you like to set a waste factor and predominant pitch?',
                avoidZeroControls: ['predominant_pitch']
            },
            backdropDismiss: false
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (!data || data === 'cancel') {
            return;
        }

        const detailsValue = detailsForm.getRawValue();

        detailsValue.predominant_pitch =
            this.utilsService.convertFractionToDecimal(`${detailsValue.predominant_pitch}/12`, true) || 0;

        const segments = this.imageAnnotation.getSegments();
        const payload = {
            appointment_id: this.appointmentId,
            openings: this.prepareOpenings(segments, detailsValue),
            catalog_id: this.catalogId,
            takeoff_details: {
                image: {
                    ...(this.imageFile ? { file: this.imageFile } : { img_url: this.imageData.imgUrl })
                },
                predominant_pitch: detailsValue.predominant_pitch,
                waste_factor: parseFloat(detailsValue.waste_factor) || 0,
                feet_per_pixel: this.imageData.feet_per_pixel
            }
        };

        this.takeoffService.saveRoofingTakeoffDetails(payload).subscribe((res) => {
            if (res.success) {
                this.dismiss(true);
            }
        });
    }

    goToMap(): void {
        this.dismiss('map');
    }

    async deleteAnnotation(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Please confirm',
            message:
                'You are about to delete roofing annotations. By deleting it, you would also remove all the items and their configurations. Would you like to proceed?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary'
                },
                {
                    text: 'Proceed',
                    handler: () => {
                        this.takeoffService
                            .deleteRoofingTakeoffDetails(this.appointmentId, this.catalogId)
                            .subscribe((res) => {
                                if (res) {
                                    this.goToMap();
                                }
                            });
                    }
                }
            ]
        });

        await alert.present();
    }

    private prepareOpenings(segments: any[], calculationDetails: { predominant_pitch: any; waste_factor: any }): any[] {
        calculationDetails.predominant_pitch = parseFloat(calculationDetails.predominant_pitch);
        calculationDetails.waste_factor = parseFloat(calculationDetails.waste_factor);

        const measurementTypeQuestion = this.categoryQuestions.find((question) => question.hash === 'measurement_type');
        const typeQuestion = this.categoryQuestions.find((question) => question.hash === 'type');
        const areaQuestion = this.categoryQuestions.find((question) => question.hash === 'area');
        const finalAreaQuestion = this.categoryQuestions.find((question) => question.hash === 'final_area');
        const lengthQuestion = this.categoryQuestions.find((question) => question.hash === 'length');
        const predominantPitchQuestion = this.categoryQuestions.find(
            (question) => question.hash === 'predominant_pitch'
        );
        const wasteFactorQuestion = this.categoryQuestions.find((question) => question.hash === 'waste_factor');

        return segments.map((segment) => {
            const openingId = segment.is_new ? null : segment.id;

            let finalArea = segment.distance;

            if (!['Ridge', 'Eave', 'Vertical Wall', 'Gutters'].includes(segment.type.name)) {
                const distanceRatio = Math.cos(Math.atan(calculationDetails.predominant_pitch));

                finalArea = segment.distance / distanceRatio;
            }
            finalArea += finalArea * (calculationDetails.waste_factor / 100);
            finalArea = Math.ceil(finalArea * 100) / 100;

            return {
                id: openingId,
                answers: {
                    [measurementTypeQuestion.id]: segment.polylines?.length ? 'SqFt' : 'Feet',
                    [typeQuestion.id]: segment.type.name,
                    [areaQuestion.id]: segment.distance,
                    [lengthQuestion.id]: segment.distance,
                    [predominantPitchQuestion.id]: calculationDetails.predominant_pitch || 0,
                    [wasteFactorQuestion.id]: calculationDetails.waste_factor || 0,
                    [finalAreaQuestion.id]: finalArea
                },
                name: segment.name,
                map_data: segment
            };
        });
    }
}
