import { Component, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'vendo-dropdown-popover',
    templateUrl: './dropdown-popover.component.html',
    styleUrls: ['./dropdown-popover.component.scss']
})
export class DropdownPopoverComponent {
    @Input() items = [];
    @Input() isIconSlotEnd = true;
    @Input() isReturnObjectItem = false;

    constructor(private popoverController: PopoverController) {}

    async selectItem(result): Promise<void> {
        if (result.disabled) {
            return;
        }

        await this.popoverController.dismiss(this.isReturnObjectItem ? result : result?.hash);
    }
}
