<ion-label>
  {{label}}
</ion-label>
<ion-item lines="none"
          class="documents"
          (click)="showDocuments()">
  <ion-label>
    <ng-container *ngIf="countOfSelectedDocuments > 0; else noDocumentsTemplate">
      <i class="material-icons">description</i>
      {{ countOfSelectedDocuments }} documents selected
    </ng-container>
    <ng-template #noDocumentsTemplate>
      {{ placeholder }}
    </ng-template>
  </ion-label>

  <div class="documents-action"
       slot="end"
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </div>
</ion-item>
