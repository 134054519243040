import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ALLOW_DISPLAY_LIST_PRICE_STATUS,
    ALLOW_DISPLAY_PRICE_MODIFIERS_STATUS
} from '@core/queries/price-configuration.queries';
import { PriceConfigurationDisplayStatus } from '@shared/enums/price-configuration-display-status';

@Injectable({
    providedIn: 'root'
})
export class PriceConfigurationService {
    constructor(private apollo: Apollo) {}

    allowDisplayListPriceStatus(): Observable<PriceConfigurationDisplayStatus> {
        return this.apollo
            .query({
                query: ALLOW_DISPLAY_LIST_PRICE_STATUS
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.allowDisplayListPriceStatus));
    }

    allowDisplayPriceModifiersStatus(): Observable<PriceConfigurationDisplayStatus> {
        return this.apollo
            .query({
                query: ALLOW_DISPLAY_PRICE_MODIFIERS_STATUS
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.allowDisplayPriceModifiersStatus));
    }
}
