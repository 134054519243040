<div class="notifications">
  <div class="notification"
       *ngFor="let notification of notifications; let i = index;">
    <ion-text>
      <i class="material-icons">notifications</i>
      <span>{{ notification.message }}</span>
    </ion-text>
    <ion-button fill="clear"
                detail="false"
                class="icon-only"
                (click)="close(notification, i)">
      <i class="material-icons">close</i>
    </ion-button>
  </div>
</div>