import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StatusBarService } from '@core/services/status-bar.service';
import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-status-bar-modal',
    templateUrl: './status-bar-modal.component.html',
    styleUrls: ['./status-bar-modal.component.scss']
})
export class StatusBarModalComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() text: string;

    value = 0;

    private destroy$: Subject<void> = new Subject<void>();

    constructor(private statusBarService: StatusBarService, public modalController: ModalController) {
        super(modalController);
    }

    ngOnInit(): void {
        this.statusBarService.statusBarPercentage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            this.value = value;

            if (this.value === 100) {
                this.dismiss(true);
                this.statusBarService.clearStatusBarModalDidDismiss();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
