import { Component, Input } from '@angular/core';

@Component({
    selector: 'vendo-price-details',
    templateUrl: './price-details.component.html',
    styleUrls: ['./price-details.component.scss']
})
export class PriceDetailsComponent {
    @Input() isShowDiscount = false;
    @Input() finalPrice = 0;
    @Input() totalPrice = 0;
}
