<div class="modal-header" *ngIf="signatureOwner === 'seller'">
  <ion-text class="title">{{sellerName}} Signature</ion-text>
</div>
<div class="modal-header" *ngIf="signatureOwner === 'customer' && signatureTabs.tabs.length === 1">
  <ion-text class="title">{{ signatureTabs.tabs[0].name }}</ion-text>
</div>
<div class="modal-body" #modalBody>
  <span class="font-preload">a</span>
  <div class="tabs">
    <div class="tabs-list" *ngIf="signatureTabs.tabs.length > 1">
      <div class="tab-title" *ngFor="let tab of signatureTabs.tabs; let i = index"
           [class.active]="tab.hash === activeTab?.hash"
           (click)="setActiveTab(tab, i)">
        {{ tab.name }}
      </div>
    </div>
    <div class="tab-content">
      <ion-input [(ngModel)]="customerInitials" (ionChange)="redrawInitials()" *ngIf="activeTab?.isInitials"></ion-input>
      <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawingStarted()"></signature-pad>
    </div>
  </div>
</div>
<div class="modal-footer">
  <ion-button class="classic-width btn--secondary" (click)="clear()">Clear</ion-button>
  <ion-button class="classic-width outline btn--primary"
              [disabled]="!signatureExist[activeTab?.hash]"
              (click)="confirm()"
              *ngIf="signatureTabs.tabs.length > 1 && !isLastTabSelected"
  >Confirm & Next</ion-button>
  <ion-button class="classic-width btn--primary" [disabled]="!isDoneEnabled()" (click)="done()">Done</ion-button>
</div>
