export enum NewComparisonSetting {
    ADDERS = 'adders',
    BALANCE_WITH_DEPOSIT = 'balance_with_deposit',
    BALANCE_WITH_FINANCING = 'balance_with_financing_option',
    DEPOSIT = 'deposit',
    DISCOUNT_AMOUNT = 'discount_amount',
    LINE_ITEMS = 'line_items',
    LONG_TERM_FINANCING = 'long_term_financing',
    PACKAGE_PRICE = 'package_price',
    SHORT_TERM_FINANCING = 'short_term_financing',
    SUB_FINAL_PRICE = 'sub_final_price',
    TAX = 'tax'
}
