import { gql } from 'apollo-angular';

const ApprovalRequestSubscription = gql`
    subscription approvalRequestUpdated($id: ID!) {
        approvalRequestUpdated(id: $id) {
            id
            status
            relation_id
        }
    }
`;

const TransactionStatusSubscription = gql`
    subscription transactionStatusUpdated($quoteId: ID!) {
        transactionStatusUpdated(quote_id: $quoteId)
    }
`;

const MyFinancingOptionsUpdatedSubscription = gql`
    subscription MyFinancingOptionsUpdated($appointmentId: ID, $quoteId: ID) {
        myFinancingOptionUpdated(appointment_id: $appointmentId, quote_id: $quoteId) {
            appointment_id
        }
    }
`;

const AppointmentPullQuoteStatusSubscription = gql`
    subscription PullQuotesProgress($appointmentId: ID!) {
        pullQuotesProgress(appointment_id: $appointmentId)
    }
`;

const QuotesUpdatePkProgressSubscription = gql`
    subscription quotesUpdatePkProgress($appointmentId: ID!) {
        quotesUpdatePkProgress(appointment_id: $appointmentId)
    }
`;

const QuotesUpdatePkResultSubscription = gql`
    subscription QuotesUpdatePkResult($appointmentId: ID!) {
        quotesUpdatePkResult(appointment_id: $appointmentId) {
            package_name
            results {
                opening_name
                old_price
                new_price
                attributes {
                    question
                    old_answer
                    new_answer
                }
                errors
            }
        }
    }
`;

const QuotesUpdatePkErrorSubscription = gql`
    subscription QuotesUpdatePkError($appointmentId: ID!) {
        quotesUpdatePkError(appointment_id: $appointmentId)
    }
`;

const AutoConfiguratorSubscription = gql`
    subscription autoConfiguratorEvent($appointmentId: ID!) {
        autoConfiguratorEvent(appointment_id: $appointmentId)
    }
`;

const MeasurementSystemAuthDataReceivedSubscription = gql`
    subscription MeasurementSystemAuthDataReceived($type: MeasurementSystemTypeEnum!, $state: String!) {
        measurementSystemAuthDataReceived(type: $type, state: $state)
    }
`;

export {
    ApprovalRequestSubscription,
    TransactionStatusSubscription,
    MyFinancingOptionsUpdatedSubscription,
    AppointmentPullQuoteStatusSubscription,
    QuotesUpdatePkProgressSubscription,
    QuotesUpdatePkResultSubscription,
    QuotesUpdatePkErrorSubscription,
    AutoConfiguratorSubscription,
    MeasurementSystemAuthDataReceivedSubscription
};
