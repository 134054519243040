<div class="modal-header">
  <ion-text class="title">{{headerText}}</ion-text>
  <ion-searchbar
          debounce="500"
          placeholder="Search"
          [(ngModel)]="searchInput"
          (ionChange)="handleSearch()"
          mode="ios"
  ></ion-searchbar>
</div>
<div class="modal-body">
  <ion-radio-group [(ngModel)]="selectedValue">
    <ion-grid>
      <ion-row>
        <ion-col *ngFor="let col of cols" [size]="col.size" (click)="touchOrdering(col)">
          <ion-text>{{col.label}}</ion-text>
          <ng-container *ngIf="col.isAllowOrderBy">
            <ion-icon
                    *ngIf="orderingColumn === col.label"
                    [name]="orderingOrientation === orderDirection.Desc ? 'arrow-down-outline' : 'arrow-up-outline'"
            ></ion-icon>
            <ng-container *ngIf="orderingColumn !== col.label">
              <ion-icon class="inactive" name="arrow-up-outline"></ion-icon>
              <ion-icon class="inactive" name="arrow-down-outline"></ion-icon>
            </ng-container>
          </ng-container>
        </ion-col>
      </ion-row>
      <ion-content class="modal-body__content">
        <ion-grid>
          <ion-row *ngFor="let item of visibleItems" (click)="setSelectedValue(item)">
            <ion-col *ngFor="let col of cols" [size]="col.size">
              <ion-radio *ngIf="col.type === 'radio'" [value]="item[col.label]" mode="md"></ion-radio>
              <ion-text *ngIf="col.type === 'text'">{{item[col.label]}}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-infinite-scroll *ngIf="lastPage > currentPage" threshold="100px" (ionInfinite)="loadData($event)">
          <ion-infinite-scroll-content>
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
    </ion-grid>
  </ion-radio-group>
</div>
<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()" *ngIf="showCancelButton">{{cancelButtonName}}</ion-button>
  <ion-button class="classic-width" [disabled]="!items.length" (click)="confirm()">{{confirmButtonName}}</ion-button>
</div>
