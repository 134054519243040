import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private storage: Storage, private navCtrl: NavController) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.storage.get('token').then((token) => {
            if (token) {
                this.navCtrl.navigateRoot(next.queryParams.returnUrl || ['main']);
            }

            return !token;
        });
    }
}
