import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';

import { AuthService } from '@core/services/auth.service';
import { FilesService } from '@core/services/files.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { SYNCHRONIZE_APPOINTMENTS } from '@core/services/offline/queries/offline.queries';
import { GRID_IMAGE_URL } from '@shared/constants/grid-image-url';
import { ResourceType } from '@shared/enums/resource-type.enum';

declare let moment: any;

@Injectable({
    providedIn: 'root'
})
export class OfflineSyncService {
    constructor(
        private offlineStorageService: OfflineStorageService,
        private fileService: FilesService,
        private authService: AuthService,
        private apollo: Apollo
    ) {}

    async syncAppointments(background = true): Promise<void> {
        const user = cloneDeep(this.authService.getUser());

        if (!user) {
            return;
        }

        const newSyncTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        const lastSyncData = await this.offlineStorageService.findOne(
            `SELECT * FROM OfflineSyncs WHERE user_id='${user.id}' AND office_id='${user.office.id}'`
        );
        const res: ApolloQueryResult<any> = await this.apollo
            .query({
                query: SYNCHRONIZE_APPOINTMENTS,
                variables: {
                    synchronized_at: lastSyncData?.synchronized_at,
                    last_schedule_time: lastSyncData?.last_schedule_time
                },
                context: {
                    extensions: {
                        background
                    }
                }
            })
            .toPromise();

        if (res.data.synchronizeAppointments.appointments?.length) {
            for (const appointmentIndex in res.data.synchronizeAppointments.appointments) {
                const appointment = res.data.synchronizeAppointments.appointments[appointmentIndex];

                appointment.schedule_time_timestamp = moment.utc(appointment.schedule_time).unix();
                appointment.office_id = user.office.id;

                if (appointment.notes) {
                    appointment.notes = this.replaceSingleQuote(appointment.notes);
                }

                if (appointment.house.properties.hasOwnProperty('address')) {
                    appointment.house.properties = {
                        address: appointment.house.properties.address
                    };
                }
            }

            try {
                await this.offlineStorageService.insert('Appointments', res.data.synchronizeAppointments.appointments);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(`Appointments - Error - ${user.email}`);
                console.error(e);
            }

            const lastAppointment = await this.offlineStorageService.findOne(
                `SELECT MAX(schedule_time_timestamp) as max_schedule_time, schedule_time FROM Appointments WHERE seller_id = '${user.id}' AND office_id='${user.office.id}'`
            );

            await this.offlineStorageService.insert('OfflineSyncs', [
                {
                    user_id: user.id,
                    office_id: user.office.id,
                    synchronized_at: newSyncTime,
                    last_schedule_time: lastAppointment?.schedule_time
                }
            ]);
        }

        if (res.data.myNeedsAssessments) {
            await this.offlineStorageService.insert('NeedsAssessments', [
                {
                    user_id: user.id,
                    office_id: user.office.id,
                    needs_assessment: JSON.stringify(res.data.myNeedsAssessments)
                }
            ]);
        }

        if (res.data.commonItems) {
            await Promise.all([
                await this.offlineStorageService.insert('Common', [
                    {
                        id: 'customer_phone_number_tags',
                        value: JSON.stringify(res.data.commonItems.customer_phone_number_tags)
                    }
                ]),
                await this.offlineStorageService.insert('Common', [
                    {
                        id: 'customer_email_tags',
                        value: JSON.stringify(res.data.commonItems.customer_email_tags)
                    }
                ])
            ]);
        }

        if (res.data.myUserPreferences) {
            await this.offlineStorageService.insert('UserPreferences', [
                {
                    user_id: user.id,
                    office_id: user.office.id,
                    preferences: JSON.stringify(res.data.myUserPreferences)
                }
            ]);
        }

        if (res.data.offlineCategories) {
            const categories = res.data.offlineCategories;

            categories.forEach((category, index) => {
                category.user_id = user.id;
                category.office_id = user.office.id;
                category.position = index;
                category.name = this.replaceSingleQuote(category.name);
                category.opening_conditions.forEach(
                    (condition) => (condition.name = this.replaceSingleQuote(condition.name))
                );
                category.category.name = this.replaceSingleQuote(category.category.name);
                category.category.all_questions.forEach((question) =>
                    this.replaceSingleQuoteInQuestionAnswer(question)
                );
                category.category.questions.forEach((question) => this.replaceSingleQuoteInQuestionAnswer(question));
            });

            await this.offlineStorageService.insert('Categories', categories);
        }

        if (res.data.myDemoResources) {
            const myDemoResources = [];

            res.data.myDemoResources.forEach((resource) => {
                if (![ResourceType.Pdf, ResourceType.Video, ResourceType.Ingage].includes(resource.resource_type)) {
                    return;
                }

                resource.user_id = user.id;
                resource.office_id = user.office.id;
                resource.id = Number(resource.id);
                resource.name = this.replaceSingleQuote(resource.name);
                resource.categories.forEach((category) => (category.name = this.replaceSingleQuote(category.name)));

                myDemoResources.push(resource);
            });

            if (myDemoResources.length) {
                for (const resource of myDemoResources) {
                    if (resource.badge.src.startsWith('https')) {
                        try {
                            resource.badge.src = await this.fileService.saveResourceLocally(
                                resource.badge.src,
                                'badges/'
                            );
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.log(`Badge - Error - ${JSON.stringify(resource)}`);
                            console.error(e);
                        }
                    }

                    if (resource.resource_type !== ResourceType.Ingage) {
                        try {
                            resource.resource_src = await this.fileService.saveResourceLocally(
                                resource.resource_src,
                                'demo_resources/'
                            );
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.log(`Resource - Error - ${JSON.stringify(resource)}`);
                            console.error(e);
                        }
                        if (resource.resource_type === ResourceType.Pdf) {
                            try {
                                await this.fileService.saveAndReplaceLinksInHTML(resource.resource_src);
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.log(`Resource ${ResourceType.Pdf} - Error - ${JSON.stringify(resource)}`);
                                console.error(e);
                            }
                        }
                    }
                }

                await this.offlineStorageService.insert('DemoResources', myDemoResources);
            }

            try {
                this.cleanupDemoResources(myDemoResources, user);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(`CleanupDemoResources - Error - ${user.email}`);
                console.error(e);
            }
        }

        if (res.data.synchronizeAppointments.date_range) {
            try {
                this.cleanupAppointments(
                    res.data.synchronizeAppointments.date_range.from_timestamp,
                    res.data.synchronizeAppointments.date_range.to_timestamp,
                    user
                );
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(`cleanupAppointments - Error - ${user.email}`);
                console.error(e);
            }
        }

        const localGridImageUrl: string = await this.fileService.saveResourceLocally(GRID_IMAGE_URL, 'images/');

        window.localStorage.setItem('localGridImageUrl', localGridImageUrl);

        const logoUrl: string = await this.authService.getStorageItem('logo_url');

        if (logoUrl.startsWith('http')) {
            const logoPath: string = await this.fileService.saveResourceLocally(logoUrl, 'images/');

            window.localStorage.setItem(`logo_user_id_${user.id}`, logoPath);
        }
    }

    replaceSingleQuote(value: string): string {
        if (!isString(value)) {
            return value;
        }

        return value.replace("'", '');
    }

    replaceSingleQuoteInQuestionAnswer(question: any): void {
        question.title = this.replaceSingleQuote(question.title);
        question.default_answer = this.replaceSingleQuote(question.default_answer);

        if (Array.isArray(question.available_answers)) {
            question.available_answers = question.available_answers.map((answer) => this.replaceSingleQuote(answer));
        } else {
            for (const key in question.available_answers) {
                question.available_answers[key] = question.available_answers[key].map((answer) =>
                    this.replaceSingleQuote(answer)
                );
            }
        }
    }

    async cleanupDemoResources(resources: any[], user: any): Promise<any> {
        const savedResources = await this.offlineStorageService.read(`
            SELECT * FROM DemoResources
            WHERE user_id='${user.id}' AND office_id='${user.office.id}'
        `);
        const deletedResourceIds: string[] = [];

        savedResources.forEach(({ id, resource_src }) => {
            if (!resources.find((resource) => resource.id == id)) {
                deletedResourceIds.push(id);
                this.fileService.deleteFile(resource_src);
            }
        });

        return this.offlineStorageService.deleteRecords(`
            SELECT * FROM DemoResources
            WHERE user_id='${user.id}' AND office_id='${user.office.id}'
            AND id IN (${deletedResourceIds.join(', ')})
        `);
    }

    async cleanupAppointments(dateFrom: number, dateTo: number, user: any): Promise<any> {
        return this.offlineStorageService.deleteRecords(`
            SELECT * FROM Appointments
              WHERE seller_id='${user.id}'
              AND (schedule_time_timestamp >= '${dateTo}'
              OR schedule_time_timestamp < '${dateFrom}')
        `);
    }
}
