export const ERROR_MESSAGES = {
    required: 'This field is required',
    maxlength: 'This field cannot be greater than {requiredLength}',
    email: 'Not a valid email',
    fileExtension: 'File extension should be {allowedExtensions}',
    maxFileSize: 'File size cannot be greater than {maxSize}Mb',
    minlength: 'This field cannot be less than {requiredLength}',
    mustMatch: '{attribute} and {compareAttribute} do not match.',
    matchPattern: '{msg}.',
    min: 'Value cannot be less than {min}',
    max: 'Value cannot be greater than {max}',
    onlyOnePrimary: 'Select only one "Primary"'
};
