import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';

import { SAVE_USER_PREFERENCE } from '@core/mutations/user-preferences.mutations';
import {
    GET_MY_USER_PREFERENCE,
    GET_MY_USER_PREFERENCES,
    SHOW_PRICING_ENABLED
} from '@core/queries/user-preferences.queries';
import { FeaturesService } from '@core/services/features.service';
import { USER_PREFERENCES_GROUPS } from '@shared/constants/user-preferences';
import { UserPreferences } from '@shared/enums/user-preferences.enum';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {
    userPreference: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private apollo: Apollo, private featureService: FeaturesService) {}

    getPreference(type: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_MY_USER_PREFERENCE,
                variables: {
                    type
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myUserPreference));
    }

    getPreferences(types: string[]): Observable<any> {
        return this.apollo
            .query({
                query: GET_MY_USER_PREFERENCES,
                variables: {
                    types
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const userPreferences = cloneDeep(res.data.myUserPreferences);

                    USER_PREFERENCES_GROUPS.forEach((group: any) => {
                        group.values.forEach((preference) => {
                            const resPreference = res.data.myUserPreferences.find(
                                (resPref) => resPref.type === preference.hash
                            );
                            const isAvailableMeasurementTools: boolean = [
                                UserPreferences.AugmentRealityMeasurement,
                                UserPreferences.BoschMeasurement
                            ].includes(preference.hash);

                            if (!resPreference) {
                                if (
                                    group.label === 'Measurement Tool' &&
                                    this.featureService.hasFeature(group.features, false)
                                ) {
                                    const measurementToolGroupValues: any[] = group.values.filter(
                                        (value: any) =>
                                            !value.features || this.featureService.hasFeature(value.features)
                                    );

                                    measurementToolGroupValues.forEach((preference) => {
                                        const userPreference = userPreferences.find(
                                            (res) => res.type === preference.hash
                                        );

                                        if (
                                            !userPreferences.find((pref) => pref.type === preference.hash) &&
                                            !res.data.myUserPreferences.find((pref) => pref.type === preference.hash)
                                        ) {
                                            res.data.myUserPreferences.push({
                                                type: preference.hash,
                                                value: userPreference
                                                    ? userPreference.value
                                                    : this.featureService.hasFeature(preference.features)
                                            });
                                        }
                                    });
                                } else {
                                    res.data.myUserPreferences.push({
                                        type: preference.hash,
                                        value:
                                            isAvailableMeasurementTools &&
                                            !this.featureService.hasFeature(preference.features)
                                                ? false
                                                : preference.defaultValue
                                    });
                                }
                            } else {
                                if (
                                    isAvailableMeasurementTools &&
                                    !this.featureService.hasFeature(preference.features)
                                ) {
                                    res.data.myUserPreferences.find(
                                        (resPref) => resPref.type === preference.hash
                                    ).value = false;
                                }
                            }
                        });
                    });

                    return res.data.myUserPreferences;
                })
            );
    }

    showPricingEnabled(): Observable<boolean> {
        return this.apollo
            .query({
                query: SHOW_PRICING_ENABLED
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.showPriceEnabled));
    }

    saveUserPreference(type: string, value: any, isEmitSavedPreference = true): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_USER_PREFERENCE,
                variables: {
                    type,
                    value
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const preference = res.data.saveUserPreference;

                    if (isEmitSavedPreference) {
                        this.userPreference.next(preference);
                    }

                    return preference;
                })
            );
    }

    getUserPreferenceByType(type: UserPreferences): Observable<any> {
        return this.userPreference.asObservable().pipe(filter((preference) => preference?.type === type));
    }
}
