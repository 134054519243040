import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';
import { Quote } from '@shared/interfaces/quote';

@Component({
    selector: 'vendo-pricing-details',
    templateUrl: './pricing-details.component.html',
    styleUrls: ['./pricing-details.component.scss']
})
export class PricingDetailsComponent extends BaseModal {
    @Input() quote: Quote;

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
