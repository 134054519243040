import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestCancelService {
    private pendingRequests$: Subject<void> = new Subject<void>();

    constructor() {}

    cancelPendingRequests(): void {
        this.pendingRequests$.next();
    }

    onCancelPendingRequests(): Observable<void> {
        return this.pendingRequests$.asObservable();
    }
}
