<ion-content class="login-page change-password">
    <div slot="fixed" class="background-box">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="changePassword()">
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col class="top-logo ion-align-self-center ion-justify-content-center" [ngClass]="{'is-higher-image': isHigherImage}" size-xl="4" size-md="7" size="10">
                        <div class="ion-align-self-center">
                            <ion-img class="main-logo ion-align-self-center"
                                     [src]="logoUrl"
                            ></ion-img>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-justify-content-center" *ngIf="resetPasswordHash && resetPasswordHash.valid">
                    <ion-col class="login-card ion-align-self-center" size-xl="4" size-md="7" size="10">
                        <div>
                            <ion-item lines="none">
                                <ion-label position="stacked">New Password</ion-label>
                                <ion-input formControlName="newPassword"
                                           aria-labelledby="New Password"
                                           type="password"
                                           autocomplete="off"
                                           required></ion-input>
                                <div class="errors-wrapper" *ngIf="resetPasswordForm.get('newPassword').touched">
                                    <ion-text color="danger" class="error"
                                              *ngFor="let error of getMessages(resetPasswordForm.get('newPassword').errors)">
                                        {{error}}
                                    </ion-text>
                                </div>
                            </ion-item>
                            <ion-item lines="none">
                                <ion-label position="stacked">Re-enter New Password</ion-label>
                                <ion-input type="password"
                                           formControlName="confirmPassword"
                                           aria-labelledby="Re-enter New Password"
                                           autocomplete="off"
                                           required></ion-input>
                                <div class="errors-wrapper" *ngIf="resetPasswordForm.get('confirmPassword').touched">
                                    <ion-text color="danger" class="error"
                                              *ngFor="let error of getMessages(resetPasswordForm.get('confirmPassword').errors)">
                                        {{error}}
                                    </ion-text>
                                </div>
                            </ion-item>
                        </div>

                        <div class="button-section ion-text-center">
                            <ion-button class="login-button"
                                        color="dark"
                                        size="large"
                                        type="submit"
                                        [disabled]="resetPasswordForm.invalid"
                                        expand="full">
                                Change Password
                            </ion-button>

                            <div class="accept">
                                <ion-checkbox mode="md"
                                              color="dark"
                                              formControlName="accept_terms_and_conditions"></ion-checkbox>
                                <ion-label>
                                    I have read, consent, and agree to this application’s <span class="terms-and-conditions" (click)="openTermsAndConditionsModal()">Terms and Conditions</span>.
                                </ion-label>
                            </div>

                            <a class="ion-margin-top underline link inline-block"
                               routerDirection="root"
                               routerLink="/login">
                                Go To Login
                            </a>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-justify-content-center" *ngIf="!resetPasswordHash || !resetPasswordHash.valid">
                    <ion-col class="login-card link-not-valid ion-align-self-center" size-xl="4" size-md="7" size="10">
                        <ion-text color="danger">
                            <ion-icon name="close-circle-outline"></ion-icon>
                        </ion-text>
                        <ion-text>
                            Reset password link is not valid anymore.
                        </ion-text>
                        <div class="button-section ion-padding ion-text-center">
                            <a class="ion-margin-top underline link inline-block"
                               routerDirection="root"
                               routerLink="/login">
                                Go To Login
                            </a>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-justify-content-center">
                    <ion-col class="bottom-section ion-text-center" size-xl="4" size-md="7" size="10">
                        <ion-text>&#9400; 2001-{{currentYear}} All Rights Reserved.</ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>
</ion-content>
