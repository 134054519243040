import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'lineBreaks'
})
export class LineBreaksPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, sanitizeBeforehand?: boolean): string {
        if (!value || typeof value !== 'string') {
            return value;
        }

        const textParsed: string = value.replace(/(?:\r\n|\r|\n)/g, '<br />');

        return sanitizeBeforehand ? this.sanitizer.sanitize(SecurityContext.HTML, textParsed) : textParsed;
    }
}
