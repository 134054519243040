import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import pluralize from 'pluralize';

import { ModalController } from '@ionic/angular';

import { merge, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import find from 'lodash/find';
import round from 'lodash/round';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-roofing-dimensions-modal',
    templateUrl: './roofing-dimensions-modal.component.html',
    styleUrls: ['./roofing-dimensions-modal.component.scss']
})
export class RoofingDimensionsModalComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() category: any;
    types: string[] = ['Facet', 'Eave', 'Rake', 'Ridge', 'Hip', 'Valley', 'Vertical Wall', 'Gutters'];
    groups: any[] = [];
    form: UntypedFormGroup = this.formBuilder.group({});
    isExistTakeoffItems = false;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(modalCtrl: ModalController, private formBuilder: UntypedFormBuilder) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.types.forEach((type: string) => {
            const group = {
                title: pluralize.plural(type),
                type,
                items: this.category.openings
                    .filter((opening) => type === find(opening.all_details, { question_hash: 'type' })?.answer)
                    .map((opening) => {
                        opening.answers = {};
                        opening.all_details.forEach((detail) => {
                            opening.answers[detail.question_hash] = detail.answer;
                        });

                        return opening;
                    })
            };

            if (!this.isExistTakeoffItems) {
                this.isExistTakeoffItems = Boolean(group.items.length);
            }

            const groupFormArray: UntypedFormArray = this.formBuilder.array(
                group.items.map((opening) => this.formBuilder.group({ isSelected: false, opening }))
            );

            this.form.addControl(
                type,
                this.formBuilder.group({ isAllSelected: false, items: groupFormArray, countSelected: 0 })
            );
            this.groups.push(group);
        });

        const formControlKeys: string[] = Object.keys(this.form.controls);

        merge(
            ...formControlKeys.map((type: string) =>
                this.form.get([type, 'isAllSelected']).valueChanges.pipe(map((checked: boolean) => ({ type, checked })))
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ type, checked }) => {
                const itemsFormArray: UntypedFormArray = this.getItemsFormArray(type);

                this.form.get([type, 'countSelected']).setValue(checked ? itemsFormArray.controls.length : 0);
                itemsFormArray.controls.forEach((group: AbstractControl) => {
                    (group as UntypedFormGroup).get('isSelected').setValue(checked);
                    if (checked) {
                        this.unselectOther(type);
                    }
                });
            });

        merge(
            ...formControlKeys.map((type: string) =>
                merge(
                    ...this.getItemsFormArray(type).controls.map((group: AbstractControl) =>
                        (group as UntypedFormGroup)
                            .get('isSelected')
                            .valueChanges.pipe(map((checked) => ({ type, checked })))
                    )
                )
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ type, checked }) => {
                const itemsFormArray: UntypedFormArray = this.getItemsFormArray(type);
                const values: boolean[] = itemsFormArray.controls.map(
                    (group: AbstractControl) => (group as UntypedFormGroup).get('isSelected').value
                );
                const isAllSelectedControl: AbstractControl = this.form.get([type, 'isAllSelected']);
                const countSelected: number = values.filter(Boolean).length;
                const isAllItemsSelected: boolean = countSelected === itemsFormArray.controls.length;

                if (isAllItemsSelected && !isAllSelectedControl.value) {
                    isAllSelectedControl.setValue(true, { emitEvent: false });
                } else if (!isAllItemsSelected && isAllSelectedControl.value) {
                    isAllSelectedControl.setValue(false, { emitEvent: false });
                }
                this.form.get([type, 'countSelected']).setValue(countSelected);

                if (checked) {
                    this.unselectOther(type);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    save(): void {
        const formData: any = this.form.getRawValue();
        let finalAreaQuestionId: string;
        const result = {
            answers: {}
        };

        Object.keys(formData).forEach((key: string) => {
            const roofingItem = formData[key];

            if (!roofingItem.countSelected) {
                return;
            }

            roofingItem.items.forEach(({ isSelected, opening }) => {
                if (!isSelected) {
                    return;
                }

                if (!finalAreaQuestionId) {
                    opening.all_details.forEach((detail) => {
                        switch (detail.question_hash) {
                            case 'type':
                                result.answers[detail.question_id] = 'Accessory';
                                break;
                            case 'measurement_type':
                                result.answers[detail.question_id] = detail.answer;
                                break;
                            case 'final_area':
                                result.answers[detail.question_id] = 0;
                                finalAreaQuestionId = detail.question_id;
                                break;
                        }
                    });
                }

                result.answers[finalAreaQuestionId] = round(
                    result.answers[finalAreaQuestionId] + Number(opening.answers['final_area']),
                    4
                );
            });
        });

        this.dismiss(result);
    }

    private getItemsFormArray(type: string): UntypedFormArray {
        return this.form.get([type, 'items']) as UntypedFormArray;
    }

    private unselectOther(type: string): void {
        this.types.forEach((item: string) => {
            const isAllSelectedControl: AbstractControl = this.form.get([item, 'isAllSelected']);
            const countSelectedControl: AbstractControl = this.form.get([item, 'countSelected']);

            if ((type === 'Facet' && item !== 'Facet') || (type !== 'Facet' && item === 'Facet')) {
                if (isAllSelectedControl.value || countSelectedControl.value) {
                    isAllSelectedControl.setValue(false);
                    countSelectedControl.setValue(0);
                }
            }
        });
    }
}
