import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ModalController, NavController } from '@ionic/angular';

import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import max from 'lodash/max';
import min from 'lodash/min';

import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { QuoteService } from '@core/services/quote.service';
import { RoundingService } from '@core/services/rounding.service';
import { StepsService } from '@core/services/steps.service';
import { SubscriptionsService } from '@core/services/websocket/subscriptions.service';
import { RequestFinancingApplicationModalComponent } from '@shared/components/request-financing-application-modal/request-financing-application-modal.component';
import { FEATURES } from '@shared/constants/features';
import { paradigmFinanceOfferId } from '@shared/constants/paradigm-finance-offer-id';
import { AppointmentType } from '@shared/enums/appointment-type';
import { QuoteStepType } from '@shared/enums/quote-step-type.enum';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { Appointment } from '@shared/interfaces/appointment';
import { ExtraPriceFields } from '@shared/interfaces/extra-price-fields';
import { FinancingOption } from '@shared/interfaces/financing-option';
import { QuotePriceInfo } from '@shared/interfaces/quote';
import { Tab } from '@shared/interfaces/tab';

import { ParadigmFinanceRateSheetSelectedService } from '../../../main/appointments/services/paradigm-finance-rate-sheet-selected.service';

@Component({
    selector: 'vendo-financing-options',
    templateUrl: './financing.component.html',
    styleUrls: ['./financing.component.scss']
})
export class FinancingComponent implements OnInit, OnDestroy {
    financingOptions: FinancingOption[] = [];
    approvedOffers: FinancingOption[] = [];
    financingUsers: any[] = [];
    selectedOption: FinancingOption;
    selectedRateSheet: any;
    quote: QuotePriceInfo;
    amountFinancing: number;
    monthlyPayment: number;
    appointmentId: number;
    quoteId: number;
    rateSheetsList: any[] = [];
    offerCodeRateSheetsListOrigin: any[] = [];
    rateSheetsListOrigin: any[] = [];
    isShowNavButtons = false;
    @ViewChildren('financingElement') financingElements: QueryList<ElementRef>;
    paradigmFinanceImagePath = 'assets/img/Paradigm-Finance.png';
    offerCodes;
    offerCodesList;
    selectedOfferCode;
    allRates = [];
    allMonths = [];
    paradigmPaymentsOptions = {
        rate: {
            min: 0,
            max: null
        },
        months: {
            min: 0,
            max: null
        }
    };
    readonly hasParadigmFinanceFeature: boolean = this.featureService.hasFeature(
        [FEATURES.PARADIGM_FINANCE, FEATURES.PARADIGM_FINANCE_SANDBOX],
        false
    );
    activeTabId: string;
    tabs: Tab<string>[] = [];
    standardOffersTab: Tab<string> = {
        hash: 'standard-offers',
        label: 'Standard Offers'
    };
    paradigmFinanceTab: Tab<string> = {
        hash: 'paradigm-finance',
        label: 'Paradigm Finance'
    };

    private isFinalSteps: boolean;
    private destroy$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        private deviceHelperService: DeviceHelperService,
        private featureService: FeaturesService,
        private modalController: ModalController,
        private navCtrl: NavController,
        private paradigmFinanceRateSheetSelectedService: ParadigmFinanceRateSheetSelectedService,
        private roundingService: RoundingService,
        private route: ActivatedRoute,
        private stepsService: StepsService,
        private subscriptionsService: SubscriptionsService,
        private quoteService: QuoteService
    ) {}

    ngOnInit(): void {
        const financingOptions: { financingOptions: FinancingOption[]; financingUsers: any[] } =
            this.route.snapshot.data.financingOptions;

        this.isFinalSteps = this.route.snapshot.parent.routeConfig.path === 'final';
        this.appointmentId = this.route.snapshot.parent.parent.params.appointmentId;
        this.quoteId = this.route.snapshot.params.quoteId;
        this.quote = this.route.snapshot.data.quote;
        this.amountFinancing = this.roundingService.round(this.quote.final_price - this.quote.deposit);
        this.financingUsers = financingOptions.financingUsers;
        this.fillFinancingOptions(this.route.snapshot.data.financingOptions.financingOptions);

        if (this.hasParadigmFinanceFeature && financingOptions.financingUsers.length) {
            this.getFinancingOfferCodesAndRateSheets();
        }

        if (this.financingOptions.length) {
            this.tabs.push(this.standardOffersTab);
        }

        this.preselectFinancingOption();
        this.activeTabId = this.tabs[0]?.hash;

        this.subscriptionsService
            .financingOptionSubscription(this.appointmentId, this.quoteId)
            .pipe(
                switchMap((data: any) =>
                    forkJoin([
                        data.appointment_id ? this.quoteService.getQuotePriceInfo(this.quoteId, true) : of(null),
                        this.quoteService.getFinancingOptions(this.appointmentId, this.quoteId, true)
                    ])
                ),
                takeUntil(this.destroy$)
            )
            .subscribe(
                ([quote, { financingOptions, financingUsers }]: [
                    QuotePriceInfo,
                    { financingOptions: FinancingOption[]; financingUsers: any[] }
                ]) => {
                    this.approvedOffers = [];
                    this.financingOptions = [];
                    this.fillFinancingOptions(financingOptions);
                    this.financingUsers = financingUsers;

                    const allOffersTab = this.offerCodesList.find((offerTab) => isNil(offerTab.id));

                    if (allOffersTab) {
                        this.selectOfferCode(allOffersTab);
                    }

                    if (quote) {
                        this.quote = quote;
                        this.preselectFinancingOption();
                    }
                }
            );
    }

    selectTab(tab): void {
        this.activeTabId = tab.hash;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    doAction(): void {
        if (this.activeTabId === this.paradigmFinanceTab.hash) {
            this.openRequestApplicationModal();

            return;
        }

        if (this.selectedOption.url !== '#') {
            this.openFinancingDetailsPage(this.selectedOption.url);

            return;
        }

        this.done();
    }

    openFinancingDetailsPage(url: string): void {
        this.completeStep().subscribe();
        this.deviceHelperService.openUrl(url);
    }

    async openRequestApplicationModal(): Promise<any> {
        const modal = await this.modalController.create({
            component: RequestFinancingApplicationModalComponent,
            cssClass: 'request-application-modal',
            backdropDismiss: false,
            componentProps: {
                isApplyFinancing: true,
                quoteId: this.quoteId,
                offerCodeName: this.selectedOfferCode?.id ? this.selectedOfferCode?.name : null
            }
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (data) {
            this.paradigmFinanceRateSheetSelectedService.setRateSheet(
                this.appointmentId,
                this.quoteId,
                this.selectedRateSheet.id
            );
        }
    }

    selectFinancing(option: FinancingOption): void {
        this.selectedRateSheet = null;
        this.selectedOption =
            !this.isFinalSteps && this.selectedOption && this.selectedOption.id === option.id ? null : option;

        this.calculateMonthlyPayment();
        this.selectedOfferCode = null;
        this.offerCodes = null;
    }

    selectRateSheet(item): void {
        this.selectedOption = null;

        if (item.id === this.selectedRateSheet?.id && item.parent_id === this.selectedRateSheet?.parent_id) {
            this.selectedRateSheet = null;

            return;
        }

        this.selectedRateSheet = item;

        this.calculateRateSheetMonthlyPayment();
    }

    selectOfferCode(item): void {
        this.selectedRateSheet = null;

        if (item === this.selectedOfferCode) {
            return;
        }

        setTimeout(() => {
            if (item.id) {
                this.rateSheetsList = cloneDeep(this.offerCodeRateSheetsListOrigin);

                this.rateSheetsList.forEach((rateSheet) => {
                    rateSheet.value = rateSheet.value.filter((rateItem) => rateItem.parent_id === item.id);
                });
            } else {
                this.rateSheetsList = cloneDeep(this.rateSheetsListOrigin);
            }

            this.selectedOfferCode = item;
            this.paradigmPaymentsOptions = {
                rate: {
                    min: min(item.rates),
                    max: max(item.rates)
                },
                months: {
                    min: min(item.months),
                    max: max(item.months)
                }
            };

            this.monthlyPayment = this.quoteService.calculateMonthlyPayment(
                {
                    interest_rate: this.paradigmPaymentsOptions.rate.min,
                    months: this.paradigmPaymentsOptions.months.min
                },
                this.amountFinancing
            );
        });
    }

    applyFinancing(): void {
        const financingOptionId = this.selectedRateSheet ? paradigmFinanceOfferId : this.selectedOption?.id;

        if (financingOptionId === paradigmFinanceOfferId) {
            this.paradigmFinanceRateSheetSelectedService.setRateSheet(
                this.appointmentId,
                this.quoteId,
                this.selectedRateSheet.id
            );
        } else {
            this.paradigmFinanceRateSheetSelectedService.removeRateSheet(this.appointmentId, this.quoteId);
        }

        this.quoteService
            .setFinancingOption(this.quote.quote_id, financingOptionId || undefined)
            .subscribe(async () => {
                const appointment: Appointment = await this.authService.getStorageItem('activeAppointment');

                this.navCtrl.navigateRoot(
                    `/main/appointments/${this.appointmentId}/${
                        appointment.type === AppointmentType.SecondMeasure ? SaleStepType.REVIEW : SaleStepType.QUOTE
                    }`,
                    appointment.type === AppointmentType.SecondMeasure
                        ? undefined
                        : {
                              queryParams: {
                                  quoteId: this.quote.quote_id
                              }
                          }
                );
            });
    }

    calculateMonthlyPayment(): void {
        if (!this.selectedOption) {
            return;
        }

        this.quoteService
            .getExtraPriceFields(this.amountFinancing, null, {
                id: this.selectedOption.id,
                interest_rate: this.selectedOption.interest_rate,
                payment_factor: this.selectedOption.payment_factor,
                months: this.selectedOption.months
            })
            .subscribe((res: ExtraPriceFields) => (this.monthlyPayment = res.monthly_payment));
    }

    done(): void {
        if (this.isFinalSteps) {
            this.completeStep().subscribe(() =>
                this.navCtrl.navigateRoot(`/main/appointments/${this.appointmentId}/final/${this.quoteId}`, {
                    replaceUrl: true
                })
            );
        } else {
            this.applyFinancing();
        }
    }

    private preselectFinancingOption(): void {
        if (!this.quote.financing_options) {
            return;
        }

        if (this.quote.financing_options?.id !== paradigmFinanceOfferId) {
            this.paradigmFinanceRateSheetSelectedService.removeRateSheet(this.appointmentId, this.quoteId);
            this.selectedRateSheet = null;
        }

        this.selectedOption = [...this.financingOptions, ...this.approvedOffers].find(
            (item) => Number(item.id) === this.quote.financing_options.id
        );
        this.calculateMonthlyPayment();

        if (this.financingOptions.length) {
            setTimeout(() => this.scrollToSelected());
        }
    }

    private getFinancingOfferCodesAndRateSheets(): void {
        forkJoin([this.quoteService.getFinancingOfferCodes(), this.quoteService.getFinancingRateSheets()]).subscribe(
            async ([offerCodes, rateSheets]) => {
                this.remapRateSheetsandOfferCodes(offerCodes, rateSheets);

                this.allRates = [];
                this.allMonths = [];

                offerCodes.forEach((offerCode) => {
                    offerCode.rates = [];
                    offerCode.months = [];

                    offerCode.rate_sheet_merchant_product_prices.forEach(({ product_term_structure }) => {
                        const range = product_term_structure.apr_range
                            .split('to')
                            .map((rangeItem: string) => parseFloat(rangeItem));

                        offerCode.rates.push(...range);
                        offerCode.months.push(parseInt(product_term_structure.term_range, 10));
                        this.allRates.push(...range);
                        this.allMonths.push(parseInt(product_term_structure.term_range, 10));
                    });
                });

                this.paradigmPaymentsOptions.rate.max = max(this.allRates);
                this.paradigmPaymentsOptions.months.max = max(this.allMonths);
                this.offerCodes = offerCodes;
            }
        );
    }

    private async remapRateSheetsandOfferCodes(offerCodes, rateSheets): Promise<void> {
        const offerCodesWithRange = this.filterItemsWithRange(offerCodes);
        const rateSheetMerchantProductPrices = [];

        const selectedRateSheetId = await this.paradigmFinanceRateSheetSelectedService.getRateSheet(
            this.appointmentId,
            this.quoteId
        );

        this.offerCodesList = offerCodesWithRange.map((offerCode) => ({
            ...offerCode,
            rate_sheet_merchant_product_prices: offerCode.rate_sheet_merchant_product_prices.filter(
                (rateSheet) => rateSheet.product_category
            )
        }));

        offerCodes.forEach((item) => {
            item.rate_sheet_merchant_product_prices.forEach((offerRateSheet) => {
                const newRateSheet = {
                    parent_id: item.id,
                    max_price_to_apply: item.max_price_to_apply,
                    min_price_to_apply: item.min_price_to_apply,
                    apr: offerRateSheet.product_term_structure.apr_range,
                    short_description: offerRateSheet.product_term_structure.short_description,
                    full_term: offerRateSheet.product_term_structure.term_range,
                    ...offerRateSheet
                };

                if (newRateSheet.product_category) {
                    rateSheetMerchantProductPrices.push(newRateSheet);
                }

                if (selectedRateSheetId === offerRateSheet.id) {
                    const selectedOfferCode = this.offerCodesList.find((item) => item.id === newRateSheet.parent_id);

                    if (selectedOfferCode) {
                        this.selectOfferCode(selectedOfferCode);

                        setTimeout(() => {
                            this.selectRateSheet(newRateSheet);
                        });
                    }
                }
            });
        });

        const offerRateSheetMerchantList = groupBy(rateSheetMerchantProductPrices, 'product_category');

        for (const key in offerRateSheetMerchantList) {
            const ratesList = offerRateSheetMerchantList[key];
            const rateSheetWithRange = this.filterItemsWithRange(ratesList);

            this.offerCodeRateSheetsListOrigin.push({
                name: key,
                value: rateSheetWithRange.map((item) => ({
                    ...item,
                    apr: item.product_term_structure.apr_range,
                    short_description: item.product_term_structure.short_description,
                    full_term: item.product_term_structure.term_range
                }))
            });
        }

        const rateSheetsList = groupBy(rateSheets, 'product_category');

        for (const key in rateSheetsList) {
            const rateSheetList = rateSheetsList[key].map((item) => ({
                id: `${key}-${item.product_name}`,
                ...item
            }));

            rateSheetList.forEach((item) => {
                if (selectedRateSheetId === item.id) {
                    this.selectedRateSheet = item;

                    this.calculateRateSheetMonthlyPayment();
                }
            });

            const itemRate = {
                name: key,
                value: rateSheetList
            };

            this.rateSheetsList.push(itemRate);
            this.rateSheetsListOrigin.push(itemRate);
        }

        const isRateSheetsExist = this.rateSheetsList.some((item) => item.value.length);

        if (this.approvedOffers.length || isRateSheetsExist) {
            this.offerCodesList.unshift({ name: 'All Offers', id: null });
        }

        if (this.approvedOffers?.length || this.offerCodesList?.length || isRateSheetsExist) {
            const tabIndex = isNil(this.selectedOption) ? 0 : Number(!this.selectedOption?.quote_id);

            this.tabs.unshift(this.paradigmFinanceTab);
            this.selectTab(this.tabs[tabIndex]);
        }
    }

    private calculateRateSheetMonthlyPayment(): void {
        const apr_range = this.selectedRateSheet.apr.includes('to')
            ? parseFloat(this.selectedRateSheet.apr.split(' ')[0])
            : parseFloat(this.selectedRateSheet.apr);

        const term_range = this.selectedRateSheet.full_term.includes('to')
            ? parseFloat(this.selectedRateSheet.full_term.split(' ')[0])
            : parseFloat(this.selectedRateSheet.full_term);

        this.monthlyPayment = this.quoteService.calculateMonthlyPayment(
            {
                interest_rate: apr_range,
                months: term_range
            },
            this.amountFinancing
        );
    }

    private filterItemsWithRange(data: any): any[] {
        const itemsWithRange = [];

        data.forEach((item) => {
            if (
                !(
                    (item.min_price_to_apply !== null && item.min_price_to_apply > this.amountFinancing) ||
                    (item.max_price_to_apply !== null && item.max_price_to_apply < this.amountFinancing)
                )
            ) {
                itemsWithRange.push(item);
            }
        });

        return itemsWithRange;
    }

    private completeStep(): Observable<any> {
        return this.stepsService.completeStep(this.appointmentId, QuoteStepType.Financing);
    }

    private scrollToSelected(): void {
        const selectedElement = this.financingElements.find((el: ElementRef) =>
            el.nativeElement.classList.contains('financing--card--body_selected')
        );

        if (selectedElement) {
            selectedElement.nativeElement.scrollIntoView();
        }
    }

    private fillFinancingOptions(financingOptions: FinancingOption[]): void {
        financingOptions.forEach((option: FinancingOption) => {
            if (option.quote_id) {
                this.approvedOffers.push(option);
            } else {
                this.financingOptions.push(option);
            }
        });
    }
}
