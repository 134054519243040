export const PERMISSIONS = {
    ADDERS: {
        SELECT_LINE: 'adders.select_line',
        CREATE_LINE: 'adders.create_line',
        SELECT_PROJECT: 'adders.select_project',
        CREATE_PROJECT: 'adders.create_project'
    },
    APPOINTMENTS: {
        CHANGE_ORDER: 'appointments.change_order',
        CREATE: 'appointments.create'
    },
    CUSTOMERS: {
        LIST: 'customers.view'
    },
    PROMO_CARDS: {
        APP_VIEW: 'promo_cards.app_view',
        ISSUE: 'promo_cards.issue',
        APP_CREATE: 'promo_cards.app_create'
    },
    PROMOTIONS: {
        CREATE_CUSTOM: 'promotions.create_custom'
    },
    SECOND_MEASURE: {
        VIEW_PRICE: 'second_measure.view_price'
    }
};
