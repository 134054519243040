import { Injectable } from '@angular/core';

import every from 'lodash/every';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import some from 'lodash/some';

import { AuthService } from '@core/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    constructor(private authService: AuthService) {}

    hasPermissions(permissions: string | string[], requireAll = false): boolean {
        if (isArray(permissions)) {
            if (requireAll) {
                return every(permissions, (permission) => this.hasPermission(permission));
            } else {
                return some(permissions, (permission) => this.hasPermission(permission));
            }
        }

        const permissionPath = permissions as string;

        return this.hasPermission(permissionPath);
    }

    private hasPermission(permissionPath: string): boolean {
        const [featureName, featureAction] = permissionPath.split('.');
        const userPermissions = this.authService.getUser().role.role_permissions;
        const permissions = get(userPermissions, featureName, []);

        return permissions.includes(featureAction);
    }
}
