import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AppointmentPullQuoteStatusSubscription,
    ApprovalRequestSubscription,
    AutoConfiguratorSubscription,
    MeasurementSystemAuthDataReceivedSubscription,
    MyFinancingOptionsUpdatedSubscription,
    QuotesUpdatePkErrorSubscription,
    QuotesUpdatePkProgressSubscription,
    QuotesUpdatePkResultSubscription,
    TransactionStatusSubscription
} from '@core/services/websocket/subscriptions';
import { AutoConfiguratorEvent } from '@shared/enums/auto-configurator-event';
import { MeasurementSystemType } from '@shared/enums/measurement-system-type';
import { QuoteUpdatePkResult } from '@shared/interfaces/quote-update-pk-result';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsService {
    constructor(private apollo: Apollo) {}

    financingOptionSubscription(appointmentId: number, quoteId: number): Observable<any> {
        return this.apollo
            .subscribe({
                query: MyFinancingOptionsUpdatedSubscription,
                variables: {
                    appointmentId,
                    quoteId
                }
            })
            .pipe(map((res: any) => res.data.myFinancingOptionUpdated));
    }

    requestApprovalSubscription(id: string): Observable<any> {
        return this.apollo
            .subscribe({
                query: ApprovalRequestSubscription,
                variables: {
                    id
                }
            })
            .pipe(map((res: any) => res.data.approvalRequestUpdated));
    }

    transactionStatusSubscription(quoteId: string): Observable<string> {
        return this.apollo
            .subscribe({
                query: TransactionStatusSubscription,
                variables: {
                    quoteId
                }
            })
            .pipe(map((res: any) => res.data.transactionStatusUpdated));
    }

    appointmentPullQuoteStatusSubscription(appointmentId: string): Observable<number> {
        return this.apollo
            .subscribe({
                query: AppointmentPullQuoteStatusSubscription,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: any) => res.data.pullQuotesProgress));
    }

    quotesUpdatePkProgressSubscription(appointmentId: string): Observable<number> {
        return this.apollo
            .subscribe({
                query: QuotesUpdatePkProgressSubscription,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: any) => res.data.quotesUpdatePkProgress));
    }

    quotesUpdatePkResultSubscription(appointmentId: string): Observable<QuoteUpdatePkResult[]> {
        return this.apollo
            .subscribe({
                query: QuotesUpdatePkResultSubscription,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: any) => res.data.quotesUpdatePkResult));
    }

    quotesUpdatePkErrorSubscription(appointmentId: string): Observable<string> {
        return this.apollo
            .subscribe({
                query: QuotesUpdatePkErrorSubscription,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: any) => res.data.quotesUpdatePkError));
    }

    autoconfigureEventSubscription(appointmentId: number): Observable<AutoConfiguratorEvent> {
        return this.apollo
            .subscribe({
                query: AutoConfiguratorSubscription,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((res: any) => res.data.autoConfiguratorEvent));
    }

    measurementSystemAuthDataReceivedSubscription(type: MeasurementSystemType, state: string): Observable<any> {
        return this.apollo
            .subscribe({
                query: MeasurementSystemAuthDataReceivedSubscription,
                variables: {
                    type,
                    state
                }
            })
            .pipe(map((res: any) => res.data.measurementSystemAuthDataReceived));
    }
}
