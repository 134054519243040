<div class="account-info" [formGroup]="form">
    <ion-label class="section-label mb-30">Contact Information</ion-label>

    <ion-item class="input-item" lines="none">
        <ion-label slot="start" position="fixed">Email</ion-label>
        <ion-input name="email"
                   placeholder="Email"
                   formControlName="notification_email_address"></ion-input>
        <div class="errors-wrapper" *ngIf="form.get('notification_email_address').touched">
            <ion-text color="danger" class="error"
                      *ngFor="let error of getMessages(form.get('notification_email_address').errors)">
                {{ error }}
            </ion-text>
        </div>
    </ion-item>

    <ion-item class="input-item" lines="none">
        <ion-label slot="start" position="fixed">Phone</ion-label>
        <ion-input name="phone_number"
                   inputmode="tel"
                   placeholder="Phone number"
                   formControlName="billing_phone"></ion-input>
        <div class="errors-wrapper" *ngIf="form.get('billing_phone').touched">
            <ion-text color="danger" class="error"
                      *ngFor="let error of getMessages(form.get('billing_phone').errors)">
                {{ error }}
            </ion-text>
        </div>
    </ion-item>

    <ion-label class="section-label mb-30">Billing Information</ion-label>

    <ion-item class="input-item" lines="none">
        <ion-label slot="start" position="fixed">Account Holder Name</ion-label>
        <ion-input name="account_holder_name"
                   placeholder="Account Holder Name"
                   formControlName="account_holder_name"></ion-input>
        <div class="errors-wrapper" *ngIf="form.get('account_holder_name').touched">
            <ion-text color="danger" class="error"
                      *ngFor="let error of getMessages(form.get('account_holder_name').errors)">
                {{ error }}
            </ion-text>
        </div>
    </ion-item>

    <ion-item class="input-item" lines="none">
        <ion-label slot="start" position="fixed">Address</ion-label>
        <ion-input name="address1"
                   placeholder="Billing Street"
                   formControlName="billing_street"></ion-input>
        <div class="errors-wrapper" *ngIf="form.get('billing_street').touched">
            <ion-text color="danger" class="error"
                      *ngFor="let error of getMessages(form.get('billing_street').errors)">
                {{ error }}
            </ion-text>
        </div>
    </ion-item>

    <ion-item class="input-item" lines="none">
        <ion-label slot="start" position="fixed">City</ion-label>
        <ion-input name="city"
                   placeholder="City"
                   formControlName="billing_city"></ion-input>
        <div class="errors-wrapper" *ngIf="form.get('billing_city').touched">
            <ion-text color="danger" class="error"
                      *ngFor="let error of getMessages(form.get('billing_city').errors)">
                {{ error }}
            </ion-text>
        </div>
    </ion-item>

    <div class="account-info-row">
        <ion-item class="input-item" lines="none">
            <ion-label slot="start" position="fixed">State</ion-label>
            <ion-input name="state"
                       placeholder="State"
                       formControlName="billing_state"></ion-input>
            <div class="errors-wrapper" *ngIf="form.get('billing_state').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('billing_state').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </ion-item>

        <ion-item class="input-item" lines="none">
            <ion-label slot="start" position="fixed">Postal Code</ion-label>
            <ion-input name="postal_code"
                       inputmode="tel"
                       placeholder="Postal code"
                       formControlName="billing_zip"></ion-input>
            <div class="errors-wrapper" *ngIf="form.get('billing_zip').touched">
                <ion-text color="danger" class="error"
                          *ngFor="let error of getMessages(form.get('billing_zip').errors)">
                    {{ error }}
                </ion-text>
            </div>
        </ion-item>
    </div>

    <ion-button color="primary"
                [disabled]="!form.valid"
                (click)="confirm()">
        Confirm
    </ion-button>
</div>
