import { Directive, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';

import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { ERROR_MESSAGES } from '@shared/constants/error-messages';

@Directive()
export abstract class AbstractForm implements OnDestroy {
    errorMessages = ERROR_MESSAGES;
    isFormSubmitted = false;
    destroy$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getMessages(errors): string[] {
        const messages = [];

        forEach(errors, (hasError, key) => {
            if (hasError) {
                let message = get(this.errorMessages, key);

                if (!message) {
                    return;
                }

                if (isObject(hasError)) {
                    forEach(hasError, (value, errorProperty) => {
                        const replacement = isArray(value) ? value.join(' or ') : value;

                        message = message.replace(`{${errorProperty}}`, replacement);
                    });
                }
                messages.push(message);
            }
        });

        return messages;
    }

    markAllFieldsAsUntouched(form: UntypedFormGroup): void {
        forEach(form.controls, (control: UntypedFormControl) => control.markAsPristine());
    }

    disableFormControls(form: UntypedFormGroup, except?: string[]): void {
        forEach(Object.keys(form.controls), (controlName: string) => {
            if (!except || !except.includes(controlName)) {
                form.get(controlName).disable({ emitEvent: false });
            }
        });
    }

    resetFormControl(
        form: UntypedFormGroup,
        control: string,
        subControlIndex?: number,
        resetFields?: string[],
        exceptFields?: string[]
    ): void {
        /* eslint-disable @typescript-eslint/no-shadow */
        forEach(Object.keys(form.controls), (controlName: string) => {
            if (controlName === control) {
                if (subControlIndex === undefined) {
                    form.get(controlName).reset();
                } else {
                    const subControl = form.get(controlName) as UntypedFormArray;
                    const subForm = subControl.controls[subControlIndex] as UntypedFormGroup;

                    forEach(Object.keys(subForm.controls), (controlName: string) => {
                        if (
                            (resetFields.length === 0 && !exceptFields.includes(controlName)) ||
                            resetFields.includes(controlName)
                        ) {
                            subForm.get(controlName).reset();
                        }
                    });
                }
            }
        });
    }

    setSubmitted(isSubmitted: boolean, form: any): void {
        this.isFormSubmitted = isSubmitted;
        if (isSubmitted) {
            form.markAllAsTouched();
        }
    }
}
