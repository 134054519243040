import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ScriptService } from '@core/services/script.service';

import { environment } from '../../../environments/environment';
import {
    PAYSIMPLE_LABELS,
    PAYSIMPLE_STYLES
} from '../../main/appointments/final-steps/components/payment/payment.constants';

declare let paysimpleJs: any;

@Injectable({
    providedIn: 'root'
})
export class PaysimpleService {
    private isScriptLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isScriptLoaded$: Observable<boolean> = this.isScriptLoadedSubject.asObservable();
    readonly isOffline = window.localStorage.getItem('offline_mode') === '1';

    constructor(private scriptService: ScriptService) {}

    getInstance(token: string, container: any): Observable<any> {
        return this.isScriptLoaded$.pipe(
            filter((value: boolean) => value),
            take(1),
            map(() =>
                paysimpleJs({
                    // the element that will contain the iframe
                    container,
                    // checkout_token is in auth
                    auth: {
                        token
                    },
                    bypassPostalCodeValidation: false,
                    preventAutocomplete: false,
                    styles: PAYSIMPLE_STYLES,
                    text: PAYSIMPLE_LABELS
                })
            )
        );
    }

    init(): void {
        if (this.isOffline) {
            return;
        }

        this.load(
            () => {
                this.isScriptLoadedSubject.next(true);
            },
            () => {
                this.isScriptLoadedSubject.next(false);
            }
        );
    }

    private load(successLoadCallback: (ev?: Event) => any, errorLoadCallback: (ev?: Event) => any): void {
        if (typeof paysimpleJs === 'function') {
            successLoadCallback();
        } else {
            const src = `https://${
                environment.production ? '' : 'sandbox-'
            }api.paysimple.com/paysimplejs/v1/scripts/client.js`;

            this.scriptService.loadJsScript(src, successLoadCallback, errorLoadCallback);
        }
    }
}
