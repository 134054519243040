<div class="modal-header">
  <ion-text class="title">Payment Declined - Code {{error?.FailureData?.Code}} ({{error?.FailureData?.Description}})</ion-text>
</div>
<div class="modal-body">
  <ion-text>
    The payment method entered has been declined.
    Please do one of the following:
    <br/>
    <br/>
    1. {{error?.FailureData?.MerchantActionText}}
    <br/>
    <br/>
    2. Homeowner can call issuing financial institution
    (the number is typically printed on the back of the card)
    to resolve any issues that are preventing the transaction
    from being approved.
  </ion-text>
</div>
<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()">Try Again</ion-button>
  <ion-button class="classic-width" (click)="dismiss('resulting')">Result</ion-button>
</div>
