<div class="modal-header">
  <ion-text class="title">Copying Package Configurations - {{copyingPackage.name}}</ion-text>
</div>
<div class="modal-body">
  <div class="help-text">
    Select Packages to which you want to copy the current Package
  </div>

  <ion-list>
    <ion-radio-group>
      <div class="radio-item ion-text-left" (ionChange)="checkHandler($event)" *ngFor="let pkg of copyToList">
        <ion-checkbox mode="ios" [(ngModel)]="pkg.isChecked"></ion-checkbox>
        <ion-label>{{ pkg.name }}</ion-label>
      </div>
    </ion-radio-group>
  </ion-list>
</div>
<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>

  <ion-button class="classic-width" (click)="copy()" [disabled]="!canDoCOpy">Copy</ion-button>
</div>
