<div class="tabs" #tabsElement="tqMHorizontalScroll" tqMHorizontalScroll (overflowed)="isShowNavButtons = $event">
  <div #tabElement
       [ngClass]="{'text-ellipsis' : useEllipsis && activeTabId != tab.hash}"
       class="tab-item"
       [class.active]="activeTabId == tab.hash"
       [class.disabled]="tab.disabled"
       [hidden]="tab.hidden"
       *ngFor="let tab of tabs; let i = index"
       (click)="emitSelectedTab(tab, i)">
    <ion-icon name="warning" *ngIf="completionTabs[tab.hash] === false"></ion-icon>
    <ion-icon name="checkmark-circle" class="completed" *ngIf="completionTabs[tab.hash] === true"></ion-icon>
    <span>{{ tab.label }}</span>
    <span *ngIf="tab.counter" class="counter">{{ tab.counter }}</span>
  </div>
</div>

<ng-container *ngIf="enableNavigationButton && isShowNavButtons">
  <ion-button class="icon-only nav-btn"
              size="small"
              [disabled]="!tabsElement.canScrollStart"
              (click)="tabsElement.scrollLeft()"
              expand="full">
    <ion-icon name="chevron-back"></ion-icon>
  </ion-button>

  <ion-button class="icon-only nav-btn"
              size="small"
              [disabled]="!tabsElement.canScrollEnd"
              (click)="tabsElement.scrollRight()"
              expand="full">
    <ion-icon name="chevron-forward"></ion-icon>
  </ion-button>
</ng-container>
