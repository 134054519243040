import { Component, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import { ProductSelectionService } from '../../services/product-selection.service';

@Component({
    selector: 'vendo-question-popover',
    templateUrl: './question-popover.component.html',
    styleUrls: ['./question-popover.component.scss']
})
export class QuestionPopoverComponent {
    @Input() question: any;
    @Input() imgBaseUrl: string;

    constructor(
        private popoverController: PopoverController,
        private productSelectionService: ProductSelectionService
    ) {}

    async dismissClick(): Promise<void> {
        await this.popoverController.dismiss();
    }

    answerModified(): void {
        this.productSelectionService.emitAnswerSelection();
    }
}
