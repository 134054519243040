import { gql } from 'apollo-angular';

const ALLOW_DISPLAY_LIST_PRICE_STATUS = gql`
    query {
        allowDisplayListPriceStatus
    }
`;

const ALLOW_DISPLAY_PRICE_MODIFIERS_STATUS = gql`
    query {
        allowDisplayPriceModifiersStatus
    }
`;

export { ALLOW_DISPLAY_LIST_PRICE_STATUS, ALLOW_DISPLAY_PRICE_MODIFIERS_STATUS };
