import { Component, Input, OnInit } from '@angular/core';

import { AlertController, ModalController } from '@ionic/angular';

import { QuoteService } from '@core/services/quote.service';
import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-copy-package-modal',
    templateUrl: './copy-package-modal.component.html',
    styleUrls: ['./copy-package-modal.component.scss']
})
export class CopyPackageModalComponent extends BaseModal implements OnInit {
    @Input() packages: any;
    @Input() copyingPackage: any;

    copyToList = [];
    canDoCOpy = false;

    constructor(
        modalCtrl: ModalController,
        private quoteService: QuoteService,
        public alertController: AlertController
    ) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.packages.forEach((pkg) => {
            if (pkg.quoteId !== this.copyingPackage.quoteId) {
                this.copyToList.push({
                    quoteId: pkg.quoteId,
                    name: pkg.name,
                    isChecked: false
                });
            }
        });
    }

    checkHandler($event): void {
        if (this.canDoCOpy === $event.detail.checked) {
            return;
        }

        if (!this.canDoCOpy && $event.detail.checked) {
            this.canDoCOpy = true;

            return;
        }

        if (!$event.detail.checked) {
            let someIsChecked = false;

            this.copyToList.forEach((item) => {
                if (item.isChecked && !someIsChecked) {
                    someIsChecked = true;
                }
            });

            this.canDoCOpy = someIsChecked;
        }
    }

    copy(): void {
        const copyToQuoteIds = [];

        this.copyToList.forEach((item) => {
            if (!item.isChecked) {
                return;
            }

            copyToQuoteIds.push(item.quoteId);
        });

        this.quoteService
            .copyQuotePackage(this.copyingPackage.quoteId, copyToQuoteIds)
            .subscribe(() => this.dismiss(true));
    }
}
