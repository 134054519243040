import { gql } from 'apollo-angular';

const GET_APPOINTMENTS_LIST = gql`
    query AppointmentsList($date: String) {
        appointments(date: $date) {
            id
            schedule_time
            status
            type
            lock_type
            notes
            products_interests
            result_type
            family_name
            pulled_quotes_type
            is_archived
            has_quotes_to_pull
            customer {
                first_name
                last_name
                phone_number
                email
            }
            second_customer {
                email
            }
            house {
                address1
                address2
                city
                country
                state
                postal_code
                additional_info
                properties
            }
            crm_results
        }
    }
`;

const GET_APPOINTMENTS_SEARCH = gql`
    query AppointmentsSearch(
        $fromDate: String
        $toDate: String
        $status: MyAppointmentStatusEnum
        $search: String
        $offset: Int
        $limit: Int
        $orderBy: String
        $orderDirection: OrderDirectionEnum
    ) {
        myAppointments(
            from_date: $fromDate
            to_date: $toDate
            status: $status
            search: $search
            offset: $offset
            limit: $limit
            order_by: $orderBy
            order_direction: $orderDirection
        ) {
            entities {
                id
                schedule_time
                status
                type
                lock_type
                notes
                products_interests
                result_type
                resulted_at
                family_name
                reopens_count
                pulled_quotes_type
                is_archived
                has_quotes_to_pull
                customer {
                    first_name
                    last_name
                    phone_number
                    email
                }
                second_customer {
                    email
                }
                house {
                    address1
                    address2
                    city
                    country
                    state
                    postal_code
                    additional_info
                    properties
                }
                crm_results
            }
            totalCount
        }
    }
`;
const GET_APPOINTMENT = gql`
    query Appointment($id: ID!) {
        appointment(id: $id) {
            id
            schedule_time
            scheduled_to
            status
            type
            activities {
                hash
                status
            }
            quotes {
                id
                status
                quote_documents {
                    id
                }
            }
            family_name
            customer {
                id
                first_name
                last_name
                email
                phone_number
                additional_info
                appointments_count
                conflict_through_crm
            }
            house {
                additional_info
            }
            second_customer {
                id
                first_name
                last_name
                email
                phone_number
                additional_info
            }
            house {
                id
                address1
                address2
                city
                state
                postal_code
                country
                appointments_count
                conflict_through_crm
            }
            second_house {
                id
                address1
                address2
                city
                state
                postal_code
                country
                appointments_count
                conflict_through_crm
            }
            notes
            products_interests
            lock_type
            pulled_quotes_type
            is_archived
            has_quotes_to_pull
            quote_types
            signers
            is_multi_omni
            sent_emails
            deeplinks {
                is_show_confirmation
                is_paste_data_to_device
                items {
                    name
                    hash
                    url
                }
            }
        }
    }
`;
const GET_APPOINTMENT_CUSTOM_TAX = gql`
    query AppointmentCustomTax($id: ID!) {
        appointment(id: $id) {
            custom_tax
            actual_tax
            accessibility {
                change_tax
                edit
            }
        }
    }
`;

const GET_APPOINTMENT_REVIEW = gql`
    query Appointment($id: ID!) {
        appointmentReview(id: $id)
    }
`;
const GET_APPOINTMENT_REMAINING_BALANCE = gql`
    query AppointmentRemainingBalance($id: ID!) {
        myAppointmentRemainingBalance(id: $id)
    }
`;
const GET_APPOINTMENT_RESULTS = gql`
    query AppointmentResults($appointmentId: ID) {
        appointmentResults(appointment_id: $appointmentId)
    }
`;
const GET_APPOINTMENT_DOCUMENT = gql`
    query GetAppointmentDocument($appointmentId: ID!, $documentType: String!) {
        myAppointmentDocument(appointment_id: $appointmentId, document_type: $documentType) {
            name
            pdf_url
        }
    }
`;

const GET_APPOINTMENT_MAIL = gql`
    query GetAppointmentMail(
        $appointmentId: ID!
        $mailType: MailTypeEnum!
        $isSkipPartOfData: Boolean!
        $customerId: Int
    ) {
        appointmentMail(id: $appointmentId, mail_type: $mailType, customer_id: $customerId) {
            body @skip(if: $isSkipPartOfData)
            is_readonly @skip(if: $isSkipPartOfData)
            is_sendable
        }
    }
`;

const GET_APPOINTMENT_MAIL_CONFIGURATION = gql`
    query GetAppointmentMailConfiguration($appointmentId: ID!, $mailType: MailTypeEnum!, $customerId: Int) {
        appointmentMail(id: $appointmentId, mail_type: $mailType, customer_id: $customerId) {
            body
            is_readonly
        }
        appointmentGoodThroughInDays(id: $appointmentId)
    }
`;

const GET_APPOINTMENT_PRODUCTS_INTERESTS = gql`
    query appointmentProductsInterests {
        appointmentProductsInterests
    }
`;

const GET_PROJECT_DESCRIPTION = gql`
    query GetProjectDescription($appointmentId: ID!) {
        projectDescription(id: $appointmentId)
    }
`;

const GET_PHOTOS_GLOSSERY = gql`
    query GetPhotosGlossary($appointmentId: ID!) {
        photosGlossary(id: $appointmentId) {
            per_page
            photos {
                url
                name
                selected
                position
                type
                opening_name
                appointment_type
            }
        }
    }
`;

const GET_PULL_QUOTES_APPOINTMENTS = gql`
    query PullQuotesAppointments($appointmentId: ID!, $appointmentType: AppointmentTypesEnum!) {
        pullQuotesAppointments(appointment_id: $appointmentId, appointment_type: $appointmentType) {
            type
            schedule_time
            seller_name
            quotes {
                id
                package_name
                quote_number
            }
        }
    }
`;

const GET_PULL_QUOTES_MIGRATIONS = gql`
    query PullQuotesMigrations($appointmentId: ID!) {
        pullQuotesMigrations(appointment_id: $appointmentId) {
            quote_number
            migrations {
                package_name
                schedule_time
                seller_name
            }
        }
    }
`;

const GET_APPOINTMENT_FOR_EXTERNAL_RESOURCE = gql`
    query AppointmentForExternalResource($id: ID!) {
        appointmentForExternalResource(id: $id)
    }
`;

const GET_APPOINTMENT_IMAGES = gql`
    query AppointmentImages(
        $appointment_id: Int!
        $opening_id: Int
        $types: [AppointmentImageTypesEnum!]
        $appointment_type: AppointmentTypesEnum
    ) {
        appointmentImages(
            appointment_id: $appointment_id
            opening_id: $opening_id
            types: $types
            appointment_type: $appointment_type
        ) {
            appointment_id
            opening_id
            type
            name
            note
            url
            position
            appointment_type
        }
    }
`;

const GET_APPOINTMENT_REQUIRED_IMAGES = gql`
    query AppointmentRequiredImages($appointment_id: ID!, $opening_id: ID, $check_only_configured_openings: Boolean) {
        appointmentRequiredImages(
            appointment_id: $appointment_id
            opening_id: $opening_id
            check_only_configured_openings: $check_only_configured_openings
        ) {
            is_required_images_uploaded
            opening_required_images {
                opening_id
                addedCount
                requireCount
            }
        }
    }
`;

const GET_APPOINTMENT_ORDER_REQUIREMENTS = gql`
    query AppointmentOrderRequirements($id: ID!, $isSkipFailedConfigurations: Boolean!) {
        appointmentOrderRequirements(id: $id) {
            failed_opening_configurations @skip(if: $isSkipFailedConfigurations)
            missed_assessment_answers
            missed_opening_images(configured: true)
        }
    }
`;

export {
    GET_APPOINTMENTS_SEARCH,
    GET_APPOINTMENTS_LIST,
    GET_APPOINTMENT,
    GET_APPOINTMENT_RESULTS,
    GET_APPOINTMENT_REVIEW,
    GET_APPOINTMENT_REMAINING_BALANCE,
    GET_APPOINTMENT_DOCUMENT,
    GET_APPOINTMENT_CUSTOM_TAX,
    GET_APPOINTMENT_MAIL_CONFIGURATION,
    GET_APPOINTMENT_PRODUCTS_INTERESTS,
    GET_PROJECT_DESCRIPTION,
    GET_PHOTOS_GLOSSERY,
    GET_PULL_QUOTES_APPOINTMENTS,
    GET_PULL_QUOTES_MIGRATIONS,
    GET_APPOINTMENT_MAIL,
    GET_APPOINTMENT_FOR_EXTERNAL_RESOURCE,
    GET_APPOINTMENT_IMAGES,
    GET_APPOINTMENT_REQUIRED_IMAGES,
    GET_APPOINTMENT_ORDER_REQUIREMENTS
};
