<div class="modal-header">
    <ion-text class="title">{{ measurementSystem.hash | replace: '_':'' | titlecase }} Integration</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <div class="logged-as" *ngIf="userData?.used_remember || userData?.remember || userDetails">
        <div class="name">Account: {{ userData.username || userDetails.email }}</div>
        <div *ngIf="measurementSystem.hash === measurementSystemTypes.EagleView" class="logout">
            <span (click)="useOtherAccount()">Logout</span>
        </div>
    </div>

    <form *ngIf="activeTabId" [formGroup]="form">
        <vendo-tabs [activeTabId]="activeTabId"
                    [tabs]="tabs"
                    (tabSelected)="selectTab($event)"></vendo-tabs>

        <ng-container *ngIf="activeTabId === tabs[1].hash; else orderTemplate">
            <ion-text>Search for the report by street address or report id</ion-text>

            <ion-searchbar placeholder="Search"
                           mode="ios"
                           formControlName="search"></ion-searchbar>

            <ion-content class="report-list"
                         [class.with-logged-as]="userData?.used_remember || userData?.remember || userDetails">
                <ion-radio-group formControlName="report">
                    <ion-list>
                        <ion-item *ngFor="let report of reports" lines="none">
                            <ion-label>
                                {{ report.address_details.address1 }}{{ report.address_details.address2 ? ', ' + report.address_details.address2 : '' }},
                                {{ report.address_details.city }}, {{ report.address_details.state }} {{ report.address_details.postal_code }} - {{ report.report_id }}
                            </ion-label>
                            <ion-radio slot="start" mode="md" [value]="report"></ion-radio>
                        </ion-item>
                    </ion-list>

                    <ion-infinite-scroll threshold="50px" (ionInfinite)="loadNextPage($event)">
                        <ion-infinite-scroll-content></ion-infinite-scroll-content>
                    </ion-infinite-scroll>
                </ion-radio-group>
            </ion-content>
        </ng-container>

        <ng-template #orderTemplate>
            <ion-content class="order-fields">
                <div *ngIf="measurementSystem.hash === measurementSystemTypes.Hover"
                     class="sticked-fields"
                     formGroupName="owner_details">
                    <div class="field-block">
                        <ion-label class="required">First Name</ion-label>
                        <ion-input type="text"
                                   formControlName="first_name"
                                   placeholder="First Name"
                                   required
                                   autocomplete="no"></ion-input>
                        <div class="errors-wrapper" *ngIf="form.get(['owner_details', 'first_name']).touched">
                            <ion-text color="danger"
                                      class="error"
                                      *ngFor="let error of getMessages(form.get(['owner_details', 'first_name']).errors)">
                                {{ error }}
                            </ion-text>
                        </div>
                    </div>

                    <div class="field-block">
                        <ion-label class="required">Last Name</ion-label>
                        <ion-input type="text"
                                   formControlName="last_name"
                                   placeholder="Last Name"
                                   required
                                   autocomplete="no"></ion-input>
                        <div class="errors-wrapper" *ngIf="form.get(['owner_details', 'last_name']).touched">
                            <ion-text color="danger"
                                      class="error"
                                      *ngFor="let error of getMessages(form.get(['owner_details', 'last_name']).errors)">
                                {{ error }}
                            </ion-text>
                        </div>
                    </div>
                </div>

                <ng-container formGroupName="address_details">
                    <div class="sticked-fields">
                        <div class="field-block">
                            <ion-label class="required">Address1</ion-label>
                            <ion-input type="text"
                                       formControlName="address1"
                                       placeholder="Address1"
                                       required
                                       autocomplete="no"></ion-input>
                            <div class="errors-wrapper" *ngIf="form.get(['address_details', 'address1']).touched">
                                <ion-text color="danger"
                                          class="error"
                                          *ngFor="let error of getMessages(form.get(['address_details', 'address1']).errors)">
                                    {{ error }}
                                </ion-text>
                            </div>
                        </div>

                        <div class="field-block">
                            <ion-label class="required">City</ion-label>
                            <ion-input type="text"
                                       formControlName="city"
                                       placeholder="City"
                                       required
                                       autocomplete="no"></ion-input>
                            <div class="errors-wrapper" *ngIf="form.get(['address_details', 'city']).touched">
                                <ion-text color="danger"
                                          class="error"
                                          *ngFor="let error of getMessages(form.get(['address_details', 'city']).errors)">
                                    {{ error }}
                                </ion-text>
                            </div>
                        </div>
                    </div>

                    <div class="sticked-fields">
                        <div class="field-block">
                            <ion-label class="required">State</ion-label>
                            <ion-input type="text"
                                       formControlName="state"
                                       placeholder="State"
                                       required
                                       autocomplete="no"></ion-input>
                            <div class="errors-wrapper small" *ngIf="form.get(['address_details', 'state']).touched">
                                <ion-text color="danger"
                                          class="error"
                                          *ngFor="let error of getMessages(form.get(['address_details', 'state']).errors)">
                                    {{ error }}
                                </ion-text>
                            </div>
                        </div>

                        <div class="field-block">
                            <ion-label class="required">Postal Code</ion-label>
                            <ion-input type="text"
                                       inputmode="tel"
                                       formControlName="postal_code"
                                       placeholder="Postal Code"
                                       required
                                       autocomplete="no"></ion-input>
                            <div class="errors-wrapper small" *ngIf="form.get(['address_details', 'postal_code']).touched">
                                <ion-text color="danger"
                                          class="error"
                                          *ngFor="let error of getMessages(form.get(['address_details', 'postal_code']).errors)">
                                    {{ error }}
                                </ion-text>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="measurementSystem.hash === measurementSystemTypes.Hover" class="px-5">
                    <ion-label class="required">Email request to:</ion-label>
                    <ion-list>
                        <ion-radio-group class="email-list" formControlName="email_selection">
                            <div class="radio-item" *ngFor="let email of emails">
                                <ion-radio mode="md" [value]="email"></ion-radio>
                                <ion-label>{{ email.label }}</ion-label>
                            </div>
                        </ion-radio-group>
                    </ion-list>

                    <div class="field-block" formGroupName="owner_details">
                        <ion-input type="email"
                                   formControlName="email"
                                   placeholder="Email"
                                   required
                                   autocomplete="no"></ion-input>
                        <div class="errors-wrapper small" *ngIf="ownerDetailsFormGroup.get('email').touched">
                            <ion-text color="danger"
                                      class="error"
                                      *ngFor="let error of getMessages(ownerDetailsFormGroup.get('email').errors)">
                                {{ error }}
                            </ion-text>
                        </div>
                    </div>
                </div>
            </ion-content>
        </ng-template>
    </form>
</div>

<div class="modal-footer">
    <ion-button class="classic-width outline"
                (click)="closeModal()">
        Cancel
    </ion-button>

    <ion-button class="classic-width"
                [disabled]="form.invalid"
                (click)="proceed()">
      {{ activeTabId === tabs[1].hash ? 'Next' : 'Send Request' }}
    </ion-button>
</div>
