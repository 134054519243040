import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseModal {
    @Input() headerText = 'Confirm your action';
    @Input() message = '';
    @Input() showCancelButton = true;
    @Input() cancelButtonName = 'Cancel';
    @Input() confirmButtonName = 'Confirm';
    @Input() centerText = false;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    confirm(): void {
        this.dismiss(true);
    }
}
