import { Injectable } from '@angular/core';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { NavController } from '@ionic/angular';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AppInitializerService } from '@core/services/app-initializer.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { ScriptService } from '@core/services/script.service';
import { ToastService } from '@core/services/toast.service';

import { environment } from '../../../environments/environment';

declare let Auth0Client: any;

@Injectable({
    providedIn: 'root'
})
export class Auth0Service {
    private isScriptLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isScriptLoaded$: Observable<boolean> = this.isScriptLoadedSubject.asObservable();
    readonly isOffline = window.localStorage.getItem('offline_mode') === '1';

    constructor(
        private appInitializerService: AppInitializerService,
        private browser: InAppBrowser,
        private deviceHelperService: DeviceHelperService,
        private navController: NavController,
        private scriptService: ScriptService,
        private toastService: ToastService
    ) {}

    init(): void {
        if (this.isOffline || !this.appInitializerService.globalSettings?.auth0) {
            return;
        }

        this.load(
            () => {
                this.isScriptLoadedSubject.next(true);
            },
            () => {
                this.isScriptLoadedSubject.next(false);
            }
        );
    }

    async login(loginConfirmation: any, queryParams: string): Promise<void> {
        if (!this.appInitializerService.globalSettings?.auth0) {
            await this.toastService.showMessage('Authentication is not available. Please contact your administrator');

            return;
        }

        const auth0 = await this.getAuth0Client();

        if (this.deviceHelperService.isWeb) {
            await auth0.loginWithRedirect({
                redirect_uri: `${window.location.protocol}//${window.location.host}/redirect-callback${window.location.search}${queryParams}`,
                login_hint: loginConfirmation.login_hint,
                scope: 'read:users read:user_idp_tokens read:current_user',
                audience: `https://${environment.auth0.tenant}/api/v2/`,
                connection: loginConfirmation.connection_name
            });
        } else {
            const url = await auth0.buildAuthorizeUrl({
                redirect_uri: `${environment.siteUrl}/redirect-callback${window.location.search}${queryParams}`,
                login_hint: loginConfirmation.login_hint,
                scope: 'read:users read:user_idp_tokens read:current_user',
                audience: `https://${environment.auth0.tenant}/api/v2/`,
                connection: loginConfirmation.connection_name
            });

            this.handleNavigation(url);
        }
    }

    async logout(): Promise<void> {
        const auth0 = await this.getAuth0Client();

        if (
            (!this.deviceHelperService.isIOSPlatform && !this.deviceHelperService.isAndroidPlatform) ||
            this.deviceHelperService.isWeb
        ) {
            await auth0.logout({
                client_id: environment.auth0.client_id,
                returnTo: `${window.location.protocol}//${window.location.host}/login${window.location.search}`,
                federated: true
            });
        } else {
            const logoutUrl = await auth0.buildLogoutUrl({
                client_id: environment.auth0.client_id,
                returnTo: `${environment.siteUrl}/login${window.location.search}`,
                federated: true
            });

            this.handleNavigation(logoutUrl);
        }
    }

    private async getAuth0Client(): Promise<any> {
        await this.isScriptLoaded$
            .pipe(
                filter((value: boolean) => value),
                take(1)
            )
            .toPromise();

        return (window as any).createAuth0Client({
            domain: environment.auth0.tenant,
            client_id: environment.auth0.client_id
        });
    }

    private handleNavigation(url: string): void {
        const browser = this.browser.create(url, '_blank');

        browser.on('loadstart').subscribe((event) => {
            const vendoUrlMatched = event.url.match(
                /^(http:\/\/*\.|https:\/\/*\.|http:\/\/|https:\/\/)([a-z0-9]+)?([\-\.]?paradigmvendo.com+)/g
            );

            if (vendoUrlMatched && vendoUrlMatched.length) {
                const path = event.url.split(vendoUrlMatched[0])[1];

                browser.close();
                this.navController.navigateRoot(path);
            }
        });
    }

    private load(successLoadCallback: (ev?: Event) => any, errorLoadCallback: (ev?: Event) => any): void {
        if (typeof Auth0Client === 'function') {
            successLoadCallback();
        } else {
            const src = 'https://cdn.auth0.com/js/auth0-spa-js/1.22.2/auth0-spa-js.production.js';

            this.scriptService.loadJsScript(src, successLoadCallback, errorLoadCallback);
        }
    }
}
