import { FEATURES } from '@shared/constants/features';
import { UserPreferences } from '@shared/enums/user-preferences.enum';

export const USER_PREFERENCES_GROUPS = [
    {
        label: 'Custom Promotions',
        values: [
            {
                label: 'Apply to Line Items',
                hash: UserPreferences.CustomProductPromotion,
                id: 'apply-custom-promotion-to-line-items-toggle',
                defaultValue: false,
                isShow: true
            }
        ]
    },
    {
        label: 'Deposit',
        values: [
            {
                label: 'Show Deposit as Percent',
                hash: UserPreferences.DefaultDepositAsPercent,
                id: 'show-deposit-as-percent-toggle',
                defaultValue: false,
                isShow: true
            }
        ]
    },
    {
        label: 'Measurement Tool',
        features: [FEATURES.MEASUREMENT_TOOL, FEATURES.BOSCH_MEASUREMENT_TOOL],
        values: [
            {
                label: 'Augmented Reality',
                features: [FEATURES.MEASUREMENT_TOOL],
                defaultValue: true,
                hash: UserPreferences.AugmentRealityMeasurement,
                id: 'augment-reality-toggle',
                isShow: true
            },
            {
                label: 'Bosch Laser Measure',
                features: [FEATURES.BOSCH_MEASUREMENT_TOOL],
                defaultValue: true,
                hash: UserPreferences.BoschMeasurement,
                id: 'bosch-measure-toggle',
                isShow: true
            }
        ]
    },
    {
        label: 'Pricing',
        values: [
            {
                label: 'Show Line Item Pricing',
                hash: UserPreferences.ShowPricing,
                id: 'show-line-item-pricing-toggle',
                defaultValue: false,
                isShow: true
            },
            {
                label: 'Show Pricing Details',
                hash: UserPreferences.ShowPriceModifiers,
                id: 'show-pricing-details',
                defaultValue: false,
                isShow: true
            }
        ]
    },
    {
        label: 'Window Inspection',
        values: [
            {
                label: 'Quick Inspection',
                hash: UserPreferences.QuickTakeoff,
                id: 'quick-takeoff-toggle',
                defaultValue: false,
                isShow: true
            }
        ]
    }
];
