export const OFFLINE_APPOINTMENT_FIELDS = [
    'id',
    'seller_id',
    'office_id',
    'family_name',
    'customer',
    'house',
    'notes',
    'reopens_count',
    'result_type',
    'crm_results',
    'resulted_at',
    'schedule_time',
    'schedule_time_timestamp',
    'status',
    'lock_type',
    'quote_types',
    'scheduled_to',
    'second_customer',
    'second_house',
    'activities',
    'openings',
    'created',
    'updated',
    'deleted',
    'is_archived',
    'deeplinks',
    'type',
    'assessment',
    'products_interests'
];
