import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';

import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-signature-modal',
    templateUrl: './signature-modal.component.html',
    styleUrls: ['./signature-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureModalComponent extends BaseModal implements OnInit, AfterViewInit {
    @ViewChild('signaturePad', { static: true }) signaturePad: SignaturePadComponent;
    @ViewChild('modalBody', { static: true }) modalBody;

    @Input() signerName;
    @Input() initials;
    @Input() viewMode;
    @Input() predefinedSignature: string;

    signatureType: string;

    signaturePadOptions = {
        minWidth: 0.1,
        canvasWidth: 500,
        canvasHeight: 250,
        penColor: '#000080'
    };

    signatureExists = false;

    canvas: HTMLCanvasElement;

    constructor(modalCtrl: ModalController, private cdr: ChangeDetectorRef) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.signatureType = this.viewMode === 'initials' ? 'Initials' : 'Signature';
    }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            const newWidth = this.modalBody.nativeElement.clientWidth - 40;

            if (newWidth < 500 && newWidth > 0) {
                this.signaturePad.set('canvasWidth', this.modalBody.nativeElement.clientWidth - 40);
            }
            this.canvas = document.querySelector('canvas');
            const ctx = this.canvas.getContext('2d');

            ctx.font = "40px 'Covered By Your Grace', cursive";
            ctx.fillStyle = '#000080';
            ctx.textBaseline = 'top';

            if (this.predefinedSignature) {
                this.clear();
                this.signaturePad.fromDataURL(this.predefinedSignature);
                this.signatureExists = true;
                this.cdr.detectChanges();
            } else if (this.viewMode === 'initials') {
                this.redrawInitials();
            }
        });
    }

    redrawInitials(): void {
        this.clear();
        let fontSize = 500 / (this.initials.length / 2);

        if (fontSize > 250) {
            fontSize = 250;
        }

        const ctx = this.canvas.getContext('2d');

        ctx.font = `${fontSize}px \'Covered By Your Grace\', cursive`;
        ctx.fillText(this.initials, 15, (250 - fontSize) / 2 + 15);
        this.signatureExists = true;
        this.cdr.detectChanges();
    }

    drawingStarted(): void {
        this.signatureExists = true;
    }

    clear(): void {
        this.signatureExists = false;
        this.signaturePad.clear();
    }

    done(): void {
        const signature = this.canvas.toDataURL();

        this.dismiss(signature);
    }

    isDoneEnabled(): boolean {
        return this.signatureExists;
    }
}
