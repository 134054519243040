import { Component } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-signature-confirmation-modal',
    templateUrl: './signature-confirmation-modal.component.html',
    styleUrls: ['./signature-confirmation-modal.component.scss']
})
export class SignatureConfirmationModalComponent extends BaseModal {
    steps = [
        {
            hash: 'agreement',
            index: 0,
            next_button_name: 'Agree',
            cancel_button_name: 'No Consent',
            text: 'The parties agree that this agreement may be electronically signed. The parties agree that the electronic signatures appearing on this agreement are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility. A fully-executed digital document will be sent to and retained by all signing parties.'
        },
        {
            hash: 'opt-out',
            index: 1,
            next_button_name: 'Opt-Out',
            cancel_button_name: 'Back',
            text: 'By opting out of electronic signatures, you are agreeing to have the electronic documents printed and completed manually.'
        }
    ];

    activeStep = this.steps[0];

    constructor(modalCtrl: ModalController) {
        super(modalCtrl);
    }

    nextStep(): void {
        if (this.activeStep.hash === 'opt-out') {
            this.dismiss(false);
        }

        this.dismiss(true);
    }

    goBack(): void {
        if (this.activeStep.hash === 'agreement') {
            this.activeStep = this.steps[1];

            return;
        }

        this.activeStep = this.steps[0];
    }
}
