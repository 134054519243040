export enum AppointmentStatusForSearch {
    Won = 'won',
    Lost = 'lost',
    Pending = 'pending',
    Accepted = 'accepted',
    Declined = 'declined',
    Open = 'open',
    NoFollowUp = 'no_follow_up',
    Rescind = 'rescind',
    FollowUp = 'follow_up',
    Unresolved = 'unresolved'
}
