import { gql } from 'apollo-angular';

const GetApprovalRequests = gql`
    query approvalRequests($type: ApprovalRequestTypeEnum!, $relationIds: [ID]) {
        approvalRequests(type: $type, relation_ids: $relationIds) {
            id
            status
            relation_id
        }
    }
`;

const GetApprovalRequestInfo = gql`
    query approvalRequestInfo($hash: String!, $auth_token: String) {
        approvalRequestInfo(hash: $hash, auth_token: $auth_token) {
            request {
                type
                relation
                description
            }
            data
            errors
        }
    }
`;

export { GetApprovalRequests, GetApprovalRequestInfo };
