export const PAYSIMPLE_STYLES = {
    body: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Montserrat, "Helvetica Neue", sans-serif !important'
    },
    container: {
        width: '100vw',
        fontFamily: 'Montserrat, "Helvetica Neue", sans-serif !important'
    },
    'container.cardInfo': {
        display: 'flex !important',
        flexFlow: 'row !important',
        marginBottom: '40px'
    },
    'container.expiration': {
        flex: '1 1 50% !important'
    },
    'container.cvv': {
        flex: '1 1 50% !important',
        marginLeft: '1rem !important'
    },
    'container.postalCode': {
        flex: '1 1 50% !important',
        marginLeft: '1rem !important'
    },
    'container.routingNumber': {
        display: 'inline-block !important',
        maxWidth: 'calc(50vw - 0.5rem) !important'
    },
    'container.bankName': {
        display: 'inline-block !important',
        maxWidth: 'calc(50vw - 0.5rem) !important'
    },
    'container.accountNumber': {
        display: 'inline-block !important',
        maxWidth: 'calc(50vw - 0.5rem) !important',
        marginLeft: '1rem !important'
    },
    'container.accountType': {
        display: 'inline-block !important',
        maxWidth: 'calc(50vw - 0.5rem) !important',
        marginLeft: '1rem !important'
    },
    validation: {
        bottom: 'auto !important',
        top: '70px !important'
    }
};

export const PAYSIMPLE_LABELS = {
    labels: {
        cardNumber: 'Card Number'
    },
    placeholder: {
        cardNumber: 'Key enter card',
        postalCode: 'Zip Code'
    }
};
