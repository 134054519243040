import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import forEach from 'lodash/forEach';

import { BaseModal } from '@shared/components/base-modal';
import { PromotionAmountType } from '@shared/enums/promotion.enum';
import { Discount } from '@shared/interfaces/discount';

@Component({
    selector: 'vendo-promotions-summary-modal',
    templateUrl: './promotions-summary-modal.component.html',
    styleUrls: ['./promotions-summary-modal.component.scss']
})
export class PromotionsSummaryModalComponent extends BaseModal implements OnInit {
    @Input() discounts: Discount[] = [];
    @Input() totalPromotionLabel: string;
    @Input() totalPromotion: number;

    discountTypes: string[] = [];

    constructor(modalCtrl: ModalController) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        forEach(this.discounts, (discount: Discount) => {
            if (discount.amount_type === PromotionAmountType.EachFollowingFree) {
                this.discountTypes.push('Buy # Get #');

                return;
            }

            this.discountTypes.push(discount.is_only_summary_level ? 'Project' : 'Product');
        });
    }
}
