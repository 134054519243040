import { from, Observable } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';

export class UserPreferencesOffline {
    constructor(private offlineStorageService: OfflineStorageService, private auth: AuthService) {}

    getPreference(type: string): Observable<any> {
        const user = this.auth.getUser();
        const userPreference = this.offlineStorageService
            .findOne(`SELECT * FROM UserPreferences WHERE user_id='${user.id}'`)
            .then((res: any) => res.preferences.find((preference) => type === preference.type));

        return from(userPreference);
    }

    getPreferences(types: string[]): Observable<any> {
        const user = this.auth.getUser();
        const preferences = this.offlineStorageService
            .findOne(`SELECT * FROM UserPreferences WHERE user_id='${user.id}'`)
            .then((res: any) => res.preferences.filter((preference) => types.includes(preference.type)));

        return from(preferences);
    }
}
