import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { AbstractForm } from '@shared/helpers/abstract-form';
import { ValidateMatchPattern } from '@shared/validators/match-pattern.validator';

@Component({
    selector: 'vendo-quick-invoice-form',
    templateUrl: './quick-invoice-form.component.html',
    styleUrls: ['./quick-invoice-form.component.scss']
})
export class QuickInvoiceFormComponent extends AbstractForm implements OnInit, OnChanges {
    @Input() remainingBalance: number;
    @Input() appointment: any;

    @Output() balanceAdjusted = new EventEmitter<number>();

    form = new UntypedFormGroup({
        amount: new UntypedFormControl({ value: 0, disabled: true }),
        email: new UntypedFormControl('', [
            Validators.required,
            ValidateMatchPattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$', 'Email provided is not valid')
        ]),
        title: new UntypedFormControl('', Validators.required),
        products_and_services: new UntypedFormControl('', Validators.required)
    });
    readonly amountPattern = '^[0-9]*(\\.[0-9]{0,2})?$';
    isRemainingBalanceType = true;
    adjustedRemainingBalance: number;

    constructor(private auth: AuthService, private toastService: ToastService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.remainingBalance) {
            this.form.patchValue({
                amount: this.remainingBalance,
                email: this.appointment.customer.email,
                title: `${this.appointment.family_name} Invoice - ${this.auth.getUser().office.name}`,
                products_and_services: `${this.appointment.house.address1} Project`
            });
            this.adjustedRemainingBalance = 0;
            const amountControl = this.form.get('amount');

            amountControl.setValidators([
                Validators.required,
                Validators.min(1),
                Validators.max(this.remainingBalance),
                ValidateMatchPattern(this.amountPattern, 'The amount format is not valid')
            ]);
        }
    }

    ngOnInit(): void {
        const amountControl = this.form.get('amount');

        amountControl.valueChanges.subscribe((val) => {
            if (val || val === 0) {
                this.adjustedRemainingBalance = this.remainingBalance - val;

                this.balanceAdjusted.emit(this.adjustedRemainingBalance);
            }
        });
    }

    setToRemainingBalanceChanged(): void {
        const amountControl = this.form.get('amount');

        if (this.isRemainingBalanceType) {
            amountControl.disable();
            this.form.patchValue({
                amount: this.remainingBalance
            });
        } else {
            amountControl.enable();
        }
    }

    async getFormValues(): Promise<any> {
        this.setSubmitted(true, this.form);

        if (this.form.invalid) {
            await this.toastService.showMessage('Please fill in the required fields!');

            return;
        }

        return this.form.getRawValue();
    }
}
