<div class="image-container" #imageContainer xmlns:html="http://www.w3.org/1999/xhtml">
    <div class="svg-wrap">
        <svg width="100%" height="100%" [attr.viewBox]="viewBox" *ngIf="viewBox"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <filter id="dropshadow" height="130%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                    <feOffset dx="2" dy="2" result="offsetblur"/>
                    <feComponentTransfer>
                        <feFuncA type="linear" slope="0.5"/>
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <image #image
                   [attr.xlink:href]="imgUrl"
                   x="0"
                   y="0"
                   height="100%"
                   width="100%"
                   [class.transparent]="hideBackground"
                   (click)="startDrawing($event)"/>
            <ng-container *ngFor="let segment of segments">
                <g *ngFor="let line of segment.lines"
                   [attr.stroke-width]="circleStrokeWidth"
                   [style]="hiddenItems[segment.id] ? 'display: none': ''">
                    <line [attr.x1]="line.from.x"
                          [attr.y1]="line.from.y"
                          [attr.x2]="line.to.x"
                          [attr.y2]="line.to.y"
                          [attr.stroke]="highlightedItem?.id === segment.id ? 'red' : line.type.color"
                          [attr.stroke-dasharray]="segment === activeSegment ? 4 : 0"
                          (click)="startDrawing($event)"
                    />
                </g>
                <g *ngFor="let polyline of segment.polylines"
                   [attr.stroke-width]="circleStrokeWidth"
                   [style]="hiddenItems[segment.id] ? 'display: none': ''"
                >
                    <polygon
                            [attr.points]="getPolylinePoints(segment)"
                            [attr.fill]="highlightedItem?.id === segment.id ? 'red' : polyline.type.color"
                            [attr.fill-opacity]="0.3"
                            [attr.stroke]="highlightedItem?.id === segment.id ? 'red' : polyline.type.color"
                            [attr.stroke-dasharray]="polyline === activePolyLine ? 4 : 0"
                            (click)="startDrawing($event)"
                    />
                </g>
                <circle *ngFor="let point of segment.points"
                        [attr.cx]="point.x + 'px'"
                        [attr.cy]="point.y + 'px'"
                        [attr.r]="radius"
                        [attr.stroke]="highlightedItem?.id === segment.id ? 'red' : point.color"
                        [attr.stroke-width]="circleStrokeWidth"
                        [style]="hiddenItems[segment.id] ? 'display: none': ''"
                        fill="#ffffff"
                        [attr.fill-opacity]="0.2"
                        (click)="pointClicked($event, point)"
                />
            </ng-container>

            <!--     LEGEND       -->
            <ng-container *ngIf="legendItems.length && !drawingInProgress">
                <rect class="legend"
                      [attr.width]="legendPosition.width"
                      [attr.height]="legendPosition.height"
                      [attr.x]="legendPosition.x"
                      [attr.y]="legendPosition.y"
                      fill="#ffffff"
                      fill-opacity="0.6"></rect>
                <g *ngFor="let item of legendItems"
                   [attr.stroke-width]="circleStrokeWidth"
                   (click)="showItemsListPopover($event, item.hash)"
                   fill="black">
                    <line [attr.x1]="item.x1"
                          [attr.y1]="item.y1"
                          [attr.x2]="item.x2"
                          [attr.y2]="item.y2"
                          [attr.stroke]="item.color"
                          [attr.stroke-width]="circleStrokeWidth"
                    />
                    <text class="legend-item cursor-pointer"
                          [attr.font-size]="fontSize + 'px'"
                          [attr.x]="item.x"
                          [attr.y]="item.y">
                        {{item.name}}
                    </text>
                </g>
            </ng-container>
            <!--    END LEGEND      -->
            <foreignObject *ngIf="drawingInProgress" [attr.width]="doneButtonPosition.width"
                           [attr.height]="doneButtonPosition.height"
                           [attr.x]="doneButtonPosition.x"
                           [attr.y]="doneButtonPosition.y">
                <html:button class="finish-button" style="width: {{doneButtonPosition.width}}px;height: {{doneButtonPosition.height}}px;"
                             (click)="doneDrawing()">
                  <i class="material-icons">done</i>
                </html:button>
            </foreignObject>
        </svg>
    </div>
</div>
<div class="tools-container">
    <ng-container *ngFor="let tool of tools">
        <div class="tool dropdown-actions"
             *ngIf="tool.types?.length"
             (click)="showDropdownPopover($event, tool)">
            <i class="material-icons" [class.selected]="selectedTool?.hash === tool.hash">{{tool.icon}}</i>
        </div>
        <div class="tool"
             *ngIf="!tool.types?.length"
             (click)="changeTool(tool, tool.type)">
            <i class="material-icons" [class.selected]="selectedTool?.hash === tool.hash">{{tool.icon}}</i>
        </div>
    </ng-container>
    <div class="tool bottom" (click)="undo()" *ngIf="actionsHistory?.length">
        <i class="material-icons">undo</i>
    </div>
</div>
