import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

import { NavController } from '@ionic/angular';

import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { ApplicationRole } from '@shared/enums/application-role.enum';

@Injectable()
export class ParadigmAuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private deviceHelperService: DeviceHelperService,
        private msalBroadcastService: MsalBroadcastService,
        private msalService: MsalService,
        private navCtrl: NavController,
        private rollbarErrorHandler: RollbarErrorHandler
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        console.log('ParadigmAuthGuard');

        return this.msalBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            switchMap(() => this.deviceHelperService.readyStorageAndPlatform()),
            switchMap(() => {
                if (this.msalService.instance.getAllAccounts().length > 0) {
                    return this.authService.getMe().pipe(
                        map((res) => {
                            if (!res) {
                                return false;
                            }

                            if (!this.authService.getToken()) {
                                this.rollbarErrorHandler.handleInfo('Logout - Token is not found in ParadigmAuthGuard');
                                this.navCtrl.navigateRoot(['logout']);

                                return false;
                            }

                            const user = this.authService.getUser();

                            if (!user || user.application_role_hash === ApplicationRole.CapturePayment) {
                                this.rollbarErrorHandler.handleInfo(
                                    'CapturePayment user do not allow to view appointments'
                                );
                                this.navCtrl.navigateRoot(['logout']);

                                return false;
                            }

                            return true;
                        })
                    );
                }

                this.navCtrl.navigateRoot(['logout']);

                return of(false);
            })
        );
    }
}
