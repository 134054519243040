import { Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { TermsAndConditionsModalComponent } from '../components/terms-and-conditions-modal/terms-and-conditions-modal.component';

@Injectable({
    providedIn: 'root'
})
export class PrivacyPolicyService {
    constructor(private modalController: ModalController) {}

    async openTermsAndConditionsModal(isNeedToConfirmUpdatedPrivacyPolicy = false): Promise<boolean> {
        const modal = await this.modalController.create({
            backdropDismiss: false,
            cssClass: 'terms-modal',
            component: TermsAndConditionsModalComponent,
            componentProps: {
                isNeedToConfirmUpdatedPrivacyPolicy
            }
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        return !!data;
    }
}
