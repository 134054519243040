export const OFFLINE_CATEGORIES_FIELDS = [
    'id',
    'category',
    'name',
    'user_id',
    'office_id',
    'opening_conditions',
    'position',
    'predefined_images'
];
