<div class="modal-header">
    <ion-text class="title">Resend email for appointment with {{ familyName }}</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-content>
        <form [formGroup]="form" class="modal-body__form" autocomplete="off">
            <div class="field-block">
                <ion-label class="required">
                    Message for the homeowner
                </ion-label>
                <ion-textarea rows="3"
                              placeholder="Write a message for the homeowner with instructions to close the deal"
                              formControlName="email_message"></ion-textarea>
            </div>

            <div class="field-block">
                <ion-label class="required">
                    Homeowner email(s)
                </ion-label>
                <tag-input placeholder="+ Email address"
                           secondaryPlaceholder="Enter email address"
                           [onAdding]="onEmailAdd.bind(this)"
                           [addOnBlur]="true"
                           [maxItems]="5"
                           [modelAsStrings]="true"
                           formControlName="emails_send_to"
                           [separatorKeyCodes]="[32, 188]">
                    <tag-input-dropdown>
                    </tag-input-dropdown>
                </tag-input>
                <ion-text class="email-hint">Separate multiple e-mail addresses using a comma or a space between them</ion-text>
            </div>
            <div class="field-block" *ngIf="sentEmailDocuments.length">
                <ion-label>Documents</ion-label>
                <div class="document" *ngFor="let document of sentEmailDocuments">
                    {{ document.fileName }} ({{ document.type | replace: '_':' ' | titlecase }}<ng-container *ngIf="document.package_name"> - {{document.package_name}}</ng-container>)
                </div>
            </div>
        </form>
    </ion-content>
</div>

<div class="modal-footer flex-end">
    <ion-button class="classic-width"
                fill="outline"
                (click)="closeModal()">
        Cancel
    </ion-button>
    <ion-button class="classic-width"
                [disabled]="form.invalid"
                (click)="sendEmail()">
        Send
    </ion-button>
</div>
