import { Injectable } from '@angular/core';

import isEqualWith from 'lodash/isEqualWith';
import isNil from 'lodash/isNil';

@Injectable({
    providedIn: 'root'
})
export class DataCompareHelperService {
    constructor() {}

    isEqual(obj1, obj2): boolean {
        return isEqualWith(obj1, obj2, (objValue, othValue) => {
            if (this.isEmpty(objValue) && this.isEmpty(othValue)) {
                return true;
            }
        });
    }

    isEmpty(value): boolean {
        return isNil(value) || value === '';
    }
}
