export const SECONDS_IN_DAY = 86400;
export const EXPIRATION_DAYS = [
    {
        label: '1 day',
        value: SECONDS_IN_DAY // seconds === 1 day
    },
    {
        label: '2 days',
        value: 2 * SECONDS_IN_DAY
    },
    {
        label: '3 days',
        value: 3 * SECONDS_IN_DAY
    },
    {
        label: '4 days',
        value: 4 * SECONDS_IN_DAY
    },
    {
        label: '5 days',
        value: 5 * SECONDS_IN_DAY
    },
    {
        label: '10 days',
        value: 10 * SECONDS_IN_DAY
    },
    {
        label: '14 days',
        value: 14 * SECONDS_IN_DAY
    },
    {
        label: '15 days',
        value: 15 * SECONDS_IN_DAY
    },
    {
        label: '30 days',
        value: 30 * SECONDS_IN_DAY
    },
    {
        label: '60 days',
        value: 60 * SECONDS_IN_DAY
    },
    {
        label: '90 days',
        value: 90 * SECONDS_IN_DAY
    }
];
export const PREDEFINED_TIMES = EXPIRATION_DAYS.map(({ value }) => value);
