import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {
    errorFound: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    emitError(message?: string): void {
        // dirty hack to fix issue with toaster not showing up in some cases
        setTimeout(() => this.errorFound.emit(message));
    }
}
