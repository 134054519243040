import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inchSign'
})
export class InchSignPipe implements PipeTransform {
    transform(value: string): string {
        if (!value || value.endsWith('"') || value.toLowerCase() === 'custom') {
            return value;
        }

        return `${value}"`;
    }
}
