<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col *ngFor="let name of names" class="ion-text-center">
        {{ name }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngFor="let item of items" class="ion-text-center">
        <ion-checkbox mode="ios" [(ngModel)]="item.checked"></ion-checkbox>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button class="classic-width" (click)="pickPackages()">
          {{ applyButtonText }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
