<div class="upload-csv" [class.invalid]="showErrors">
  <input #fileInput
         type="file"
         name="file-upload"
         class="file-input"
         [accept]="allowedTypes"
         [disabled]="isDisabled"
         (click)="clearFile()"
         (drop)="clearFile()"
         (change)="fileChanged($event)"/>

  <div class="upload-section" [class.has-file]="hasFile" [style.background-image]="backgroundImageStyle">
    <div class="file-placeholder">
      <i class="material-icons">cloud_upload</i>
      <ng-container *ngIf="!file; else fileName">
        <h3>Drag and Drop file Here</h3>
        <h3>or</h3>
        <h3 class="browse-label">Browse File</h3>
        <div class="label-info">({{ allowedTypes }})</div>
        <ng-content></ng-content>
      </ng-container>
      <ng-template #fileName>
        <h3>{{ file.name }}</h3>
      </ng-template>
    </div>
  </div>

  <div class="errors" *ngIf="showErrors">
    <span class="mat-error" *ngFor="let message of getMessages(errors)">{{ message }}</span>
  </div>
</div>
