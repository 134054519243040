import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';

import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { ThemeService } from '@core/modules/theme/services/theme.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { FeaturesService } from '@core/services/features.service';
import { IntercomService } from '@core/services/intercom.service';
import { PaysimpleService } from '@core/services/paysimple.service';
import { PendoService } from '@core/services/pendo.service';
import { FEATURES } from '@shared/constants/features';
import { IGNORED_ROUTES } from '@shared/constants/ignore-routes';
import { PaymentSystem } from '@shared/enums/payment-system';
import { DomainHelper } from '@shared/helpers/domain-helper';
import { DeeplinkInfo } from '@shared/interfaces/deeplink-info';

import { environment } from '../../../environments/environment';
import { ResetPasswordHashModel } from '../../components/auth/models/reset-password-hash-model';
import {
    AcceptUpdatedPrivacyPolicy,
    AppointmentDeeplink,
    BiometricLoginMutation,
    CheckAuthTypes,
    ForgotPassword,
    GetMe,
    LoginMutation,
    LogoutMutation,
    RefreshToken,
    ResetPasswordLogin,
    SSO_SIGN_IN
} from '../../components/auth/mutations/auth.mutations';
import { CheckResetPasswordHash } from '../../components/auth/queries/reset-password.queries';
import { PrivacyPolicyService } from '../../components/auth/services/privacy-policy.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    signedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    userInfo: any;
    token: string;
    private orgHash: string;
    private _isLoginViaDeeplink: boolean;
    get isLoginViaDeeplink(): boolean {
        return this._isLoginViaDeeplink;
    }
    set isLoginViaDeeplink(value: boolean) {
        this._isLoginViaDeeplink = value;
    }

    private isOffline = window.localStorage.getItem('offline_mode') === '1';

    constructor(
        private apollo: Apollo,
        private storage: Storage,
        private termsAndConditionsModalService: PrivacyPolicyService,
        private intercomService: IntercomService,
        private deviceHelper: DeviceHelperService,
        private rollbarErrorHandler: RollbarErrorHandler,
        private themeService: ThemeService,
        private featuresService: FeaturesService,
        private pendoService: PendoService,
        private cookieService: CookieService,
        private paysimpleService: PaysimpleService,
        private navCtrl: NavController
    ) {
        this.storage.get('orgHash').then((orgHash) => {
            this.orgHash = orgHash;
        });
        this.storage.get('user').then((userInfo) => {
            this.userInfo = userInfo;
            if (userInfo) {
                const user = this.getUser();

                this.featuresService.setFeatures(user.organization_features);
                this.themeService.init(user);
                this.pendoService.initUser(user);
                this.intercomService.setUser(user);
                this.intercomService.update();
                this.rollbarErrorHandler.addRollbarPerson(user);
            }
        });
        this.storage.get('token').then((token) => {
            this.token = token;
            if (this.token && this.deviceHelper.isWeb) {
                this.addLocalStorageEventListener();
            }
            this.signedIn.next(this.isLoggedIn());
        });
    }

    /**
     * Get token from local storage
     */
    getToken(): string {
        return this.token;
    }

    getUser(): any {
        return JSON.parse(this.userInfo);
    }

    /**
     * Login to the app
     */
    login(user, device_id: string, platform: string): Observable<any> {
        this.userInfo = null;
        this.token = null;

        return this.apollo
            .mutate({
                mutation: LoginMutation,
                variables: {
                    userName: user.userName,
                    password: user.password,
                    device_id,
                    device: platform,
                    use_touch: !!device_id,
                    org_hash: this.orgHash
                }
            })
            .pipe(
                map((res: any) => {
                    if (!res) {
                        return { errors: [{ message: 'Failed to load response data' }] };
                    }
                    if (!res.errors && !res.data.mobileSignIn.reset_password) {
                        res.data.mobileSignIn.me.role.role_permissions = this.remapPermissions(
                            res.data.mobileSignIn.me.role.role_permissions
                        );
                        this.saveUserLogin(res.data.mobileSignIn, user);
                    }

                    return res.data.mobileSignIn;
                })
            );
    }

    /**
     * SSO Login to the app
     */
    ssoSignIn(
        code,
        code_verifier,
        device_id: string,
        platform: string,
        redirect_url: string,
        org_hash: string
    ): Observable<any> {
        this.userInfo = null;
        this.token = null;

        return this.apollo
            .mutate({
                mutation: SSO_SIGN_IN,
                variables: {
                    device_id,
                    code,
                    code_verifier,
                    redirect_url,
                    device: platform,
                    org_hash
                }
            })
            .pipe(
                map(async (res: any) => {
                    if (!res) {
                        return { errors: [{ message: 'Failed to load response data' }] };
                    }

                    if (!res.errors && res.data.ssoMobileSignIn) {
                        res.data.ssoMobileSignIn.me.role.role_permissions = this.remapPermissions(
                            res.data.ssoMobileSignIn.me.role.role_permissions
                        );
                        await this.saveUserLogin(res.data.ssoMobileSignIn, {
                            userName: res.data.ssoMobileSignIn.me.email
                        });
                        this.setStorageItem('isSSOLogin', 'true');
                    }

                    return res.data.ssoMobileSignIn;
                })
            );
    }

    /**
     * Login to the app
     */
    checkEmail(userEmail: string): Observable<any> {
        this.userInfo = null;
        this.token = null;

        return this.apollo
            .mutate({
                mutation: CheckAuthTypes,
                variables: {
                    email: userEmail
                }
            })
            .pipe(map((res: any) => res.data.checkAuthTypes));
    }

    loginWithBiometric(user: any, device_id: string): Promise<any> {
        return this.getUserLogin(user.userName).then((userLoginRecord) => {
            return this.apollo
                .mutate({
                    mutation: BiometricLoginMutation,
                    variables: {
                        userName: user.userName,
                        device_id,
                        t_id_hash: userLoginRecord.t_id_hash,
                        org_hash: this.orgHash
                    }
                })
                .pipe(
                    map((res: any) => {
                        if (!res) {
                            return { errors: [{ message: 'Failed to load response data' }] };
                        }
                        if (!res.errors) {
                            res.data.biometricSignIn.me.role.role_permissions = this.remapPermissions(
                                res.data.biometricSignIn.me.role.role_permissions
                            );
                            this.saveUserLogin(res.data.biometricSignIn, user);
                        }

                        return res;
                    })
                )
                .subscribe();
        });
    }

    /**
     * Forgot password request
     */
    forgotPassword(email: string, resetPasswordUrl: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: ForgotPassword,
                variables: {
                    email,
                    reset_password_url: resetPasswordUrl,
                    org_hash: this.orgHash
                }
            })
            .pipe(
                map((res: any) => {
                    return !res.errors;
                })
            );
    }

    /**
     * Check if reset password hash is valid
     */
    checkResetPasswordHash(hash: string): Observable<ResetPasswordHashModel> {
        return this.apollo
            .query({
                query: CheckResetPasswordHash,
                variables: {
                    hash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.resetPassword as ResetPasswordHashModel));
    }

    /**
     * Change password
     */
    resetPassword(
        hash: string,
        password: string,
        compare_password: string,
        accept_terms_and_conditions: boolean
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: ResetPasswordLogin,
                variables: {
                    hash,
                    password,
                    compare_password,
                    accept_terms_and_conditions
                }
            })
            .pipe(
                map((res: any) => {
                    if (!res.errors) {
                        res.data.resetPasswordLogin.me.role.role_permissions = this.remapPermissions(
                            res.data.resetPasswordLogin.me.role.role_permissions
                        );
                        this.saveUserLogin(res.data.resetPasswordLogin);
                    }

                    return !res.errors;
                })
            );
    }

    /**
     * Logout from app
     */
    async logout(): Promise<void> {
        console.log('logout');
        if (this.isLoggedIn() && !this.isOffline) {
            await this.apollo
                .mutate({
                    mutation: LogoutMutation,
                    context: {
                        extensions: {
                            background: true
                        }
                    }
                })
                .pipe(catchError(() => of(null)))
                .toPromise();
        }

        if (this.deviceHelper.isWeb) {
            if (this.cookieService.check('isVendoMobileUser')) {
                this.cookieService.delete(
                    'isVendoMobileUser',
                    '/',
                    DomainHelper.getDomainWithoutSubdomain(environment.siteUrl)
                );
            }
            this.removeLocalStorageEventListener();
            localStorage.removeItem('token');
        }

        this.rollbarErrorHandler.removeRollbarPerson();
        this.pendoService.clearSession();
        this.storage.remove('token');
        this.storage.remove('user');
        this.storage.remove('orgHash');
        this.isLoginViaDeeplink = false;
        this.token = null;
        this.userInfo = null;
        this.signedIn.next(false);
        this.intercomService.clearUserData();
        this.featuresService.setFeatures([]);

        if (this.isOffline) {
            window.localStorage.setItem('offline_mode', '0');
            (window as any).location.reload();
        }

        return Promise.resolve();
    }

    setStorageItem(key: string, value: any): Promise<any> {
        return this.storage.set(key, value);
    }

    getStorageItem(key: string): Promise<any> {
        return this.storage.get(key);
    }

    async removeStorageItem(key: string): Promise<void> {
        await this.storage.remove(key);
    }

    getMe(): Observable<any> {
        console.log('getMe');
        if (this.isOffline) {
            // todo
            return of(null);
        }

        return this.apollo
            .query({
                query: GetMe
            })
            .pipe(
                switchMap((res: ApolloQueryResult<any>) => {
                    console.log('getMe', res);
                    if (!res?.data?.me) {
                        this.rollbarErrorHandler.handleInfo('Logout - User did not receive');
                        this.navCtrl.navigateRoot(['logout']);

                        return null;
                    }

                    res.data.me.role.role_permissions = this.remapPermissions(res.data.me.role.role_permissions);

                    return from(
                        this.saveUserLogin(res.data, {
                            userName: res.data.me.email
                        })
                    ).pipe(map(() => res.data.me));
                })
            );
    }

    refreshToken(officeId): Observable<any> {
        return this.apollo
            .mutate({
                mutation: RefreshToken,
                variables: {
                    officeId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    res.data.refreshToken.me.role.role_permissions = this.remapPermissions(
                        res.data.refreshToken.me.role.role_permissions
                    );

                    this.pendoService.clearSession();
                    this.saveUserLogin(res.data.refreshToken);

                    return res.data.refreshToken;
                })
            );
    }

    getAppointmentDeeplinkInfo(hash: string): Observable<string> {
        return this.apollo
            .mutate({
                mutation: AppointmentDeeplink,
                variables: {
                    hash
                }
            })
            .pipe(
                switchMap(async (res: ApolloQueryResult<{ appointmentDeeplink: DeeplinkInfo }>) => {
                    if (!res.errors && res.data?.appointmentDeeplink?.auth?.me) {
                        res.data.appointmentDeeplink.auth.me.role.role_permissions = this.remapPermissions(
                            res.data.appointmentDeeplink.auth.me.role.role_permissions
                        );
                        await this.saveUserLogin(res.data.appointmentDeeplink.auth, {
                            userName: res.data.appointmentDeeplink.auth.me.email
                        });
                    }

                    return res.data?.appointmentDeeplink?.appointment_id;
                })
            );
    }

    /**
     * Check if user has already been signed in
     */
    private isLoggedIn(): boolean {
        return !!this.token;
    }

    private async saveUserLogin(userResponse: any, user?: any): Promise<void> {
        let privacyPolicyAccepted: boolean;

        if (user && userResponse.me.privacy_policy_updated) {
            privacyPolicyAccepted = await this.termsAndConditionsModalService.openTermsAndConditionsModal(true);
            if (!privacyPolicyAccepted) {
                this.logout();

                return;
            }
        }

        if (user) {
            const newUser = {
                userName: user.userName,
                t_id_hash: userResponse.t_id_hash
            };

            this.storage.set('login', newUser);
        }

        this.pendoService.initUser(userResponse.me);

        this.intercomService.setUser(userResponse.me);

        this.rollbarErrorHandler.addRollbarPerson(userResponse.me);

        this.themeService.init(userResponse.me);
        this.featuresService.setFeatures(userResponse.me.organization_features);

        this.fillCustomerSettings(userResponse.me.office);
        const logo = this.featuresService.hasFeature(FEATURES.OFFICE_LOGO_PRIORITIZATION)
            ? userResponse.me.office.logo_url
            : userResponse.me.organization.logo_url;
        const userInfo = JSON.stringify(userResponse.me);

        this.userInfo = userInfo;
        if (userResponse.token) {
            this.token = userResponse.token;
        }

        await Promise.all([
            userResponse.token ? this.storage.set('token', userResponse.token) : Promise.resolve(),
            this.storage.set('user', userInfo),
            this.storage.set('logo_url', logo),
            this.storage.set('background_url', userResponse.me.organization.background_url)
        ]);

        if (this.deviceHelper.isWeb) {
            localStorage.setItem('token', userResponse.token);
            this.addLocalStorageEventListener();
        }

        if (privacyPolicyAccepted) {
            this.acceptUpdatedPrivacyPolicy().subscribe();
        }

        if (userResponse.me?.office.payment_system === PaymentSystem.Paysimple) {
            this.paysimpleService.init();
        }

        this.rollbarErrorHandler.handleInfo('Login');

        if (!this.signedIn.getValue()) {
            this.signedIn.next(true);
        }
    }

    setToken(token): void {
        this.storage.set('token', token);
        this.token = token;
        if (this.deviceHelper.isWeb) {
            localStorage.setItem('token', token);
            this.addLocalStorageEventListener();
        }
    }

    private fillCustomerSettings(office: any): void {
        if (!office.customer_settings) {
            office.customer_settings = {};
        }

        ['is_show_email', 'is_show_phone_number'].forEach((key: string) => {
            const value = office.customer_settings[key];

            if (typeof value !== 'boolean') {
                office.customer_settings[key] = true;
            }
        });
    }

    async getUserLogin(userName: string): Promise<any> {
        const login = await this.storage.get('login');

        if (login.userName === userName) {
            return login;
        }

        return {};
    }

    async getLastUsedLogin(): Promise<any> {
        const login = await this.storage.get('login');

        return login || {};
    }

    async setOrgHash(orgHash: string): Promise<void> {
        this.orgHash = orgHash;
        await this.setStorageItem('orgHash', orgHash);
    }

    getOrgHash(): string {
        return this.orgHash;
    }

    getReturnUrl(): string {
        const returnUrl = `${decodeURI(window.location.pathname)}${
            window.location.search ? decodeURI(window.location.search) : ''
        }`;

        if (returnUrl === '/' || returnUrl.includes('login') || returnUrl.includes('logout')) {
            return '';
        }

        return returnUrl;
    }

    private acceptUpdatedPrivacyPolicy(): Observable<any> {
        return this.apollo
            .mutate({
                mutation: AcceptUpdatedPrivacyPolicy
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.acceptUpdatedPrivacyPolicy));
    }

    private addLocalStorageEventListener(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private removeLocalStorageEventListener(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent): void {
        if (
            event.storageArea == localStorage &&
            event.key === 'token' &&
            !localStorage.getItem('token') &&
            !IGNORED_ROUTES.some((item) => window.location.href.includes(item))
        ) {
            this.logout();
        }
    }

    private remapPermissions(permissions: any[]): any {
        return permissions.reduce((acc, obj) => {
            acc[obj.permission_hash] = obj.permissions;

            return acc;
        }, {});
    }
}
