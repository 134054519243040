import { TitleCasePipe } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IonSlides, ModalController } from '@ionic/angular';

import { forkJoin, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import toNumber from 'lodash/toNumber';

import { AuthService } from '@core/services/auth.service';
import { PermissionsService } from '@core/services/permissions.service';
import { StepsService } from '@core/services/steps.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { UploadPhotosModalComponent } from '@shared';
import { ConfigurationInfoModalComponent } from '@shared/components/configuration-info-modal/configuration-info-modal.component';
import { MaskedImageComponent } from '@shared/components/masked-image/masked-image.component';
import { PriceModifiersModalComponent } from '@shared/components/price-modifiers-modal/price-modifiers-modal.component';
import { TakeoffInfoModalComponent } from '@shared/components/takeoff-info-modal/takeoff-info-modal.component';
import { PERMISSIONS } from '@shared/constants/permissions';
import { AppointmentImageType } from '@shared/enums/appointment-image-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { CategoryType } from '@shared/enums/category-type';
import { PhotoModalTabHash } from '@shared/enums/photo-modal-tab-hash.enum';
import { PriceConfigurationDisplayStatus } from '@shared/enums/price-configuration-display-status';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { UserPreferences } from '@shared/enums/user-preferences.enum';
import { Appointment } from '@shared/interfaces/appointment';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';
import { AppointmentImagesService } from '../../../main/appointments/services/appointment-images.service';
import { AppointmentsService } from '../../../main/appointments/services/appointments.service';
import { TakeoffService } from '../../../main/appointments/takeoff/services/takeoff.service';

@Component({
    selector: 'vendo-product-preview',
    templateUrl: './product-preview.component.html',
    styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() openingData: any;
    @Input() configuratorData: any;
    @Input() nextOpening: number;
    @Input() allowSave: boolean;
    @Input() allowExpandedInfo = true;
    @Input() isHideDrawingSwitch = false;
    @Input() isFullStepsShowing = true;
    appointment: Appointment;
    isShowAfter = false;
    currentDrawing: any;
    readonly isCanViewPrice: boolean = this.permissionsService.hasPermissions(PERMISSIONS.SECOND_MEASURE.VIEW_PRICE);
    priceConfigurations = {
        display_status: PriceConfigurationDisplayStatus.AlwaysOff,
        price: null
    };
    takeoffDetailsTitle: string;
    configureTitle: string;
    config;
    takeoffDetails: any[] = [];
    activeSlideIndex = 0;
    isShowPriceModifiers = false;
    openingImage = {
        [PhotoModalTabHash.Sales]: [],
        [PhotoModalTabHash.SecondMeasure]: []
    };
    @ViewChild('imageSlides') imageSlides: IonSlides;
    @ViewChild(MaskedImageComponent) imageComponent: MaskedImageComponent;
    readonly priceConfigurationDisplayStatuses: typeof PriceConfigurationDisplayStatus =
        PriceConfigurationDisplayStatus;
    readonly maxImageCount = 10;
    private quoteId: number;
    private destroy$: Subject<void> = new Subject<void>();
    private appointmentId: number = this.route.snapshot.parent.parent.params.appointmentId;

    constructor(
        private appointmentImagesService: AppointmentImagesService,
        private appointmentsService: AppointmentsService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private configureService: ConfigureService,
        private modalController: ModalController,
        private permissionsService: PermissionsService,
        private route: ActivatedRoute,
        private stepsService: StepsService,
        private userPreferenceService: UserPreferencesService,
        private takeoffService: TakeoffService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (get(changes, 'openingData.currentValue.opening_details')) {
            const category = get(changes, 'openingData.currentValue.category');
            const isRoofCategory = [CategoryType.CustomRoofs, CategoryType.Roofs].includes(category.category_type);
            const isShowFirstFourQuestions = category.allow_configure_questions;

            if (isRoofCategory || isShowFirstFourQuestions) {
                this.takeoffDetails = this.openingData.opening_details.slice(0, 4);

                if (category.category_type === CategoryType.Roofs) {
                    if (this.openingData.temporary) {
                        this.takeoffDetails = [];
                    } else {
                        const openingDetail = find(
                            this.openingData.opening_details,
                            (detail) => detail.question.title === 'Type' && detail.answer === 'Accessory'
                        );

                        if (openingDetail) {
                            openingDetail.answer = 'Component';
                        }
                    }
                }

                if (isRoofCategory) {
                    return;
                }
            }

            if (!isShowFirstFourQuestions) {
                this.openingData.opening_details.forEach((item) => {
                    if (
                        !['# wide', 'area type', 'door style', 'door type', 'width', 'height'].includes(
                            item.question.title.toLowerCase()
                        )
                    ) {
                        return;
                    }

                    if (item.answer === 'Custom' && ['width', 'height'].includes(item.question.title.toLowerCase())) {
                        const customDetail = find(this.openingData.opening_details, {
                            question: { title: `Custom ${item.question.title}` }
                        });

                        if (customDetail) {
                            this.takeoffDetails.push(customDetail);
                        }
                    } else {
                        this.takeoffDetails.push(item);
                    }
                });
                this.takeoffDetails = sortBy(this.takeoffDetails, ['question.order']);
            }
        }

        if (changes.configuratorData && get(changes, 'configuratorData.currentValue.ConfigurationDrawingURL')) {
            if (this.currentDrawing?.DrawingID === 'view') {
                this.currentDrawing.image = {
                    url: this.configuratorData.ViewDrawing.DrawingUrl
                };
                this.currentDrawing.DrawingUrl = this.configuratorData.ViewDrawing.DrawingUrl;
                this.openingData.configurationDrawingUrl = this.currentDrawing.DrawingUrl;
            } else {
                this.currentDrawing = find(this.configuratorData.drawings, {
                    DrawingID: this.configuratorData.CurrentDrawing.DrawingID
                });
                this.currentDrawing.image = {
                    url: this.configuratorData.CurrentDrawing.DrawingUrl
                };
            }
        } else if (get(changes, 'configuratorData.currentValue.ConfigurationDrawingURL') === null) {
            this.currentDrawing = this.configuratorData.drawings[0] || {};
            this.currentDrawing.image = {
                url: this.currentDrawing?.DrawingUrl
            };
        }
        this.isShowAfter = !!this.configuratorData.CurrentDrawing;

        if (this.appointment && (this.appointment.type !== AppointmentType.SecondMeasure || this.isCanViewPrice)) {
            this.handlePrices();
        }

        this.cdr.detectChanges();
    }

    ngOnInit(): void {
        this.quoteId = this.route.snapshot.params.quoteId;
        this.config = this.route.snapshot.data.config;

        if (this.openingData) {
            this.openingData.images.forEach((image) => {
                if (image.type !== AppointmentImageType.Project) {
                    if (image.appointment_type === AppointmentType.SecondMeasure) {
                        this.openingImage[AppointmentType.SecondMeasure].push(image);
                    } else if (
                        image.appointment_type === AppointmentType.Sales ||
                        image.appointment_type === AppointmentType.ChangeOrder
                    ) {
                        this.openingImage[AppointmentType.Sales].push(image);
                    }
                }
            });
        }

        this.authService.getStorageItem('activeAppointment').then((appointment: Appointment) => {
            this.appointment = appointment;

            if (this.appointment.type !== AppointmentType.SecondMeasure || this.isCanViewPrice) {
                this.handlePrices();
                this.setUserPreferenceSubsciption();
            }

            if (appointment.type !== AppointmentType.SecondMeasure) {
                const takeOffStepName: string = this.stepsService.getStepName(appointment.type, SaleStepType.TAKE_OFF);
                const configureStepName: string = this.stepsService.getStepName(
                    appointment.type,
                    SaleStepType.CONFIGURE
                );

                this.takeoffDetailsTitle = `${takeOffStepName} details`;
                this.configureTitle = configureStepName === 'Configure' ? 'Configuration' : configureStepName;
            }
        });

        if (this.configuratorData.ConfigurationDrawingURL) {
            this.currentDrawing = find(this.configuratorData.drawings, {
                DrawingID: this.configuratorData.CurrentDrawing.DrawingID
            });
            this.currentDrawing.image = {
                url: this.configuratorData.CurrentDrawing.DrawingUrl
            };
        } else if (this.configuratorData.opening_configurations.product_image_url) {
            this.currentDrawing = this.configuratorData.drawings[0] || {};
            this.currentDrawing.image = {
                url: this.currentDrawing?.DrawingUrl
            };
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    togglePricesVisibility(): void {
        if (
            this.isFullStepsShowing &&
            this.appointment.type === AppointmentType.SecondMeasure &&
            !this.isCanViewPrice
        ) {
            return;
        }

        const newStatus = 5 - this.priceConfigurations.display_status;
        const isDefaultOn: boolean = newStatus === PriceConfigurationDisplayStatus.DefaultOn;

        this.userPreferenceService.saveUserPreference(UserPreferences.ShowPricing, isDefaultOn).subscribe(() => {
            if (isDefaultOn) {
                this.getPrice(newStatus);
            }
        });

        if (isDefaultOn) {
            this.priceConfigurations.display_status = newStatus;
        }
    }

    async openPriceModifiersModal(): Promise<void> {
        if (!this.isShowPriceModifiers) {
            return;
        }

        const modal = await this.modalController.create({
            component: PriceModifiersModalComponent,
            componentProps: {
                pricingInfoGroups: this.configuratorData.display_list_price.pricing_info_groups
            },
            cssClass: 'price-modifiers-modal'
        });

        await modal.present();
    }

    showExpandedInfo(): void {
        if (this.isShowAfter) {
            this.showConfigurationExpandedInfo();
        } else {
            this.showTakeoffExpandedInfo();
        }
    }

    toggleShowMode(): void {
        this.isShowAfter = !this.isShowAfter;
        this.activeSlideIndex = 0;
    }

    async slideChanged(): Promise<void> {
        this.activeSlideIndex = await this.imageSlides.getActiveIndex();
    }

    setActiveDrawing(newDrawing: any): void {
        if (newDrawing.DrawingID === this.currentDrawing.DrawingID) {
            return;
        }

        this.currentDrawing = newDrawing;

        if (this.currentDrawing.DrawingID === 'view') {
            const exteriorDrawing = this.configuratorData.drawings.find(
                (drawing) =>
                    drawing.DisplayName.toLowerCase().includes('exterior') && drawing.hasOwnProperty('DrawingID')
            );

            const setViewImage = (imageUrl): void => {
                this.currentDrawing.image = {
                    url: imageUrl
                };
                this.openingData.configurationDrawingUrl = imageUrl;
                this.cdr.detectChanges();
            };

            if (exteriorDrawing) {
                this.configureService
                    .getSpecificDrawing(this.quoteId, this.openingData.id, exteriorDrawing.DrawingID, false)
                    .subscribe((res) => {
                        const imageUrl = get(res, ['ViewDrawing', 'DrawingUrl']);

                        setViewImage(imageUrl ? imageUrl : this.currentDrawing.DrawingUrl);
                    });
            } else {
                // this.currentDrawing.image = {
                //     url: this.currentDrawing.DrawingUrl,
                // };
                // this.openingData.configurationDrawingUrl = this.currentDrawing.DrawingUrl;
                // this.cdr.detectChanges();
                setViewImage(this.currentDrawing.DrawingUrl);
            }

            return;
        }

        if (this.currentDrawing.DrawingID || this.currentDrawing.DrawingID === 0) {
            this.configureService
                .getSpecificDrawing(this.quoteId, this.openingData.id, this.currentDrawing.DrawingID, false)
                .subscribe((res) => {
                    const imageUrl = get(res, ['CurrentDrawing', 'DrawingUrl']);

                    if (imageUrl) {
                        this.currentDrawing.image = {
                            url: imageUrl
                        };

                        this.cdr.detectChanges();

                        return;
                    }

                    // use Product Image by default
                    const productImage = get(
                        find(this.configuratorData.drawings, { DisplayName: 'Product' }),
                        'DrawingUrl'
                    );

                    if (!productImage) {
                        return;
                    }

                    this.currentDrawing.image = {
                        url: productImage
                    };

                    this.cdr.detectChanges();
                });
        } else {
            this.currentDrawing.image = {
                url: this.configuratorData.drawings[0]?.DrawingUrl
            };
        }
    }

    async handleImages(): Promise<void> {
        let tabs = [];

        if (this.openingData.id) {
            const predefinedImages = await this.takeoffService
                .getCatalogImage(this.appointment.id, this.openingData.catalog_id)
                .pipe(
                    map((images) => {
                        if (images.predefined_images.length) {
                            images.predefined_images.map((image) => (image.type = AppointmentImageType.Predefined));
                        }

                        return images;
                    })
                )
                .toPromise();

            const photos = this.appointmentImagesService.remapPhotos(
                this.openingData.id,
                this.openingData.catalog_id,
                this.openingImage[
                    this.appointment.type === AppointmentType.SecondMeasure
                        ? AppointmentType.SecondMeasure
                        : AppointmentType.Sales
                ],
                predefinedImages,
                false
            );

            this.openingImage[
                this.appointment.type === AppointmentType.SecondMeasure
                    ? AppointmentType.SecondMeasure
                    : AppointmentType.Sales
            ] = photos;
        }

        let openingHasPhoto;
        let tabToAdd = null;

        switch (this.appointment.type) {
            case AppointmentType.Sales:
            case AppointmentType.ChangeOrder:
                openingHasPhoto = this.openingImage[AppointmentType.SecondMeasure].length > 0;

                if (!openingHasPhoto) {
                    tabToAdd = PhotoModalTabHash.Sales;
                }
                break;
            case AppointmentType.SecondMeasure:
                openingHasPhoto = this.openingImage[AppointmentType.Sales].length > 0;

                if (!openingHasPhoto) {
                    tabToAdd = PhotoModalTabHash.SecondMeasure;
                }
                break;
        }

        const propertiesWithValues = this.appointmentImagesService.getPropertiesWithValues(
            this.openingImage,
            openingHasPhoto
                ? this.appointment.type === AppointmentType.SecondMeasure
                    ? PhotoModalTabHash.SecondMeasure
                    : PhotoModalTabHash.Sales
                : tabToAdd
        );

        const isUseTab = propertiesWithValues.length > 1;
        const sort = this.appointment.type === AppointmentType.SecondMeasure ? [3, 2, 1] : [1, 2, 3];

        tabs = this.appointmentImagesService.getTabs(propertiesWithValues, sort);

        const modal = await this.modalController.create({
            component: UploadPhotosModalComponent,
            backdropDismiss: false,
            componentProps: {
                appointmentType: this.appointment.type,
                useTabs: isUseTab,
                tabs,
                appointmentId: this.appointmentId,
                isUseForOneOpening: true,
                openingId: this.openingData.id,
                tabsImages: this.openingImage,
                readOnlyTab:
                    this.appointment.type === AppointmentType.SecondMeasure
                        ? AppointmentType.Sales
                        : AppointmentType.SecondMeasure,
                needToUpdateWcp: false
            },
            cssClass: isUseTab ? 'upload-photos-with-tabs-modal' : 'upload-photos-modal'
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (!data || tabs.length > 1) {
            this.appointmentsService
                .getAppointmentImages(toNumber(this.appointmentId), toNumber(this.openingData.id), [
                    AppointmentImageType.Predefined,
                    AppointmentImageType.Custom
                ])
                .subscribe((images) => {
                    this.openingData.images = images;

                    this.openingImage[
                        this.appointment.type === AppointmentType.SecondMeasure
                            ? AppointmentType.SecondMeasure
                            : AppointmentType.Sales
                    ] = images;
                    this.imageSlides.update();
                });
        }
    }

    private getPrice(newStatus: number): void {
        this.configureService.getConfigurationPrice(this.quoteId, this.openingData.id).subscribe((res) => {
            this.priceConfigurations.display_status = newStatus;
            this.priceConfigurations.price = res.price;
            this.cdr.detectChanges();
        });
    }

    private async showConfigurationExpandedInfo(): Promise<any> {
        forkJoin([
            this.currentDrawing.DrawingID === undefined
                ? of(null)
                : this.configureService.getSpecificDrawing(
                      this.quoteId,
                      this.openingData.id,
                      this.currentDrawing.DrawingID,
                      true
                  ),
            this.configureService.getConfiguratorQuestions(this.quoteId, this.openingData.id)
        ]).subscribe(async ([specificDrawing, configuratorQuestions]) => {
            if (specificDrawing) {
                configuratorQuestions.CurrentDrawing = specificDrawing.CurrentDrawing;
                configuratorQuestions.ConfigurationDrawingURL = specificDrawing.ConfigurationDrawingURL;
            }

            if (isNil(this.currentDrawing.DrawingID)) {
                configuratorQuestions.CurrentDrawing.DisplayName = this.currentDrawing.DisplayName;
                configuratorQuestions.CurrentDrawing.DrawingUrl = this.currentDrawing.image.url;
            }

            const modal = await this.modalController.create({
                component: ConfigurationInfoModalComponent,
                componentProps: {
                    configurator: configuratorQuestions,
                    selectedDrawing: this.currentDrawing,
                    openingData: this.imageComponent
                        ? this.imageComponent.resetOpeningPoints(cloneDeep(this.openingData))
                        : cloneDeep(this.openingData),
                    availableDrawings: this.configuratorData.drawings,
                    quoteId: this.quoteId,
                    openingId: this.openingData.id,
                    configureTitle: this.configureTitle
                },
                cssClass: 'full-screen'
            });

            await modal.present();
            this.cdr.detectChanges();
        });
    }

    private async showTakeoffExpandedInfo(): Promise<any> {
        const modal = await this.modalController.create({
            component: TakeoffInfoModalComponent,
            componentProps: {
                openingData: this.openingData,
                activeSlideIndex: this.activeSlideIndex,
                takeoffDetailsTitle: new TitleCasePipe().transform(this.takeoffDetailsTitle)
            },
            cssClass: 'full-screen'
        });

        await modal.present();
        this.cdr.detectChanges();
    }

    private handlePrices(): void {
        this.priceConfigurations = this.configuratorData.display_list_price;
        this.isShowPriceModifiers =
            this.appointment.type !== AppointmentType.SecondMeasure &&
            this.isFullStepsShowing &&
            this.configuratorData.display_list_price.display_price_modifiers_status ===
                PriceConfigurationDisplayStatus.DefaultOn;
    }

    private setUserPreferenceSubsciption(): void {
        const preferencePrevValue = this.userPreferenceService.userPreference.getValue();

        this.userPreferenceService.userPreference.pipe(takeUntil(this.destroy$)).subscribe((preference) => {
            if (preferencePrevValue === preference) {
                return;
            }

            switch (preference?.type) {
                case UserPreferences.ShowPricing:
                    if (this.priceConfigurations.display_status !== PriceConfigurationDisplayStatus.AlwaysOff) {
                        const newStatus = preference.value
                            ? PriceConfigurationDisplayStatus.DefaultOn
                            : PriceConfigurationDisplayStatus.DefaultOff;

                        if (newStatus === PriceConfigurationDisplayStatus.DefaultOn) {
                            this.getPrice(newStatus);
                        } else {
                            this.priceConfigurations.display_status = newStatus;
                        }
                    }
                    break;
                case UserPreferences.ShowPriceModifiers:
                    this.isShowPriceModifiers =
                        this.appointment.type !== AppointmentType.SecondMeasure &&
                        this.isFullStepsShowing &&
                        preference.value;
                    break;
            }
        });
    }
}
