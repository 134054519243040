export enum SaleStepType {
    HOME = 'home',
    TAKE_OFF = 'takeoff',
    NEEDS_ASSESSMENT = 'needs-assessment',
    DEMO = 'demo',
    CONFIGURE = 'configure',
    QUOTE = 'quote',
    MEASURE = 'measure',
    REVIEW = 'review'
}
