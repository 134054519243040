import { Pipe, PipeTransform } from '@angular/core';

import isString from 'lodash/isString';

import { DeviceHelperService } from '@core/services/device-helper.service';

@Pipe({
    name: 'offlineAndroid'
})
export class OfflineAndroidPipe implements PipeTransform {
    isOffline = window.localStorage.getItem('offline_mode') === '1';

    constructor(private deviceHelperService: DeviceHelperService) {}

    // fixed videos in online/offline mode and images in offline mode on android device
    transform(value: string, isForce = false): string {
        if (
            this.deviceHelperService.isAndroidPlatform &&
            !this.deviceHelperService.isWeb &&
            isString(value) &&
            value.startsWith('http') &&
            (isForce || this.isOffline)
        ) {
            return `${value}?ngsw-bypass=true`;
        }

        return value;
    }
}
