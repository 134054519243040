import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ModalController } from '@ionic/angular';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import toNumber from 'lodash/toNumber';

import { BaseModal } from '@shared/components/base-modal';
import { UploadPhotosComponent } from '@shared/components/upload-photos/upload-photos.component';
import { ImagesTabs } from '@shared/constants/images-tabs';
import { AppointmentImageType } from '@shared/enums/appointment-image-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { PhotoModalTabHash } from '@shared/enums/photo-modal-tab-hash.enum';

import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { AppointmentImagesService } from '../../../main/appointments/services/appointment-images.service';
import { AppointmentsService } from '../../../main/appointments/services/appointments.service';

@Component({
    selector: 'vendo-upload-photos-modal',
    templateUrl: './upload-photos-modal.component.html',
    styleUrls: ['./upload-photos-modal.component.scss']
})
export class UploadPhotosModalComponent extends BaseModal implements OnInit {
    @Input() appointmentId;
    @Input() readOnlyTab;
    @Input() appointmentType;
    @Input() useTabs;
    @Input() tabs;
    @Input() tabsImages;
    @Input() openingId;
    @Input() isUseForOneOpening = false;
    @Input() useSaveAsClose = false;
    @Input() images = [];
    @Input() needToUpdateWcp = true;

    isExpanded: boolean[];
    selectedMenuIndex;
    imagesTabs = cloneDeep(ImagesTabs);
    activeTabId = null;
    tabsImagesBefore;
    isCloseWithoutCall = false;

    @ViewChild('uploadPhotosComponent') uploadPhotos: UploadPhotosComponent;

    readonly appointmentImageType: typeof AppointmentImageType = AppointmentImageType;
    readonly photoModalTabHashes: typeof PhotoModalTabHash = PhotoModalTabHash;

    private isOffline = window.localStorage.getItem('offline_mode') === '1';

    constructor(
        private modalController: ModalController,
        private appointmentsService: AppointmentsService,
        private appointmentImagesService: AppointmentImagesService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.tabsImagesBefore = cloneDeep(this.tabsImages);
        this.imagesTabs = cloneDeep(this.tabs);
        this.activeTabId = this.imagesTabs[0].hash;

        this.setRequiredImagesCounter();
    }

    expandItem(index): void {
        const isExpanded = this.isExpanded.some((item) => item);

        if (!isExpanded) {
            this.isExpanded[index] = !this.isExpanded[index];
            this.selectedMenuIndex = index;
        } else {
            const imagesAfter = this.appointmentImagesService.remapImagesToCheckIsEqual(this.uploadPhotos.photosList);

            const openingPhotos = this.tabsImagesBefore[this.activeTabId].find(
                (item) => item.id === this.uploadPhotos.openingId
            ).photos;

            const imagesBefore = this.appointmentImagesService.remapImagesToCheckIsEqual(openingPhotos);

            if (isEqual(imagesAfter, imagesBefore)) {
                this.setExpanded(index);
                this.selectedMenuIndex = index;
            } else {
                this.showConfirmationModal().then((result) => {
                    if (result) {
                        this.uploadPhotos.save();
                        this.reSetImages();
                        this.setRequiredImagesCounter();
                    }

                    this.setExpanded(index);
                    this.selectedMenuIndex = index;
                });
            }
        }
    }

    async showConfirmationModal(): Promise<boolean> {
        const modal = await this.modalController.create({
            backdropDismiss: false,
            component: ConfirmationModalComponent,
            componentProps: {
                confirmButtonName: 'Yes',
                cancelButtonName: 'No',
                showCancelButton: true,
                headerText: 'Save changes?',
                message: 'Do you want to save changes you made?'
            },
            cssClass: 'available-update-modal',
            showBackdrop: true
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        return data;
    }

    async selectTab(tab: any): Promise<void> {
        if (tab.hash === this.activeTabId) {
            return;
        }

        let result = false;
        let isEqualTab = true;

        switch (tab.hash) {
            case PhotoModalTabHash.Sales:
            case PhotoModalTabHash.ChangeOrder:
            case PhotoModalTabHash.SecondMeasure:
                this.isExpanded = new Array(this.tabsImages[this.activeTabId].length).fill(false);
                this.isExpanded[this.selectedMenuIndex] = true;
                break;
        }

        isEqualTab = this.checkIfIsEqual();

        result = isEqualTab ? false : await this.showConfirmationModal();

        if (!isEqualTab && result) {
            this.uploadPhotos.save();
            this.reSetImages();
            this.setRequiredImagesCounter();
        }

        this.activeTabId = tab.hash;
    }

    private reSetImages(): void {
        const photosList = cloneDeep(this.uploadPhotos.photosList);

        photosList.map((image) => {
            if (image.url) {
                image.url = this.resetImageUrl(
                    image.url,
                    this.activeTabId === PhotoModalTabHash.ProjectPhotos ? 'appointment_project_photo' : 'opening'
                );
            }

            return image;
        });

        this.uploadPhotos.photosList = photosList;
        if (this.isUseForOneOpening || this.activeTabId === PhotoModalTabHash.ProjectPhotos) {
            this.tabsImages[this.activeTabId] = cloneDeep(this.uploadPhotos.photosList);
            this.tabsImagesBefore[this.activeTabId] = cloneDeep(this.uploadPhotos.photosList);
        } else {
            this.tabsImages[this.activeTabId].find((opening) => opening.id === this.uploadPhotos.openingId).photos =
                this.uploadPhotos.photosList;
            this.tabsImagesBefore[this.activeTabId].find(
                (opening) => opening.id === this.uploadPhotos.openingId
            ).photos = this.uploadPhotos.photosList;
        }
    }

    private checkIfIsEqual(): boolean {
        let imagesAfter;
        let imagesBefore;

        if (this.uploadPhotos) {
            if (this.uploadPhotos.openingId) {
                imagesAfter = this.appointmentImagesService.remapImagesToCheckIsEqual(this.uploadPhotos.photosList);
                const tabImagesBefore = cloneDeep(this.tabsImagesBefore[this.activeTabId]);

                imagesBefore = this.appointmentImagesService.remapImagesToCheckIsEqual(
                    this.isUseForOneOpening
                        ? tabImagesBefore
                        : tabImagesBefore.find((opening) => opening.id === this.uploadPhotos.openingId)?.photos
                );
            } else {
                imagesAfter = this.appointmentImagesService.remapImagesToCheckIsEqual(this.uploadPhotos.photosList);

                imagesBefore = this.appointmentImagesService.remapImagesToCheckIsEqual(
                    this.tabsImagesBefore[this.activeTabId]
                );
            }

            return isEqual(imagesAfter, imagesBefore);
        } else {
            return true;
        }
    }

    async close(isUseConfirmation = true): Promise<void> {
        this.isCloseWithoutCall = false;

        if (isUseConfirmation && this.useTabs) {
            switch (this.activeTabId) {
                case PhotoModalTabHash.ProjectPhotos:
                    await this.setActionOnClose(this.checkIfIsEqual());
                    break;
                case PhotoModalTabHash.Sales:
                case PhotoModalTabHash.SecondMeasure:
                case PhotoModalTabHash.ChangeOrder:
                    await this.setActionOnClose(this.checkIfIsEqual());
                    break;
            }
        }

        if (!this.useTabs) {
            await this.setActionOnClose(this.checkIfIsEqual());
        }
    }

    save(images, openingId: string): void {
        let type;

        switch (this.activeTabId) {
            case PhotoModalTabHash.ProjectPhotos:
                type = AppointmentImageType.Project;
                break;
            case PhotoModalTabHash.Sales:
            case PhotoModalTabHash.SecondMeasure:
            case PhotoModalTabHash.ChangeOrder:
                type = AppointmentImageType.Predefined;
                break;
            default:
                type = AppointmentImageType.Custom;
        }

        if (this.useSaveAsClose) {
            this.modalController.dismiss(this.uploadPhotos.getImages());

            return;
        }

        this.appointmentsService
            .saveAppointmentImages(toNumber(this.appointmentId), type, [
                {
                    opening_id: openingId || this.openingId,
                    need_to_update_wcp: this.needToUpdateWcp,
                    images: this.isOffline
                        ? images
                        : images.map((image) => {
                              delete image.appointment_type;
                              delete image.opening_id;

                              return image;
                          })
                }
            ])
            .subscribe((result) => {
                if (result) {
                    if (!this.useTabs) {
                        this.closeModal();
                    } else if (this.isCloseWithoutCall) {
                        this.modalController.dismiss(true);
                    } else {
                        if (this.uploadPhotos) {
                            this.reSetImages();

                            if (this.uploadPhotos.openingId) {
                                this.setRequiredImagesCounter();
                            }
                        }
                    }
                }
            });
    }

    private countRequired(photo, counter): number {
        if (
            photo.hasOwnProperty('is_required') &&
            photo.is_required &&
            !photo.url &&
            photo.type === AppointmentImageType.Predefined
        ) {
            counter++;
        }

        return counter;
    }

    private setRequiredImagesCounter(): void {
        let counter = 0;

        const tabHash =
            this.readOnlyTab === AppointmentType.SecondMeasure ? AppointmentType.Sales : AppointmentType.SecondMeasure;

        if (this.tabs.length > 1 && !this.isUseForOneOpening) {
            this.tabsImages[tabHash].forEach((opening) =>
                opening.photos.forEach((photo) => {
                    counter = this.countRequired(photo, counter);
                })
            );

            this.imagesTabs[1].counter = counter;
        } else {
            this.tabsImages[tabHash].forEach((photo) => {
                counter = this.countRequired(photo, counter);
            });
            this.imagesTabs[0].counter = counter;
        }
    }

    private async setActionOnClose(isEqual: boolean): Promise<void> {
        if (isEqual) {
            this.modalController.dismiss(true);
        } else {
            if (!this.openingId && this.useSaveAsClose) {
                this.isCloseWithoutCall = await this.showConfirmationModal();
                this.modalController.dismiss(this.isCloseWithoutCall ? this.uploadPhotos.getImages() : true);
            } else {
                this.isCloseWithoutCall = await this.showConfirmationModal();
                this.isCloseWithoutCall ? this.uploadPhotos.save() : this.modalController.dismiss(true);
            }
        }
    }

    private setExpanded(index: number): void {
        if (this.selectedMenuIndex === index) {
            this.isExpanded[index] = !this.isExpanded[index];
        } else {
            this.isExpanded = new Array(this.tabsImages[this.activeTabId].length)
                .fill(false)
                .map((_, itemIndex) => itemIndex === index);
        }
    }

    private resetImageUrl(url: string, partToReplace: string): string {
        if (url.includes('temporary_uploaded_images')) {
            return url.replace('temporary_uploaded_images', partToReplace);
        }

        return url;
    }
}
