<div class="modal-header">
  <ion-button fill="outline" (click)="closeModal()">
    <ion-icon name="chevron-back-outline"></ion-icon>
    <ion-text>Back</ion-text>
  </ion-button>
</div>

<div class="modal-body">
  <ion-text class="title">{{ title }}</ion-text>

  <ion-content>
    <ng-container *ngFor="let groupedDocument of groupedDocuments">
      <div [class.grouped]="groupedDocument.documents.length !== documents.length">
        <ion-item *ngIf="groupedDocument.documents.length !== documents.length"
                  lines="none"
                  class="grouped-title">
          <ion-text>{{ groupedDocument.name }}</ion-text>
        </ion-item>

        <ng-container *ngFor="let document of groupedDocument.documents; let i = index">
          <ion-item lines="none"
                    (click)="presentPopover(document, $event)">
            <ion-label>
              {{ document.name }}
              <ng-container *ngIf="document.type === documentTypes.PriceConditioning; else documentTypeTitle">(Energy Savings)</ng-container>
              <ng-template #documentTypeTitle>({{document.type | replace: '_':' ' | titlecase}})</ng-template>
              <ng-container *ngIf="isOnlyAttachments && document.package_name"> - {{ document.package_name }}</ng-container>
            </ion-label>
            <ion-checkbox slot="start"
                          mode="md"
                          [disabled]="document.signed"
                          (ngModelChange)="$event ? setDocumentContext(document) : ''"
                          [(ngModel)]="document.selected"></ion-checkbox>
          </ion-item>
        </ng-container>
      </div>
    </ng-container>
  </ion-content>
</div>

<div class="modal-footer flex-end">
  <ion-button class="classic-width"
              (click)="done()">
    Done
  </ion-button>
</div>
