<div class="modal-header">
  <ion-text class="title">Request Approval</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ion-content>
    <div *ngIf="approvalRequest" class="successful-message">
      <div class="title">
        <span>Submission Successful</span>
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <div>Approval times can take up to 24 hours.</div>
      <div>Just hang tight and we'll contact you.</div>
    </div>

    <ng-container *ngIf="!approvalRequest">
      <div class="field-block">
        <ion-label>Description</ion-label>
        <ion-textarea rows="4"
                      placeholder="Include a short description here"
                      autocomplete="off"
                      [formControl]="descriptionControl"></ion-textarea>
        <div class="errors-wrapper" *ngIf="descriptionControl.touched">
          <ion-text color="danger" class="error"
                    *ngFor="let error of getMessages(descriptionControl.errors)">
            {{ error }}
          </ion-text>
        </div>
      </div>

      <div class="field-block">
        <ion-label>Promotions Included</ion-label>
        <div class="chips-block">
          <ion-chip *ngFor="let promotion of promotions">
            <ion-label class="text-ellipsis">{{ promotion.name }}</ion-label>
          </ion-chip>
        </div>
      </div>

      <ion-grid>
        <ion-row *ngFor="let item of pricePresentationSettings">
          <ion-col size="8">
            {{ item.fields[item.hash] }}
          </ion-col>
          <ion-col size="4" class="ion-text-right">
            <ng-container [ngSwitch]="item.hash">
              <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.PackagePrice">
                <s>{{ quote.sub_total_price | customCurrency: true }}</s>
              </ng-container>
              <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.SubTotal">
                {{ quote.sub_final_price | customCurrency: true }}
              </ng-container>
            </ng-container>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>
  </ion-content>
</div>

<div class="modal-footer">
  <ion-button *ngIf="!approvalRequest"
              fill="outline"
              (click)="closeModal()">
    Cancel
  </ion-button>
  <ion-button (click)="approvalRequest ? closeModal() : submit()">
    {{ approvalRequest ? 'Finish' : 'Apply' }}
  </ion-button>
</div>
