import { Pipe, PipeTransform } from '@angular/core';

import get from 'lodash/get';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string | number, filterBy?: string, strict = true): any[] {
        if (!items) {
            return [];
        }
        if (searchText) {
            searchText = searchText.toString();

            return items.filter((it) => {
                if (filterBy) {
                    if (strict) {
                        return get(it, filterBy) === searchText;
                    }

                    return get(it, filterBy).includes(searchText);
                }
                if (strict) {
                    return it === searchText;
                }

                return it.includes(searchText);
            });
        } else {
            return items.filter((it) => {
                if (filterBy) {
                    return !get(it, filterBy);
                }
            });
        }
    }
}
