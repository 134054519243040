import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MsalService } from '@azure/msal-angular';

import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { from, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private msalService: MsalService,
        private navCtrl: NavController,
        private rollbarErrorHandler: RollbarErrorHandler,
        private storage: Storage
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(Promise.all([this.storage.get('token'), this.storage.get('orgHash')])).pipe(
            tap(([token, orgHash]: [string, string]) => {
                const isParadigmAuth = !!this.msalService.instance.getActiveAccount();
                const setHeaders = {};

                if (
                    token &&
                    !['/e-document/v2', '/offer-request'].some((item) => window.location.href.includes(item))
                ) {
                    setHeaders[isParadigmAuth ? 'Authorization' : 'x-token'] = isParadigmAuth
                        ? `Bearer ${token}`
                        : token;
                }

                if (isParadigmAuth && orgHash) {
                    setHeaders['X-Organization-Hash'] = orgHash;
                }

                if (!isEmpty(setHeaders)) {
                    req = req.clone({ setHeaders });
                }
            }),
            switchMap(() => next.handle(req)),
            catchError((response) => {
                const errors = get(response, 'error.errors');

                if (errors) {
                    const isAuthError = some(errors, (errObj) => {
                        return get(errObj, 'extensions.code') === 'UNAUTHENTICATED';
                    });

                    if (isAuthError && req.body.operationName !== 'logout') {
                        this.rollbarErrorHandler.handleInfo('Logout - User is not authenticated');
                        const returnUrl: string = this.authService.getReturnUrl();

                        this.navCtrl.navigateRoot(['logout'], returnUrl ? { queryParams: { returnUrl } } : undefined);
                    }
                }

                return of(errors);
            })
        );
    }
}
