<ion-content class="login-page">
    <form [formGroup]="form" (keypress)="handlePressEnter($event)">
        <ion-grid>
            <ion-row class="ion-justify-content-center">
                <ion-col class="top-logo ion-align-self-center ion-justify-content-center" [ngClass]="{'is-higher-image': isHigherImage}" size-xl="4" size-md="7" size="10">
                    <div class="ion-align-self-center">
                        <ion-img class="main-logo ion-align-self-center"
                                 [src]="logoUrl"></ion-img>
                    </div>
                </ion-col>
            </ion-row>

            <ion-row class="ion-justify-content-center">
                <ion-col class="login-card ion-align-self-center" size-xl="4" size-md="7" size="10">
                    <div>
                        <ion-item>
                            <ion-label position="stacked">Email</ion-label>
                            <ion-input formControlName="userName" name="userName" type="email" required (ionBlur)="cdr.detectChanges()"></ion-input>
                        </ion-item>
                        <ion-item *ngIf="loginConfirmation && !loginConfirmation.external">
                            <ion-label position="stacked">Password</ion-label>
                            <ion-input name="password" type="password" formControlName="password"
                                       required></ion-input>
                        </ion-item>
                    </div>

                    <div class="button-section ion-text-center">
                        <div class="grouped-button">
                            <ng-container *ngIf="loginConfirmation && !loginConfirmation.external">
                                <ion-button class="icon-only login-back"
                                            size="small"
                                            color="secondary"
                                            (click)="backToEmailVerification()"
                                            expand="full">
                                    <ion-icon name="chevron-back-outline"></ion-icon>
                                </ion-button>
                                <ion-button class="login-button"
                                            color="dark"
                                            size="large"
                                            (click)="login()"
                                            [disabled]="form.invalid"
                                            expand="full">
                                    Log In
                                </ion-button>
                                <ion-button class="icon-only biometric"
                                            size="small"
                                            *ngIf="biometricAvailable"
                                            (click)="loginWithBiometric()"
                                            expand="full">
                                    <ion-icon name="finger-print"></ion-icon>
                                </ion-button>
                            </ng-container>
                            <ion-button class="login-button"
                                        color="dark"
                                        size="large"
                                        (click)="checkEmail()"
                                        [disabled]="isDisableCheckEmailButton || form.invalid"
                                        *ngIf="!loginConfirmation || loginConfirmation.external"
                                        expand="full">
                                Continue
                            </ion-button>
                        </div>

                        <div class="terms">
                            By logging into this service, you affirm your acceptance of our <span (click)="openTermsAndConditionsModal()">Terms and Conditions</span>.
                        </div>

                        <a class="ion-margin-top underline link inline-block"
                           *ngIf="loginConfirmation && !loginConfirmation.external"
                           routerDirection="root"
                           routerLink="/forgot-password">
                            Forgot Password?
                        </a>
                    </div>
                </ion-col>
            </ion-row>

            <ion-row class="ion-justify-content-center">
                <ion-col size="7" class="bottom-section ion-text-center">
                    <ion-text>&#9400; 2001-{{currentYear}} All Rights Reserved.</ion-text>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>
