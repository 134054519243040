import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';
import { AppointmentImage } from '@shared/interfaces/appointment-image';
import { MeasurementSystem } from '@shared/interfaces/measurement-system';

import { AppointmentsService } from '../../../main/appointments/services/appointments.service';

@Component({
    selector: 'vendo-view-measurement-report',
    templateUrl: './view-measurement-report.component.html',
    styleUrls: ['./view-measurement-report.component.scss']
})
export class ViewMeasurementReportComponent extends BaseModal implements OnInit {
    @Input() appointment: any;
    @Input() measurementSystem: MeasurementSystem;
    @Input() report: any;
    tabs = [
        {
            label: 'View Report',
            hash: 'report'
        }
    ];
    activeTab = this.tabs[0];
    images: AppointmentImage[] = [];
    selectedImage: AppointmentImage;

    constructor(private appointmentsService: AppointmentsService, public modalController: ModalController) {
        super(modalController);
    }

    ngOnInit(): void {
        this.appointmentsService
            .getAppointmentImages(+this.appointment.id, null, [this.measurementSystem.hash], this.appointment.type)
            .subscribe({
                next: async (images: AppointmentImage[]) => {
                    this.images = images;

                    if (this.images.length) {
                        this.tabs.push({
                            label: 'Report Images',
                            hash: 'images'
                        });
                    }
                }
            });
    }

    setActiveTab(tab: any): void {
        if (this.selectedImage) {
            this.setImage();
        }
        this.activeTab = tab;
    }

    setImage(image: AppointmentImage = null): void {
        this.selectedImage = image;
    }
}
