import { gql } from 'apollo-angular';

const GetDemoResources = gql`
    query DemoResourcesList($appointment_id: ID) {
        myDemoResources(appointment_id: $appointment_id) {
            id
            name
            icon
            text_color
            widget_color
            resource_type
            resource_src
            badge {
                src
                type
            }
            categories {
                id
                name
            }
            package_links {
                package_name
                resource_src
            }
        }
    }
`;

const GetDemoResource = gql`
    query DemoResource($id: ID!, $appointment_id: ID, $geolocation: JSON) {
        myDemoResource(id: $id, appointment_id: $appointment_id, geolocation: $geolocation) {
            id
            resource_type
            resource_src
        }
    }
`;

export { GetDemoResources, GetDemoResource };
