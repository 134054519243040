export enum QuotePricePresentationFieldType {
    PackagePrice = 'package_price',
    DiscountAmount = 'discount_amount',
    SubTotal = 'sub_final_price',
    Tax = 'tax',
    Deposit = 'deposit',
    Balance = 'balance',
    BalanceWithDeposit = 'balance_with_deposit',
    BalanceWithFinancingOption = 'balance_with_financing_option'
}
