import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';
import { QuotePricingProfile } from '@shared/interfaces/quote-pricing-profile';

@Component({
    selector: 'vendo-select-pricing-profile-modal',
    templateUrl: './select-pricing-profile-modal.component.html',
    styleUrls: ['./select-pricing-profile-modal.component.scss']
})
export class SelectPricingProfileModalComponent extends BaseModal implements OnInit {
    @Input() profiles: QuotePricingProfile[] = [];
    @Input() isLineItemsExist: boolean;
    isUpdateExistingLines = false;
    selectedProfileId: string;
    defaultSelected: QuotePricingProfile;
    applyOptions = [
        {
            label: 'Updates preferences for new lines',
            value: false
        },
        {
            label: 'Updates pricing on existing lines and preferences for new lines',
            value: true
        }
    ];

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit(): void {
        this.defaultSelected = this.profiles.find((item: QuotePricingProfile) => item.IsDefault);
        if (this.defaultSelected) {
            this.selectedProfileId = this.defaultSelected.ProfileId;
        }
    }

    closeModal(): void {
        this.dismiss({
            isUpdateExistingLines: false,
            pricingProfileId: this.defaultSelected?.ProfileId
        });
    }

    confirm(): void {
        this.dismiss({
            isUpdateExistingLines: this.isUpdateExistingLines,
            pricingProfileId: this.selectedProfileId
        });
    }
}
