<div class="modal-header">
  <ion-text class="title">{{headerText}}</ion-text>
</div>
<div class="modal-body">
  <ion-text [innerHTML]="message"></ion-text>
</div>
<div class="modal-footer">
  <ion-button *ngFor="let item of actions; let i = index"
              class="classic-width {{ item.cssClass }}"
              [ngClass]="{'outline' : !item?.primaryButton }"
              (click)="action(item.value)">
    {{ item.label }}
  </ion-button>
</div>
