import {
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    ElementRef,
    OnDestroy
} from '@angular/core';

import { IonTextarea, ModalController, PopoverController } from '@ionic/angular';

import cloneDeep from 'lodash/cloneDeep';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { FilesService } from '@core/services/files.service';
import { ImageService } from '@core/services/image.service';
import { DropdownPopoverComponent, FreeTextPopoverComponent, ImageAnnotationModalComponent } from '@shared';
import { BaseModal } from '@shared/components/base-modal';
import { GRID_IMAGE_URL } from '@shared/constants/grid-image-url';
import { AppointmentImageType } from '@shared/enums/appointment-image-type.enum';
import { OpeningImageType } from '@shared/enums/opening-image-type.enum';
import { AppointmentMappedImage } from '@shared/interfaces/appointment-mapped-image';

import { AppointmentImagesService } from '../../../main/appointments/services/appointment-images.service';
import { AppointmentsService } from '../../../main/appointments/services/appointments.service';

@Component({
    selector: 'vendo-upload-photos',
    templateUrl: './upload-photos.component.html',
    styleUrls: ['./upload-photos.component.scss']
})
export class UploadPhotosComponent extends BaseModal implements OnDestroy {
    @Input() set photos(list: any[]) {
        if (list?.length) {
            this.photosList = [];

            list.forEach((photo, index) => {
                this.photosList.push({
                    url: photo.url,
                    name: photo.name || photo.label,
                    index: index,
                    ...photo
                });
            });

            this.originalPhotosList = cloneDeep(this.photosList);

            if (this.photosList.length) {
                this.selectedPhoto = this.photosList[0];
            }
        }
    }
    @Input() maxImages = 10;
    @Input() openingId;
    @Input() isReadOnly = false;
    @Input() type: AppointmentImageType;
    @ViewChildren('fileUpload') fileInputs: QueryList<any>;
    @ViewChild('inputField') inputField: IonTextarea;
    @ViewChild('videoPlayer') videoPlayer: ElementRef;
    @ViewChild('canvas') canvas: ElementRef;
    @Output() emitSave: EventEmitter<any> = new EventEmitter<any>();

    isOfflineMode: boolean = window.localStorage.getItem('offline_mode') === '1';
    isDesktopWeb = this.deviceHelper.isDesktopWeb;
    isTabletWeb = this.deviceHelper.isTabletWeb;
    emptyGridImageUrl = GRID_IMAGE_URL;
    allowedFileExtensions = this.imageService.allowedFileExtensions.join(', ');
    photosList: any[] = [];
    originalPhotosList: any[] = [];
    selectedPhoto = null;
    isListValid = false;
    imageCapture = '';
    stream: MediaStream;
    isStreamActive = false;

    readonly appointmentImageType: typeof AppointmentImageType = AppointmentImageType;

    constructor(
        private appointmentImagesService: AppointmentImagesService,
        private appointmentService: AppointmentsService,
        private deviceHelper: DeviceHelperService,
        private fileService: FilesService,
        private imageService: ImageService,
        private modalController: ModalController,
        private popoverController: PopoverController,
        private renderer2: Renderer2
    ) {
        super(modalController);
    }

    ngOnDestroy(): void {
        this.closeStream();
    }

    closeStream(): void {
        if (this.stream) {
            const tracks = this.stream.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });

            this.isStreamActive = false;
        }
    }

    async takeSnapshot(): Promise<void> {
        const video = this.videoPlayer.nativeElement;
        const canvas = this.canvas.nativeElement;
        const context = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageData = canvas.toDataURL('image/jpeg');
        const blob = this.fileService.base64ToBlob(
            'data:image/jpeg;base64,' + imageData,
            'image/jpeg',
            512,
            `camera_photo_${this.selectedPhoto ? this.selectedPhoto.index : 0}.jpeg`
        );

        this.closeStream();

        if (!this.selectedPhoto) {
            this.addImage();
            setTimeout(async () => await this.uploadAndSetPhoto(blob, this.selectedPhoto.index));
        } else {
            await this.uploadAndSetPhoto(blob, this.selectedPhoto.index);
        }
    }

    getImages(): AppointmentMappedImage[] {
        const images: AppointmentMappedImage[] = [];

        this.photosList.forEach((photo) => {
            if (photo.url) {
                images.push({
                    url: photo.url,
                    original_url: photo.url,
                    name: photo.name,
                    note: photo.note,
                    type: photo.type,
                    appointment_type: photo.appointment_type,
                    opening_id: photo.opening_id || this.openingId
                });
            }
        });

        return images;
    }

    save(): void {
        const images = this.getImages();

        this.emitSave.emit(images);

        this.originalPhotosList = cloneDeep(images);
        this.checkIsEqual();
    }

    async showActionsPopover(event: any, imageType: OpeningImageType, index: number): Promise<any> {
        event.stopPropagation();

        if (this.isReadOnly) {
            return;
        }

        if (index !== this.selectedPhoto?.index) {
            this.selectPhoto(this.photosList[index]);
        }

        const items = [
            {
                name: 'Replace',
                hash: 'new_photo',
                icon: 'camera'
            },
            ...(imageType === OpeningImageType.Predefined
                ? []
                : [
                      {
                          name: 'Delete',
                          hash: 'delete',
                          icon: 'trash'
                      }
                  ]),
            ...(this.isOfflineMode && this.photosList[index].url.startsWith('https')
                ? []
                : [
                      {
                          name: 'Annotate',
                          hash: 'annotate',
                          icon: 'brush'
                      }
                  ])
        ];

        const popover = await this.popoverController.create({
            component: DropdownPopoverComponent,
            translucent: true,
            mode: 'md',
            cssClass: 'dropdown-popover photo-actions',
            componentProps: {
                items
            },
            side: 'bottom',
            alignment: 'center'
        });

        await popover.present();

        const result = await popover.onDidDismiss();

        if (!result || !result.data) {
            return;
        }

        switch (result.data) {
            case 'new_photo':
                this.takePhoto();
                break;
            case 'delete':
                this.removePhoto(index);
                break;
            case 'annotate':
                await this.annotate(index);
                break;
        }
    }

    async fileUploaded(event: any, index: number): Promise<void> {
        this.uploadAndSetPhoto(event.target.files[0], index);
    }

    async takePhoto(): Promise<any> {
        if (this.isReadOnly) {
            return;
        }

        const items = [
            {
                name: 'Take a photo',
                hash: 'camera',
                icon: 'camera'
            },
            {
                name: this.isDesktopWeb ? 'Upload' : 'Choose from library',
                hash: 'upload',
                icon: 'image'
            },
            {
                name: 'Sketch',
                hash: 'sketch',
                icon: 'timeline'
            }
        ];

        const popover = await this.popoverController.create({
            component: DropdownPopoverComponent,
            translucent: true,
            mode: 'md',
            cssClass: 'dropdown-popover mobile-photo-actions',
            componentProps: {
                items
            }
        });

        await popover.present();

        const result = await popover.onWillDismiss();

        if (!result || !result.data) {
            return;
        }

        switch (result.data) {
            case 'sketch':
                this.imageCapture = undefined;
                const data = await this.showAnnotationModal(
                    this.emptyGridImageUrl,
                    'sketch.jpeg',
                    this.selectedPhoto ? 0 : this.selectedPhoto?.id
                );

                if (!data) {
                    return;
                }

                if (!this.selectedPhoto) {
                    this.addImage();
                    setTimeout(() => this.uploadAndSetPhoto(data, 0));
                } else {
                    this.uploadAndSetPhoto(data, this.selectedPhoto.index);
                }

                break;
            case 'upload':
                this.imageCapture = undefined;
                if (this.deviceHelper.isWeb) {
                    if (!this.selectedPhoto) {
                        this.addImage();
                        setTimeout(() => this.uploadPhoto(0));
                    } else {
                        this.uploadPhoto(this.selectedPhoto.index);
                    }
                } else {
                    if (!this.selectedPhoto) {
                        this.addImage();
                        setTimeout(() => this.getPicture(result.data, 0));
                    } else {
                        this.getPicture(result.data, this.selectedPhoto.index);
                    }
                }
                break;
            case 'camera':
                this.imageCapture = 'environment';
                if (this.isTabletWeb) {
                    if (!this.selectedPhoto) {
                        this.addImage();
                        setTimeout(() => this.uploadPhoto(0));
                    } else {
                        this.uploadPhoto(this.selectedPhoto.index);
                    }
                } else if (this.isDesktopWeb) {
                    setTimeout(() => {
                        navigator.mediaDevices
                            .getUserMedia({ video: true })
                            .then((stream) => {
                                this.isStreamActive = true;
                                this.stream = stream;

                                setTimeout(() => (this.videoPlayer.nativeElement.srcObject = this.stream));
                            })
                            .catch(async () => {
                                this.isStreamActive = false;

                                const popover = await this.popoverController.create({
                                    component: FreeTextPopoverComponent,
                                    translucent: true,
                                    mode: 'md',
                                    cssClass: 'auto-width',
                                    componentProps: {
                                        text: '<h6>Camera does not exist or access denied.</h6>'
                                    }
                                });

                                await popover.present();
                            });
                    });
                } else {
                    if (!this.selectedPhoto) {
                        this.addImage();
                        setTimeout(() => this.getPicture(result.data, 0));
                    } else {
                        this.getPicture(result.data, this.selectedPhoto.index);
                    }
                }
                break;
        }
    }

    selectPhoto(photo: any): void {
        this.selectedPhoto = photo;
    }

    addImage(): void {
        const item = {
            index: this.photosList.length,
            url: null,
            name: 'New Image',
            type: this.type
        };

        this.photosList.push(item);
        this.selectedPhoto = item;
    }

    setValue(event, field: string): void {
        event.stopPropagation();

        if (this.photosList.length && this.selectedPhoto) {
            this.photosList[this.selectedPhoto.index][field] = event.target.value;
            this.checkIsEqual(true);
        }
    }

    private getPicture(hash: 'camera' | 'upload', index: number): void {
        this.imageService.getPicture(hash, `photo${index}.jpg`).then((blob) => this.uploadAndSetPhoto(blob, index));
    }

    private uploadPhoto(index: number): void {
        const input = this.fileInputs.find((item, itemIndex) => index === itemIndex);

        if (!this.imageCapture && input.nativeElement.hasAttribute('capture')) {
            this.renderer2.removeAttribute(input.nativeElement, 'capture');
        }

        if (this.imageCapture && this.isTabletWeb) {
            this.renderer2.setAttribute(input.nativeElement, 'capture', this.imageCapture);
        }

        input.nativeElement.click();
    }

    private removePhoto(index: number): void {
        this.photosList.splice(index, 1);
        this.selectedPhoto = null;
        this.photosList.forEach((item, index) => (item.index = index));

        this.checkIsEqual();
    }

    private async annotate(index: number): Promise<void> {
        const fileName = this.generateFileName(index);

        const newPhoto = await this.showAnnotationModal(this.photosList[index].url, fileName);

        if (newPhoto) {
            await this.uploadAndSetPhoto(newPhoto, index);
        }
    }

    private async showAnnotationModal(photo: any, fileName: string, index?: number): Promise<any> {
        const modal = await this.modalController.create({
            component: ImageAnnotationModalComponent,
            componentProps: {
                imgUrl: photo,
                fileName,
                index
            },
            cssClass: 'full-screen',
            backdropDismiss: false
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data) {
            return data;
        }
    }

    private generateFileName(index?: number): string {
        return `photo${index === undefined ? '' : index}.jpg`;
    }

    private async uploadAndSetPhoto(file, index: number): Promise<void> {
        if (await this.imageService.isValidImage(file)) {
            this.appointmentService.uploadImage(file).subscribe((result) => {
                const photoItem = this.photosList.find((item) => item.index === index);

                photoItem.url = result;
                this.selectedPhoto = photoItem;

                this.checkIsEqual(true);
            });
        }
    }

    private checkIsEqual(checkIsUrlExist = false): void {
        const originalImages = this.appointmentImagesService.remapImagesToCheckIsEqual(this.originalPhotosList);
        const currentImages = this.appointmentImagesService.remapImagesToCheckIsEqual(this.photosList);

        if (checkIsUrlExist) {
            const diff = differenceWith(currentImages, originalImages, isEqual);

            this.isListValid = !isEqual(originalImages, currentImages) && diff.some((photo) => photo.url);
        } else {
            this.isListValid = !isEqual(originalImages, currentImages);
        }
    }
}
