import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-fab-button',
    templateUrl: './fab-button.component.html',
    styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent {
    @Input() buttonId: string;
    @Output() buttonClicked: EventEmitter<Event> = new EventEmitter<Event>();
}
