import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-select-item',
    templateUrl: './select-item.component.html',
    styleUrls: ['./select-item.component.scss']
})
export class SelectItemComponent {
    @Input() isHorizontalScroll = true;
    @Input() items: any[] = [];
    @Input() noDataText = 'No data are available';
    @Input() isAllowUnselect = false;
    @Input() titleProperty: string;
    @Input() labelProperty: string;
    @Input() imageProperty: string;
    @Input() isMultiSelect = false;
    @Input() valueProperty: string;
    @Input() selectedValue: string | string[];
    @Input() showPosition = false;
    @Output() selectedValueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    selectItem(item): void {
        if (this.isMultiSelect) {
            if (this.selectedValue.includes(item[this.valueProperty])) {
                this.selectedValue = (this.selectedValue as string[]).filter(
                    (photo) => photo !== item[this.valueProperty]
                );
            } else {
                (this.selectedValue as string[]).push(item[this.valueProperty]);
            }
        } else {
            this.selectedValue =
                this.isAllowUnselect && this.selectedValue === item[this.valueProperty]
                    ? undefined
                    : item[this.valueProperty];
        }

        this.selectedValueChange.emit(this.selectedValue);
    }
}
