<div class="question-block custom-question notes-question">
    <div class="question-header">
        <div class="question-title">Installation Notes</div>
    </div>
    <div class="answers">
        <ion-grid class="notes ion-no-padding">
            <ion-row *ngFor="let note of notes; let i = index"
                     class="grid-body">
                <ion-item-sliding>
                    <ion-item-options side="end">
                        <ion-item-option color="warning" (click)="removeNote(i)">
                            <i slot="icon-only" class="material-icons">
                                delete
                            </i>
                        </ion-item-option>
                    </ion-item-options>
                    <ion-item class="row-slide">
                        <ion-col size="12">
                            <ion-text class="note">
                                {{ note }}
                            </ion-text>
                        </ion-col>
                    </ion-item>
                </ion-item-sliding>
            </ion-row>

            <ion-row>
                <ion-item lines="none" class="add-note" (click)="addNewNote()">
                    <ion-button fill="outline" class="icon-only" slot="start">
                        <ion-icon name="add"></ion-icon>
                    </ion-button>
                    <ion-text color="primary">Add New</ion-text>
                </ion-item>
            </ion-row>
        </ion-grid>
    </div>
</div>
