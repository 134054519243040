import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    SaveAppointmentAssessmentAnswers,
    SetQuoteFieldsByAssessmentAnswers
} from '../mutations/needs-assessment.mutations';
import { GetAppointmentAssessmentQuestions } from '../queries/needs-assessment.queries';
import { NeedAssessmentAnswer, NeedsAssessment } from '@shared/interfaces/needs-assessment';

@Injectable({
    providedIn: 'root'
})
export class NeedsAssessmentService {
    constructor(private apollo: Apollo) {}
    getAppointmentAssessmentQuestions(appointmentId: string): Observable<NeedsAssessment[]> {
        return this.apollo
            .query({
                query: GetAppointmentAssessmentQuestions,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.getAppointmentAssessmentQuestions));
    }

    saveAppointmentAssessmentQuestions(appointmentId: string, answers: NeedAssessmentAnswer[]): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SaveAppointmentAssessmentAnswers,
                variables: {
                    appointmentId,
                    answers
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.saveAppointmentAssessmentAnswers));
    }

    setQuoteFieldsByAssessmentAnswers(appointmentId: string, ...quoteIds: string[]): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: SetQuoteFieldsByAssessmentAnswers,
                variables: {
                    appointmentId,
                    quoteIds
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.setQuoteFieldsByAssessmentAnswers));
    }
}
