import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { OnChangeFunctionType, OnTouchedFunctionType } from '@shared/interfaces/types';

@Component({
    selector: 'vendo-number-picker',
    templateUrl: './number-picker.component.html',
    styleUrls: ['./number-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberPickerComponent),
            multi: true
        }
    ]
})
export class NumberPickerComponent implements ControlValueAccessor {
    @Input() max: number;
    @Input() min: number;

    /**
     * Holds the current value of the slider
     */
    value = 0;

    /**
     * Invoked when the model has been changed
     */
    onChange: OnChangeFunctionType = (_: any) => {};

    /**
     * Invoked when the model has been touched
     */
    onTouched: OnTouchedFunctionType = () => {};

    constructor() {}

    /**
     * Method that is invoked on an update of a model.
     */
    updateChanges(): void {
        this.onChange(this.value);
    }

    /**
     * Writes a new item to the element.
     * @param value the value
     */
    writeValue(value: number): void {
        if ((this.max && value > this.max) || (this.min && value < this.min)) {
            return;
        }
        this.value = value;
        this.updateChanges();
    }

    /**
     * Registers a callback function that should be called when the control's value changes in the UI.
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when the control receives a blur event.
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
