import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[tqMScrollToMe]'
})
export class ScrollToMeDirective implements OnInit {
    @Input() tqMScrollToMe;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        if (this.tqMScrollToMe) {
            // eslint-disable-next-line no-console
            console.log(this.tqMScrollToMe, this.getPosition(this.elementRef.nativeElement));
        }
    }

    /**
     * returns the absolute position of an element regardless of position/float issues
     * @param {HTMLElement} el - element to return position for
     * @returns {object} { x: num, y: num }
     */
    getPosition(el): any {
        let x = 0;
        let y = 0;

        while (el != null && (el.tagName || '').toLowerCase() != 'html') {
            x += el.offsetLeft || 0;
            y += el.offsetTop || 0;
            el = el.parentElement;
        }

        return { x, y };
    }
}
