import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScriptService {
    private renderer2: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory2: RendererFactory2) {
        this.renderer2 = this.rendererFactory2.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
    }

    loadJsScript(
        src: string,
        successLoadCallback: (ev: Event) => any,
        errorLoadCallback?: (ev: Event) => any
    ): HTMLScriptElement {
        const script = this.renderer2.createElement('script');

        script.type = 'text/javascript';
        script.src = src;

        if ((script as any).attachEvent) {
            (script as any).attachEvent('onload', successLoadCallback);
            errorLoadCallback && (script as any).attachEvent('onerror', errorLoadCallback);
        } else {
            script.addEventListener('load', successLoadCallback, false);
            errorLoadCallback && script.addEventListener('error', errorLoadCallback, false);
        }

        this.renderer2.appendChild(this.document.head, script);

        return script;
    }
}
