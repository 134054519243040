<div class="toolbar">
  <div class="actions">
    <ion-button class="icon-only" fill="clear" (click)="download()">
      <ion-icon name="download-outline"></ion-icon>
    </ion-button>
    <ion-button class="icon-only" fill="clear" (click)="print()">
      <ion-icon name="print-outline"></ion-icon>
    </ion-button>
  </div>
</div>
