import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import isString from 'lodash/isString';

import { RESULT_APPOINTMENT } from '../../main/appointments/mutations/appointment.mutations';

@Injectable({
    providedIn: 'root'
})
export class ResultingService {
    constructor(private apollo: Apollo) {}

    resultAppointment(appointmentId: number, results: any, useLocalClient: boolean): Observable<any> {
        return this.apollo
            .mutate({
                mutation: RESULT_APPOINTMENT,
                variables: {
                    appointment_id: appointmentId,
                    result_type: results.result.type,
                    result_id: results.result.id,
                    result_name: results.result.name,
                    result_reason_id: results.result_reason.id,
                    result_reason_name: results.result_reason.name || results.result_reason,
                    opportunity_results: results.opportunity_results.map((result) => ({
                        catalog: result.catalog_name,
                        id: result.result.id,
                        name: result.result.name,
                        reason_id: isString(result.result_reason)
                            ? result.result_reason
                            : result.result_reason?.id || null,
                        reason_name: isString(result.result_reason)
                            ? result.result_reason
                            : result.result_reason?.name || null
                    })),
                    send_email: results.send_email,
                    email_message: results.email_message,
                    emails_send_to: results.emails_send_to,
                    notes: results.notes,
                    use_local_client: useLocalClient,
                    ...(results.attachments.length && { attachments: results.attachments })
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.resultAppointment));
    }
}
