import { gql } from 'apollo-angular';

const CheckResetPasswordHash = gql`
    query resetPassword($hash: String!) {
        resetPassword(hash: $hash) {
            hash
            valid
            error
            errorCode
        }
    }
`;

export { CheckResetPasswordHash };
