import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    Validators
} from '@angular/forms';
import { Data } from '@angular/router';

import { IonDatetime } from '@ionic/angular';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { EXPIRATION_DAYS, PREDEFINED_TIMES } from '@shared/constants/expiration-days';
import { OnChangeFunctionType, OnTouchedFunctionType } from '@shared/interfaces/types';

import { pickerOptionNearestMinuteValue } from '../../../main/components/create-appointment-modal/create-appointment-modal.constants';

declare let moment: any;

@Component({
    selector: 'vendo-expires-dropdown',
    templateUrl: './expires-dropdown.component.html',
    styleUrls: ['./expires-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ExpiresDropdownComponent)
        }
    ]
})
export class ExpiresDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() set value(val: number) {
        this.writeValue(val);
    }
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
    form: UntypedFormGroup;
    isShowDatePicker = true;
    useOnlyDatePicker = true;
    expirationDaysOptions = [
        {
            label: 'Custom',
            value: 0
        },
        ...EXPIRATION_DAYS
    ];
    minStartsAt: string = moment().startOf('day').format();
    maxStartsAt: string = moment().add(365, 'd').endOf('day').format();
    @ViewChild(IonDatetime, { static: false }) popoverDatetime: IonDatetime;
    private destroy$: Subject<void> = new Subject<void>();
    onChange: OnChangeFunctionType = (_: any) => {};
    onTouched: OnTouchedFunctionType = () => {};

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.initForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    writeValue(value: number): void {
        if (value) {
            if (this.useOnlyDatePicker) {
                this.setDateValue(value);

                return;
            }

            const index: number = PREDEFINED_TIMES.indexOf(value);

            this.isShowDatePicker = index === -1;
            if (this.isShowDatePicker) {
                this.setDateValue(value);
            } else {
                this.form.get('expired_in').setValue(this.expirationDaysOptions[index + 1].value, { emitEvent: false });
            }
        }
    }

    registerOnChange(fn: OnChangeFunctionType): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: OnTouchedFunctionType): void {
        this.onTouched = fn;
    }

    async setDateTime(): Promise<void> {
        await this.popoverDatetime.confirm(true);
        this.form.get('expired_date').setValue(this.popoverDatetime.value);
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            expired_in: [null, Validators.required],
            expired_date: [this.useOnlyDatePicker ? null : this.getDefaultExpiredDate(), Validators.required]
        });

        if (!this.useOnlyDatePicker) {
            this.form
                .get('expired_in')
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value: number) => {
                    this.isShowDatePicker = value === this.expirationDaysOptions[0].value;
                    this.form.get('expired_date').setValue(this.isShowDatePicker ? this.getDefaultExpiredDate() : '');
                });
        }

        this.form.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((values: Data) => this.updateChanges(values));
    }

    private getDefaultExpiredDate(): string {
        let defaultExpiredDate = moment();

        defaultExpiredDate = defaultExpiredDate.add(1, 'hour').set({
            minute: pickerOptionNearestMinuteValue(defaultExpiredDate.minutes()),
            second: 0
        });

        return defaultExpiredDate.format();
    }

    private setDateValue(value: number): void {
        const expiredDate = moment().add(value, 's').set({ h: 23, m: 59, s: 59 });

        this.form.patchValue(
            {
                expired_in: this.expirationDaysOptions[0].value,
                expired_date: expiredDate.toISOString()
            },
            { emitEvent: false }
        );
    }

    private updateChanges(values: any): void {
        const today = moment();
        const expiredDate = moment(values.expired_date).set({ h: 23, m: 59, s: 59 });
        const expiredIn: number = this.isShowDatePicker ? expiredDate.diff(today, 's') : values.expired_in;

        this.onTouched();
        this.onChange(expiredIn);
        this.valueChange.emit(expiredIn);
    }
}
