import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { OnChangeFunctionType, OnTouchedFunctionType } from '@shared/interfaces/types';

@Component({
    selector: 'vendo-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => RatingComponent)
        }
    ]
})
export class RatingComponent implements ControlValueAccessor {
    ratingRange: number[] = new Array(10).fill(0).map((item: number, index: number) => index + 1);
    ratingControl: UntypedFormControl = this.formBuilder.control(null);
    onChange: OnChangeFunctionType = (_: any) => {};
    onTouched: OnTouchedFunctionType = () => {};

    constructor(private formBuilder: UntypedFormBuilder) {}

    writeValue(value: number): void {
        this.ratingControl.setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    rate(index: number): void {
        this.ratingControl.setValue(index + 1);
        this.onTouched();
        this.onChange(this.ratingControl.value);
    }
}
