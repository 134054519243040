import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    spinnerShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private activeRequestsCount = 0;
    private shownAt;

    constructor() {}

    changeActiveRequestsCount(countChange: number): void {
        this.activeRequestsCount += countChange;

        if (this.activeRequestsCount < 0) {
            this.activeRequestsCount = 0;
        }

        if (this.activeRequestsCount >= 1) {
            if (!this.spinnerShown.getValue()) {
                this.shownAt = new Date().getTime();
            }
            this.spinnerShown.next(true);
        } else {
            const hidAt = new Date().getTime();
            const timeShown = hidAt - this.shownAt;

            if (timeShown < 100) {
                setTimeout(() => {
                    if (!this.activeRequestsCount) {
                        this.spinnerShown.next(false);
                    }
                }, 200);
            } else {
                this.spinnerShown.next(false);
            }
            this.shownAt = null;
        }
    }

    showSpinner(): void {
        this.spinnerShown.next(true);
    }

    hideSpinner(): void {
        if (!this.activeRequestsCount) {
            this.spinnerShown.next(false);
        }
    }
}
