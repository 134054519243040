import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { IonContent, IonInfiniteScroll, ModalController } from '@ionic/angular';

import chunk from 'lodash/chunk';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import flow from 'lodash/flow';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import join from 'lodash/join';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import partialRight from 'lodash/partialRight';
import toLower from 'lodash/toLower';
import toString from 'lodash/toString';
import values from 'lodash/values';

import { BaseModal } from '@shared/components/base-modal';
import { ColSetting } from '@shared/components/radio-group-list-modal/radio-group-list-modal.interface';
import { OrderDirection } from '@shared/enums/order-direction.enum';

@Component({
    selector: 'vendo-radio-group-list-modal',
    templateUrl: './radio-group-list-modal.component.html',
    styleUrls: ['./radio-group-list-modal.component.scss']
})
export class RadioGroupListModalComponent extends BaseModal implements OnInit {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonContent) infiniteContent: IonContent;

    @Input() headerText = 'Select one item from the list';
    @Input() items: any[] = [];
    @Input() cols: ColSetting[] = [];
    @Input() showCancelButton = true;
    @Input() cancelButtonName = 'Cancel';
    @Input() confirmButtonName = 'Confirm';
    @Input() enableInfinityScroll = true;
    @Input() itemsPerPage = 20;

    searchInput: string;

    orderingColumn: string;
    orderDirection: typeof OrderDirection = OrderDirection;
    orderingOrientation: OrderDirection;

    currentPage = 1;

    visibleItems: any[] = [];

    lastPage: number;

    get selectedValue(): any {
        if (this._selectedValue) {
            return this._selectedValue;
        }

        const modelCol: ColSetting = this.cols.find(({ isModelInput }: ColSetting) => isModelInput);

        if (modelCol && modelCol.selectedValueKey) {
            const defaultItem = this.items.find((item: any) => item[modelCol.selectedValueKey]);

            return defaultItem && this.getItemValue(defaultItem, modelCol);
        }

        return (
            this._selectedValue ||
            this.getItemValue(
                this.items.find(({ IsDefault }) => IsDefault),
                modelCol
            )
        );
    }

    set selectedValue(value: any) {
        this._selectedValue = value;
    }

    private _selectedValue: any;

    private transformedItems: any[];

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit(): void {
        this.lastPage = Math.ceil(this.items.length / this.itemsPerPage);

        this.setVisibleItems();
    }

    getItemValue(item: any, col: ColSetting): string {
        if (typeof col.value === 'string') {
            return item && item[col.value];
        }

        if (typeof col.value === 'function') {
            return item && col.value(item);
        }

        return '';
    }

    setSelectedValue(item: any): void {
        const modelCol: ColSetting = this.cols.find(({ isModelInput }: ColSetting) => isModelInput);

        this.selectedValue = this.getItemValue(item, modelCol);
    }

    loadData(event): void {
        if (!this.enableInfinityScroll) {
            return;
        }

        event.target.complete();

        const nextPage = this.getNextPage();

        if (this.currentPage === nextPage) {
            event.target.disabled = true;
        }

        this.currentPage = nextPage;

        this.setVisibleItems();
    }

    confirm(): void {
        this.dismiss(this.selectedValue);
    }

    async touchOrdering(col: ColSetting): Promise<void> {
        if (!col.isAllowOrderBy) {
            return;
        }
        await this.infiniteContent.scrollToTop();

        this.currentPage = 1;

        if (this.orderingColumn !== col.label) {
            this.orderingColumn = col.label;
            this.orderingOrientation = OrderDirection.Asc;
        } else if (this.orderingOrientation === OrderDirection.Asc) {
            this.orderingOrientation = OrderDirection.Desc;
        } else {
            delete this.orderingColumn;
            delete this.orderingOrientation;
        }

        this.setVisibleItems();
    }

    async handleSearch(): Promise<void> {
        await this.infiniteContent.scrollToTop();

        this.currentPage = 1;

        this.setVisibleItems();
    }

    private setVisibleItems(): void {
        let visibleItems = this.getTransformedItems();

        visibleItems = this.filterBySearch(visibleItems);

        visibleItems = this.applyOrdering(visibleItems);

        visibleItems = this.applyPagination(visibleItems);

        this.visibleItems = visibleItems;
    }

    private getTransformedItems(): any[] {
        if (!this.transformedItems) {
            this.transformedItems = map(this.items, (item: any) => {
                const colItem = {};

                this.cols.forEach((col: ColSetting) => {
                    if (typeof col.value === 'string') {
                        colItem[col.value] = this.getItemValue(item, col);
                    }

                    colItem[col.label] = this.getItemValue(item, col);
                });

                return colItem;
            });
        }

        return this.transformedItems;
    }

    private filterBySearch(visibleItems: any[]): any[] {
        const search = toString(this.searchInput).trim().toLowerCase();

        if (search) {
            return filter(visibleItems, flow(identity, values, join, toLower, partialRight(includes, search)));
        }

        return visibleItems;
    }

    private applyOrdering(visibleItems: any[]): any[] {
        if (this.orderingColumn) {
            visibleItems = orderBy(visibleItems, [this.orderingColumn], [this.orderingOrientation]);
        }

        return visibleItems;
    }

    private applyPagination(visibleItems: any[]): any[] {
        if (this.enableInfinityScroll) {
            visibleItems = concat(...chunk(visibleItems, this.itemsPerPage).slice(0, this.currentPage));
        }

        return visibleItems;
    }

    private getNextPage(): number {
        const maxPage = this.lastPage;

        if (maxPage <= this.currentPage) {
            return this.currentPage;
        }

        return this.currentPage + 1;
    }
}
