export const OFFLINE_DEMO_RESOURCES_FIELDS = [
    'id',
    'name',
    'badge',
    'icon',
    'resource_src',
    'resource_type',
    'text_color',
    'widget_color',
    'user_id',
    'office_id',
    'categories'
];
