import { gql } from 'apollo-angular';

const SIGN_E_DOCUMENT = gql`
    mutation SignEDocument(
        $hash: String!
        $customer_signature: String
        $customer_initials: String
        $application_url: String!
        $updated_at: String
    ) {
        customerSignDocument(
            hash: $hash
            customer_signature: $customer_signature
            customer_initials: $customer_initials
            application_url: $application_url
            updated_at: $updated_at
        ) {
            document
            errors
        }
    }
`;

export { SIGN_E_DOCUMENT };
