<div class="modal-header">
    <ion-text class="title">Change Appointment Tax</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="baseForm.closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-label>Tax</ion-label>
    <div class="flex-center">
        <ion-input [formControl]="customTaxControl"
                   tqMIntegerInput="[0-9.]"
                   aria-labelledby="Tax"
                   autocomplete="off"
                   min="0"
                   max="100"
                   title=""
                   step="1"
                   inputmode="decimal"
                   type="number"></ion-input>
        <span class="suffix"> % </span>
    </div>
    <div class="errors-wrapper" *ngIf="customTaxControl.touched">
        <ion-text color="danger" class="error"
                  *ngFor="let error of getMessages(customTaxControl.errors)">
            {{error}}
        </ion-text>
    </div>
</div>

<div class="modal-footer">
    <ion-button [disabled]="customTaxControl.invalid"
                class="classic-width"
                (click)="save()">
        Save
    </ion-button>
</div>
