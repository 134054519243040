import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';

import { from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import find from 'lodash/find';
import round from 'lodash/round';

import { ImageService } from '@core/services/image.service';
import { BaseModal } from '@shared/components/base-modal';
import { DropdownPopoverComponent } from '@shared/components/dropdown-popover/dropdown-popover.component';

import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'vendo-segmentize-image',
    templateUrl: './segmentize-image.component.html',
    styleUrls: ['./segmentize-image.component.scss']
})
export class SegmentizeImageComponent extends BaseModal implements OnInit {
    @Input() imgUrl: string;
    @Input() feetPerPixel: number;
    @Input() textItems = [];
    @Input() segments = [];

    actionsHistory: any[] = [];
    drawingInProgress = false;

    activeSegment: any;
    activePoint: any;
    activeTextElement: any;
    allowTextEditing = false;
    viewBox: string;
    widthRatio = 1;
    heightRatio = 1;
    lineStrokeWidth = 1;
    circleStrokeWidth = 2;
    radius = 5;
    fontSize = 16;
    emulatedLine: any;
    legendPosition: any;
    legendItems = [];
    hideBackground = false;
    textEditPosition = '';
    tools = [
        {
            name: 'Line',
            hash: 'line',
            icon: 'timeline',
            types: [
                {
                    name: 'Eave',
                    hash: 'eave',
                    color: '#82FF9E'
                },
                {
                    name: 'Hip',
                    hash: 'hip',
                    color: '#FF1C76'
                },
                {
                    name: 'Rake Edge',
                    hash: 'rake_edge',
                    color: '#531CB3'
                },
                {
                    name: 'Ridge',
                    hash: 'ridge',
                    color: '#F9A03F'
                },
                {
                    name: 'Valley',
                    hash: 'valley',
                    color: '#A3D9FF'
                },
                {
                    name: 'Drip Edge',
                    hash: 'drip_edge',
                    color: '#036D19'
                }
            ]
        },
        {
            name: 'Text',
            hash: 'text',
            icon: 'text_fields'
        }
    ];
    selectedTool: any;

    offsetX = 0;
    offsetY = 0;

    @ViewChild('image') imageElement;
    @ViewChild('imageContainer') imageContainerElement;

    constructor(
        public modalCtrl: ModalController,
        public imageService: ImageService,
        public popoverController: PopoverController
    ) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        const image = new Image();

        image.src = this.imgUrl;

        image.onload = () => {
            this.viewBox = `0 0 ${image.width} ${image.height}`;
            setTimeout(() => {
                const imageBoundingRect = this.imageElement.nativeElement.getBoundingClientRect();

                this.widthRatio = imageBoundingRect.width / image.width;
                this.heightRatio = imageBoundingRect.height / image.height;
                const imageContainerBoundingRect = this.imageContainerElement.nativeElement.getBoundingClientRect();

                this.offsetX = imageBoundingRect.x - imageContainerBoundingRect.x;
                this.offsetY = imageBoundingRect.y - imageContainerBoundingRect.y;
                this.lineStrokeWidth = round(this.lineStrokeWidth / this.widthRatio, 0);
                this.circleStrokeWidth = round(this.circleStrokeWidth / this.widthRatio, 0);
                this.radius = round(this.radius / this.widthRatio, 0);
                this.fontSize = round(this.fontSize / this.heightRatio, 0);
                this.legendPosition = {
                    x: 0,
                    y: image.height - 100 / this.heightRatio,
                    width: 350 / this.widthRatio,
                    height: 100 / this.heightRatio,
                    fontSize: round(14 / this.heightRatio, 0)
                };

                if (this.segments?.length) {
                    this.rebuildLegend();
                }
            }, 100);
        };
    }

    textElementClicked(textElement: any, event: any, type?: string): void {
        this.selectedTool = find(this.tools, { hash: 'text' });
        this.allowTextEditing = true;
        this.setTextFieldPosition(textElement.x, textElement.y);
        this.activeTextElement = textElement;
        this.activeTextElement.type = type;

        if (type === 'measurements') {
            this.activeTextElement.oldValue = this.activeTextElement.title;
        }
    }

    toggleBackground(): void {
        this.hideBackground = !this.hideBackground;
    }

    pointClicked(segment: any, point: any): void {
        if (this.activePoint) {
            if (this.activePoint.x === point.x && this.activePoint.y === point.y) {
                this.stopDrawing();

                return;
            }

            this.drawLine(this.activePoint, point);
            this.stopDrawing();
        } else {
            this.activeSegment = segment;
            this.activePoint = point;
            this.drawingInProgress = true;
        }
    }

    async updateMeasurements(e: any): Promise<void> {
        if (this.activeTextElement.type !== 'measurements') {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: ConfirmationModalComponent,
            componentProps: {
                headerText: 'Update Measurements',
                confirmButtonName: 'Update',
                message: 'Would you like to update other measurements appropriately?'
            }
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data) {
            const ratio = this.activeTextElement.title / this.activeTextElement.oldValue;

            if (ratio) {
                this.segments.forEach((segment) => {
                    segment.lines.forEach((line) => {
                        if (line.distance?.title && line.distance.x !== this.activeTextElement.x) {
                            line.distance.title = Math.round(line.distance.title * ratio);
                        }
                    });
                });

                this.feetPerPixel = this.feetPerPixel * ratio;
            }
        }

        this.stopTextEditing();
    }

    startDrawing(event: any): void {
        if (!this.selectedTool) {
            return;
        }

        if (this.activeTextElement) {
            this.stopTextEditing();

            return;
        }

        switch (this.selectedTool.hash) {
            case 'line':
                this.drawSegment(event);
                break;
            case 'text':
                this.addTextElement(event);
                break;
        }
    }

    changeTool(tool: any, type?: any): void {
        this.selectedTool = tool;
        this.selectedTool.type = type;
    }

    async showDropdownPopover(event: Event, tool: any): Promise<any> {
        event.stopPropagation();
        const popover = await this.popoverController.create({
            component: DropdownPopoverComponent,
            event,
            translucent: true,
            mode: 'md',
            cssClass: 'dropdown-popover',
            componentProps: {
                items: tool.types
            }
        });

        await popover.present();

        return popover.onDidDismiss().then((result: any) => {
            const toolType = tool.types.find((type) => type.hash === result.data);

            this.changeTool(tool, toolType);
        });
    }

    async save(): Promise<any> {
        this.imageService
            .getImage(this.imgUrl, 'arraybuffer' as XMLHttpRequestResponseType)
            .pipe(
                tap((base64Image) =>
                    this.imageElement.nativeElement.setAttribute('xlink:href', `data:image/png;base64,${base64Image}`)
                ),
                switchMap(() => {
                    const svgElement = this.imageContainerElement.nativeElement.querySelector('.svg-wrap svg');

                    return from(this.imageService.convertSvgToAnotherType(svgElement, 'image/jpeg', 0.9));
                })
            )
            .subscribe((blob: Blob) => {
                this.dismiss({
                    image: blob,
                    text_elements: this.textItems,
                    segments: this.segments,
                    feetPerPixel: this.feetPerPixel
                });
            });
    }

    emulateLine(event: any): void {
        if (!this.drawingInProgress || !this.activePoint) {
            return;
        }

        this.emulatedLine = {
            from: this.activePoint,
            to: {
                x: event.layerX / this.widthRatio,
                y: event.layerY / this.heightRatio - this.offsetY
            }
        };
    }

    undo(): void {
        if (this.drawingInProgress) {
            this.stopDrawing();
        }

        const lastAction = this.actionsHistory.pop();

        switch (lastAction.type) {
            case 'segment_added':
                this.segments.pop();
                break;
            case 'line_added':
                this.segments.forEach((segment) => {
                    segment.lines = segment.lines.filter((line) => {
                        return !(
                            line.from.x === lastAction.item.from.x &&
                            line.from.y === lastAction.item.from.y &&
                            line.to.x === lastAction.item.to.x &&
                            line.to.y === lastAction.item.to.y
                        );
                    });
                });
                this.rebuildLegend();
                break;
            case 'points_added':
                this.segments.forEach((segment) => {
                    segment.points = segment.points.filter((point) => {
                        return !(point.x === lastAction.item.x && point.y === lastAction.item.y);
                    });
                });
                const latestHistoryItem = this.actionsHistory[this.actionsHistory.length - 1];

                if (latestHistoryItem.type === 'segment_added') {
                    this.undo();
                }
                break;
            case 'text_added':
                this.textItems = this.textItems.filter((item) => {
                    return !(item.x === lastAction.item.x && item.y === lastAction.item.y);
                });
                this.stopTextEditing();
                break;
        }
    }

    private addTextElement(event: any): void {
        const x = event.layerX / this.widthRatio;
        const y = event.layerY / this.heightRatio;

        const textItem = {
            x,
            y,
            title: 'Placeholder'
        };

        this.textItems.push(textItem);
        this.setTextFieldPosition(textItem.x, textItem.y);
        this.activeTextElement = textItem;

        this.actionsHistory.push({
            type: 'text_added',
            item: textItem
        });

        this.allowTextEditing = true;
    }

    private drawSegment(event: any): void {
        if (!this.selectedTool.type) {
            return;
        }

        const x = event.layerX / this.widthRatio;
        const y = event.layerY / this.heightRatio - this.offsetY;

        const newPoint = {
            x,
            y,
            color: this.selectedTool.type.color
        };

        if (!this.activeSegment) {
            this.activeSegment = {
                points: [newPoint],
                lines: [],
                completed: false
            };
            this.drawingInProgress = true;

            this.segments.push(this.activeSegment);

            this.actionsHistory.push({
                type: 'segment_added',
                item: this.activeSegment
            });

            this.actionsHistory.push({
                type: 'points_added',
                item: newPoint
            });
        } else {
            this.activeSegment.points.push(newPoint);
        }

        if (this.activeSegment.points.length > 1 && this.drawingInProgress) {
            this.drawLine(newPoint, this.activePoint);
            this.actionsHistory.push({
                type: 'points_added',
                item: newPoint
            });
        }

        this.activePoint = newPoint;
    }

    private drawLine(from: any, to: any): void {
        const a = from.x - to.x;
        const b = from.y - to.y;
        const newLine = {
            from,
            to,
            distance: {
                title: this.feetPerPixel ? Math.floor(Math.sqrt(a * a + b * b) * this.feetPerPixel) : 0,
                x: (from.x + to.x) / 2,
                y: (from.y + to.y) / 2
            },
            type: this.selectedTool.type
        };

        this.activeSegment.lines.push(newLine);

        this.actionsHistory.push({
            type: 'line_added',
            item: newLine
        });

        this.fillLegend(this.selectedTool.type);
    }

    private fillLegend(type: any): void {
        const existingType = this.legendItems.find((item) => item.hash === type.hash);

        if (existingType) {
            return;
        }

        const legendItemsCount = this.legendItems.length;
        let yIndex = legendItemsCount;

        if (legendItemsCount >= 6) {
            yIndex -= 6;
        } else if (legendItemsCount >= 3) {
            yIndex -= 3;
        }

        const x: number = (Math.floor(legendItemsCount / 3) * 160 + 20) / this.heightRatio;
        const y: number = this.legendPosition.y + (20 + yIndex * 30) / this.widthRatio;

        this.legendItems.push({
            ...type,
            x: x + 50 / this.widthRatio,
            y,
            x1: x,
            y1: y - 4 / this.heightRatio,
            x2: x + 40 / this.widthRatio,
            y2: y - 4 / this.heightRatio
        });
    }

    private rebuildLegend(): void {
        const uniqueLegendItemTypes = [];

        this.legendItems = [];

        this.segments.forEach((segment) => {
            segment.lines.forEach((line) => {
                if (!uniqueLegendItemTypes.includes(line.type.hash)) {
                    uniqueLegendItemTypes.push(line.type.hash);
                    this.fillLegend(line.type);
                }
            });
        });
    }

    private setTextFieldPosition(x: number, y: number): void {
        this.textEditPosition = `left: ${x * this.widthRatio - 5}px; top: ${y * this.heightRatio - 20}px`;
    }
    private stopDrawing(): void {
        this.activeSegment = null;
        this.activePoint = null;
        this.drawingInProgress = false;
        this.emulatedLine = null;
    }

    private stopTextEditing(): void {
        this.activeTextElement = null;
        this.allowTextEditing = false;
    }
}
