import { Pipe, PipeTransform } from '@angular/core';

import sumBy from 'lodash/sumBy';

@Pipe({
    name: 'sum'
})
export class SumPipe implements PipeTransform {
    transform(items: any[], sumByField: string, sumTypeField?: string, percentageFrom?: number): number {
        return sumBy(items, (item) => {
            if (item[sumTypeField] === 'percentage') {
                return (item[sumByField] / 100) * percentageFrom;
            }

            return item[sumByField];
        });
    }
}
