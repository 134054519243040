import { gql } from 'apollo-angular';

const GET_VIEW_PHOTOS_AND_OPENINGS = gql`
    query ViewOpeningsList($appointmentId: ID!) {
        appointmentOpenings(appointment_id: $appointmentId) {
            id
            view_photo_id
            name
            notes
            wcp_config_id
            wcp_item_id
            autoconfigured
            category {
                name
                category_type
                id
            }
            catalog_id
            catalog {
                name
                category_type
                id
                predefined_images {
                    appointment_type
                    category_type
                    predefined_images {
                        label
                        is_required
                    }
                }
            }
            opening_details {
                question_id
                answer
                question {
                    title
                    hash
                }
            }
        }
        viewPhotos(appointment_id: $appointmentId) {
            id
            name
            img_url
        }
    }
`;

const GET_OPENING_WITH_PHOTO = gql`
    query OpeningWithPhoto($openingId: ID!) {
        opening(opening_id: $openingId) {
            id
            name
            category_id
            catalog_id
            view_coordinates
            view_photo_id
            view_photo {
                name
                img_url
                id
            }
            opening_details {
                id
            }
        }
    }
`;

const GET_VIEW_PHOTO = gql`
    query ViewPhoto($photoId: ID!) {
        viewPhoto(photo_id: $photoId) {
            id
            name
            img_url
            openings {
                id
                name
                view_coordinates
                opening_details {
                    id
                }
            }
        }
    }
`;

const GET_VIEW_CATEGORIES = gql`
    query ViewCategories($appointmentId: ID!) {
        myViewCategories(appointment_id: $appointmentId) {
            id
            name
            category {
                id
            }
        }
    }
`;

export { GET_VIEW_PHOTOS_AND_OPENINGS, GET_VIEW_PHOTO, GET_OPENING_WITH_PHOTO, GET_VIEW_CATEGORIES };
