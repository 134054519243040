<ion-content>
    <div class="sticky">
        <ion-row>
            <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                <b>Your Packages</b>
            </ion-col>

            <ion-col *ngFor="let quote of quotes; let i = index"
                     [size]="columnSize"
                     class="ion-align-self-center ion-justify-content-center">
                <div class="package-button" (click)="openPackage(i)">{{ quote.package_name }}</div>
            </ion-col>
        </ion-row>
    </div>

    <div class="lines-wrapper">
        <div class="line-items">
            <ion-accordion-group [multiple]="true">
                <ion-row *ngIf="settings[comparisonSettingList.LINE_ITEMS].prices.length">
                    <ion-accordion toggleIconSlot="end">
                        <ion-item slot="header" class="item-background-color">
                            <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                <label>{{ settings[comparisonSettingList.LINE_ITEMS].label }}</label>
                            </ion-col>

                            <ion-col *ngFor="let price of settings[comparisonSettingList.LINE_ITEMS].prices"
                                     [size]="columnSize"
                                     class="ion-align-self-center ion-justify-content-center">
                                <label>{{ price === '-' ? price : price | customCurrency: true }}</label>
                            </ion-col>
                        </ion-item>

                        <ion-list class="list" slot="content">
                            <ion-row *ngFor="let quote of mappedQuotes">
                                <ion-accordion toggleIconSlot="end">
                                    <ion-item slot="header" class="catalog-background-color">
                                        <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                            <label><b>{{ quote.opening_name }}</b></label>
                                        </ion-col>

                                        <ion-col *ngFor="let price of quote.line_item_total_price"
                                                 [size]="columnSize"
                                                 class="ion-align-self-center ion-justify-content-center">
                                            <label>{{ (price === 'N/A' || price === '-') ? price : price | customCurrency: true }}</label>
                                        </ion-col>
                                    </ion-item>

                                    <ion-list class="list" slot="content">
                                        <ion-row class="extra-info">
                                            <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                                <label><b>Unit Price</b></label>
                                            </ion-col>

                                            <ion-col *ngFor="let price of quote.unit_price"
                                                     [size]="columnSize"
                                                     class="ion-align-self-center ion-justify-content-center">
                                                <label>{{ (price === 'N/A' || price === '-') ? price : price | customCurrency: true }}</label>
                                            </ion-col>
                                        </ion-row>

                                        <ion-row class="extra-info">
                                            <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                                <label><b>Quantity</b></label>
                                            </ion-col>

                                            <ion-col *ngFor="let quantity of quote.quantity"
                                                     [size]="columnSize"
                                                     class="ion-align-self-center ion-justify-content-center">
                                                <label>{{ quantity }}</label>
                                            </ion-col>
                                        </ion-row>

                                        <ng-container *ngFor="let question of quote.questions">
                                            <ng-container *ngIf="selectedComparisonType === selectedComparisonTypes.DetailedDisplay ||
                                                 ((selectedComparisonType === selectedComparisonTypes.DifferenceDisplay) && question.isUniq)">
                                                <ion-row class="extra-info">
                                                    <ion-col size="3"
                                                             class="ion-align-self-center ion-justify-content-center">
                                                        <label><b>{{ question.question }}</b></label>
                                                    </ion-col>

                                                    <ion-col *ngFor="let answer of question.answers"
                                                             [size]="columnSize"
                                                             class="ion-align-self-center ion-justify-content-center">
                                                        <label>{{ answer }}</label>
                                                    </ion-col>
                                                </ion-row>
                                            </ng-container>
                                        </ng-container>
                                    </ion-list>
                                </ion-accordion>
                            </ion-row>
                        </ion-list>
                    </ion-accordion>
                </ion-row>

                <ion-row *ngIf="settings[comparisonSettingList.ADDERS].total.length">
                    <ion-accordion toggleIconSlot="end">
                        <ion-item slot="header" class="item-background-color">
                            <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                <label>{{ settings[comparisonSettingList.ADDERS].label }}</label>
                            </ion-col>

                            <ion-col *ngFor="let amount of settings[comparisonSettingList.ADDERS].total"
                                     [size]="columnSize"
                                     class="ion-align-self-center ion-justify-content-center">
                                <label>{{ (amount === 'N/A' || amount === '-') ? amount : amount | customCurrency: true }}</label>
                            </ion-col>
                        </ion-item>

                        <ion-list class="list" slot="content">
                            <ion-row *ngFor="let adder of settings[comparisonSettingList.ADDERS].prices"
                                     class="extra-info">
                                <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                    <label><b>{{ adder.name }}</b></label>
                                </ion-col>

                                <ion-col *ngFor="let amount of adder.amounts"
                                         [size]="columnSize"
                                         class="ion-align-self-center ion-justify-content-center">
                                    <label>{{ (amount === 'N/A' || amount === '-') ? amount : amount | customCurrency: true }}</label>
                                </ion-col>
                            </ion-row>
                        </ion-list>
                    </ion-accordion>
                </ion-row>
            </ion-accordion-group>

            <ion-row class="package-price" *ngIf="settings[comparisonSettingList.PACKAGE_PRICE].isActive">
                <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                    <label>{{ settings[comparisonSettingList.PACKAGE_PRICE].label }}</label>
                </ion-col>

                <ion-col *ngFor="let price of settings[comparisonSettingList.PACKAGE_PRICE].prices"
                         [size]="columnSize"
                         class="ion-align-self-center ion-justify-content-center">
                    <label>{{ price | customCurrency: true }}</label>
                </ion-col>
            </ion-row>

            <ion-accordion-group
                    [multiple]="true"
                    class="delimiter"
                    *ngIf="settings[comparisonSettingList.DISCOUNT_AMOUNT].isActive &&
                     (!settings[comparisonSettingList.DISCOUNT_AMOUNT].isHideWhenZero ||
                     !settings[comparisonSettingList.DISCOUNT_AMOUNT].isAllZero)">
                <ion-row>
                    <ion-accordion toggleIconSlot="end"
                                   [disabled]="!settings[comparisonSettingList.DISCOUNT_AMOUNT].total.length">
                        <ion-item slot="header" class="item-background-color">
                            <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                <label>{{ settings[comparisonSettingList.DISCOUNT_AMOUNT].label }}</label>
                            </ion-col>

                            <ion-col *ngFor="let price of settings[comparisonSettingList.DISCOUNT_AMOUNT].prices"
                                     [size]="columnSize"
                                     class="ion-align-self-center ion-justify-content-center">
                                <label>{{ price | customCurrency: true }}</label>
                            </ion-col>
                        </ion-item>

                        <ion-list class="list" slot="content">
                            <ion-row *ngFor="let promotion of settings[comparisonSettingList.DISCOUNT_AMOUNT].total"
                                     class="extra-info">
                                <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                                    <label><b>{{ promotion.name }}</b></label>
                                </ion-col>

                                <ng-container *ngFor="let discount of promotion.discount_amount">
                                    <ion-col [size]="columnSize"
                                             class="ion-align-self-center ion-justify-content-center">
                                        <label>{{ discount === 'N/A' ? discount : discount | customCurrency: true }}</label>
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                        </ion-list>
                    </ion-accordion>
                </ion-row>
            </ion-accordion-group>
            <ng-container [ngTemplateOutlet]="packageTemplate"
                          [ngTemplateOutletContext]="{
                            entity: settings[comparisonSettingList.SUB_FINAL_PRICE],
                            isShow: settings[comparisonSettingList.SUB_FINAL_PRICE].isActive
                          }">
            </ng-container>

            <ng-container [ngTemplateOutlet]="packageTemplate"
                          [ngTemplateOutletContext]="{
                            entity: settings[comparisonSettingList.TAX],
                            isShow: settings[comparisonSettingList.TAX].isActive &&
                             (!settings[comparisonSettingList.TAX].isHideWhenZero ||
                             !settings[comparisonSettingList.TAX].isAllZero),
                            class: 'gray delimiter'
                          }">
            </ng-container>

            <ng-container [ngTemplateOutlet]="packageTemplate"
                          [ngTemplateOutletContext]="{
                            entity: settings[comparisonSettingList.DEPOSIT],
                            isShow: settings[comparisonSettingList.DEPOSIT].isActive &&
                             (!settings[comparisonSettingList.DEPOSIT].isHideWhenZero ||
                              !settings[comparisonSettingList.DEPOSIT].isAllZero),
                            class: 'gray'
                          }">
            </ng-container>

            <ng-container [ngTemplateOutlet]="packageTemplate"
                          [ngTemplateOutletContext]="{
                            entity: settings[comparisonSettingList.BALANCE_WITH_DEPOSIT],
                            isShow: settings[comparisonSettingList.BALANCE_WITH_DEPOSIT].isActive,
                            class: 'gray'
                          }">
            </ng-container>

            <ng-container [ngTemplateOutlet]="packageTemplate"
                          [ngTemplateOutletContext]="{
                            entity: settings[comparisonSettingList.BALANCE_WITH_FINANCING],
                            isShow: settings[comparisonSettingList.BALANCE_WITH_FINANCING].isActive &&
                            !settings[comparisonSettingList.BALANCE_WITH_FINANCING].isAllNA,
                            class: 'gray'
                          }">
            </ng-container>
        </div>

        <div class="financing-term delimiter">
            <ng-container [ngTemplateOutlet]="financingTermTemplate"
                          [ngTemplateOutletContext]="{financing: settings[comparisonSettingList.SHORT_TERM_FINANCING]}">
            </ng-container>

            <ng-container [ngTemplateOutlet]="financingTermTemplate"
                          [ngTemplateOutletContext]="{financing: settings[comparisonSettingList.LONG_TERM_FINANCING]}">
            </ng-container>
        </div>
    </div>
</ion-content>

<ng-template #packageTemplate let-entity="entity" let-isShow="isShow" let-class="class">
    <ion-row class="package-price {{class}}"
             *ngIf="isShow">
        <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
            {{ entity.label }}
        </ion-col>

        <ion-col *ngFor="let price of entity.prices"
                 [size]="columnSize"
                 class="ion-align-self-center ion-justify-content-center">
            <label>{{ price === 'N/A' ? price : price | customCurrency: true }}</label>
        </ion-col>
    </ion-row>
</ng-template>

<ng-template #financingTermTemplate let-financing="financing">
    <ion-accordion-group [multiple]="true" *ngIf="financing.prices.length">
        <ion-row>
            <ion-accordion toggleIconSlot="end">
                <ion-item slot="header" class="item-background-color">
                    <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                        <label>{{ financing.label }}</label>
                    </ion-col>

                    <ion-col *ngFor="let quote of quotes; let i = index"
                             [size]="columnSize"
                             class="ion-align-self-center ion-justify-content-center">
                        <label>{{ quote.package_name }}</label>
                    </ion-col>
                </ion-item>

                <ion-list class="list" slot="content">
                    <ion-row *ngFor="let price of financing.prices" class="extra-info">
                        <ion-col size="3" class="ion-align-self-center ion-justify-content-center">
                            <label><b>{{ price.label }}</b></label>
                        </ion-col>

                        <ion-col *ngFor="let value of price.value"
                                 [size]="columnSize"
                                 class="ion-align-self-center ion-justify-content-center">
                            <label>{{ value.monthly_payment === 'N/A' ? value.monthly_payment : value.monthly_payment | customCurrency: true }}</label>
                        </ion-col>
                    </ion-row>
                </ion-list>
            </ion-accordion>
        </ion-row>
    </ion-accordion-group>
</ng-template>
