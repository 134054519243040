import { OFFLINE_APPOINTMENT_FIELDS } from '@core/services/offline/offline-tables/appointment-fields';
import { OFFLINE_CATEGORIES_FIELDS } from '@core/services/offline/offline-tables/categories-fields';
import { OFFLINE_COMMON_FIELDS } from '@core/services/offline/offline-tables/common-fields';
import { OFFLINE_DEMO_RESOURCES_FIELDS } from '@core/services/offline/offline-tables/demo-resources-fields';
import { OFFLINE_NEEDS_ASSESSMENT_FIELDS } from '@core/services/offline/offline-tables/needs-assessment-fields';
import { OFFLINE_USER_PREFERENCES_FIELDS } from '@core/services/offline/offline-tables/user-preferences-fields';

// MODIFY THIS IF OFFLINE TABLES STRUCTURE NEED TO BE CHANGED
export const OFFLINE_TABLES_VERSION = '0.0.19';

export const OFFLINE_TABLES = [
    {
        table_name: 'Appointments',
        fields: OFFLINE_APPOINTMENT_FIELDS,
        primary_key: 'id',
        column_types: {
            assessment: 'JSON',
            deeplinks: 'JSON',
            openings: 'JSON',
            customer: 'JSON',
            house: 'JSON',
            second_customer: 'JSON',
            second_house: 'JSON',
            products_interests: 'JSON',
            created: 'INTEGER DEFAULT 0 NOT NULL',
            updated: 'INTEGER DEFAULT 0 NOT NULL',
            deleted: 'INTEGER DEFAULT 0 NOT NULL',
            schedule_time_timestamp: 'INTEGER'
        }
    },
    {
        table_name: 'NeedsAssessments',
        fields: OFFLINE_NEEDS_ASSESSMENT_FIELDS,
        primary_key: 'user_id',
        column_types: {
            needs_assessment: 'JSON'
        }
    },
    {
        table_name: 'Common',
        fields: OFFLINE_COMMON_FIELDS,
        primary_key: 'id',
        column_types: {
            value: 'JSON'
        }
    },
    {
        table_name: 'OfflineSyncs',
        fields: ['user_id', 'office_id', 'synchronized_at', 'last_schedule_time'],
        primary_key: 'user_id'
    },
    {
        table_name: 'Categories',
        fields: OFFLINE_CATEGORIES_FIELDS,
        primary_key: 'id',
        column_types: {
            category: 'JSON',
            opening_conditions: 'JSON',
            predefined_images: 'JSON'
        }
    },
    {
        table_name: 'UserPreferences',
        fields: OFFLINE_USER_PREFERENCES_FIELDS,
        primary_key: 'user_id',
        column_types: {
            preferences: 'JSON'
        }
    },
    {
        table_name: 'DemoResources',
        fields: OFFLINE_DEMO_RESOURCES_FIELDS,
        primary_key: 'id',
        column_types: {
            badge: 'JSON',
            categories: 'JSON'
        }
    }
];
