import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { TagInputComponent } from 'ngx-chips';

import { ModalController } from '@ionic/angular';

import { EMPTY, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EmailService } from '@core/services/email.service';
import { ToastService } from '@core/services/toast.service';
import { BaseModal } from '@shared/components/base-modal';
import { AppointmentWonStatuses } from '@shared/constants/appointment-statuses';

@Component({
    selector: 'vendo-email-resend-modal',
    templateUrl: './email-resend-modal.component.html',
    styleUrls: ['./email-resend-modal.component.scss']
})
export class EmailResendModalComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() appointmentId: any;
    @Input() sentEmails: any;
    @Input() familyName: any;

    @ViewChild(TagInputComponent) emailsInput;
    form: UntypedFormGroup;
    sentEmail: any;
    sentEmailDocuments: any[] = [];
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        public modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder,
        private emailService: EmailService,
        private toastService: ToastService
    ) {
        super(modalCtrl);
    }

    async ngOnInit(): Promise<void> {
        this.initForm();

        const sentEmails = this.sentEmails.reverse();

        this.sentEmail = sentEmails.find((email) => AppointmentWonStatuses.includes(email.result_type));

        if (this.sentEmail) {
            this.form.get('emails_send_to').setValue(this.sentEmail.recipients);
            this.form.get('email_message').setValue(this.sentEmail.message);

            this.sentEmailDocuments = ['email_attachments', 'package_documents', 'signed_documents'].reduce(
                (acc, item) => acc.concat(this.sentEmail[item] || []),
                []
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onEmailAdd(tag: any): Observable<string> {
        const tagString = tag?.value || tag;
        const trimmedTag: string = tagString ? tagString.trim() : tagString;

        if (trimmedTag && this.emailService.isEmailValid(trimmedTag)) {
            return of(trimmedTag);
        } else {
            this.emailsInput.inputTextValue = '';

            return EMPTY;
        }
    }

    async sendEmail(): Promise<void> {
        if (this.form.invalid || !this.sentEmail.id) {
            return;
        }

        const formData = this.form.getRawValue();

        this.emailService
            .resendEmail(this.appointmentId, this.sentEmail.id, formData.emails_send_to, formData.email_message, false)
            .pipe(
                tap(async (result) => {
                    if (result.send_mail_details) {
                        this.emailService.openClient(result.send_mail_details);
                    } else {
                        await this.toastService.showMessage('Email has been successfully sent');
                        this.closeModal();
                    }
                })
            )
            .subscribe();
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            email_message: ['', Validators.required],
            emails_send_to: [[], Validators.required]
        });
    }
}
