export const FEATURES = {
    INSPECTION_REPORT: 'inspection_report',
    MEASUREMENT_TOOL: 'measurement_tool',
    BOSCH_MEASUREMENT_TOOL: 'bosch_measurement_tool',
    OFFICE_LOGO_PRIORITIZATION: 'office_logo_prioritization',
    VIEW_FLOW: 'view_flow',
    SINGLE_WCP_ACCOUNT_PER_OFFICE: 'single_wcp_account_per_office',
    WCP_PREFERENCES: 'wcp_preferences',
    OFFLINE_MODE: 'offline_mode',
    PRICE_CONDITIONING: 'price_conditioning',
    PARADIGM_FINANCE: 'paradigm_finance',
    PARADIGM_FINANCE_SANDBOX: 'paradigm_finance_sandbox',
    PARADIGM_QUOTE_HEADERS: 'paradigm_quote_headers',
    OMNI_CLIENT_MODE: 'paradigm_wcp_settings',
    SECOND_MEASURE: 'second_measure'
};
