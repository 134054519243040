export const IGNORED_ROUTES: string[] = [
    '/login',
    '/forgot-password',
    '/reset-password',
    '/logout',
    'redirect-callback',
    '/e-document/v2',
    '/offer-request',
    '/deeplink'
];

export const DISALLOW_EDIT_APPOINTMENTS_ROUTES: string[] = ['/product-selection', '/customize'];
