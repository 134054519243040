import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ResetPasswordHashModel } from '../models/reset-password-hash-model';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class CheckResetPasswordResolver implements Resolve<ResetPasswordHashModel> {
    constructor(private authService: AuthService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResetPasswordHashModel> {
        return this.authService.checkResetPasswordHash(route.params.token);
    }
}
