<div class="modal-header">
  <ion-text class="title">{{drawingData.description}}</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>
<div class="modal-body">
  <div class="picture">
    <ion-img [src]="drawingData.image_url"></ion-img>
  </div>
  <div class="answers">
    <div *ngFor="let step of drawingData.answers; let i = index" class="step"
         [class.before-last]="i === drawingData.answers.length - 2 && drawingData.answers.length % 2 === 0">
      {{step.stepName}}
      <ion-text *ngFor="let item of step.answers">
        {{item.question}}: {{item.answer}}
      </ion-text>
    </div>
  </div>
</div>
