import { Component, Input, OnInit } from '@angular/core';

import { ModalController, AlertController } from '@ionic/angular';

import { finalize } from 'rxjs/operators';

import { DictionaryService } from '@core/services/dictionary.service';
import { BaseModal } from '@shared/components/base-modal';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { Dictionary } from '@shared/interfaces/dictionary';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

@Component({
    selector: 'vendo-copy-opening-within-quote-modal',
    templateUrl: './copy-opening-within-quote-modal.component.html',
    styleUrls: ['./copy-opening-within-quote-modal.component.scss']
})
export class CopyOpeningWithinQuoteModalComponent extends BaseModal implements OnInit {
    @Input() openings: any;
    @Input() copyingOpening: any;
    @Input() quoteId: number;

    copyToList = [];
    canDoCOpy = false;
    reachedLimit = false;
    includeAdders = true;
    includeAccessories = true;
    adderNameSetting: Dictionary;

    constructor(
        modalCtrl: ModalController,
        private configureService: ConfigureService,
        public alertController: AlertController,
        private dictionaryService: DictionaryService
    ) {
        super(modalCtrl);
    }

    async ngOnInit(): Promise<void> {
        this.adderNameSetting = await this.dictionaryService.getDictionaryName(DictionaryType.Adder);
        this.includeAccessories = this.copyingOpening?.category?.catalog_type !== 'wcp';
        this.copyToList.push({
            id: null,
            name: 'Create a new opening with the same configurations',
            isChecked: false,
            qty: 1
        });

        this.openings.forEach((opening) => {
            if (opening.id !== this.copyingOpening.id) {
                this.copyToList.push({
                    id: opening.id,
                    name: opening.name,
                    isChecked: false,
                    qty: 1
                });
            }
        });
    }

    checkHandler(): void {
        let checkedCount = 0;

        this.copyToList.forEach((item) => {
            if (item.isChecked) {
                checkedCount++;
            }
        });

        this.canDoCOpy = checkedCount !== 0;

        this.reachedLimit = checkedCount >= 5;
    }

    async copy(): Promise<void> {
        const copyToOpeningIds = [];
        let newOpeningsCount = 0;

        this.copyToList.forEach((item) => {
            if (!item.isChecked) {
                return;
            }

            if (item.id === null) {
                newOpeningsCount = item.qty;

                return;
            }

            copyToOpeningIds.push(item.id);
        });

        const totalCopyCount = copyToOpeningIds.length + (newOpeningsCount !== 0 ? 1 : 0);

        if (totalCopyCount > 2) {
            let upToTime = '';
            const seconds = totalCopyCount * 10; // one item takes about 10 seconds

            if (Math.trunc(seconds / 60) !== 0) {
                let minutes = Math.trunc(seconds / 60);

                if (seconds % 60 >= 30) {
                    minutes += 1;
                }

                upToTime = `${minutes} minute${minutes > 1 ? 's' : ''}`;
            } else {
                upToTime = `${seconds} seconds`;
            }

            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Confirm your action',
                message: `Copying may take up to ${upToTime}`,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {}
                    },
                    {
                        text: 'Proceed',
                        handler: () => {
                            this.doCopyRequest(copyToOpeningIds, newOpeningsCount);
                        }
                    }
                ]
            });

            await alert.present();
        } else {
            this.doCopyRequest(copyToOpeningIds, newOpeningsCount);
        }
    }

    private doCopyRequest(copyToOpeningIds, newOpeningsCount): void {
        this.configureService
            .copyOpeningWithinQuote(
                this.quoteId,
                this.copyingOpening.id,
                copyToOpeningIds,
                newOpeningsCount,
                this.includeAdders,
                this.includeAccessories
            )
            .pipe(finalize(() => this.dismiss(true)))
            .subscribe();
    }
}
