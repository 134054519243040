import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { createFragmentRegistry } from '@apollo/client/cache';
import { InMemoryCache, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import { extractFiles } from 'extract-files';
import { createClient } from 'graphql-ws';

import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import get from 'lodash/get';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { GraphqlHelperService } from '@core/services/graphql-helper.service';

import { environment } from '../../../../environments/environment';
import { MeFragment } from '../../../components/auth/mutations/auth.mutations';

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none'
    },
    includeExtensions: true
};

export function createApollo(
    httpBatchLink: HttpBatchLink,
    httpLink: HttpLink,
    graphqlHelperService: GraphqlHelperService,
    deviceHelperService: DeviceHelperService,
    platform: Platform,
    storage: Storage
) {
    const http = httpLink.create({
        uri(operation) {
            const silentMode = get(operation.getContext(), 'extensions.background');

            return silentMode ? '/graphql?background=true' : '/graphql';
        },
        headers: new HttpHeaders({
            'Apollo-Require-Preflight': 'true'
        }),
        extractFiles
    });

    const ws = new GraphQLWsLink(
        createClient({
            url: environment.wsUrl,
            connectionParams: async () => {
                await platform.ready();
                const { installedVersion, actualVersion } = await graphqlHelperService.getApplicationVersion();
                const token = await storage.get('token');

                return {
                    'x-app-version': installedVersion || actualVersion,
                    'x-live-updated-version': deviceHelperService.isWeb
                        ? 'mobile-latest'
                        : actualVersion || installedVersion,
                    'x-token': token
                };
            }
        })
    );

    const link = split(
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as any;

            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        ws,
        http
    );

    return {
        link,
        cache: new InMemoryCache({
            fragments: createFragmentRegistry(MeFragment)
        }),
        defaultOptions
    };
}

@NgModule({
    exports: [HttpClientModule, ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpBatchLink, HttpLink, GraphqlHelperService, DeviceHelperService, Platform, Storage]
        }
    ]
})
export class GraphQLModule {}
