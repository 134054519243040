<div class="provia-form">
    <div class="logged-as" *ngIf="usedRemember">
        <div class="name">You are logged in as <b>{{ form.get('user_name').value }}</b></div>
        <div class="logout">
            <span (click)="useOtherAccount()">Use Different Account</span>
        </div>
    </div>

    <form [formGroup]="form">
        <ion-content [class.after-logged-as]="usedRemember">
            <ng-container *ngIf="!usedRemember">
                <div class="field-block">
                    <ion-label class="required">{{ userNameLabel }}</ion-label>
                    <ion-input type="text"
                               formControlName="user_name"
                               required
                               [placeholder]="userNameLabel"
                               autocomplete="off"></ion-input>
                    <div class="errors-wrapper" *ngIf="form?.get('user_name').touched">
                        <ion-text color="danger"
                                  class="error"
                                  *ngFor="let error of getMessages(form.get('user_name').errors)">
                            {{ error }}
                        </ion-text>
                    </div>
                </div>

                <div class="field-block">
                    <ion-label class="required">Password</ion-label>
                    <ion-input type="password"
                               formControlName="password"
                               required
                               placeholder="Password"
                               autocomplete="off"></ion-input>
                    <div class="errors-wrapper" *ngIf="form?.get('password').touched">
                        <ion-text color="danger"
                                  class="error"
                                  *ngFor="let error of getMessages(form.get('password').errors)">
                            {{ error }}
                        </ion-text>
                    </div>
                </div>
            </ng-container>

            <div class="field-block">
                <ion-label>Order #</ion-label>
                <ion-input #orderNumberInput
                           type="text"
                           formControlName="order_number"
                           placeholder="Order #"
                           autocomplete="off"></ion-input>
            </div>

            <div class="field-block">
                <ion-label>PO #</ion-label>
                <ion-input type="text"
                           formControlName="po_number"
                           placeholder="PO #"
                           autocomplete="off"></ion-input>
            </div>

            <div class="field-block">
                <ion-label>Job Name</ion-label>
                <ion-input type="text"
                           formControlName="job_name"
                           placeholder="Job Name"
                           autocomplete="off"></ion-input>
            </div>
        </ion-content>

        <div [class]="'actions ' + (!usedRemember ? 'with-remember' : '')">
            <ion-item class="remember" *ngIf="!usedRemember">
                <ion-checkbox mode="md"
                              color="dark"
                              formControlName="remember"></ion-checkbox>
                <ion-label>Remember me</ion-label>
            </ion-item>
            <ion-button class="classic-width btn--primary"
                        (click)="isShowSearchButton ? submit() : pickOrder()">
                {{ isShowSearchButton ? 'Search' : 'Import' }}
            </ion-button>
        </div>
    </form>
</div>
