import { AppointmentStatusForSearch } from '@shared/enums/appointment-status-for-search.enum';

export const AppointmentSalesStatuses = [
    AppointmentStatusForSearch.Won,
    AppointmentStatusForSearch.Lost,
    AppointmentStatusForSearch.Pending
];

export const AppointmentSecondMeasureStatuses = [
    AppointmentStatusForSearch.NoFollowUp,
    AppointmentStatusForSearch.Rescind,
    AppointmentStatusForSearch.FollowUp
];

export const AppointmentChangeOrderStatuses = [
    AppointmentStatusForSearch.Accepted,
    AppointmentStatusForSearch.Declined,
    AppointmentStatusForSearch.Open
];

export const AppointmentLostStatuses = [
    AppointmentStatusForSearch.Lost,
    AppointmentStatusForSearch.Rescind,
    AppointmentStatusForSearch.Declined
];

export const AppointmentPendingStatuses = [
    AppointmentStatusForSearch.Pending,
    AppointmentStatusForSearch.FollowUp,
    AppointmentStatusForSearch.Open
];

export const AppointmentWonStatuses = [
    AppointmentStatusForSearch.Won,
    AppointmentStatusForSearch.NoFollowUp,
    AppointmentStatusForSearch.Accepted
];

export const AppointmentResultStatuses = [
    ...AppointmentSalesStatuses,
    ...AppointmentSecondMeasureStatuses,
    ...AppointmentChangeOrderStatuses,
    AppointmentStatusForSearch.Unresolved
];
