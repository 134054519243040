import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalController } from '@ionic/angular';

import cloneDeep from 'lodash/cloneDeep';

import { SelectDocumentsModalComponent } from '@shared/components';

@Component({
    selector: 'vendo-select-documents-input',
    templateUrl: './select-documents-input.component.html',
    styleUrls: ['./select-documents-input.component.scss']
})
export class SelectDocumentsInputComponent {
    @Input() countOfSelectedDocuments: number;
    @Input() documents = [];
    @Input() placeholder = 'No documents selected';
    @Input() label = 'Documents for homeowner';

    @Output() selectedDocuments = new EventEmitter<any[]>();

    constructor(public modalController: ModalController) {}

    async showDocuments(): Promise<void> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            backdropDismiss: false,
            component: SelectDocumentsModalComponent,
            componentProps: {
                documents: cloneDeep(this.documents)
            },
            cssClass: 'result-appointment-modal'
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {
            this.selectedDocuments.emit(data);
        }
    }
}
