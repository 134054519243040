import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ApproveApprovalRequest,
    CancelApprovalRequest,
    CloseApprovalRequest,
    DeclineApprovalRequest,
    SendApprovalRequest
} from '@core/mutations/request-approval.mutations';
import { GetApprovalRequestInfo, GetApprovalRequests } from '@core/queries/request-approval.queries';
import { ApprovalRequest, ApprovalRequestInfo } from '@shared/interfaces/approval-request';
import { QuoteLineItem } from '@shared/interfaces/quote';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestApprovalService {
    constructor(private apollo: Apollo) {}

    getApprovalRequests(relationIds): Observable<ApprovalRequest[]> {
        return this.apollo
            .query({
                query: GetApprovalRequests,
                variables: {
                    type: 'discount_limit',
                    relationIds
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.approvalRequests));
    }

    getApprovalRequestInfo(hash: string, auth_token?: string): Observable<ApprovalRequestInfo> {
        return this.apollo
            .query({
                query: GetApprovalRequestInfo,
                variables: {
                    hash,
                    auth_token
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    if (res.data.approvalRequestInfo.data?.price) {
                        res.data.approvalRequestInfo.data.price.line_items =
                            res.data.approvalRequestInfo.data.price.line_items.filter(
                                (item: QuoteLineItem) => !item.excluded
                            );
                    }

                    return res.data.approvalRequestInfo;
                })
            );
    }

    sendApprovalRequest(description: string, relation_id): Observable<ApprovalRequest> {
        return this.apollo
            .mutate({
                mutation: SendApprovalRequest,
                variables: {
                    type: 'discount_limit',
                    application_url: `${environment.siteUrl}/offer-request/`,
                    description,
                    relation_id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.sendApprovalRequest));
    }

    approveApprovalRequest(hash: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: ApproveApprovalRequest,
                variables: {
                    hash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.approveApprovalRequest));
    }

    declineApprovalRequest(hash: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: DeclineApprovalRequest,
                variables: {
                    hash
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.declineApprovalRequest));
    }

    cancelApprovalRequest(id: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: CancelApprovalRequest,
                variables: {
                    id
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.cancelApprovalRequest));
    }

    closeApprovalRequest(id: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: CloseApprovalRequest,
                variables: {
                    id
                },
                context: {
                    extensions: {
                        background: true
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.closeApprovalRequest));
    }
}
