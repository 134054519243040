import { gql } from 'apollo-angular';

const GetAppleStoreLastVersion = gql`
    query AppleStoreLastVersion($envVersion: AppEnvironmentTypeEnum!) {
        appleStoreLastVersion(envVersion: $envVersion) {
            release_version
            apple_store_url
            testflight_url
        }
    }
`;
const GetGooglePlayLastVersion = gql`
    query GooglePlayStoreLastVersion($envVersion: AppEnvironmentTypeEnum!) {
        googlePlayStoreLastVersion(envVersion: $envVersion) {
            release_version
            google_play_url
        }
    }
`;

export { GetAppleStoreLastVersion, GetGooglePlayLastVersion };
