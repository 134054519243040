<ion-grid>
    <div class="energy-details" *ngIf="energyDetailCategories.length">
        <ion-text id="energy-details-link" (click)="navigateToEnergyDetailsPage()">Energy Details</ion-text>
    </div>

    <ng-container *ngFor="let item of pricePresentationSettings; let k = index">
        <ng-container *ngIf="item.is_active && (
                                                item.hash === quotePricePresentationFieldTypes.PackagePrice
                                                || (item.hash === quotePricePresentationFieldTypes.DiscountAmount && selectedPromotionIds?.length && (!item.fields.is_zero_then_hidden || quote.final_discount_amount > 0))
                                                || (item.hash === quotePricePresentationFieldTypes.SubTotal && selectedPromotionIds?.length && quote.final_discount_amount > 0)
                                                || (item.hash === quotePricePresentationFieldTypes.Tax && (!item.fields.is_zero_then_hidden || quote.tax))
                                                || (item.hash === quotePricePresentationFieldTypes.Deposit && (!item.fields.is_zero_then_hidden || quote.deposit))
                                                || (item.hash === quotePricePresentationFieldTypes.Balance && (monthlyPayment === null || item.fields.is_always_display_balance))
                                                || (item.hash === quotePricePresentationFieldTypes.BalanceWithFinancingOption && monthlyPayment !== null)
                                            )">
            <ion-row class="row-item"
                     [ngClass]="{
                                     'total-promotion': item.hash === quotePricePresentationFieldTypes.DiscountAmount,
                                     'pointer': item.hash === quotePricePresentationFieldTypes.DiscountAmount && isDisplayPromosInModal,
                                     'highlight-row': item.hash === quotePricePresentationFieldTypes.DiscountAmount && item.fields.is_highlight_value,
                                     'bold-text': (item.fields.is_bold  && (item.hash !== quotePricePresentationFieldTypes.Balance || monthlyPayment === null)) || item.fields.is_bold  && (item.hash === quotePricePresentationFieldTypes.Balance && !isHideFinancingOptions)
                                 }"
                     (click)="item.hash === quotePricePresentationFieldTypes.DiscountAmount && openPromotionsSummary(item.fields[item.hash])">
                <ion-col [size]="item.hash === quotePricePresentationFieldTypes.DiscountAmount || item.hash === quotePricePresentationFieldTypes.BalanceWithFinancingOption ? 7 : 8">
                    <ng-container [ngSwitch]="item.hash">
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.BalanceWithFinancingOption">
                            <ng-container *ngIf="isHideFinancingOptions">
                                <span class="label">
                                    {{ item.fields[item.hash] }}
                                </span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.DiscountAmount">
                            {{ item.fields[item.hash] }}
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <span class="label">
                                {{ item.fields[item.hash === quotePricePresentationFieldTypes.Balance && quote.deposit ? quotePricePresentationFieldTypes.BalanceWithDeposit : item.hash] }}
                            </span>
                        </ng-container>
                    </ng-container>
                    <ion-icon *ngIf="item.hash === quotePricePresentationFieldTypes.DiscountAmount && isDisplayPromosInModal"
                              class="info-icon"
                              name="information-circle-outline"></ion-icon>
                </ion-col>

                <ion-col class="value"
                         [class.line-through]="isChangeOrderEnabled && quote.changes && item.hash === quotePricePresentationFieldTypes.PackagePrice"
                         [size]="item.hash === quotePricePresentationFieldTypes.DiscountAmount || item.hash === quotePricePresentationFieldTypes.BalanceWithFinancingOption ? 5 : 4">
                    <ng-container *ngIf="item.hash === quotePricePresentationFieldTypes.Deposit && quote.deposit > 0">
                        -
                    </ng-container>
                    <ng-container [ngSwitch]="item.hash">
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.PackagePrice">
                            {{ (isChangeOrderEnabled && quote.changes) ? quote.changes.sub_total_price.old : (quote.sub_total_price | customCurrency: true) }}
                        </ng-container>
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.DiscountAmount">
                            {{ totalPromotion | customCurrency: true }}
                        </ng-container>
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.SubTotal">
                            {{ quote.sub_final_price | customCurrency: true }}
                        </ng-container>
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.Tax">
                            {{ quote.tax | customCurrency: true }}
                        </ng-container>
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.Deposit">
                            {{ quote.deposit | customCurrency: true }}
                        </ng-container>
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.BalanceWithFinancingOption">
                            <ng-container *ngIf="isHideFinancingOptions">
                                {{
                                    selectedFinancingOption?.id === paradigmFinanceOfferId
                                        ? 'Paradigm Finance'
                                        : (monthlyPayment | customCurrency: true) + '/mo'
                                }}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="quotePricePresentationFieldTypes.Balance">
                            {{ balance | customCurrency: true }}
                        </ng-container>
                    </ng-container>
                </ion-col>

                <ion-col size="12"
                         *ngIf="item.hash === quotePricePresentationFieldTypes.DiscountAmount && !isDisplayPromosInModal">
                    <ion-row *ngFor="let discount of quote.discount_details; let i = index" class="modal-info">
                        <ion-col size="7">
                            {{ discount.name }} Savings
                        </ion-col>
                        <ion-col size="5" class="ion-text-right">
                            -{{ discount.discount_amount | customCurrency: true }}
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="isChangeOrderEnabled && quote.changes && item.hash === quotePricePresentationFieldTypes.PackagePrice"
                     class="row-item"
                     [ngClass]="{
                                    'highlight-row': item.fields.is_highlight_value,
                                    'bold-text': item.fields.is_bold
                                }">
                <ion-col [size]="8">
                    <span class="label">
                        Updated {{ item.fields[item.hash] }}
                    </span>
                </ion-col>
                <ion-col class="value" [size]="4">
                    {{ quote.sub_total_price | customCurrency: true }}
                </ion-col>
            </ion-row>
        </ng-container>
    </ng-container>
</ion-grid>
