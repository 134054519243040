import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import some from 'lodash/some';
import sortBy from 'lodash/sortBy';

import {
    ANSWER_CONFIGURATOR_QUESTION,
    BULK_EDIT_OPENING_QUANTITY,
    CHANGE_LINE_ADDER_QUANTITY,
    CHANGE_PROJECT_ADDER_QUANTITY,
    COMPLETE_CONFIGURATION_STEP,
    COPY_OPENING_WITHIN_QUOTE,
    CREATE_ADDER,
    CREATE_CONFIGURATOR_SESSION,
    CREATE_EMPTY_OPENING,
    DELETE_LINE_ITEM,
    EDIT_ACCESSORY_QUANTITY,
    EDIT_ADDER,
    EDIT_LINE_ITEM,
    EDIT_OPENING_QUANTITY,
    FETCH_PRODUCT_SELECTION_OPTIONS,
    FILL_TAKEOFF_ANSWERS,
    FINISH_CONFIGURATION,
    MULTIPLE_CONFIGURATION_ADDERS,
    SAVE_CONFIGURATIONS_ACCESSORIES,
    SAVE_CONFIGURATIONS_ADDERS,
    SAVE_CONFIGURATIONS_NOTES
} from '../mutations/configure.mutations';
import {
    GET_AVAILABLE_ACCESSORIES,
    GET_AVAILABLE_ADDERS,
    GET_CONFIG,
    GET_CONFIG_WITH_OPENINGS,
    GET_CONFIGURATION_PRICE,
    GET_QUESTIONS,
    GET_SPECIFIC_DRAWING,
    GET_STEPS_QUESTIONS,
    GET_USED_ACCESSORIES,
    GET_USED_ADDERS
} from '../queries/opening.queries';
import { FeaturesService } from '@core/services/features.service';
import { StepsService } from '@core/services/steps.service';
import { FEATURES } from '@shared/constants/features';
import { ConfigurationType } from '@shared/enums/configuration-type.enum';
import { SaleStepType } from '@shared/enums/sale-step-type.enum';
import { FinishConfiguration } from '@shared/interfaces/finish-configuration';
import { ProductSelection, ProductSelectionPagination } from '@shared/interfaces/product-selection';

import { UPDATE_OPENING_NAME } from '../../takeoff/mutations/opening.mutations';

@Injectable({
    providedIn: 'root'
})
export class ConfigureService {
    constructor(private apollo: Apollo, private featuresService: FeaturesService, private stepsService: StepsService) {}

    completeStep(appointmentId: number | string, presentationType: ConfigurationType): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: COMPLETE_CONFIGURATION_STEP,
                variables: {
                    appointmentId,
                    presentationType
                }
            })
            .pipe(
                switchMap((resp: ApolloQueryResult<any>) =>
                    resp.data.finishConfigureStep
                        ? this.stepsService.completeStep(appointmentId, SaleStepType.CONFIGURE)
                        : of(false)
                )
            );
    }

    fillTakeoffAnswers(quoteId: string, openingId: string, answers: any[]): Observable<any> {
        return this.apollo.mutate({
            mutation: FILL_TAKEOFF_ANSWERS,
            variables: {
                quoteId,
                openingId,
                answers
            }
        });
    }

    getConfigurationPrice(quoteId: number, openingId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_CONFIGURATION_PRICE,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.configuratorPrice));
    }

    getConfiguratorWithOpening(quoteId: number, openingId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_CONFIG_WITH_OPENINGS,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const resData = res.data.configurator;

                    if (resData?.ViewDrawing?.DrawingUrl && this.featuresService.hasFeature(FEATURES.VIEW_FLOW)) {
                        resData.drawings.push({
                            DrawingID: 'view',
                            DisplayName: 'View',
                            DrawingUrl: resData.ViewDrawing.DrawingUrl
                        });
                    }

                    return res.data;
                })
            );
    }

    getConfigurator(quoteId: string, openingId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_CONFIG,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.configurator;
                })
            );
    }

    getStepsQuestions(quoteId: string, openingId: number, stepIds: number[]): Observable<any> {
        return this.apollo
            .query({
                query: GET_STEPS_QUESTIONS,
                variables: {
                    quoteId,
                    openingId,
                    stepIds
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.configuratorStepsQuestions));
    }

    getConfiguratorQuestions(quoteId: number, openingId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_QUESTIONS,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.configuratorAnswers));
    }

    getAvailableAdders(appliesTo: string, categoryType?: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_AVAILABLE_ADDERS,
                variables: {
                    appliesTo,
                    categoryType
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.configurationAdders));
    }

    getAvailableAccessories(catalogId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_AVAILABLE_ACCESSORIES,
                variables: {
                    catalog_id: catalogId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.configurationAccessories));
    }

    editLineItem(quoteId: number, openingId: number): Observable<any> {
        return this.apollo.mutate({
            mutation: EDIT_LINE_ITEM,
            variables: {
                quoteId,
                openingId
            }
        });
    }

    updateOpeningQuantity(appointmentId: number, openingId: number, quantity: number): Observable<any> {
        return this.apollo.mutate({
            mutation: EDIT_OPENING_QUANTITY,
            variables: {
                appointmentId,
                openingId,
                quantity
            }
        });
    }

    bulkUpdateOpeningQuantity(appointmentId: number, inputs: Array<{ opening_id; quantity }>): Observable<any> {
        return this.apollo.mutate({
            mutation: BULK_EDIT_OPENING_QUANTITY,
            variables: {
                appointmentId,
                inputs
            }
        });
    }

    updateAccessoryQuantity(
        quoteId: string,
        openingId: string,
        accessoryId: number,
        quantity: number
    ): Observable<any> {
        return this.apollo.mutate({
            mutation: EDIT_ACCESSORY_QUANTITY,
            variables: {
                quoteId,
                openingId,
                accessoryId,
                quantity
            }
        });
    }

    fetchProductSelectionQuestions(
        quoteId: string,
        openingId: string,
        answers?: any[],
        update = true,
        pagination?: ProductSelectionPagination,
        search?: string
    ): Observable<ProductSelection> {
        return this.apollo
            .mutate({
                mutation: FETCH_PRODUCT_SELECTION_OPTIONS,
                variables: {
                    quoteId,
                    openingId,
                    answers,
                    update,
                    pagination,
                    search
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const trimResponse = res.data.fetchProductSelection;

                    if (!update) {
                        trimResponse.ProductListQuestions = sortBy(trimResponse.ProductListQuestions, (question) => {
                            return some(question.AvailableAnswers, (answer) => answer.IsSelected);
                        });
                    }

                    return trimResponse;
                })
            );
    }

    createEmptyOpening(
        appointmentId: number,
        presentationType: ConfigurationType,
        categoryId: number,
        answers?: any
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CREATE_EMPTY_OPENING,
                variables: {
                    presentationType,
                    categoryId,
                    appointmentId,
                    answers
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    res.data.emptyOpening.quote.wcp_quote_id = res.data.emptyOpening.quote.quote_id;
                    res.data.emptyOpening.quote.quote_id = res.data.emptyOpening.quote.id;

                    return res.data.emptyOpening;
                })
            );
    }

    selectProduct(
        quoteId: string,
        openingId: string,
        productListSelections: any,
        productImageUrl?: string
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CREATE_CONFIGURATOR_SESSION,
                variables: {
                    quoteId,
                    openingId,
                    productListSelections,
                    productImageUrl
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.configurator));
    }

    answerQuestion(quoteId: string, openingId: string, question: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: ANSWER_CONFIGURATOR_QUESTION,
                variables: {
                    quoteId,
                    openingId,
                    question
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const resData = res.data.updateQuestion;

                    if (resData?.ViewDrawing?.DrawingUrl && this.featuresService.hasFeature(FEATURES.VIEW_FLOW)) {
                        resData.drawings.push({
                            DrawingID: 'view',
                            DisplayName: 'View',
                            DrawingUrl: resData.ViewDrawing.DrawingUrl
                        });
                    }

                    return res.data.updateQuestion;
                })
            );
    }

    saveNote(quoteId: string, openingId: string, notes: string[]): Observable<any> {
        return this.apollo.mutate({
            mutation: SAVE_CONFIGURATIONS_NOTES,
            variables: {
                quoteId,
                openingId,
                notes
            }
        });
    }

    getUsedAddersList(quoteId: string, openingId?: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_USED_ADDERS,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.usedConfigurationAdders));
    }

    getUsedAccessoriesList(quoteId: string, openingId?: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_USED_ACCESSORIES,
                variables: {
                    quoteId,
                    openingId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.usedConfigurationAccessories));
    }

    changeProjectAdderQuantity(appointmentId: number, adderId: number, quantity: number): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CHANGE_PROJECT_ADDER_QUANTITY,
                variables: {
                    appointmentId,
                    adderId,
                    quantity
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.projectAdderQuantity;
                })
            );
    }

    changeLineAdderQuantity(
        appointmentId: string,
        quoteId: string,
        openingId: string,
        adderId: number,
        quantity: number
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CHANGE_LINE_ADDER_QUANTITY,
                variables: {
                    appointmentId,
                    quoteId,
                    openingId,
                    adderId,
                    quantity
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.lineAdderQuantity));
    }

    saveAccessories(quoteId: string, accessories: number[], openingId?: number): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_CONFIGURATIONS_ACCESSORIES,
                variables: {
                    quoteId,
                    openingId,
                    accessories
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.configurationAccessories;
                })
            );
    }

    saveAdders(
        quoteId: number | string,
        adders: number[],
        addersConfigs: any[],
        appliesTo: string,
        openingId?: number | string
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_CONFIGURATIONS_ADDERS,
                variables: {
                    quoteId,
                    openingId,
                    adders,
                    addersConfigs,
                    appliesTo
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    return res.data.configurationAdders;
                })
            );
    }

    saveMultiAdders(input: any[]): Observable<any> {
        return this.apollo
            .mutate({
                mutation: MULTIPLE_CONFIGURATION_ADDERS,
                variables: {
                    input
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.multipleApplyConfigurationAdders));
    }

    createMiscAdder(adder: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CREATE_ADDER,
                variables: {
                    ...adder
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.createCustomAdder));
    }

    updateMiscAdder(adder: any, quoteId?: number, openingId?: number): Observable<any> {
        return this.apollo
            .mutate({
                mutation: EDIT_ADDER,
                variables: {
                    ...adder,
                    quote_id: quoteId,
                    opening_id: openingId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.updateCustomAdder));
    }

    finishConfiguration(quoteId: string, openingId: number, answers: any[]): Observable<FinishConfiguration> {
        return this.apollo
            .mutate({
                mutation: FINISH_CONFIGURATION,
                variables: {
                    quoteId,
                    openingId,
                    answers
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.finishConfiguration));
    }

    deleteLineItem(quoteId: number, openingId: number): Observable<any> {
        return this.apollo.mutate({
            mutation: DELETE_LINE_ITEM,
            variables: {
                quoteId,
                openingId
            }
        });
    }

    getSpecificDrawing(
        quoteId: number,
        openingId: number,
        drawingId: number,
        highResolution: boolean
    ): Observable<any> {
        return this.apollo
            .query({
                query: GET_SPECIFIC_DRAWING,
                variables: {
                    quoteId,
                    openingId,
                    drawingId,
                    highResolution
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.configurationDrawing));
    }

    copyOpeningWithinQuote(
        quoteId: number,
        openingId: number,
        copyToOpeningIds: any,
        newOpeningsCount: number,
        includeAdders: boolean,
        includeAccessories: boolean
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: COPY_OPENING_WITHIN_QUOTE,
                variables: {
                    quoteId,
                    openingId,
                    copyToOpeningIds,
                    newOpeningsCount,
                    includeAdders,
                    includeAccessories
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.copyOpeningConfigurations));
    }

    updateOpeningName(categoryId: number, appointmentId: number, opening: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: UPDATE_OPENING_NAME,
                variables: {
                    categoryId,
                    appointmentId,
                    opening
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.updateOpeningName));
    }
}
