<ion-grid>
  <ng-container *ngFor="let item of items; let i = index">
    <ion-row *ngIf="item?.children?.length && isUseCollapse">
      <ion-col>
        <div class="collapse-item" (click)="collapseMenu(i)">
          <ion-item lines="none" [disabled]="item.disabled">
            <ion-text>
              {{ item.name }}
            </ion-text>
          </ion-item>
          <ion-icon [name]="isCollapsed[i] ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
        </div>

        <ng-container *ngIf="isCollapsed[i]">
          <ion-row *ngFor="let child of item.children; let i = index">
            <ion-col class="child-item"
                     [class.selected]="(selectedRateSheetId && child?.hash === selectedRateSheetId) || (selectedFinancingOptionId && child?.id === selectedFinancingOptionId)"
                     (click)="selectItem(child)">
              <ion-text>
                {{ child.name }}
              </ion-text>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!isUseCollapse" (click)="selectItem(item)">
      <ion-col [class.selected]="(selectedRateSheetId && item?.hash === selectedRateSheetId) || (selectedFinancingOptionId && item?.id === selectedFinancingOptionId)">
        <ion-item lines="none" [disabled]="item.disabled">
          <ion-text>
            {{ item.name }}
          </ion-text>
        </ion-item>
      </ion-col>
    </ion-row>
  </ng-container>
</ion-grid>
