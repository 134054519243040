import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductSelectionService {
    private answerSelected: Subject<void> = new Subject<void>();
    private questionSelected: Subject<any> = new Subject<void>();
    answerSelected$: Observable<void> = this.answerSelected.asObservable();
    questionSelected$: Observable<any> = this.questionSelected.asObservable();

    constructor() {}

    emitAnswerSelection(): void {
        this.answerSelected.next();
    }

    emitQuestionSelection(index): void {
        this.questionSelected.next(index);
    }
}
