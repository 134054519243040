import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';

@Component({
    selector: 'vendo-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnChanges {
    @Input() tabs: any[] = [];
    @Input() completionTabs = {};
    @Input() activeTabId: string;
    @Input() useEllipsis = false;
    @Output() tabSelected: EventEmitter<any> = new EventEmitter<any>();
    @Input() enableNavigationButton = true;
    @ViewChildren('tabElement') tabElements: QueryList<ElementRef>;
    isShowNavButtons = false;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        const activeTabId = changes.activeTabId;

        if (activeTabId?.currentValue && !activeTabId?.previousValue) {
            setTimeout(() => this.scrollToSelected(), 500);
        }
    }

    emitSelectedTab(tab: any, index: number): void {
        if (tab.disabled) {
            return;
        }
        if (index === 0 || this.isValidTabByIndex(index - 1)) {
            this.tabSelected.emit(tab);

            if (this.useEllipsis) {
                setTimeout(() => this.scrollToSelected(), 500);
            }
        }
    }

    private isValidTabByIndex(index: number): boolean {
        return !this.tabs[index].hasOwnProperty('isValid') || this.tabs[index].isValid;
    }

    private scrollToSelected(): void {
        const selectedElement = this.tabElements.find((el: ElementRef) =>
            el.nativeElement.classList.contains('active')
        );

        if (selectedElement) {
            selectedElement.nativeElement.scrollIntoView({ block: 'center', inline: 'start' });
        }
    }
}
