import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';

import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

import { ModalController } from '@ionic/angular';

import every from 'lodash/every';

import { AuthService } from '@core/services/auth.service';
import { BaseModal } from '@shared/components/base-modal';
import { SignatureTabs } from '@shared/components/signature-modal/signature-modal.constants';
import { SignatureTabsModel } from '@shared/components/signature-modal/signature-tabs.model';

@Component({
    selector: 'vendo-signature-modal',
    templateUrl: './customer-signature-modal.component.html',
    styleUrls: ['./customer-signature-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSignatureModalComponent extends BaseModal implements OnInit, AfterViewInit {
    @ViewChild('signaturePad', { static: true }) signaturePad: SignaturePadComponent;
    @ViewChild('modalBody', { static: true }) modalBody;

    @Input() signatureImages;
    @Input() signatureOwner;
    @Input() customer;

    signaturePadOptions = {
        minWidth: 0.1,
        canvasWidth: 500,
        canvasHeight: 250,
        penColor: '#000080'
    };

    @Input() signatureTabs = new SignatureTabsModel({
        customerSignatures: 1,
        customerInitials: 1,
        sellerSignatures: 1
    });
    activeTab: any;
    isLastTabSelected = false;
    customerInitials: string;
    signatures: any = {};
    canvas: HTMLCanvasElement;
    signatureExist = {
        [SignatureTabs.CUSTOMER_SIGNATURE]: false,
        [SignatureTabs.CUSTOMER_INITIALS]: false,
        [SignatureTabs.SELLER_SIGNATURE]: false
    };
    sellerName = '';

    constructor(modalCtrl: ModalController, private auth: AuthService, private cdr: ChangeDetectorRef) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        if (this.signatureImages.initials) {
            this.customerInitials = this.signatureImages.initials;
        }

        const seller = this.auth.getUser();

        if (seller) {
            this.sellerName = `${seller.first_name} ${seller.last_name}`;
        }

        Object.assign(this.signatureExist, this.signatureImages);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const newWidth = this.modalBody.nativeElement.clientWidth - 40;

            if (newWidth < 500 && newWidth > 0) {
                this.signaturePad.set('canvasWidth', this.modalBody.nativeElement.clientWidth - 40);
            }

            this.canvas = document.querySelector('canvas');
            const ctx = this.canvas.getContext('2d');

            ctx.font = "40px 'Covered By Your Grace', cursive";
            ctx.fillStyle = '#000080';
            ctx.textBaseline = 'top';

            if (!this.customerInitials && this.customer) {
                this.customerInitials = `${this.customer.first_name[0]}.${this.customer.last_name[0]}.`;
            }

            this.setActiveTab(this.signatureTabs.tabs[0], 0);
            if (this.signatureImages && this.signatureImages[this.activeTab.hash]) {
                this.signaturePad.fromDataURL(this.signatureImages[this.activeTab.hash]);
            }
        });
    }

    setActiveTab(tab: any, tabIndex: number): void {
        this.isLastTabSelected = this.signatureTabs.tabs.length - 1 === tabIndex;
        this.activeTab = tab;
        this.clear();
        if (this.signatureImages && this.signatureImages[this.activeTab.hash]) {
            this.signaturePad.fromDataURL(this.signatureImages[this.activeTab.hash]);
            this.signatureExist[this.activeTab.hash] = true;
        } else if (tab.isInitials) {
            this.redrawInitials();
        } else {
            this.signatureExist[this.activeTab.hash] = false;
        }
        this.cdr.detectChanges();
    }

    redrawInitials(): void {
        this.clear();
        let fontSize = 500 / (this.customerInitials.length / 2);

        if (fontSize > 250) {
            fontSize = 250;
        }

        const ctx = this.canvas.getContext('2d');

        ctx.font = `${fontSize}px \'Covered By Your Grace\', cursive`;
        ctx.fillText(this.customerInitials, 15, (250 - fontSize) / 2 + 15);
        this.signatureExist[this.activeTab.hash] = true;
    }

    drawingStarted(): void {
        this.signatureExist[this.activeTab.hash] = true;
    }

    clear(): void {
        this.signatureExist[this.activeTab.hash] = false;
        this.signaturePad.clear();
    }

    confirm(): void {
        this.signatures[this.activeTab.hash] = this.signaturePad.toDataURL();

        if (!this.isLastTabSelected) {
            this.setActiveTab(this.signatureTabs.tabs[1], 1);
        }
    }

    done(): void {
        this.confirm();
        this.dismiss(this.signatures);
    }

    isDoneEnabled(): boolean {
        return every(this.signatureTabs.tabs, (tab) => this.signatureExist[tab.hash]);
    }
}
