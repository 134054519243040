<ion-grid>
  <ion-row *ngFor="let item of items"
           [class]="item.cssClass"
           (click)="selectItem(item)">
    <ion-col>
      <ion-img *ngIf="item?.imageUrl"
               class="ion-align-self-center"
               [src]="item?.imageUrl"></ion-img>
      <ion-item *ngIf="!item?.imageUrl"
                lines="none"
                [disabled]="item.disabled">
        <i *ngIf="item.icon"
           slot="icon-only"
           class="material-icons"
           [slot]="isIconSlotEnd ? 'end': 'start'">
          {{ item.icon }}
        </i>
        <ion-text>
          {{ item.name }}
          <ion-icon name="alert-circle" *ngIf="item.isErrorExist"></ion-icon>
        </ion-text>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
