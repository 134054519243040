import { Component, Input, OnInit } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';

import { SelectDocument, SelectGroupOfDocuments } from './select-documents-modal.interfaces';
import { BaseModal } from '@shared/components/base-modal';
import { NotesModalComponent } from '@shared/components/notes-modal/notes-modal.component';
import { TooltipPopoverComponent } from '@shared/components/tooltip-popover/tooltip-popover.component';
import { DocumentType } from '@shared/enums/document-type';

@Component({
    selector: 'vendo-select-documents-modal',
    templateUrl: './select-documents-modal.component.html',
    styleUrls: ['./select-documents-modal.component.scss']
})
export class SelectDocumentsModalComponent extends BaseModal implements OnInit {
    @Input() documents = [];
    @Input() isOnlyAttachments = false;
    title: string;
    groupedDocuments: SelectGroupOfDocuments[] = [];
    documentTypes: typeof DocumentType = DocumentType;

    constructor(public modalCtrl: ModalController, private popoverController: PopoverController) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.title = `Select ${this.isOnlyAttachments ? 'attachments' : 'documents'} to send`;
        this.setGroupedDocuments();
    }

    done(): void {
        this.dismiss(this.documents);
    }

    async setDocumentContext(document: any): Promise<void> {
        if (document.type !== 'proposal') {
            return;
        }

        const modal: HTMLIonModalElement = await this.modalCtrl.create({
            component: NotesModalComponent,
            componentProps: {
                headerText: 'Description of Work'
            },
            cssClass: 'result-appointment-modal'
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        document.context = {
            description_of_work: data
        };
    }

    async presentPopover(document, event: any): Promise<void> {
        if (!document.signed) {
            return;
        }

        const popover: HTMLIonPopoverElement = await this.popoverController.create({
            component: TooltipPopoverComponent,
            cssClass: 'tooltip-popover ion-text-nowrap ion-padding',
            event,
            translucent: true,
            mode: 'md',
            showBackdrop: false,
            componentProps: {
                message: 'Document is signed and can not be unchecked'
            }
        });

        await popover.present();
    }

    private setGroupedDocuments(): void {
        const packageNames: { [key: string]: string } = {};
        const packagesDocumentsMap: Map<string, SelectDocument[]> = new Map();
        const additionalDocuments: SelectDocument[] = [];

        for (const document of this.documents) {
            if (!document.quote?.id) {
                additionalDocuments.push(document);
                continue;
            }

            const packageId = String(document.quote.id);

            if (packagesDocumentsMap.has(packageId)) {
                packagesDocumentsMap.get(packageId).push(document);
            } else {
                packagesDocumentsMap.set(packageId, [document]);
            }

            if (document.quote.package_name) {
                packageNames[packageId] = document.quote.package_name;
            }
        }

        const groupedDocuments: SelectGroupOfDocuments[] = Array.from(packagesDocumentsMap.keys())
            .sort()
            .map((packageId) => ({
                name: packageNames[packageId],
                documents: packagesDocumentsMap.get(packageId)
            }));

        if (additionalDocuments.length) {
            groupedDocuments.push({
                name: 'Additional attachments',
                documents: additionalDocuments
            });
        }

        this.groupedDocuments = groupedDocuments;
    }
}
