import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { ApplicationRole } from '@shared/enums/application-role.enum';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private deviceHelperService: DeviceHelperService,
        private navCtrl: NavController,
        private rollbarErrorHandler: RollbarErrorHandler,
        private storage: Storage
    ) {}

    canActivate(): Observable<boolean> {
        console.log('AuthGuard');

        return this.deviceHelperService.readyStorageAndPlatform().pipe(
            switchMap(() => this.storage.get('token')),
            switchMap((token: string) => {
                console.log('token', token);
                if (!token) {
                    this.rollbarErrorHandler.handleInfo('Logout - Token is not found in AuthGuard');
                    this.navCtrl.navigateRoot(['logout']);

                    return of(false);
                }

                return this.authService.getMe().pipe(
                    map((res) => {
                        if (!res) {
                            return false;
                        }

                        const user = this.authService.getUser();

                        if (!user || user.application_role_hash === ApplicationRole.CapturePayment) {
                            this.rollbarErrorHandler.handleInfo(
                                'CapturePayment user do not allow to view appointments'
                            );
                            this.navCtrl.navigateRoot(['logout']);

                            return false;
                        }

                        return true;
                    })
                );
            })
        );
    }
}
