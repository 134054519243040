import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { HeaderInfo } from '@shared/interfaces/header-info';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private headerInfo: BehaviorSubject<HeaderInfo> = new BehaviorSubject<HeaderInfo>({
        title: 'Appointments'
    });
    headerInfo$: Observable<HeaderInfo> = this.headerInfo.asObservable();

    constructor() {}

    setHeaderInfo(items): void {
        this.headerInfo.next(items);
    }
}
