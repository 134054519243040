<div class="modal-header">
    <ion-text class="title">Copying Opening Configurations - {{copyingOpening.name}}</ion-text>
</div>
<div class="modal-body">
    <div class="help-text">
        Select Opening to which you want to copy the configurations (Max 5)
    </div>

    <ion-list>
        <ion-radio-group>
            <div class="radio-item ion-text-left" (ionChange)="checkHandler()" *ngFor="let opening of copyToList">
                <ion-checkbox mode="ios" [disabled]="reachedLimit && !opening.isChecked" [(ngModel)]="opening.isChecked"></ion-checkbox>
                <ion-label [color] = "opening.id === null ? 'secondary': ''">{{ opening.name }}</ion-label>
            </div>
        </ion-radio-group>
    </ion-list>

    <div class="include-section">
        <ion-label>Include:</ion-label>
        <ion-label>{{ adderNameSetting?.plural }}?</ion-label>
        <ion-checkbox [(ngModel)]="includeAdders" mode="ios"></ion-checkbox>
        <ng-container *ngIf="copyingOpening?.category?.catalog_type !== 'wcp'">
            <ion-label>Accessories?</ion-label>
            <ion-checkbox [(ngModel)]="includeAccessories" mode="ios"></ion-checkbox>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>

    <ion-button class="classic-width" (click)="copy()" [disabled]="!canDoCOpy">Copy</ion-button>
</div>
