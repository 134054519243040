import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastController: ToastController) {}

    async showMessage(message: string, duration = 2500): Promise<void> {
        const toast: HTMLIonToastElement = await this.toastController.create({
            message,
            duration
        });

        await toast.present();
    }
}
