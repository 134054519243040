import { Component, Input } from '@angular/core';

import { Quote } from '@shared/interfaces/quote';

@Component({
    selector: 'vendo-quote-numbers',
    templateUrl: './quote-numbers.component.html',
    styleUrls: ['./quote-numbers.component.scss']
})
export class QuoteNumbersComponent {
    @Input() quote: Quote;
}
