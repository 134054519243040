import { gql } from 'apollo-angular';

const GET_DOCUMENT = gql`
    query GetESignDocument($hash: String!, $auth_token: String) {
        customerESignatureDocument(hash: $hash, auth_token: $auth_token) {
            document
            errors
            customer {
                id
                first_name
                last_name
            }
            quote_document {
                signers
                updated_at
            }
            office {
                id
                name
                logo_url
                colors
                website
                timezone
            }
        }
    }
`;

const GET_DOCUMENT_PDF = gql`
    query customerESignatureDocumentDownload($hash: String!, $auth_token: String) {
        customerESignatureDocumentDownload(hash: $hash, auth_token: $auth_token) {
            url
            name
            errors
        }
    }
`;

export { GET_DOCUMENT, GET_DOCUMENT_PDF };
