<ion-grid *ngIf="addersForm" class="table" [formGroup]="addersForm">
    <ion-row class="header-row ion-align-items-center">
        <ion-col size="0.5">
            #
        </ion-col>
        <ion-col class="name-col" size="4.5">
            Name
        </ion-col>
        <ion-col>
            {{ adderNameSetting?.single }} Category
        </ion-col>
        <ion-col *ngIf="showPrices && isCanViewPrice"
                 class="ion-text-right"
                 size="2">
            List Price
        </ion-col>
        <ion-col class="ion-text-center" size="1.5">
            Qty
        </ion-col>
    </ion-row>
    <ng-container *ngIf="usedAdders.length; else noAdders" formArrayName="adders">
        <ion-row *ngFor="let adder of usedAdders; let i = index"
                 class="grid-body ion-align-items-center">
            <ion-item-sliding [class.disabled]="adder.applied_type === adderAppliedTypes.Auto || adder.created_from === configurationAdderCreatedFromTypes.Wcp"
                              [disabled]="adder.applied_type === adderAppliedTypes.Auto || adder.created_from === configurationAdderCreatedFromTypes.Wcp">
                <ion-item-options side="end">
                    <ion-item-option *ngIf="adder.adder_type.name === 'Custom'" color="medium" (click)="openAdderModal(adder)">
                        <i slot="icon-only" class="material-icons">
                            edit
                        </i>
                    </ion-item-option>
                    <ion-item-option color="warning" (click)="removeAdder(adder.id)">
                        <i slot="icon-only" class="material-icons">
                            delete
                        </i>
                    </ion-item-option>
                </ion-item-options>
                <ion-item class="row-slide" lines="none">
                    <ion-col size="0.5">
                        {{ i + 1 }}
                    </ion-col>
                    <ion-col class="name-col" size="4.5">
                        {{ adder.name }}
                    </ion-col>
                    <ion-col>
                        {{ adder.adder_type.name }}
                    </ion-col>
                    <ion-col *ngIf="showPrices && isCanViewPrice"
                             class="ion-text-right"
                             size="2">
                        {{ adder.final_price | customCurrency: true }}
                    </ion-col>
                    <ion-col class="ion-text-center adder-quantity" size="1.5">
                        <ng-container *ngIf="adder.created_from !== configurationAdderCreatedFromTypes.Wcp && adder.amount_type === amountTypes.STATIC && adder.type === adderTypes.Standard; else quantityTemplate">
                            <ion-input type="number"
                                       inputmode="numeric"
                                       tqMIntegerInput
                                       min="1"
                                       [formControlName]="i"
                                       required
                                       autocomplete="off"></ion-input>
                        </ng-container>
                        <ng-template #quantityTemplate>
                            <span>{{ adder.quantity }}</span>
                        </ng-template>
                    </ion-col>
                </ion-item>
            </ion-item-sliding>
        </ion-row>
    </ng-container>
    <ng-template #noAdders>
        <ion-row class="no-data-row">
            No {{ adderNameSetting?.plural }}
        </ion-row>
    </ng-template>
</ion-grid>

<vendo-fab-button (buttonClicked)="openAdderActions($event)"></vendo-fab-button>
