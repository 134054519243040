import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';

import { from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { RollbarErrorHandler } from '@core/handlers/rollbar-error-handler';
import { AppService } from '@core/services/app.service';
import { AuthService } from '@core/services/auth.service';
import { DeviceHelperService } from '@core/services/device-helper.service';
import { IGNORED_ROUTES } from '@shared/constants/ignore-routes';
import { ApplicationVersion } from '@shared/interfaces/application-version';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private deviceHelperService: DeviceHelperService,
        private navCtrl: NavController,
        private rollbarErrorHandler: RollbarErrorHandler
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.appService.getApplicationVersion()).pipe(
            tap(({ installedVersion, actualVersion }: ApplicationVersion) => {
                req = req.clone({
                    headers: req.headers
                        .set('x-app-version', installedVersion || actualVersion)
                        .set(
                            'x-live-updated-version',
                            this.deviceHelperService.isWeb ? 'mobile-latest' : actualVersion || installedVersion
                        )
                });
            }),
            switchMap(({ apiVersion }: ApplicationVersion) =>
                next.handle(req).pipe(map((res: HttpEvent<any>) => [res, apiVersion]))
            ),
            mergeMap(([res, apiVersion]: [HttpEvent<any>, string]) => {
                if (res instanceof HttpResponse && res.headers.has('version')) {
                    const version: string = res.headers.get('version');

                    if (!apiVersion || IGNORED_ROUTES.some((item) => window.location.href.includes(item))) {
                        return this.setApiVersion(version, false, res);
                    }

                    if (apiVersion !== version) {
                        return this.setApiVersion(version, true, res);
                    }
                }

                return of([res, false]);
            }),
            map(([res, isVersionDiff]: [HttpEvent<any>, boolean]) => {
                if (!isVersionDiff) {
                    return res;
                }

                const returnUrl: string = this.authService.getReturnUrl();

                this.rollbarErrorHandler.handleInfo('Logout - Version is different');

                if (this.deviceHelperService.isWeb) {
                    window.location.href = returnUrl ? `/logout?returnUrl=${returnUrl}` : 'logout';
                } else {
                    this.navCtrl.navigateRoot(['logout'], returnUrl ? { queryParams: { returnUrl } } : undefined);
                }

                throw new HttpErrorResponse({
                    error: 'Wrong version of application',
                    status: 500
                });
            })
        );
    }

    private setApiVersion(
        version: string,
        isVersionDiff: boolean,
        res: HttpEvent<any>
    ): Observable<[HttpEvent<any>, boolean]> {
        return from(this.appService.setApiVersion(version)).pipe(map(() => [res, isVersionDiff]));
    }
}
