import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { FormHelperService } from '@core/services/form-helper.service';
import { NotificationService } from '@core/services/notification.service';
import { RequestApprovalService } from '@core/services/request-approval.service';
import { BaseModal } from '@shared/components/base-modal';
import { NotificationType } from '@shared/enums/notification-type';
import { QuotePricePresentationFieldType } from '@shared/enums/quote-price-presentation-field-type.enum';
import { ApprovalRequest } from '@shared/interfaces/approval-request';
import { Promotion } from '@shared/interfaces/promotion';
import { Quote } from '@shared/interfaces/quote';
import { QuotePricePresentationPackageViewSetting } from '@shared/interfaces/quote-price-presentation-package-view-setting';

@Component({
    selector: 'vendo-request-approval-modal',
    templateUrl: './request-approval-modal.component.html',
    styleUrls: ['./request-approval-modal.component.scss']
})
export class RequestApprovalModalComponent extends BaseModal implements OnInit {
    @Input() promotions: Promotion[] = [];
    @Input() quote: Quote;
    @Input() pricePresentationSettings: QuotePricePresentationPackageViewSetting[] = [];
    descriptionControl = new UntypedFormControl();
    approvalRequest: ApprovalRequest;
    readonly quotePricePresentationFieldTypes: typeof QuotePricePresentationFieldType = QuotePricePresentationFieldType;

    constructor(
        private formHelperService: FormHelperService,
        modalCtrl: ModalController,
        private notificationService: NotificationService,
        private requestApprovalService: RequestApprovalService
    ) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.pricePresentationSettings = this.pricePresentationSettings.filter(({ hash }) =>
            [QuotePricePresentationFieldType.PackagePrice, QuotePricePresentationFieldType.SubTotal].includes(hash)
        );
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    submit(): void {
        this.closePreviousNotification()
            .pipe(
                switchMap(() =>
                    this.requestApprovalService.sendApprovalRequest(this.descriptionControl.value, this.quote.quote_id)
                )
            )
            .subscribe((approvalRequest: ApprovalRequest) => {
                this.approvalRequest = approvalRequest;
                this.notificationService.startSubscription(approvalRequest, NotificationType.approvalRequest);
            });
    }

    private closePreviousNotification(): Observable<boolean> {
        const notification: ApprovalRequest = this.notificationService.existingNotification(
            String(this.quote.quote_id)
        );

        return notification?.id
            ? this.requestApprovalService.closeApprovalRequest(notification.id).pipe(
                  tap(() => {
                      this.notificationService.emitAutoCloseNotification(notification.id);
                      this.notificationService.closeSubscription(notification.id, NotificationType.approvalRequest);
                  })
              )
            : of(true);
    }
}
