<div class="modal-header">
  <ion-text class="title">{{ title }}</ion-text>

  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body" [formGroup]="form">
  <ion-content>
    <ion-list *ngIf="isMultiSelect; else singleSelectTemplate" lines="none" formArrayName="items">
      <ion-item *ngFor="let item of items; let i = index" lines="none">
        <ng-container [ngTemplateOutlet]="labelTemplate"
                      [ngTemplateOutletContext]="{ item: item }"></ng-container>
        <ion-checkbox slot="start"
                      mode="md"
                      [formControlName]="i"></ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>

  <ng-template #singleSelectTemplate>
    <ion-radio-group formControlName="item">
      <ion-list>
        <ion-item *ngFor="let item of items" lines="none">
          <ng-container [ngTemplateOutlet]="labelTemplate"
                        [ngTemplateOutletContext]="{ item: item }"></ng-container>
          <ion-radio slot="start" mode="md" [value]="item.id"></ion-radio>
        </ion-item>
      </ion-list>
    </ion-radio-group>
  </ng-template>

  <ng-template #labelTemplate let-item="item">
    <ion-label>
      <span class="text-ellipsis">{{ item.name }}</span>
      <span *ngIf="item[subNameProperty]">{{ item[subNameProperty] }}</span>
    </ion-label>
  </ng-template>
</div>

<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()">Cancel</ion-button>

  <ion-button class="classic-width" [disabled]="form.invalid" (click)="apply()">{{ applyButtonName }}</ion-button>
</div>
