import { gql } from 'apollo-angular';

const COMPLETE_STEP = gql`
    mutation completeStep($appointment_id: ID!, $stepHash: String!) {
        completeStep(appointment_id: $appointment_id, stepHash: $stepHash)
    }
`;
const SKIP_STEP = gql`
    mutation skipStep($appointment_id: ID!, $stepHash: String!) {
        skipStep(appointment_id: $appointment_id, stepHash: $stepHash)
    }
`;
const VISIT_STEP = gql`
    mutation visitStep($appointment_id: ID!, $stepHash: String!) {
        visitStep(appointment_id: $appointment_id, stepHash: $stepHash)
    }
`;

const CHILD_VISIT_STEP = gql`
    mutation childVisitStep($appointment_id: ID!, $stepHash: String!, $child_id: ID!, $visit_info: JSON!) {
        childVisitStep(
            appointment_id: $appointment_id
            stepHash: $stepHash
            child_id: $child_id
            visit_info: $visit_info
        )
    }
`;

export { CHILD_VISIT_STEP, COMPLETE_STEP, SKIP_STEP, VISIT_STEP };
