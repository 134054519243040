import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ModalController, PopoverController } from '@ionic/angular';

import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';

import { AuthService } from '@core/services/auth.service';
import { PermissionsService } from '@core/services/permissions.service';
import { AddersModalComponent, CreateAdderModalComponent, DropdownPopoverComponent } from '@shared';
import { maxQuantity } from '@shared/constants/form-validation-constants';
import { PERMISSIONS } from '@shared/constants/permissions';
import { AdderAmountType } from '@shared/enums/adder-amount-type.enum';
import { AdderAppliedType, AdderType } from '@shared/enums/adder-type.enum';
import { AppointmentType } from '@shared/enums/appointment-type';
import { ConfigurationAdderCreatedFromType } from '@shared/enums/configuration-adder-created-from-type';
import { PriceConfigurationDisplayStatus } from '@shared/enums/price-configuration-display-status';
import { Appointment } from '@shared/interfaces/appointment';
import { Dictionary } from '@shared/interfaces/dictionary';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

@Component({
    selector: 'vendo-line-item-adders',
    templateUrl: './line-item-adders.component.html',
    styleUrls: ['./line-item-adders.component.scss']
})
export class LineItemAddersComponent implements OnInit {
    @Input() opening: any;
    @Input() set priceConfigurationDisplayStatus(priceConfigurationDisplayStatus) {
        this.showPrices = priceConfigurationDisplayStatus === PriceConfigurationDisplayStatus.DefaultOn;
    }
    @Input() adderNameSetting: Dictionary;
    @Input() adderPermissions: { isCanCreateLine: boolean; isCanSelectLine: boolean };
    @Output() countOfAddersChange: EventEmitter<number> = new EventEmitter<number>();

    appointment: Appointment;
    addersForm: UntypedFormGroup;
    usedAdders: any[] = [];
    showPrices = false;
    isCanViewPrice: boolean;
    readonly adderTypes = AdderType;
    readonly configurationAdderCreatedFromTypes = ConfigurationAdderCreatedFromType;
    readonly adderAppliedTypes = AdderAppliedType;
    readonly amountTypes = AdderAmountType;

    private appointmentId: string;
    private quoteId: string;
    private openingId: string;
    private destroyAddersForm$: Subject<void>;

    constructor(
        private authService: AuthService,
        private configureService: ConfigureService,
        private formBuilder: UntypedFormBuilder,
        private modalController: ModalController,
        private permissionsService: PermissionsService,
        private popoverController: PopoverController,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        this.appointment = await this.authService.getStorageItem('activeAppointment');
        this.isCanViewPrice =
            this.appointment.type !== AppointmentType.SecondMeasure ||
            this.permissionsService.hasPermissions(PERMISSIONS.SECOND_MEASURE.VIEW_PRICE);
        this.appointmentId = this.route.snapshot.parent.parent.params.appointmentId;
        this.quoteId = this.route.snapshot.params.quoteId;
        this.openingId = this.route.snapshot.params.openingId;
        this.configureService.getUsedAddersList(this.quoteId, this.openingId).subscribe((res) => {
            this.handleAdders(res);
            this.initAddersForm();
        });
    }

    async openAdderActions(event: Event): Promise<any> {
        event.stopPropagation();

        switch (true) {
            case this.adderPermissions.isCanSelectLine && !this.adderPermissions.isCanCreateLine:
                this.showAddersList();
                break;

            case this.adderPermissions.isCanCreateLine && !this.adderPermissions.isCanSelectLine:
                this.openAdderModal();
                break;

            default:
                const popover = await this.popoverController.create({
                    component: DropdownPopoverComponent,
                    event,
                    translucent: true,
                    mode: 'md',
                    cssClass: 'dropdown-popover fab-actions',
                    componentProps: {
                        isIconSlotEnd: false,
                        items: [
                            {
                                name: 'Create New',
                                hash: 'create',
                                icon: 'add_circle_outline'
                            },
                            {
                                name: 'Select Existing',
                                hash: 'select',
                                icon: 'playlist_add'
                            }
                        ]
                    }
                });

                await popover.present();
                const { data } = await popover.onDidDismiss();

                if (data) {
                    switch (data) {
                        case 'create':
                            this.openAdderModal();
                            break;
                        case 'select':
                            this.showAddersList();
                            break;
                    }
                }
        }
    }

    async openAdderModal(adder?): Promise<any> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: CreateAdderModalComponent,
            componentProps: {
                adder,
                openingId: this.openingId,
                quoteId: this.quoteId,
                isProjectAdder: false
            },
            cssClass: 'create-adder-modal',
            backdropDismiss: false
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data) {
            const { selectedIds, configs } = this.getSelectedAddersIds(adder ? undefined : data[1][0]);

            this.saveAdders(selectedIds, configs);
        }
    }

    removeAdder(adderId: number): void {
        const { selectedIds, configs } = this.getSelectedAddersIds(undefined, adderId);

        this.saveAdders(selectedIds, configs);
    }

    saveAdders(adderIds: number[], addersConfigs: any[] = []): void {
        this.configureService
            .saveAdders(this.quoteId, adderIds, addersConfigs, 'line', this.openingId)
            .subscribe((res) => {
                this.handleAdders(res);
                this.reInitAddersForm();
            });
    }

    showAddersList(): void {
        this.configureService
            .getAvailableAdders('line', this.opening.category.category_type)
            .subscribe(async (categories) => {
                const modal: HTMLIonModalElement = await this.modalController.create({
                    component: AddersModalComponent,
                    componentProps: {
                        addersCategories: categories,
                        selectedAdders: cloneDeep(this.usedAdders)
                            .filter((adder) => adder.created_from !== ConfigurationAdderCreatedFromType.Wcp)
                            .map((adder) => {
                                adder['packages'] = [true];

                                return adder;
                            }),
                        countOfColumns: 1,
                        isProjectAdders: false,
                        isShowPrice: this.showPrices && this.isCanViewPrice
                    },
                    cssClass: 'adders-modal'
                });

                await modal.present();
                const { data } = await modal.onWillDismiss();

                if (data) {
                    this.saveAdders(data.adderIds[0].map(Number), data.addersConfigs[0]);
                }
            });
    }

    private getSelectedAddersIds(customId?: number, excludedId?: number): { selectedIds: number[]; configs: any[] } {
        const selectedIds: number[] = [];
        const configs: any[] = [];
        let countOfExcludedAdders = 0;

        this.usedAdders.forEach((adder, index: number) => {
            if (
                adder.created_from === ConfigurationAdderCreatedFromType.Wcp ||
                adder.type === AdderType.Smart ||
                (excludedId && adder.id === excludedId)
            ) {
                countOfExcludedAdders++;

                return;
            }

            selectedIds.push(Number(adder.id));
            configs.push({
                adder_id: adder.id,
                ...(adder.variable && { amount: adder.amount }),
                position: index - countOfExcludedAdders
            });
        });

        if (customId) {
            selectedIds.push(Number(customId));
            configs.push({
                adder_id: customId,
                position: this.usedAdders.length - countOfExcludedAdders
            });
        }

        return { selectedIds, configs };
    }

    private handleAdders(adders: any[]): void {
        this.usedAdders = adders;
        this.countOfAddersChange.emit(this.usedAdders.length);
    }

    private handleQuantityControl(control: UntypedFormControl, rowIndex: number): Observable<any> {
        return control.valueChanges.pipe(
            filter(() => {
                if (control.valid) {
                    return true;
                } else {
                    control.setValue(this.usedAdders[rowIndex].quantity, { emitEvent: false });
                }
            }),
            map((value: number) => ({ value, rowIndex }))
        );
    }

    private initAddersForm(): void {
        this.destroyAddersForm$ = new Subject<void>();
        this.addersForm = this.formBuilder.group({
            adders: this.formBuilder.array(
                this.usedAdders.map(({ quantity }) =>
                    this.formBuilder.control(quantity, {
                        validators: [Validators.min(1), Validators.max(maxQuantity)],
                        updateOn: 'blur'
                    })
                )
            )
        });

        merge(
            ...(this.addersForm.get('adders') as UntypedFormArray).controls.map(
                (control: AbstractControl, rowIndex: number) =>
                    this.handleQuantityControl(control as UntypedFormControl, rowIndex)
            )
        )
            .pipe(
                map(({ value, rowIndex }) => ({ value: round(value), rowIndex })),
                switchMap(({ value, rowIndex }) =>
                    this.configureService
                        .changeLineAdderQuantity(
                            this.appointmentId,
                            this.quoteId,
                            this.openingId,
                            this.usedAdders[rowIndex].id,
                            value
                        )
                        .pipe(
                            catchError(() => of(false)),
                            map((res: boolean) => ({ value: res && value, rowIndex }))
                        )
                ),
                takeUntil(this.destroyAddersForm$)
            )
            .subscribe(({ value, rowIndex }) => {
                if (value) {
                    this.usedAdders[rowIndex].quantity = value;

                    const adders = this.addersForm.get('adders') as UntypedFormArray;

                    adders.at(rowIndex).setValue(value, { emitEvent: false });
                }
            });
    }

    private reInitAddersForm(): void {
        this.destroyAddersForm$.next();
        this.destroyAddersForm$.complete();
        this.initAddersForm();
    }
}
