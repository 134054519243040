<div class="modal-header">
  <ion-text class="title">{{headerText}}</ion-text>
</div>
<div class="modal-body">
  <ion-text [ngClass]="{ 'ion-text-center': centerText }" [innerHTML]="message"></ion-text>
</div>
<div class="modal-footer">
  <ion-button class="classic-width outline" (click)="closeModal()" *ngIf="showCancelButton">{{cancelButtonName}}</ion-button>
  <ion-button class="classic-width" (click)="confirm()">{{confirmButtonName}}</ion-button>
</div>
