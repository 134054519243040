import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import {
    COPY_OPENING,
    CREATE_HOUSE_AREA,
    CREATE_OPENING,
    DELETE_HOUSE_AREA,
    DELETE_OPENING,
    DELETE_TAKEOFF_DETAILS,
    RENAME_HOUSE_AREA,
    SAVE_OPENING_PHOTOS,
    SAVE_TAKEOFF_DETAILS,
    UPDATE_OPENING
} from '../mutations/opening.mutations';
import {
    GET_APPOINTMENT_CATALOGS_BY_PRODUCT_INTERESTS,
    GET_APPOINTMENT_CATEGORIES_LIST,
    GET_APPOINTMENT_CATEGORIES_WITHOUT_OPENINGS,
    GET_CATALOG_IMAGE,
    GET_CATEGORIES_LIST,
    GET_CATEGORY_WITH_QUESTIONS,
    GET_CATEGORY_WITH_QUESTIONS_AND_OPENINGS
} from '../queries/category.queries';
import {
    GET_OPENING,
    GET_OPENING_CONDITIONS,
    GET_OPENINGS_LIST,
    GET_ROOFING_TAKEOFF_DETAILS
} from '../queries/opening.queries';
import { CategoryType } from '@shared/enums/category-type';

@Injectable({
    providedIn: 'root'
})
export class TakeoffService {
    constructor(private apollo: Apollo) {}

    getProductCategories(appointmentId: number): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_CATEGORIES_LIST,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((resp: ApolloQueryResult<any>) => {
                    const categories = resp.data.myCategories;
                    const hideQuestions = ['location'];

                    categories.forEach((category) => {
                        if (category.category.allow_configure_questions) {
                            return;
                        }

                        category.category.questions = category.category.questions
                            .map((question) => {
                                const questionNames = {
                                    width: 'W',
                                    height: 'H',
                                    'product type': 'Style',
                                    'door style': 'Style',
                                    'final area (sq.ft.)': 'Final Area'
                                };

                                question.cssClass = question.title.toLowerCase().replace(' ', '');

                                if (
                                    questionNames.hasOwnProperty(question.title.toLowerCase()) &&
                                    category.category.category_type !== CategoryType.Siding
                                ) {
                                    question.title = questionNames[question.title.toLowerCase()];
                                }

                                return question;
                            })
                            .filter(
                                (question) =>
                                    !hideQuestions.includes(question.title.toLowerCase()) && isEmpty(question.show_if)
                            );
                    });

                    return categories;
                })
            );
    }

    getAppointmentProductCategories(appointmentId: number): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_CATEGORIES_LIST,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.myAppointmentCategories));
    }

    getAppointmentCategoriesWithoutOpenings(appointmentId: number): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_CATEGORIES_WITHOUT_OPENINGS,
                variables: {
                    appointmentId
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.myAppointmentCategories));
    }

    getOpenings(categoryId: number, appointmentId: number): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_OPENINGS_LIST,
                variables: {
                    categoryId,
                    appointmentId
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.openings));
    }

    getOpening(openingId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_OPENING,
                variables: {
                    openingId
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.opening));
    }

    getOpeningConditions(categoryType: string): Observable<string[]> {
        return this.apollo
            .query({
                query: GET_OPENING_CONDITIONS,
                variables: {
                    category_type: categoryType
                }
            })
            .pipe(
                map((resp: ApolloQueryResult<any>) => resp.data.myOpeningConditions.map((condition) => condition.name))
            );
    }

    getCategoryWithOpenings(categoryId: number, appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_CATEGORY_WITH_QUESTIONS_AND_OPENINGS,
                variables: {
                    categoryId,
                    appointmentId
                }
            })
            .pipe(
                map((resp: ApolloQueryResult<any>) => {
                    const category = resp.data.category;

                    if (!category.questions) {
                        category.questions = [];
                        if (!category.openings) {
                            category.openings = [];
                        }

                        return category;
                    }

                    category.questions = (
                        category.category_type === CategoryType.Roofs ? category.all_questions : category.questions
                    ).filter((question) => isEmpty(question.show_if));

                    if (!category.openings.length) {
                        forEach(category.questions, (question) => {
                            question.cssClass = question.title.toLowerCase().replace(' ', '');
                        });
                    }

                    category.openings = category.openings.filter((opening) => !opening.temporary);

                    forEach(category.openings, (opening) => {
                        opening.initial_quantity = opening.quantity;
                        const remappedDetails = [];

                        forEach(category.questions, (question) => {
                            if (!isEmpty(question.show_if)) {
                                return;
                            }

                            question.cssClass = question.title.toLowerCase().replace(' ', '');
                            let answer = find(opening.opening_details, { question_id: question.id });

                            if (!answer) {
                                remappedDetails.push({
                                    question_id: question.id,
                                    question_hash: question.hash,
                                    answer: '',
                                    cssClass: question.cssClass
                                });
                            } else {
                                const childQuestion = find(
                                    category.questions,
                                    (item) => item.show_if[question.hash] === answer.answer
                                );

                                if (childQuestion) {
                                    answer = find(opening.opening_details, { question_id: childQuestion.id });
                                }

                                answer.question_hash = question.hash;

                                answer.cssClass = question.cssClass;
                                remappedDetails.push(answer);
                            }
                        });
                        opening.all_details = opening.opening_details;
                        opening.opening_details = remappedDetails;

                        if (category.allow_configure_questions) {
                            opening.opening_details.forEach((item) => {
                                const categoryQuestion = find(category.questions, { id: item.question_id });

                                item.column_width = categoryQuestion.column_width;
                            });
                        }
                    });

                    return category;
                })
            );
    }

    createCustomAppointmentArea(appointmentId: number, title: string, categoryType: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CREATE_HOUSE_AREA,
                variables: {
                    appointment_id: appointmentId,
                    title,
                    category_type: categoryType
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.createHouseArea));
    }

    deleteCustomAppointmentArea(areaId: number): Observable<any> {
        return this.apollo.mutate({
            mutation: DELETE_HOUSE_AREA,
            variables: {
                areaId
            }
        });
    }

    renameCustomAppointmentArea(groupId: number, title: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: RENAME_HOUSE_AREA,
                variables: {
                    id: groupId,
                    title
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.renameHouseArea));
    }

    getCategoryWithQuestions(categoryId: number, appointmentId: number, openingCountBy?: string): Observable<any> {
        return this.apollo
            .query({
                query: GET_CATEGORY_WITH_QUESTIONS,
                variables: {
                    categoryId,
                    appointmentId,
                    openingCountBy
                }
            })
            .pipe(
                map((resp: ApolloQueryResult<any>) => {
                    resp.data.categoryWithQuestions.questions = resp.data.categoryWithQuestions.questions.map(
                        (question) => {
                            if (question.depends_on) {
                                question.depends_on = find(resp.data.categoryWithQuestions.questions, {
                                    hash: question.depends_on
                                }).id;
                            }

                            if (
                                !isEmpty(question.show_if) &&
                                resp.data.categoryWithQuestions.category_type !== CategoryType.Siding
                            ) {
                                const parentQuestionHash = Object.keys(question.show_if)[0];
                                const parentQuestion = find(resp.data.categoryWithQuestions.questions, {
                                    hash: parentQuestionHash
                                });

                                parentQuestion.child = question;
                            }

                            return question;
                        }
                    );

                    if (resp.data.categoryWithQuestions.category_type !== CategoryType.Siding) {
                        resp.data.categoryWithQuestions.questions = resp.data.categoryWithQuestions.questions.filter(
                            (question) => isEmpty(question.show_if)
                        );
                    }

                    return resp.data.categoryWithQuestions;
                })
            );
    }

    saveRoofingTakeoffDetails(data: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_TAKEOFF_DETAILS,
                variables: data,
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.openingAnnotations));
    }

    deleteRoofingTakeoffDetails(appointmentId: number, catalogId: number): Observable<any> {
        return this.apollo.mutate({
            mutation: DELETE_TAKEOFF_DETAILS,
            variables: {
                appointmentId,
                catalogId
            }
        });
    }

    getRoofingTakeoffDetails(appointmentId: number, catalogId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_ROOFING_TAKEOFF_DETAILS,
                variables: {
                    appointmentId,
                    catalogId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.takeoffDetailsWithOpenings));
    }

    createOpening(
        categoryId: number,
        catalogId: number,
        appointmentId: number,
        opening: any,
        geolocation: any = {},
        images: any = []
    ): Observable<any> {
        if (opening.hasOwnProperty('image_details')) {
            delete opening['image_details'];
        }

        return this.apollo
            .mutate({
                mutation: CREATE_OPENING,
                variables: {
                    categoryId,
                    catalogId,
                    appointmentId,
                    opening,
                    geolocation,
                    images: this.remapImages(images)
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.createOpening));
    }

    copyOpening(openingId: number, copyCount: number): Observable<any> {
        return this.apollo
            .mutate({
                mutation: COPY_OPENING,
                variables: {
                    openingId,
                    copyCount
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.copyOpening));
    }

    updateOpening(
        categoryId: number,
        catalogId: number,
        appointmentId: number,
        opening: any,
        images: any = []
    ): Observable<any> {
        if (opening.hasOwnProperty('opening_details')) {
            delete opening['opening_details'];
        }

        if (opening.hasOwnProperty('image_details')) {
            delete opening['image_details'];
        }

        if (opening.hasOwnProperty('__typename')) {
            delete opening['__typename'];
        }

        if (opening.hasOwnProperty('catalog_id')) {
            delete opening['catalog_id'];
        }

        if (opening.hasOwnProperty('category_id')) {
            delete opening['category_id'];
        }

        return this.apollo
            .mutate({
                mutation: UPDATE_OPENING,
                variables: {
                    categoryId,
                    catalogId,
                    appointmentId,
                    opening,
                    images: this.remapImages(images)
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.updateOpening));
    }

    updateImages(appointmentId: number, openingId: number, files: any = []): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SAVE_OPENING_PHOTOS,
                variables: {
                    appointmentId,
                    openingId,
                    files
                },
                context: {
                    useMultipart: true
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.saveOpeningPhotos));
    }

    /**
     * Delete opening
     *
     * @param openingId
     * @param appointmentId needed for offline mode, don't remove
     */
    deleteOpening(openingId: number, appointmentId?: number): Observable<any> {
        return this.apollo.mutate({
            mutation: DELETE_OPENING,
            variables: {
                openingId
            }
        });
    }

    getCatalogImage(appointmentId: number, catalogId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_CATALOG_IMAGE,
                variables: {
                    appointmentId,
                    catalogId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.getCatalogImage));
    }

    getCatalogsByProductInterests(appointmentId: string): Observable<string[]> {
        return this.apollo
            .query({
                query: GET_APPOINTMENT_CATALOGS_BY_PRODUCT_INTERESTS,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) =>
                    res.data.appointmentCatalogsByProductsInterests.map(({ category_type }) => category_type)
                )
            );
    }

    private remapImages(images): any[] {
        return images.map((image) => {
            delete image.appointment_type;
            delete image.appointment_id;
            delete image.opening_id;
            delete image.position;
            delete image.__typename;

            return image;
        });
    }
}
