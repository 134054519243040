import { ModalController } from '@ionic/angular';

export class BaseModal {
    constructor(public modalCtrl: ModalController) {}

    closeModal(): any {
        this.dismiss(false);
    }

    dismiss(data): void {
        this.modalCtrl.getTop().then((modal) => {
            modal.dismiss(data);
        });
    }
}
