import { from, Observable } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';
import { NeedAssessmentAnswer, NeedsAssessment } from '@shared/interfaces/needs-assessment';

export class NeedsAssessmentOffline {
    constructor(private offlineStorageService: OfflineStorageService, private auth: AuthService) {}

    getAppointmentAssessmentQuestions(appointmentId: string): Observable<NeedsAssessment[]> {
        const user = this.auth.getUser();
        const appointmentPromise = this.offlineStorageService.findOne(
            `SELECT * FROM Appointments WHERE id='${appointmentId}' AND seller_id='${user.id}' AND office_id='${user.office.id}'`
        );
        const needsAssessmentPromise = this.offlineStorageService.findOne(
            `SELECT * FROM NeedsAssessments WHERE user_id='${user.id}'`
        );

        const promise = Promise.all([appointmentPromise, needsAssessmentPromise]).then(
            ([appointment, needsAssessment]) => {
                const needsAssessmentQuestions = needsAssessment.needs_assessment.find(
                    ({ appointment_type }) => appointment_type === appointment.type
                );

                if (needsAssessmentQuestions?.questions) {
                    if (appointment.assessment?.answers?.length) {
                        needsAssessmentQuestions.questions.forEach((question: NeedsAssessment) => {
                            const answeredQuestion = appointment.assessment.answers.find(
                                (answer: NeedAssessmentAnswer) => answer.question === question.title
                            );

                            if (answeredQuestion) {
                                question.default_answer = answeredQuestion.answer;
                            }
                        });
                    }

                    return needsAssessmentQuestions.questions;
                }

                return [];
            }
        );

        return from(promise);
    }

    saveAppointmentAssessmentQuestions(appointmentId: string, answers: NeedAssessmentAnswer[]): Observable<boolean> {
        const user = this.auth.getUser();
        const promise = this.offlineStorageService
            .findOne(
                `SELECT * FROM Appointments WHERE id='${appointmentId}' AND seller_id='${user.id}' AND office_id='${user.office.id}'`
            )
            .then((appointment) => {
                if (!appointment.assessment) {
                    appointment.assessment = {};
                }

                appointment.assessment.answers = answers;
                appointment.updated = '1';

                return this.offlineStorageService.insert('Appointments', [appointment]).then(() => true);
            });

        return from(promise);
    }
}
