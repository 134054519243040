<ng-container [formGroup]="form">
    <ion-grid class="table" [class.empty-table]="!mappedAdders.length" formArrayName="adders">
        <ion-row class="header-row ion-align-items-center">
            <ion-col size="0.5">
                #
            </ion-col>
            <ion-col class="name-col">
                Name
            </ion-col>
            <ng-container *ngIf="quotesWithAdders.length === 1">
                <ion-col [size]="2">
                    {{ adderNameSetting?.single }} Category
                </ion-col>
                <ion-col class="ion-text-right" [size]="1.5" *ngIf="showPrices">
                    List Price
                </ion-col>
            </ng-container>

            <ion-col class="ion-text-center" [size]="quotesWithAdders.length > 1 ? 0.75 : 1.25">
                Qty
            </ion-col>

            <ng-container>
                <ion-col [size]="quotesWithAdders.length === 5 ? 1.5 : 1.75"
                         class="ion-text-center"
                         *ngFor="let name of packageNames; let j = index">
                    <div class="flex-center ion-justify-content-center package-button" (click)="rename($event, j)">
                        <div class="text-ellipsis-2-rows">
                            {{ quotesWithAdders === 1 ? 'Package' : name }}
                        </div>
                    </div>
                </ion-col>
            </ng-container>

            <ion-col [size]="0.8"
                     *ngIf="quotesWithAdders.length < 5 && configureType === configureTypes.Manual"
                     class="ion-text-center">
                <div class="flex-center ion-justify-content-center package-button" (click)="addNewPackage()">
                    + Add
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!mappedAdders.length; else addersGrid" class="no-data-row">
            No {{ adderNameSetting?.plural }}
        </ion-row>

        <ng-template #addersGrid>
            <ng-container *ngFor="let adder of mappedAdders; let i = index">
                <ion-row [formGroupName]="i"
                         class="grid-body ion-align-items-center"
                         [class.group]="true">
                    <ion-item-sliding [class.disabled]="adder.created_from === configurationAdderCreatedFromTypes.Wcp || adder.applied_type === adderAppliedTypes.Auto"
                                      [disabled]="adder.created_from === configurationAdderCreatedFromTypes.Wcp || adder.applied_type === adderAppliedTypes.Auto">
                        <ion-item-options side="end">
                            <ion-item-option *ngIf="adder.adder_type.name === 'Custom'"
                                             color="medium"
                                             (click)="openAdderModal(adder)">
                                <i slot="icon-only" class="material-icons">
                                    edit
                                </i>
                            </ion-item-option>
                            <ion-item-option color="warning"
                                             (click)="removeAdder(adder.id)">
                                <i slot="icon-only" class="material-icons">
                                    delete
                                </i>
                            </ion-item-option>
                        </ion-item-options>

                        <ion-item class="row-slide" lines="none" (click)="toggleDetails(adder)">
                            <ion-col size="0.5">
                                {{ i + 1 }}
                            </ion-col>

                            <ion-col class="name-col">
                                {{ adder.name }}
                            </ion-col>

                            <ng-container *ngIf="quotesWithAdders.length === 1">
                                <ion-col [size]="2">
                                    {{ adder.adder_type.name }}
                                </ion-col>

                                <ion-col class="ion-text-right" [size]="1.5" *ngIf="showPrices">
                                    <ng-container [ngTemplateOutlet]="priceTemplate"
                                                  [ngTemplateOutletContext]="{adder: adder}"></ng-container>
                                </ion-col>
                            </ng-container>

                            <ion-col class="ion-text-center"
                                     tabindex="0"
                                     [size]="quotesWithAdders.length > 1 ? 0.75 : 1.25"
                                     (click)="preventClick($event)">
                                <ng-container *ngIf="adder.created_from !== configurationAdderCreatedFromTypes.Wcp && adder.amount_type === amountTypes.STATIC && adder.type === adderTypes.Standard; else quantityTemplate">
                                    <ion-input type="number"
                                               inputmode="numeric"
                                               tqMIntegerInput
                                               min="1"
                                               formControlName="quantity"
                                               required
                                               tabindex="-1"
                                               autocomplete="off"></ion-input>
                                </ng-container>
                                <ng-template #quantityTemplate>
                                    <span>{{ adder.quantity }}</span>
                                </ng-template>
                            </ion-col>

                            <ng-container *ngIf="quotesWithAdders.length >= 1" formArrayName="packages">
                                <ion-col [size]="quotesWithAdders.length === 5 ? 1.5 : 1.75"
                                         class="ion-text-center"
                                         *ngFor="let item of adder.packages; let j = index">
                                    <ion-checkbox (click)="preventClick($event)"
                                                  mode="md"
                                                  [formControlName]="j"></ion-checkbox>
                                </ion-col>
                            </ng-container>
                            <ion-col [size]="0.8"
                                     *ngIf="quotesWithAdders.length < 5 && configureType === configureTypes.Manual"
                                     class="ion-text-center">
                                <ion-checkbox mode="md" [disabled]="true"></ion-checkbox>
                            </ion-col>
                            <i *ngIf="packageNames.length > 1"
                               class="toggle-icon material-icons"
                               [class.expanded]="isShowDetails[adder.id]">keyboard_arrow_down</i>
                        </ion-item>
                    </ion-item-sliding>
                </ion-row>

                <ion-row *ngIf="isShowDetails[adder.id]" class="grid-body ion-align-items-center details">
                    <ion-col [size]="6" [offset]="6" class="details-grid">
                        <ion-row>
                            <ion-col class="details-question" size="6">
                                {{ adderNameSetting?.single }} Category
                            </ion-col>
                            <ion-col class="details-answer" size="6">
                                {{ adder.adder_type.name }}
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="showPrices">
                            <ion-col class="details-question" size="6">List Price</ion-col>
                            <ion-col class="details-answer" size="6">
                                <ng-container [ngTemplateOutlet]="priceTemplate"
                                              [ngTemplateOutletContext]="{adder: adder}"></ng-container>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-template>
    </ion-grid>
</ng-container>

<ng-template #priceTemplate let-adder="adder">
    <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="[amountTypes.PERCENTAGE_OF_SUBTOTAL, amountTypes.PERCENTAGE].includes(adder.amount_type)">
            {{ adder.amount | number:'1.2' }}%
        </ng-container>

        <ng-container *ngSwitchCase="!!adder.amounts?.length">
            <ng-container *ngFor="let amount of adder.amounts; let isFirst = first">
                {{ !isFirst ? '|' : '' }} {{ amount | customCurrency: true }}
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
            {{ adder.amount | customCurrency: true }}
        </ng-container>
    </ng-container>
</ng-template>
