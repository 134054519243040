import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavigationClient } from '@azure/msal-browser';

import { Platform } from '@ionic/angular';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { SpinnerService } from '@core/services/spinner.service';

export class CustomNavigationClient extends NavigationClient {
    constructor(
        private browser: InAppBrowser,
        private deviceHelperService: DeviceHelperService,
        private platform: Platform,
        private spinnerService: SpinnerService
    ) {
        super();
    }

    async navigateExternal(url: string, options: any): Promise<boolean> {
        await this.platform.ready();
        if (!this.deviceHelperService.isWeb) {
            const browser = this.browser.create(url, '_blank', {
                location: 'no',
                clearcache: 'yes',
                clearsessioncache: 'yes',
                hidenavigationbuttons: 'yes',
                hideurlbar: 'yes',
                fullscreen: 'yes',
                zoom: 'no'
            });

            browser.on('loadstart').subscribe((event) => {
                const vendoUrlMatched = event.url.match(
                    /^(http:\/\/*\.|https:\/\/*\.|http:\/\/|https:\/\/)([a-z0-9]+)?([\-\.]?paradigmvendo.com+)/g
                );

                if (vendoUrlMatched && vendoUrlMatched.length) {
                    const path = event.url.split(vendoUrlMatched[0])[1];

                    browser.close();
                    this.spinnerService.showSpinner();
                    window.location.href = path;
                }
            });
        } else {
            if (options.noHistory) {
                window.location.replace(url);
            } else {
                window.location.assign(url);
            }
        }

        return true;
    }
}
