import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMPORT_PROVIA_ORDER, REMOVE_PROVIA_ORDER } from '@core/mutations/provia.mutations';
import { GET_PROVIA_ORDERS, GET_PROVIA_REMEMBER } from '@core/queries/provia.queries';

@Injectable({
    providedIn: 'root'
})
export class ProviaService {
    constructor(private apollo: Apollo) {}

    getOrders(data: any): Observable<any> {
        return this.apollo
            .query({
                query: GET_PROVIA_ORDERS,
                variables: data
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.proviaOrders));
    }

    getRemember(): Observable<any> {
        return this.apollo
            .query({
                query: GET_PROVIA_REMEMBER,
                variables: {
                    type: 'provia'
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) =>
                    res.data.publicRememberProperties && res.data.publicRememberProperties.provia
                        ? res.data.publicRememberProperties.provia
                        : {}
                )
            );
    }

    importOrder(
        userData: any,
        quoteIds: number[],
        appointmentId: number,
        orderNumber: string,
        remember = false,
        usedRemember = false
    ): Observable<any> {
        return this.apollo
            .mutate({
                mutation: IMPORT_PROVIA_ORDER,
                variables: {
                    user_name: userData.user_name,
                    password: userData.password,
                    remember: userData.remember,
                    used_remember: userData.used_remember,
                    quote_ids: quoteIds,
                    order_number: orderNumber,
                    appointment_id: appointmentId
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.importProviaOrder));
    }

    removeOrder(appointmentId: number): Observable<any> {
        return this.apollo.mutate({
            mutation: REMOVE_PROVIA_ORDER,
            variables: {
                appointment_id: appointmentId
            }
        });
    }
}
