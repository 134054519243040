<ion-select interface="popover"
            [interfaceOptions]="{mode: 'md', alignment: 'start'}"
            [(ngModel)]="activePackageId"
            (ionChange)="selectionChanged()"
>
  <ion-select-option *ngFor="let option of packages"
                     [value]="option.id"
  >
    {{ option.package_name }}
  </ion-select-option>
</ion-select>
