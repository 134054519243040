<ng-container *ngIf="items.length; else noData">
  <ion-content class="items-container"
               [ngClass]="{'multi-select': isMultiSelect, 'vertical': !isHorizontalScroll}"
               [scrollY]="!isHorizontalScroll"
               [scrollX]="isHorizontalScroll">
    <ion-grid>
      <ion-row [class.ion-nowrap]="isHorizontalScroll">
        <ion-col *ngFor="let item of items"
                 class="item"
                 [ngClass]="{
                     'selected': isMultiSelect ? selectedValue.includes(item[valueProperty]) : selectedValue === item[valueProperty],
                     'with-title': titleProperty && item[titleProperty]
                 }"
                 (click)="selectItem(item)">
          <ion-img *ngIf="imageProperty" class="glossary-image" [src]="item[imageProperty]"></ion-img>
          <div *ngIf="titleProperty && item[titleProperty]" class="text-wrap">
            <ion-text class="name">{{ item[titleProperty] }}:</ion-text>
          </div>
          <div class="text-wrap">
            <ion-text class="name">{{ item[labelProperty] }}</ion-text>
          </div>

          <p *ngIf="showPosition && isMultiSelect ? selectedValue.includes(item[valueProperty]) : selectedValue === item[valueProperty]"
             class="selected-position">
            {{ selectedValue.indexOf(item[valueProperty]) + 1 }}
          </p>

          <ion-icon *ngIf="!showPosition && isMultiSelect ? selectedValue.includes(item[valueProperty]) : selectedValue === item[valueProperty]"
                    name="checkmark"
                    class="selected-icon"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>

<ng-template #noData>
  <div class="no-data-row">
    {{ noDataText }}
  </div>
</ng-template>
