<div class="modal-header">
    <ion-text class="title">{{headerText}}</ion-text>
</div>

<div class="modal-body">
    <ion-list [ngClass]="{'has-scroll': options && options.length >= 5}">
        <ion-item *ngFor="let option of options">
            <ion-label>{{ option.value }}</ion-label>
            <ion-checkbox *ngIf="!isReadonly"
                          slot="end"
                          mode="md"
                          [checked]="option.checked"
                          [value]="option.value"
                          (ionChange)="selectionChanged($event)"></ion-checkbox>
        </ion-item>
        <ion-item *ngIf="!isReadonly" lines="none" mode="ios">
            <ion-input placeholder="Custom condition" [(ngModel)]="customTag"></ion-input>
            <ion-button [disabled]="!customTag || customTag.length > 20" class="icon-only" fill="outline" slot="end" (click)="addCustom()" color="dark">
                <ion-icon name="add"></ion-icon>
            </ion-button>
        </ion-item>
    </ion-list>
</div>

<div *ngIf="!isReadonly" class="modal-footer">
    <ion-button class="classic-width" (click)="save()" color="dark">Apply</ion-button>
</div>
