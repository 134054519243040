import { Component, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'vendo-multiselect-popover',
    templateUrl: './multiselect-popover.component.html',
    styleUrls: ['./multiselect-popover.component.scss']
})
export class MultiSelectPopoverComponent {
    @Input() items: any[];
    @Input() names: string[];
    @Input() applyButtonText = 'Import';

    constructor(private popoverController: PopoverController) {}

    pickPackages(): void {
        const selectedPackages = this.items.filter((item) => item.checked);

        this.popoverController.dismiss(selectedPackages.map((item) => item.partial_quote_id));
    }
}
