import { Component, Input, OnInit } from '@angular/core';

import { SpinnerService } from '@core/services/spinner.service';

@Component({
    selector: 'vendo-pdf-document-viewer',
    templateUrl: './pdf-document-viewer.component.html',
    styleUrls: ['./pdf-document-viewer.component.scss']
})
export class PdfDocumentViewerComponent implements OnInit {
    @Input() url: string;

    constructor(private spinnerService: SpinnerService) {}

    ngOnInit(): void {
        this.spinnerService.showSpinner();
    }

    hideSpinner(): void {
        this.spinnerService.hideSpinner();
    }
}
