import { Pipe, PipeTransform } from '@angular/core';

import startCase from 'lodash/startCase';

@Pipe({
    name: 'appointmentStatus'
})
export class AppointmentStatusPipe implements PipeTransform {
    transform(value: string): string {
        return value ? startCase(value).replace('w Up', 'w-Up') : value;
    }
}
