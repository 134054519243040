import { gql } from 'apollo-angular';

const RESEND_EMAIL = gql`
    mutation ResendEmail(
        $appointmentId: ID!
        $mailId: ID!
        $useLocalClient: Boolean
        $recipients: [String!]!
        $message: String!
    ) {
        resendEmail(
            id: $appointmentId
            mail_id: $mailId
            use_local_client: $useLocalClient
            email_message: $message
            emails_send_to: $recipients
        )
    }
`;

export { RESEND_EMAIL };
