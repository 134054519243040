<div class="modal-header">
  <div class="title-section">
    <div class="title">{{signerName}}'s {{signatureType}}</div>
    <div class="description">Draw your {{signatureType | lowercase}} on the field below. You can clear and retry it as much as you need.</div>
  </div>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>
<div class="modal-body" #modalBody>
  <span class="font-preload">a</span>
    <div class="tab-content">
      <ion-input [(ngModel)]="initials" (ngModelChange)="redrawInitials()" *ngIf="viewMode === 'initials'"></ion-input>
      <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawingStarted()"></signature-pad>
    </div>
</div>
<div class="modal-footer">
  <ion-button class="classic-width" fill="outline" (click)="clear()">Clear</ion-button>
  <ion-button class="classic-width" [disabled]="!isDoneEnabled()" (click)="done()">Confirm</ion-button>
</div>
