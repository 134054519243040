import { Injectable } from '@angular/core';

import { ActionSheetController, MenuController, ModalController, PopoverController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AutoCloseOverlaysService {
    constructor(
        private actionSheetController: ActionSheetController,
        private popoverController: PopoverController,
        private modalController: ModalController,
        private menuController: MenuController
    ) {}

    async trigger(): Promise<void> {
        await this.closeActionSheet();

        await this.closePopover();

        await this.closeModal();

        await this.closeSideMenu();
    }

    private async closeActionSheet(): Promise<void> {
        try {
            const element: HTMLIonActionSheetElement = await this.actionSheetController.getTop();

            if (element) {
                await element.dismiss();
            }
        } catch (error) {}
    }

    private async closePopover(): Promise<void> {
        try {
            const element: HTMLIonPopoverElement = await this.popoverController.getTop();

            if (element) {
                await element.dismiss();
            }
        } catch (error) {}
    }

    private async closeModal(): Promise<void> {
        try {
            const element = await this.modalController.getTop();

            if (element) {
                await element.dismiss();
            }
        } catch (error) {}
    }

    private async closeSideMenu(): Promise<void> {
        try {
            const element: HTMLIonMenuElement = await this.menuController.getOpen();

            if (element !== null) {
                await this.menuController.close();
            }
        } catch (error) {}
    }
}
