import range from 'lodash/range';

import { CreateAppointmentTab } from './create-appointment-modal.interfaces';

export const DURATION_OPTIONS = [
    {
        label: '30 minutes',
        value: 0.5
    },
    {
        label: '1 hour',
        value: 1
    },
    {
        label: '1 hour 30 minutes',
        value: 1.5
    },
    {
        label: '2 hours',
        value: 2
    },
    {
        label: '3 hours',
        value: 3
    }
];

export const TAB_APPOINTMENT = 'appointment';
export const TAB_CUSTOMER = 'customer';
export const TAB_HOUSE = 'house';

export const TABS: CreateAppointmentTab[] = [
    {
        hash: TAB_APPOINTMENT,
        label: 'Appointment',
        formValues: {},
        options: {},
        errors: {},
        isValid: false
    },
    {
        hash: TAB_CUSTOMER,
        label: 'Customer',
        formValues: {},
        options: {},
        errors: {},
        isValid: false
    },
    {
        hash: TAB_HOUSE,
        label: 'Address',
        formValues: {},
        options: {},
        errors: {},
        isValid: false
    }
];

export const MINUTE_VALUES: number[] = range(0, 59, 15);
export const HOUR_VALUES: number[] = range(1, 13);
export const pickerOptionNearestMinuteValue = (minutes: number): number => {
    for (const minute of MINUTE_VALUES) {
        if (minute >= minutes) {
            return minute;
        }
    }

    return MINUTE_VALUES[0];
};
