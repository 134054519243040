<div class="modal-header">
    <ion-text class="title">Pricing Details</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>

<div class="modal-body">
    <ion-grid>
        <ion-content>
            <ion-row class="header-row">
                <ion-col size="6">Name</ion-col>
                <ion-col size="3">Original</ion-col>
                <ion-col class="ion-text-end" size="3">New</ion-col>
            </ion-row>

            <ion-row *ngFor="let impact of quote.changes.impacts; let i = index" class="row-item">
                <ion-col size="6">
                    {{ impact.label }}
                </ion-col>

                <ion-col size="3">
                    {{ impact.old }}

                </ion-col>

                <ion-col class="ion-text-end font-weight-500" size="3">
                    {{ impact.new }}
                </ion-col>
            </ion-row>
        </ion-content>

        <ion-row class="row-item total-result">
            <ion-col size="8">
                Price Impact
            </ion-col>
            <ion-col class="ion-text-end" size="4">
                {{ quote.changes.impacts_sum }}
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
