import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { IonSlides } from '@ionic/angular';

@Component({
    selector: 'vendo-configurator-warnings',
    templateUrl: './configurator-warnings.component.html',
    styleUrls: ['./configurator-warnings.component.scss']
})
export class ConfiguratorWarningsComponent {
    @Input() set configurator(configurator) {
        if (configurator) {
            if (configurator.Limitations) {
                configurator.Limitations = configurator.Limitations.filter(
                    (item) => !item.IsHardStop || (item.IsHardStop && !item.SourceQuestions)
                );

                const hiddenStepId = configurator.StepsInfo.find((step) => step.Name === 'Hidden')?.ID;

                if (hiddenStepId) {
                    configurator.Limitations.forEach(
                        (limitation) =>
                            (limitation.SourceQuestions = limitation.SourceQuestions.filter(
                                (q) => q.StepId !== hiddenStepId
                            ))
                    );
                }
            }

            this.configuratorData = configurator;
        }
    }
    @Output() warmingClicked: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('warningSlides') warningSlides: IonSlides;
    activeWarningSlideIndex = 0;
    configuratorData;

    async warningSlideChanged(): Promise<void> {
        this.activeWarningSlideIndex = await this.warningSlides.getActiveIndex();
    }
}
