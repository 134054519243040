<div class="rating">
  <div *ngFor="let item of ratingRange; let i = index"
       class="rating-item"
       [class.selected-item]="item === ratingControl.value"
       (click)="rate(i)">
    {{ item }}
  </div>
</div>

<div class="labels">
  <span>Very unlikely</span>
  <span>Very likely</span>
</div>
