import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-opening-drawing-modal',
    templateUrl: './opening-drawing-modal.component.html',
    styleUrls: ['./opening-drawing-modal.component.scss']
})
export class OpeningDrawingModalComponent extends BaseModal implements OnInit {
    @Input() drawingData: any;

    constructor(modalCtrl: ModalController) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        if (
            (!this.drawingData.image_url && this.drawingData.opening_drawings?.length) ||
            this.drawingData.image_url?.includes('https://')
        ) {
            this.drawingData.image_url = this.drawingData.opening_drawings[0].url;
        } else {
            this.drawingData.image_url = `https://${this.drawingData.image_url}`;
        }
    }
}
