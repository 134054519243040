import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sidingMeasurementType'
})
export class SidingMeasurementTypePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'Feet':
                return 'Ft.';
            case 'Inches':
                return 'In.';
            case 'SqFt':
                return 'SqFt.';
            case 'Squares':
                return 'Sqs.';
        }

        return value;
    }
}
