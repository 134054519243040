import { gql } from 'apollo-angular';

const GET_MY_USER_PREFERENCE = gql`
    query myUserPreference($type: String!) {
        myUserPreference(type: $type) {
            value
        }
    }
`;

const GET_MY_USER_PREFERENCES = gql`
    query MyUserPreferences($types: [String!]!) {
        myUserPreferences(types: $types) {
            value
            type
        }
    }
`;

const SHOW_PRICING_ENABLED = gql`
    query {
        showPriceEnabled
    }
`;

export { GET_MY_USER_PREFERENCE, GET_MY_USER_PREFERENCES, SHOW_PRICING_ENABLED };
