import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { AlertController, ModalController, NavController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import round from 'lodash/round';

import { QuoteService } from '@core/services/quote.service';
import { StepsService } from '@core/services/steps.service';
import { ToastService } from '@core/services/toast.service';
import { PaymentErrorModalComponent } from '@shared/components/payment-error-modal/payment-error-modal.component';
import { PaymentSystem } from '@shared/enums/payment-system';
import { QuoteStepType } from '@shared/enums/quote-step-type.enum';
import { TransactionStatus } from '@shared/enums/transaction-status.enum';
import { SurchargeInfo } from '@shared/interfaces/surcharge-info';

import { PROCESS_CHECK, SEND_QUICK_INVOICE } from '../../main/appointments/final-steps/mutations/payment.mutations';
import {
    GENERATE_PAYMENT_FORM,
    GENERATE_UNLINKED_PAYMENT_FORM,
    GET_PAYMENT_TOKEN,
    GET_QUOTE_INVOICES,
    GET_SURCHARGE_INFO,
    GET_TRANSACTION_STATUS,
    GET_UNLINKED_PAYMENTS,
    GET_UNLINKED_TRANSACTION_STATUS
} from '../../main/appointments/final-steps/queries/payment.queries';

declare let moment: any;

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(
        private quoteService: QuoteService,
        private stepsService: StepsService,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        private modalController: ModalController,
        private apollo: Apollo,
        private toastService: ToastService
    ) {}

    async handlePayment(appointmentId: string, quoteId: number, data: string): Promise<void> {
        const parsedData: any = JSON.parse(decodeURIComponent(data));

        if (parsedData.ErrorMessages) {
            this.showAlert(parsedData.ErrorMessages);

            return;
        }

        const successStatuses = ['Authorized', 'Posted'];

        if (successStatuses.indexOf(parsedData.Status) === -1) {
            this.showAlert('Something goes wrong');

            return;
        }

        await this.quoteService
            .syncPayment(quoteId, parsedData.PaymentId)
            .toPromise()
            .then(async (res) => await this.handlePaymentInfo(appointmentId, res));
    }

    async handlePaymentInfo(appointmentId: string, res: any, path?: string): Promise<void> {
        const paymentProcessed = res.transaction_status === 'Success';

        if (paymentProcessed) {
            await this.toastService.showMessage('Your payment has been successfully processed!');
            this.completeStep(appointmentId, path);
        } else {
            const errors = JSON.parse(res.transaction_details) || [];

            this.openPaymentErrorModal(errors.pop());
        }
    }

    generatePaymentForm(
        paymentType: PaymentSystem,
        quoteId: string,
        paymentMethod: string,
        contactData: any,
        applySurcharge = true
    ): Observable<any> {
        return this.apollo
            .query({
                query: GENERATE_PAYMENT_FORM,
                variables: {
                    paymentType,
                    quoteId,
                    paymentMethod,
                    contactData,
                    applySurcharge
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.generatePaymentForm));
    }

    generateUnlinkedPaymentForm(
        paymentType: PaymentSystem,
        paymentMethod: string,
        contactData: any,
        applySurcharge = true
    ): Observable<any> {
        return this.apollo
            .query({
                query: GENERATE_UNLINKED_PAYMENT_FORM,
                variables: {
                    paymentType,
                    paymentMethod,
                    contactData,
                    applySurcharge
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.generateUnlinkedPaymentForm));
    }

    getSurchargeInfo(amount: number): Observable<{ surchargeInfo: SurchargeInfo; surchargeValue: number }> {
        return this.apollo
            .query({
                query: GET_SURCHARGE_INFO
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const surchargeInfo = res.data.getSurchargeInfo;
                    let surchargeValue = 0;

                    if (surchargeInfo) {
                        surchargeValue =
                            surchargeInfo.surcharge_fee || round(amount * (surchargeInfo.surcharge_rate / 100), 2);

                        if (surchargeValue < surchargeInfo.min_fee_amount) {
                            surchargeValue = surchargeInfo.min_fee_amount;
                        } else if (surchargeValue > surchargeInfo.max_fee_amount) {
                            surchargeValue = surchargeInfo.max_fee_amount;
                        }
                    }

                    return {
                        surchargeInfo,
                        surchargeValue
                    };
                })
            );
    }

    getTransactionStatus(quoteId: string): Observable<TransactionStatus> {
        return this.apollo
            .query({
                query: GET_TRANSACTION_STATUS,
                variables: {
                    quoteId
                },
                context: {
                    extensions: {
                        background: true
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.getTransactionStatus));
    }

    getUnlinkedTransactionStatus(
        transactionId: string
    ): Observable<{ status: TransactionStatus; error_message?: string }> {
        return this.apollo
            .query({
                query: GET_UNLINKED_TRANSACTION_STATUS,
                variables: {
                    transactionId
                },
                context: {
                    extensions: {
                        background: true
                    }
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.getUnlinkedTransactionStatus));
    }

    getUnlinkedPayments(): Observable<any[]> {
        return this.apollo
            .query({
                query: GET_UNLINKED_PAYMENTS
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.myUnlinkedPayments));
    }

    getPaymentToken(): Observable<any> {
        return this.apollo
            .query({
                query: GET_PAYMENT_TOKEN
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.paymentToken));
    }

    getQuoteInvoices(appointmentId: number): Observable<any> {
        return this.apollo
            .query({
                query: GET_QUOTE_INVOICES,
                variables: {
                    appointmentId
                }
            })
            .pipe(
                map((res: ApolloQueryResult<any>) => {
                    const invoices = res.data.myQuoteInvoices;

                    invoices.map((invoice) => {
                        invoice.is_expired =
                            moment.utc().isAfter(moment(invoice.due_date)) && invoice.payment_status !== 'Completed';

                        return invoice;
                    });

                    return invoices;
                })
            );
    }

    makePayment(appointmentId: string, quoteId: string, path: string, accountInfo: any): void {
        this.quoteService
            .makePayment(quoteId, accountInfo)
            .subscribe((res) => this.handlePaymentInfo(appointmentId, res, path));
    }

    sendQuickInvoice(appointmentId: number, invoiceData: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: SEND_QUICK_INVOICE,
                variables: {
                    appointmentId,
                    ...invoiceData
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.sendMyQuickInvoice));
    }

    processCheck(quoteId: string, images: any): Observable<any> {
        return this.apollo
            .mutate({
                mutation: PROCESS_CHECK,
                variables: {
                    quoteId,
                    ...images
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.processCheck));
    }

    completeStep(appointmentId: number | string, path?: string, replaceUrl = false): void {
        this.stepsService.completeStep(appointmentId, QuoteStepType.Payment).subscribe(() => {
            if (path) {
                this.navCtrl.navigateRoot(path, {
                    replaceUrl
                });
            }
        });
    }

    async showAlert(message: string): Promise<any> {
        const alert = await this.alertCtrl.create({
            header: 'Alert',
            message,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'OK',
                    handler: () => {}
                }
            ]
        });

        await alert.present();
    }

    private openPaymentErrorModal(error: any): void {
        this.modalController
            .create({
                component: PaymentErrorModalComponent,
                componentProps: {
                    error
                }
            })
            .then((modal) => {
                modal.present();
            });
    }
}
