import { Injectable } from '@angular/core';

import every from 'lodash/every';
import isArray from 'lodash/isArray';
import some from 'lodash/some';

import { DeviceHelperService } from '@core/services/device-helper.service';
import { FEATURES } from '@shared/constants/features';

@Injectable({
    providedIn: 'root'
})
export class FeaturesService {
    private userFeatures: any[] = [];

    constructor(private deviceHelperService: DeviceHelperService) {}

    setFeatures(features: any[]): void {
        this.userFeatures = features;
    }

    hasFeature(features: string | string[], requireEvery = true): boolean {
        if (isArray(features)) {
            if (requireEvery) {
                return every(features, (feature) => this.userFeatures.includes(feature));
            } else {
                return some(features, (feature) => this.userFeatures.includes(feature));
            }
        } else {
            return this.userFeatures.includes(features);
        }
    }

    isOfflineModeAvailable(): boolean {
        return (
            (this.deviceHelperService.isIOSPlatform || this.deviceHelperService.isAndroidPlatform) &&
            !this.deviceHelperService.isPhone &&
            !this.deviceHelperService.isWeb &&
            this.hasFeature(FEATURES.OFFLINE_MODE)
        );
    }
}
