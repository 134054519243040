import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { ThemeDirective } from './directives/theme.directive';
import { ThemeService } from './services/theme.service';
import { ACTIVE_THEME, Theme, THEMES } from '@core/modules/theme/symbols';

export interface ThemeOptions {
    themes: Theme[];
    active?: string;
}

@NgModule({
    imports: [CommonModule],
    providers: [ThemeService],
    declarations: [ThemeDirective],
    exports: [ThemeDirective]
})
export class ThemeModule {
    static forRoot(options: ThemeOptions): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                {
                    provide: THEMES,
                    useValue: options.themes
                },
                {
                    provide: ACTIVE_THEME,
                    useValue: options.active
                }
            ]
        };
    }
}
