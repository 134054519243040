import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortItemsByValues'
})
export class SortItemsByValuesPipe implements PipeTransform {
    /**
     * @example items = [{id: 4}, {id: 1}, {id: 2}, {id: 3}];
     * @example values = [2, 3, 1];
     * @example field = 'id';
     * @example response = [{id: 2}, {id: 3}, {id: 1}, {id: 4}];
     */
    transform<T extends object, K extends keyof T>(items: T[], values: T[K][], field: K): T[] {
        return items.slice().sort((firstItem: T, secondItem: T): number => {
            const firstSelectedField: T[K] = firstItem[field];
            const secondSelectedField: T[K] = secondItem[field];

            const selectedFirstIndex: number = values.indexOf(firstSelectedField);

            if (selectedFirstIndex === -1) {
                return 1;
            }

            const selectedSecondIndex: number = values.indexOf(secondSelectedField);

            if (selectedSecondIndex === -1) {
                return -1;
            }

            return selectedFirstIndex > selectedSecondIndex ? 1 : -1;
        });
    }
}
