import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    transform(value: string[], separator = ', '): unknown {
        if (!value || !value.length) {
            return '';
        }

        return value.join(separator);
    }
}
