<div class="modal-header">
  <ion-text class="title">Add to existing</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ion-content>
    <ion-accordion-group [multiple]="true" [formGroup]="form">
      <ng-container *ngFor="let group of groups">
        <ng-container *ngIf="group.items.length" [formGroupName]="group.type">
          <ion-accordion mode="md">
            <ion-item slot="header">
              <label>{{ group.title }} <ng-container *ngIf="form.get([group.type, 'countSelected']).value">({{ form.get([group.type, 'countSelected']).value }})</ng-container></label>
              <ion-checkbox slot="end" formControlName="isAllSelected" (click)="$event.stopPropagation()"></ion-checkbox>
            </ion-item>

            <ion-list slot="content" formArrayName="items">
              <ion-item *ngFor="let opening of group.items; let i = index">
                <ng-container [formGroupName]="i">
                  <ion-col class="name-col">
                    {{ opening.name }}
                  </ion-col>
                  <ion-col size="3.5">
                    {{ opening.answers['final_area'] | number }} {{ opening.answers['measurement_type'] }}
                  </ion-col>
                  <ion-col size="1.64">
                    <ion-checkbox mode="md" formControlName="isSelected"></ion-checkbox>
                  </ion-col>
                </ng-container>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ng-container>
      </ng-container>
    </ion-accordion-group>
  </ion-content>
</div>

<div class="modal-footer">
  <ion-button fill="outline"
              (click)="closeModal()">
    Cancel
  </ion-button>
  <ion-button [disabled]="!isExistTakeoffItems" (click)="save()">
    Proceed
  </ion-button>
</div>
