import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { ModalController, Platform, PopoverController } from '@ionic/angular';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfirmationModalComponent } from '../../../../components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'vendo-items-list-popover',
    templateUrl: './items-list-popover.component.html',
    styleUrls: ['./items-list-popover.component.scss']
})
export class ItemsListPopoverComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() set items(value: any[]) {
        this._items = value.map((item) => {
            item.measurement_type = item.type.hash === 'facet' ? 'sqft.' : 'ft.';

            return item;
        });
    }

    get items(): any[] {
        return this._items;
    }

    @Input() visibilityMap: any;
    @Input() propagateActiveItem: any;
    @Input() askAboutMeasurements = true;

    generalVisibility = false;
    activeItem: any;
    form = new UntypedFormGroup({
        id: new UntypedFormControl(null, Validators.required),
        name: new UntypedFormControl('', Validators.required),
        distance: new UntypedFormControl(0, Validators.required)
    });

    distanceRatio: number;

    private _items: any[];
    private offsetChanged = false;
    private isKeyboardOpened = false;
    private destroy$: Subject<void> = new Subject<void>();

    deletedItems = [];
    modifiedItems = [];

    constructor(
        private popoverController: PopoverController,
        private modalCtrl: ModalController,
        private keyboard: Keyboard,
        private platform: Platform,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const visibleCount = this.items.reduce((acc, item) => {
            return (acc += this.visibilityMap[item.id] ? 0 : 1);
        }, 0);

        if (visibleCount < this.items.length) {
            this.generalVisibility = true;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.handleIosKeyboard(), 1000);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.keyboard.disableScroll(true);
    }

    async setActiveItem(event: any, item: any): Promise<any> {
        if (item.is_deleted) {
            return;
        }
        this.activeItem = item;
        this.propagateActiveItem(item);

        this.form.reset({
            id: item.id,
            name: item.name,
            distance: item.distance
        });

        const popover = await this.popoverController.getTop();
        const shadowDom = popover.shadowRoot;
        const content = shadowDom.querySelector('.popover-content') as HTMLDivElement;

        if (content.offsetHeight < 240) {
            content.style.top = `${content.offsetTop - 146}px`;
            this.offsetChanged = true;
        }

        setTimeout(() => event.target.scrollIntoView(true));
    }

    async saveChanges(): Promise<any> {
        if (!this.form.valid) {
            return;
        }

        const formValues = this.form.getRawValue();

        if (this.activeItem.distance !== formValues.distance && this.askAboutMeasurements) {
            await this.updateMeasurements(this.activeItem.distance, formValues.distance);
        }

        Object.assign(this.activeItem, this.form.getRawValue());
        this.modifiedItems.push(this.activeItem);

        this.activeItem = null;
        this.propagateActiveItem(null);

        const popover = await this.popoverController.getTop();
        const shadowDom = popover.shadowRoot;
        const content = shadowDom.querySelector('.popover-content') as HTMLDivElement;

        if (this.offsetChanged) {
            content.style.top = `${content.offsetTop + 146}px`;
            this.offsetChanged = false;
        }
    }

    async updateMeasurements(oldValue: number, newValue: number): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ConfirmationModalComponent,
            componentProps: {
                headerText: 'Update Measurements',
                confirmButtonName: 'Update',
                message: 'Would you like to update other measurements appropriately?'
            }
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data) {
            this.distanceRatio = newValue / oldValue;

            if (this.distanceRatio) {
                this._items.forEach((item) => {
                    item.distance = Math.ceil(item.distance * this.distanceRatio * 100) / 100;
                    this.modifiedItems.push(item);
                });
            }
        } else {
            this.askAboutMeasurements = false;
        }
    }

    async deleteItem(id: number): Promise<any> {
        const deletedItem = this.items.find((item) => item.id === id);

        this.deletedItems.push(deletedItem);
        deletedItem.is_deleted = true;
        this.visibilityMap[deletedItem.id] = true;
        this.activeItem = null;
        this.propagateActiveItem(null);

        const popover = await this.popoverController.getTop();
        const shadowDom = popover.shadowRoot;
        const content = shadowDom.querySelector('.popover-content') as HTMLDivElement;

        if (this.offsetChanged) {
            content.style.top = `${content.offsetTop + 146}px`;
            this.offsetChanged = false;
        }

        if (!this.items.length) {
            this.close();
        }
    }

    restoreItem(event: Event, item: any): void {
        event.stopPropagation();
        item.is_deleted = false;
        this.visibilityMap[item.id] = false;
        this.deletedItems = this.deletedItems.filter((deletedItem) => deletedItem.id !== item.id);
    }

    toggleItemVisibility(event: any, id: number): void {
        event.stopPropagation();
        this.visibilityMap[id] = !this.visibilityMap[id];

        const visibleCount = this.items.reduce((acc, item) => {
            return (acc += this.visibilityMap[item.id] ? 0 : 1);
        }, 0);

        let newVisibility = false;

        if (visibleCount < this.items.length) {
            newVisibility = true;
        }

        this.generalVisibility = newVisibility;
    }

    toggleAllItemsVisibility(): void {
        this.generalVisibility = !this.generalVisibility;
        this._items.forEach((item) => {
            if (item.is_deleted) {
                return;
            }
            this.visibilityMap[item.id] = this.generalVisibility;
        });
    }

    async close(): Promise<any> {
        const popover = await this.popoverController.getTop();

        popover.dismiss({
            deleted: this.deletedItems,
            modified: this.modifiedItems,
            distanceRatio: this.distanceRatio
        });
    }

    private async handleIosKeyboard(): Promise<any> {
        if (this.platform.is('ios')) {
            const popover = await this.popoverController.getTop();
            const shadowDom = popover.shadowRoot;
            const content = shadowDom.querySelector('.popover-content') as HTMLDivElement;
            let keyboardHeight = 0;
            let offsetTopChangedBy = 0;

            fromEvent(window, 'keyboardDidShow')
                .pipe(takeUntil(this.destroy$))
                .subscribe((event) => {
                    if (this.isKeyboardOpened) {
                        return;
                    }

                    keyboardHeight = (event as any).keyboardHeight;
                    const activeElement = document.activeElement as any;
                    const elementOffsetTop =
                        activeElement.offsetParent.offsetTop + activeElement.offsetParent.offsetParent.offsetTop;
                    let newPopoverOffsetTop =
                        content.offsetTop - keyboardHeight + activeElement.offsetParent.offsetParent.clientHeight;

                    if (Math.abs(newPopoverOffsetTop) > elementOffsetTop && newPopoverOffsetTop < 0) {
                        newPopoverOffsetTop = 20;
                    }

                    offsetTopChangedBy = content.offsetTop - newPopoverOffsetTop;

                    content.style.top = `${newPopoverOffsetTop}px`;

                    this.isKeyboardOpened = true;
                    this.cdr.detectChanges();
                    if (activeElement) {
                        const scrollableContainer = document.querySelector('.items-container');

                        scrollableContainer.scrollTop = activeElement.offsetParent.offsetParent.offsetTop + 40;
                        this.cdr.detectChanges();
                    }
                });

            fromEvent(window, 'keyboardWillHide')
                .pipe(takeUntil(this.destroy$))
                .subscribe((event) => {
                    this.isKeyboardOpened = false;
                    content.style.top = `${content.offsetTop + offsetTopChangedBy}px`;
                    this.cdr.detectChanges();
                });
        }
    }
}
