import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import map from 'lodash/map';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-list-promotion-items',
    templateUrl: './list-promotion-items.component.html',
    styleUrls: ['./list-promotion-items.component.scss']
})
export class ListPromotionItemsComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() items: any[] = [];
    @Input() stackablePromotions: any[] = [];
    form: UntypedFormGroup;

    private destroy$: Subject<void> = new Subject<void>();

    constructor(public modalCtrl: ModalController, private formBuilder: UntypedFormBuilder) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({});
        const allIds = [];
        const selectedIds = [];
        let idsToDisable = [];

        this.items.forEach((item) => {
            allIds.push(+item.id);

            if (item.isSelected) {
                selectedIds.push(+item.id);
            }
        });

        if (selectedIds.length) {
            const idsToEnable = this.stackablePromotions.map(({ id }) => id);

            idsToDisable = difference(allIds, idsToEnable);
        }

        this.items.forEach((item) => {
            this.form.addControl(
                item.id,
                new UntypedFormControl({
                    value: item.isSelected,
                    disabled: idsToDisable.includes(+item.id) && !item.isSelected
                })
            );
        });

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
            const trueIds = [];
            let idsToEnable = [];
            let idsToDisable = [];

            for (const id in values) {
                if (values.hasOwnProperty(id) && values[id] === true) {
                    trueIds.push(+id);
                }
            }

            idsToEnable = this.getStackablePromotions(trueIds);

            if (idsToEnable.length) {
                idsToDisable = difference(allIds, idsToEnable);
            }

            if (idsToDisable.length) {
                idsToDisable.forEach((id) => {
                    if (!this.form.get(`${id}`).value) {
                        this.form.get(`${id}`).reset(false, { emitEvent: false });
                        this.form.get(`${id}`).disable({ emitEvent: false });
                    }
                });
            } else {
                allIds.forEach((id) => {
                    this.form.get(id.toString()).enable({ emitEvent: false });
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    apply(): void {
        if (this.form.invalid) {
            return;
        }

        for (const formId in this.form.getRawValue()) {
            this.items.find(({ id }) => id == formId).isSelected = this.form.get(formId).value;
        }

        this.dismiss(this.items);
    }

    getStackablePromotions(selectedPromotionIds = []): number[] {
        if (!selectedPromotionIds.length) {
            return this.items.map((item) => +item.id);
        }

        const stackablePromotions = cloneDeep(this.stackablePromotions);
        const selectedPromotions = stackablePromotions.filter(({ id }) => selectedPromotionIds.includes(id));
        const stackablePromotionIds = intersection(...map(selectedPromotions, 'stackable_with_ids'));

        return [
            ...selectedPromotions,
            ...stackablePromotions.filter(({ id }) => stackablePromotionIds.includes(id))
        ].map((item) => item.id);
    }
}
