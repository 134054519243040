import { Component, Input } from '@angular/core';

@Component({
    selector: 'vendo-stepper-progress-bar',
    templateUrl: './stepper-progress-bar.component.html',
    styleUrls: ['./stepper-progress-bar.component.scss']
})
export class StepperProgressBarComponent {
    @Input() selectedStep = 1;
    @Input() totalSteps = 2;
}
