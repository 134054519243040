<div class="take-photo" *ngIf="!isStreamActive">
    <div class="wrapper is-a-modal">
        <div class="preview-photo">
            <ng-container *ngIf="selectedPhoto?.type === appointmentImageType.Predefined || isReadOnly; else newLabelTemplate">
                <div class="label">
                    <ion-label class="selected-label" slot="start" position="fixed">Label</ion-label>
                    <div class="image text-ellipsis">{{selectedPhoto?.name || selectedPhoto?.label}}</div>
                </div>
            </ng-container>

            <ng-template #newLabelTemplate>
                <div class="photo">
                    <ion-label slot="start" position="fixed" class="photo-label">Label</ion-label>
                    <ion-input type="text"
                               class="label-input"
                               maxlength="50"
                               [disabled]="!selectedPhoto?.url"
                               [placeholder]="selectedPhoto?.name"
                               [value]="selectedPhoto?.name"
                               [readonly]="photosList.length === 0"
                               (ionChange)="setValue($event, 'name')"></ion-input>
                </div>
            </ng-template>

            <ng-container *ngIf="selectedPhoto?.url; else emptyTemplate">
                <div class="img-wrapper"
                     (click)="showActionsPopover($event, selectedPhoto.type, selectedPhoto.index)">
                    <i *ngIf="isOfflineMode && selectedPhoto.url.startsWith('https')"
                       slot="icon-only"
                       class="material-icons">
                        image_not_supported
                    </i>
                    <img *ngIf="!isOfflineMode || !selectedPhoto.url.startsWith('https')"
                         class="image"
                         [src]="selectedPhoto?.url">
                </div>
            </ng-container>

            <ng-template #emptyTemplate>
                <div class="empty-image" (click)="takePhoto()">
                    <ion-icon name="camera"></ion-icon>
                </div>
            </ng-template>
            <ion-item class="input-item" lines="none">
                <ion-label slot="start" position="fixed" class="notes-label">Notes</ion-label>
                <ion-textarea maxlength="50"
                              [disabled]="!selectedPhoto?.url || isReadOnly"
                              #inputField
                              [value]="selectedPhoto?.note"
                              (ionChange)="setValue($event, 'note')"
                              rows="3">
                </ion-textarea>
            </ion-item>
        </div>
        <div class="photo-list">
            <ion-list lines="none" mode="ios">
                <ng-container *ngFor="let photo of photosList; let i = index">
                    <div class="img-item"
                         (click)="selectPhoto(photo)"
                         [ngClass]="{
                              'required': photo?.is_required && !photo?.url,
                              'selected': photo?.index === selectedPhoto?.index,
                              'without-name': photo.name === ''
                         }">
                        <ng-container *ngIf="photo?.url">
                            <i *ngIf="isOfflineMode && photo.url.startsWith('https')"
                               slot="icon-only"
                               class="material-icons"
                               (click)="showActionsPopover($event, photo.type, i)">
                                image_not_supported
                            </i>
                            <img *ngIf="!isOfflineMode || !photo.url.startsWith('https')"
                                 class="image"
                                 (click)="showActionsPopover($event, photo.type, i)"
                                 [src]="photo?.url">
                            <input *ngIf="isDesktopWeb || isTabletWeb"
                                   class="upload-photo-input full-size"
                                   type="file"
                                   [accept]="allowedFileExtensions"
                                   #fileUpload
                                   (change)="fileUploaded($event, i)"
                                   (click)="$event.stopPropagation()"/>
                        </ng-container>

                        <div *ngIf="!photo?.url" class="add-image" (click)="takePhoto()">
                            <ion-icon name="add-outline"></ion-icon>
                            <input *ngIf="isDesktopWeb || isTabletWeb"
                                   class="upload-photo-input full-size"
                                   type="file"
                                   [accept]="allowedFileExtensions"
                                   #fileUpload
                                   (change)="fileUploaded($event, i)"
                                   (click)="$event.stopPropagation()"/>
                        </div>
                        <div class="image-label">{{photo.name || photo.label}}</div>
                    </div>
                </ng-container>
            </ion-list>

            <ng-container *ngIf="!isReadOnly">
                <ion-button fill="clear"
                            [disabled]="photosList.length >= maxImages"
                            (click)="addImage()">
                    <ion-icon class="add-icon" slot="icon-only" name="add-circle"></ion-icon>
                        Add Image
                </ion-button>

                <ion-button [disabled]="!isListValid"
                            class="classic-width"
                            (click)="save()">
                    Save
                </ion-button>
            </ng-container>
        </div>
    </div>
</div>

<div class="video-player" *ngIf="isStreamActive"
                          [ngClass]="{ 'project-tab' : type === appointmentImageType.Project}">
    <video #videoPlayer width="485" autoplay></video>
    <ion-button class="icon-only close-stream" fill="outline" (click)="closeStream()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
    <ion-button class="icon-only take-photo-button" fill="clear" (click)="takeSnapshot()">
        <ion-icon name="camera-outline"></ion-icon>
    </ion-button>
    <canvas #canvas style="display: none;"></canvas>
</div>
