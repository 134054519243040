<ion-content>
    <ion-grid>
        <ion-row class="header-row">
            <ion-col size="3">Order #</ion-col>
            <ion-col size="3">PO #</ion-col>
            <ion-col size="3">Job Name</ion-col>
            <ion-col size="3">Date Entered</ion-col>
        </ion-row>
        <ion-row *ngFor="let order of orders" class="table-row">
            <ion-col size="3">
                <a (click)="pickOrder(order, $event)">{{ order.orderno }}</a>
            </ion-col>
            <ion-col size="3">{{ order.ponumber }}</ion-col>
            <ion-col size="3">{{ order.jobname }}</ion-col>
            <ion-col size="3">{{ order.enterdate }}</ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
