import { UntypedFormArray, ValidatorFn } from '@angular/forms';

export function RequiredOneTrueValidator(key?: string): ValidatorFn {
    return (form: UntypedFormArray) => {
        const values: any[] = form.getRawValue();

        const isInvalid = !values.some((value: any) => (key ? value[key] : value));

        if (isInvalid) {
            return {
                required: true
            };
        }

        return null;
    };
}
