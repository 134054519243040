<ion-grid>
    <ion-row *ngFor="let adder of adders">
        <ion-col>
            {{ adder.name }}
        </ion-col>
        <ion-col size="4" class="ion-text-end">
            <ion-text *ngIf="adder.total_price !== adder.display_price"
                      class="discounted-price"
                      color="secondary">
                {{ lineItemQuantity || adder.quantity }} x {{ adder.display_price | customCurrency: true }}
            </ion-text>
            <ion-text color="primary"
                      [class.discounted]="adder.total_price !== adder.display_price">
                <span *ngIf="adder.total_price === adder.display_price">{{ lineItemQuantity || adder.quantity }}
                    x </span> {{ adder.total_price | customCurrency: true }}
            </ion-text>
        </ion-col>
    </ion-row>
</ion-grid>
