import { gql } from 'apollo-angular';

const GET_PROVIA_ORDERS = gql`
    query GetProviaOrders(
        $user_name: String!
        $password: String!
        $po_number: String
        $job_name: String
        $remember: Boolean
        $used_remember: Boolean
    ) {
        proviaOrders(
            user_name: $user_name
            password: $password
            po_number: $po_number
            job_name: $job_name
            remember: $remember
            used_remember: $used_remember
        )
    }
`;

const GET_PROVIA_REMEMBER = gql`
    query GetProviaRemember($type: String!) {
        publicRememberProperties(type: $type)
    }
`;

export { GET_PROVIA_ORDERS, GET_PROVIA_REMEMBER };
