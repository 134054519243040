import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
    @Input() question: any;
    @Input() imgBaseUrl: string;

    @Output() answerModified = new EventEmitter<any>();

    toggleAnswer(answer): void {
        answer.IsSelected = !answer.IsSelected;

        this.answerModified.emit(this.question);
    }
}
