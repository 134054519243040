import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SAVE_USER_FEEDBACK } from '@core/mutations/feedback.mutations';
import { isAllowProvideUserFeedback } from '@core/queries/feedback.queries';
import { UserFeedbackInput } from '@shared/interfaces/user-feedback';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    constructor(private apollo: Apollo) {}

    saveFeedback(input: UserFeedbackInput): Observable<boolean> {
        if (!input.additional_info.device_model) {
            delete input.additional_info.device_model;
        }

        if (!input.additional_info.device_operating_system_version) {
            delete input.additional_info.device_operating_system_version;
        }

        if (!input.additional_info.app_version) {
            delete input.additional_info.app_version;
        }

        return this.apollo
            .query({
                query: SAVE_USER_FEEDBACK,
                variables: {
                    input
                }
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.saveUserFeedback));
    }

    isAllowToShowFeedback(): Observable<boolean> {
        return this.apollo
            .query({
                query: isAllowProvideUserFeedback
            })
            .pipe(map((res: ApolloQueryResult<any>) => res.data.isAllowProvideUserFeedback));
    }
}
