import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
    @Input() title = '';
    @Input() doneButtonName = 'Finish';
    @Input() cancelButtonName = '';
    @Input() isDisabled = false;
    @Input() isButtonsVisible = true;
    @Input() isDoneButtonOutlined = false;
    @Output() doneButtonClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancelButtonClicked: EventEmitter<void> = new EventEmitter<void>();
}
