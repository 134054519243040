<div class="modal-header">
    <ion-text class="title">Map</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <vendo-map
            [location]="houseData.additional_info.location"
            [featuresCollection]="featuresCollection"
    ></vendo-map>
</div>
<div class="modal-footer">
    <ion-button class="classic-width" (click)="capture()">Capture</ion-button>
</div>
