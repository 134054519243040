import { gql } from 'apollo-angular';

const GetAppointmentAssessmentQuestions = gql`
    query getAppointmentAssessmentQuestions($appointmentId: ID!) {
        getAppointmentAssessmentQuestions(appointment_id: $appointmentId) {
            title
            field_type
            multiple
            quote_header_question
            required
            answers
            default_answer
        }
    }
`;

export { GetAppointmentAssessmentQuestions };
