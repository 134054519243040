import { Component, Input } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'vendo-financing-dropdown-popover',
    templateUrl: './financing-dropdown-popover.component.html',
    styleUrls: ['./financing-dropdown-popover.component.scss']
})
export class FinancingDropdownPopoverComponent {
    @Input() items = [];
    isCollapsed = [];
    @Input() isUseCollapse = false;
    @Input() selectedRateSheetId;
    @Input() selectedFinancingOptionId;

    constructor(private popoverController: PopoverController) {}

    async selectItem(result): Promise<void> {
        if (result.disabled) {
            return;
        }

        await this.popoverController.dismiss(result);
    }

    collapseMenu(index): void {
        this.isCollapsed[index] = !this.isCollapsed[index];

        this.isCollapsed.forEach((item, itemIndex) => {
            if (itemIndex !== index) {
                this.isCollapsed[itemIndex] = false;
            }
        });
    }
}
