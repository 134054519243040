import { Injectable } from '@angular/core';

import round from 'lodash/round';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor() {}

    convertFractionToDecimal(value: string, useFloat = false): number {
        if (!value) {
            return;
        }

        const valueStr = value.toString().replace(',', '.');
        let parts: string[] = [];
        let wholeNum = 0;
        let fraction: string;
        let decimals = 0;
        let result: number;
        const indexFraction: number = valueStr.indexOf('/');

        if (indexFraction > 0 && indexFraction < valueStr.length - 1) {
            const separators: string[] = ['-', ' '];
            let separator: string;

            for (const key in separators) {
                const indexSeparator: number = valueStr.indexOf(separators[key]);

                if (indexSeparator > 0) {
                    separator = valueStr[indexSeparator];
                    break;
                }
            }
            if (valueStr.indexOf(separator) > 0) {
                parts = valueStr.split(separator);
                wholeNum = useFloat ? parseFloat(parts[0]) : parseInt(parts[0], 10);
                fraction = parts[1];
            } else {
                fraction = valueStr;
            }

            if (fraction.includes('/')) {
                const fractionParts = fraction.split('/');

                decimals =
                    (useFloat ? parseFloat(fractionParts[0]) : parseInt(fractionParts[0], 10)) /
                    (useFloat ? parseFloat(fractionParts[1]) : parseInt(fractionParts[1], 10));
            }
            result = wholeNum + decimals;
        } else {
            result = parseFloat(valueStr);
        }

        return isNaN(result) ? '0' : round(Math.abs(result), 4);
    }
}
