<div class="modal-header">
  <ion-text class="title">Select Bluetooth device</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <ion-list *ngIf="devices?.length">
    <ion-item button [detail]="true" *ngFor="let item of devices" (click)="selectDevice(item)">
      <ion-text>
        {{item.name}}
      </ion-text>
    </ion-item>
  </ion-list>
  <div *ngIf="!devices?.length && isSearching" class="search-block">
    <ion-icon name="search-outline"></ion-icon>
    <ion-text>Searching for devices...</ion-text>
  </div>
  <div *ngIf="!devices?.length && !isSearching" class="search-block">
    <ion-text>No devices available</ion-text>
    <ion-button (click)="searchDevices()">Try again</ion-button>
  </div>
</div>

<div class="modal-footer">
  <ion-button fill="outline" (click)="closeModal()">
    Cancel
  </ion-button>
</div>

