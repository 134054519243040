import { from, Observable, of } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { OfflineStorageService } from '@core/services/offline/offline-storage.service';

export class CommonOffline {
    constructor(private offlineStorageService: OfflineStorageService, private auth: AuthService) {}

    getCommonItem(type: string): Observable<any> {
        return of({
            values: {
                auth0: false,
                pendo: false,
                rollbar: false
            }
        });
    }

    getCommonItems(types?: string[], silentRequest = false): Observable<any> {
        const promise = this.offlineStorageService.read(`SELECT * FROM Common where id in ("${types.join('", "')}")`);

        return from(
            promise.then((res) => {
                return res.reduce((acc, item) => {
                    acc[item.id] = item.value;

                    return acc;
                }, {});
            })
        );
    }

    getOptionList(): Observable<any> {
        const user = this.auth.getUser();

        return of([
            {
                value: user.office.id,
                label: user.office.name
            }
        ]);
    }
}
