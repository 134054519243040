<div class="modal-header">
    <ion-text class="title">Select Existing Accessory</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <div class="tabs">
        <div class="tabs-list">
            <div class="tab-title" *ngFor="let category of accessoriesCategories; let i = index"
                 [class.active]="category.id === activeTab.id"
                 (click)="setActiveTab(category, i)">
                <div class="text-ellipsis">{{ category.name }}</div>
            </div>
        </div>
        <ion-content class="tab-content" (swipeleft)="changeTab(1)" (swiperight)="changeTab(-1)">
            <form [formGroup]="form">
                <ng-container formArrayName="accessoriesCategories">
                    <ion-item *ngIf="countOfColumns > 1" lines="full">
                        <ion-grid>
                            <ion-row>
                                <ion-col [size]="countOfColumns > 1 ? 6 : 10.5"></ion-col>
                                <ion-col *ngIf="countOfColumns > 1" class="package-title ion-text-center" size="1.5">
                                    All
                                </ion-col>
                                <ng-container *ngFor="let item of packages; let j = index">
                                    <ion-col *ngIf="countOfColumns > 1 || j === 0" class="package-title ion-text-center" size="1.5">
                                        {{ item }} {{ j + 1 }}
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                        </ion-grid>
                    </ion-item>
                    <ng-container [formGroupName]="activeTabIndex">
                        <ng-container formArrayName="accessories">
                            <ion-item *ngFor="let accessory of activeTab?.accessories; let i = index" [formGroupName]="i"
                                      lines="none">
                                <ng-container formArrayName="packages">
                                    <ion-grid>
                                        <ion-row>
                                            <ion-col [size]="countOfColumns > 1 ? 6 : 10.5">
                                                <ion-text>{{ accessory.name }}</ion-text>
                                            </ion-col>
                                            <ion-col *ngIf="countOfColumns > 1" class="ion-text-center" size="1.5">
                                                <ion-checkbox mode="md"
                                                              class="package"
                                                              formControlName="0"></ion-checkbox>
                                            </ion-col>
                                            <ng-container *ngFor="let item of packages; let j = index">
                                                <ion-col *ngIf="countOfColumns > 1 || j === 0" class="ion-text-center" size="1.5">
                                                    <ion-checkbox mode="md"
                                                                  class="package"
                                                                  [formControlName]="j + 1"></ion-checkbox>
                                                </ion-col>
                                            </ng-container>
                                        </ion-row>
                                    </ion-grid>
                                </ng-container>
                            </ion-item>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </form>
        </ion-content>
    </div>
</div>
<div class="modal-footer">
    <ion-button class="classic-width" (click)="save()">Save</ion-button>
</div>
