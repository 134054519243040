<div class="modal-header">
  <ion-text class="title">{{title}}</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
  <div class="notice" *ngIf="notice">{{notice}}</div>
  <ion-content>
    <ion-list lines="inset">
      <ion-item *ngFor="let resource of resources" class="item" lines="none" (click)="selectSource(resource)">
        <div class="list-item">
          <ion-text>{{ resource[propToShow] }}</ion-text>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </ion-item>
    </ion-list>
  </ion-content>
</div>
