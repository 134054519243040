import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hashToLabel'
})
export class HashToLabelPipe implements PipeTransform {
    transform(
        value: string,
        items: any[],
        hashProperty = 'hash',
        labelProperty = 'label',
        isStrictComparing = true
    ): string {
        if (!items || !Array.isArray(items) || !items.length) {
            return value;
        }

        const index: number = items.findIndex((item) =>
            isStrictComparing ? item[hashProperty] === value : item[hashProperty] == value
        );

        if (index === -1) {
            return value;
        }

        return items[index][labelProperty];
    }
}
