import { Injectable } from '@angular/core';

import { Network } from '@awesome-cordova-plugins/network/ngx';

import { AlertController } from '@ionic/angular';

import { BehaviorSubject } from 'rxjs';

import { FeaturesService } from '@core/services/features.service';
import { NetworkStatus } from '@shared/enums/network-status';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private networkStatus: BehaviorSubject<NetworkStatus> = new BehaviorSubject<NetworkStatus>(NetworkStatus.Online);
    get currentNetworkStatus(): NetworkStatus {
        return this.networkStatus.getValue();
    }
    private modalShown = false;
    private isOffline = window.localStorage.getItem('offline_mode') === '1';

    constructor(
        private alertController: AlertController,
        private featuresService: FeaturesService,
        private network: Network
    ) {
        // HACK: fix alert not presented when offline, due to lazy loading the alert controller.
        // Can be removed once #17450 is resolved: https://github.com/ionic-team/ionic/issues/17450
        this.alertController.create({ animated: false }).then((t) => {
            t.present();
            t.dismiss();
        });
    }

    initializeNetworkEvents(): void {
        this.network.onChange().subscribe((status: NetworkStatus) => {
            if (status === NetworkStatus.Offline && !this.isOffline) {
                this.showAlert();
            }
            this.networkStatus.next(status);
        });
    }

    async showAlert(): Promise<void> {
        if (this.modalShown) {
            return;
        }

        this.modalShown = true;
        let header: string;
        let message: string;
        const buttons = [
            {
                text: 'Dismiss',
                handler: () => {
                    this.modalShown = false;
                }
            }
        ];

        if (this.featuresService.isOfflineModeAvailable()) {
            header = 'No Internet Connection Available';
            message = 'Turn offline mode on to keep working on your appointment. Items will be synced up once online.';
            buttons.push({
                text: 'Go to offline mode',
                handler: () => {
                    window.localStorage.setItem('offline_mode', '1');
                    (window as any).location.reload();
                }
            });
        } else {
            header = 'Lost Internet Connectivity';
            message = 'Please check your internet connection to regain full functionality.';
        }

        const alert = await this.alertController.create({
            header,
            message,
            backdropDismiss: false,
            buttons
        });

        alert.onDidDismiss().then(() => (this.modalShown = false));
        await alert.present();
    }
}
