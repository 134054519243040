import { Component } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'vendo-confirmation-popover',
    templateUrl: './confirmation-popover.component.html',
    styleUrls: ['./confirmation-popover.component.scss']
})
export class ConfirmationPopoverComponent {
    constructor(private popoverController: PopoverController) {}

    skip(): void {
        this.dismiss('skip');
    }

    confirm(): void {
        this.dismiss('confirmed');
    }

    private dismiss(data?: any): void {
        this.popoverController.getTop().then((modal) => {
            modal.dismiss(data);
        });
    }
}
