<ion-app *ngIf="!isIframe"
         tqTheme
         [ngClass]="{
            'is-ipad': isIPad,
            'is-iphone-portrait': isIPhone && isPortrait,
            'keyboard-shown': isKeyboardOpened
         }">
    <ion-router-outlet id="root" root [swipeGesture]="false"></ion-router-outlet>
    <div class="spinner-background" slot="fixed" *ngIf="showSpinner">
        <ion-spinner [color]="'primary'" name="crescent"></ion-spinner>
    </div>
    <vendo-app-update *ngIf="updatesInProgress"></vendo-app-update>
</ion-app>
