import { gql } from 'apollo-angular';

const GET_CONFIG_WITH_OPENINGS = gql`
    query ConfiguratorWithOpening($quoteId: ID!, $openingId: ID!) {
        opening(opening_id: $openingId) {
            id
            name
            notes
            images {
                appointment_id
                opening_id
                type
                name
                note
                url
                position
                appointment_type
            }
            mulled
            quantity
            view_coordinates
            category {
                id
                category_type
                catalog_type
                allow_configure_questions
            }
            catalog {
                is_hide_product_image
                allow_request_hidden_step_questions
                predefined_images {
                    appointment_type
                    category_type
                    predefined_images {
                        label
                        is_required
                    }
                }
            }
            view_photo {
                img_url
            }
            catalog_id
            opening_details {
                answer
                relates_to
                catalog_attribute_hash
                question {
                    display_on_grid
                    order
                    title
                }
            }
            temporary
        }
        configurator(quote_id: $quoteId, opening_id: $openingId)
        nextOpening(opening_id: $openingId, quote_id: $quoteId) {
            id
        }
        mullingActions(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const GET_CONFIGURATION_PRICE = gql`
    query ConfiguratorWithOpening($quoteId: ID!, $openingId: ID!) {
        configuratorPrice(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const GET_CONFIG = gql`
    query ConfiguratorWithSteps($quoteId: ID!, $openingId: ID!) {
        configurator(quote_id: $quoteId, opening_id: $openingId)
    }
`;
const GET_STEPS_QUESTIONS = gql`
    query ConfiguratorStepsQuestions($quoteId: ID!, $openingId: ID!, $stepIds: [Int!]!) {
        configuratorStepsQuestions(quote_id: $quoteId, opening_id: $openingId, step_ids: $stepIds)
    }
`;

const GET_QUESTIONS = gql`
    query ConfiguratorQuestions($quoteId: ID!, $openingId: ID!) {
        configuratorAnswers(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const GET_USED_ADDERS = gql`
    query usedConfigurationAdders($quoteId: ID!, $openingId: ID!) {
        usedConfigurationAdders(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const GET_USED_ACCESSORIES = gql`
    query usedConfigurationAccessories($quoteId: ID!, $openingId: ID!) {
        usedConfigurationAccessories(quote_id: $quoteId, opening_id: $openingId)
    }
`;

const GET_AVAILABLE_ADDERS = gql`
    query GetAvailableAdders($appliesTo: String!, $categoryType: String) {
        configurationAdders(applies_to: $appliesTo, category_type: $categoryType) {
            name
            id
            adders {
                id
                name
                taxable
                default
                amount
                amount_type
                variable
                additional_info {
                    question_for_multiplier
                }
            }
        }
    }
`;

const GET_AVAILABLE_ACCESSORIES = gql`
    query GetAvailableAccessories($catalog_id: ID!) {
        configurationAccessories(catalog_id: $catalog_id) {
            name
            id
            accessories {
                id
                name
            }
        }
    }
`;

const GET_SPECIFIC_DRAWING = gql`
    query ConfigurationDrawing($quoteId: ID!, $openingId: ID!, $drawingId: Int!, $highResolution: Boolean) {
        configurationDrawing(
            quote_id: $quoteId
            opening_id: $openingId
            drawing_id: $drawingId
            high_resolution: $highResolution
        )
    }
`;

export {
    GET_CONFIG_WITH_OPENINGS,
    GET_STEPS_QUESTIONS,
    GET_AVAILABLE_ADDERS,
    GET_AVAILABLE_ACCESSORIES,
    GET_SPECIFIC_DRAWING,
    GET_CONFIGURATION_PRICE,
    GET_QUESTIONS,
    GET_CONFIG,
    GET_USED_ADDERS,
    GET_USED_ACCESSORIES
};
