<div class="modal-header">
    <ion-text class="title">{{title}}</ion-text>
    <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
    </ion-button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-row" *ngFor="let control of controlNames">
            <ion-label *ngIf="inputLabels[control]">{{inputLabels[control]}}</ion-label>
            <ion-input
                    [class]="control"
                    [formControlName]="control"
                    inputmode="tel"
                    type="text"
            ></ion-input>
        </div>
    </form>
</div>
<div class="modal-footer">

    <ion-button *ngFor="let button of buttons" class="classic-width" [fill]="button.fill"
                (click)="performAction(button.action)">{{button.label}}</ion-button>
</div>
