import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { NotesModalComponent } from '@shared/components';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

@Component({
    selector: 'vendo-installation-notes',
    templateUrl: './installation-notes.component.html',
    styleUrls: ['./installation-notes.component.scss']
})
export class InstallationNotesComponent {
    @Input() notes: any[];
    @Input() quoteId: string;
    @Input() openingId: string;

    constructor(private configureService: ConfigureService, private modalController: ModalController) {}

    async addNewNote(): Promise<void> {
        const modal = await this.modalController.create({
            component: NotesModalComponent,
            cssClass: 'add-notes-modal'
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (!data) {
            return;
        }

        this.notes.push(data);

        this.configureService.saveNote(this.quoteId, this.openingId, this.notes).subscribe();
    }

    removeNote(index: number): void {
        this.notes.splice(index, 1);

        this.configureService.saveNote(this.quoteId, this.openingId, this.notes).subscribe();
    }
}
