import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ImportOrderData } from '@shared/components/provia-order-modal/provia-order-modal.interfaces';

@Component({
    selector: 'vendo-provia-orders-list',
    templateUrl: './provia-orders-list.component.html',
    styleUrls: ['./provia-orders-list.component.scss']
})
export class ProviaOrdersListComponent {
    @Input() orders: any[];

    @Output() orderSelected = new EventEmitter<ImportOrderData>();

    pickOrder(order: any, event: Event): void {
        this.orderSelected.emit({
            orderNumber: order.orderno,
            event
        });
    }
}
