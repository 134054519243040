<div class="page-header">
  <ion-text class="page-title">
    {{ title }}
    <ng-content select="[filter]"></ng-content>
  </ion-text>

  <div *ngIf="isButtonsVisible" class="actions-wrap">
    <ion-button *ngIf="cancelButtonName"
                color="primary"
                fill="outline"
                class="classic-width"
                (click)="cancelButtonClicked.emit()">
      {{ cancelButtonName }}
    </ion-button>

    <ng-content></ng-content>

    <ion-button *ngIf="doneButtonName"
                color="primary"
                [fill]="isDoneButtonOutlined ? 'outline' : 'solid'"
                class="classic-width"
                [disabled]="isDisabled"
                (click)="!isDisabled && doneButtonClicked.emit()">
      {{ doneButtonName }}
    </ion-button>
  </div>
</div>
