import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetDemoResource, GetDemoResources } from '../queries/demo.queries';

@Injectable({
    providedIn: 'root'
})
export class DemoService {
    constructor(private apollo: Apollo) {}

    getDemoResources(appointment_id: string): Observable<any> {
        return this.apollo
            .query({
                query: GetDemoResources,
                variables: {
                    appointment_id
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.myDemoResources));
    }

    getDemoResource(id: number, appointment_id: number, geolocation = {}): Observable<any> {
        return this.apollo
            .query({
                query: GetDemoResource,
                variables: {
                    id,
                    appointment_id,
                    geolocation
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.myDemoResource));
    }
}
