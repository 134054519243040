import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { BaseModal } from '@shared/components/base-modal';

@Component({
    selector: 'vendo-payment-error-modal',
    templateUrl: './payment-error-modal.component.html',
    styleUrls: ['./payment-error-modal.component.scss']
})
export class PaymentErrorModalComponent extends BaseModal {
    @Input() error: any = {};

    constructor(public modalCtrl: ModalController) {
        super(modalCtrl);
    }
}
