<div class="modal-header">
  <ion-text class="title">Pricing Details</ion-text>
  <ion-button class="icon-only" fill="outline" color="dark" (click)="closeModal()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>

<div class="modal-body">
    <ng-container *ngIf="pricingInfoGroups.length <= 1; else moreThanOneGroup">
      <ng-container *ngIf="pricingInfoGroups[0]?.PriceModifiers.length; else noDataTemplate">
        <ion-content class="content-body">
          <ion-list>
              <ion-item *ngFor="let modifier of pricingInfoGroups[0].PriceModifiers">
                <div class="flex-between w-100">
                  <div>{{ modifier.Name }}</div>
                  <div>{{ modifier.Amount | customCurrency }}</div>
                </div>
              </ion-item>
          </ion-list>
        </ion-content>
        <div class="list-price">
          <b>List Price</b> <b>{{ pricingInfoGroups[0].Price | customCurrency }}</b>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #moreThanOneGroup>
      <ion-content class="content-body">
        <ion-accordion-group [multiple]="true">
        <ng-container *ngFor="let group of pricingInfoGroups">
          <ion-accordion toggleIconSlot="end">
            <ion-item slot="header">
              <ion-label>
                <div class="flex-between">
                  <div>{{ group.GroupName }}</div>
                  <div>List Price - <b>{{ group.Price | customCurrency }}</b></div>
                </div>
              </ion-label>
            </ion-item>
            <ion-list slot="content">
              <ng-container *ngIf="pricingInfoGroups[0].PriceModifiers.length; else noDataTemplate">
                <ion-item *ngFor="let modifier of group.PriceModifiers">
                  <div class="flex-between w-100">
                    <div>{{ modifier.Name }}</div>
                    <div>{{ modifier.Amount | customCurrency }}</div>
                  </div>
                </ion-item>
              </ng-container>
            </ion-list>
          </ion-accordion>
        </ng-container>
      </ion-accordion-group>
      </ion-content>
    </ng-template>
  <ng-template #noDataTemplate>
    <div class="no-data-row">
      No data are available
    </div>
  </ng-template>
</div>
