<div class="drawing-container">
  <div *ngFor="let area of areas"
       class="clickable-area"
       [ngStyle]="{
       'left': area.dimensions[0] + 'px',
       'top': area.dimensions[1] + 'px',
       'width': area.dimensions[2] + 'px',
       'height': area.dimensions[3] + 'px'
       }" (click)="selectArea(area)"
  ></div>
  <ion-img class="drawing" [src]="imgSrc"></ion-img>
</div>
