import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NavController } from '@ionic/angular';

import { switchMap, takeUntil } from 'rxjs/operators';

import { AppService } from '@core/services/app.service';
import { AuthService } from '@core/services/auth.service';
import { FeaturesService } from '@core/services/features.service';
import { FEATURES } from '@shared/constants/features';
import { AbstractForm } from '@shared/helpers/abstract-form';
import { ValidateMatchPattern } from '@shared/validators/match-pattern.validator';
import { ValidateMustMatch } from '@shared/validators/must-match.validator';

import { ResetPasswordHashModel } from '../../models/reset-password-hash-model';
import { PrivacyPolicyService } from '../../services/privacy-policy.service';

@Component({
    selector: 'vendo-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss', '../../auth.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent extends AbstractForm implements OnInit {
    resetPasswordForm: UntypedFormGroup;
    resetPasswordHash: ResetPasswordHashModel;
    currentYear = new Date().getFullYear();
    logoUrl: string;
    isHigherImage: boolean = this.appService.checkIsAndersen() || this.appService.checkIsGenesis();

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private termsAndConditionsModalService: PrivacyPolicyService,
        private featureService: FeaturesService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.resetPasswordHash = this.route.snapshot.data.resetPasswordHash;

        this.resetPasswordForm = this.formBuilder.group(
            {
                confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
                newPassword: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        ValidateMatchPattern(
                            '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$',
                            'Value should include: a lower-case letter, an upper-case letter, a number and special character'
                        )
                    ]
                ],
                accept_terms_and_conditions: [false, Validators.requiredTrue]
            },
            {
                validator: ValidateMustMatch(
                    { name: 'newPassword', label: 'New Password' },
                    { name: 'confirmPassword', label: 'Confirm Password' }
                )
            }
        );

        this.logoUrl = await this.appService.getLogoUrl('../../');

        this.authService.signedIn
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: boolean) => {
                if (value) {
                    this.navigateToMain();
                }
            });
    }

    changePassword(): void {
        this.resetPasswordForm.markAllAsTouched();

        if (!this.resetPasswordForm.valid) {
            return;
        }

        this.authService
            .resetPassword(
                this.resetPasswordHash.hash,
                this.resetPasswordForm.value.newPassword,
                this.resetPasswordForm.value.confirmPassword,
                this.resetPasswordForm.value.accept_terms_and_conditions
            )
            .pipe(
                switchMap(() => this.authService.signedIn.asObservable()),
                takeUntil(this.destroy$)
            )
            .subscribe((value: boolean) => {
                if (value) {
                    this.navigateToMain();
                }
            });
    }

    async openTermsAndConditionsModal(): Promise<void> {
        await this.termsAndConditionsModalService.openTermsAndConditionsModal();
    }

    private navigateToMain(): void {
        this.navCtrl.navigateRoot(['main']).then(() => {
            if (
                !this.featureService.hasFeature(FEATURES.OFFLINE_MODE) &&
                window.localStorage.getItem('offline_mode') === '1'
            ) {
                window.localStorage.setItem('offline_mode', '0');
                (window as any).location.reload();
            }
        });
    }
}
