import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import isEqual from 'lodash/isEqual';

import { BaseModal } from '@shared/components/base-modal';
import { QuestionType } from '@shared/enums/question-type.enum';
import { WcpPreferenceOptionType } from '@shared/enums/wcp-preference-option-type.enum';
import { OriginalAnswer } from '@shared/interfaces/answer';
import { CommonWcpPreference } from '@shared/interfaces/common-items';
import { OriginalQuestion } from '@shared/interfaces/question';

@Component({
    selector: 'vendo-preference-type-modal',
    templateUrl: './preference-type-modal.component.html',
    styleUrls: ['./preference-type-modal.component.scss']
})
export class PreferenceTypeModalComponent extends BaseModal implements OnInit {
    @Input() answer: OriginalAnswer;
    @Input() question: OriginalQuestion;
    @Input() imgBaseUrl = '';
    @Input() availableWcpPreferenceOptions: number[] = [];
    @Input() wcpPreferences: CommonWcpPreference[] = [];
    preferences;
    readonly questionTypes: typeof QuestionType = QuestionType;
    form: UntypedFormGroup;
    private initData = {};

    constructor(modalCtrl: ModalController, private formBuilder: UntypedFormBuilder) {
        super(modalCtrl);
    }

    ngOnInit(): void {
        this.wcpPreferences = this.wcpPreferences.filter(({ value }) =>
            this.availableWcpPreferenceOptions.includes(value)
        );
        this.form = this.formBuilder.group(
            this.wcpPreferences.reduce((acc, val) => {
                acc[val.value] = Boolean(this.answer.PreferenceType && this.answer.PreferenceType.includes(val.value));

                return acc;
            }, {})
        );
        this.initData = this.form.getRawValue();
    }

    apply(): void {
        if (isEqual(this.initData, this.form.getRawValue())) {
            this.closeModal();

            return;
        }

        const data: WcpPreferenceOptionType[] = Object.entries(this.form.getRawValue())
            .filter(([key, value]: [string, boolean]) => value)
            .map(([key, value]: [string, boolean]) => Number(key));

        this.dismiss(data);
    }
}
