import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';

@Component({
    selector: 'vendo-steps-nav',
    templateUrl: './steps-nav.component.html',
    styleUrls: ['./steps-nav.component.scss']
})
export class StepsNavComponent implements OnChanges, AfterViewChecked {
    @Input() steps: any[];
    @Output() stepClicked = new EventEmitter<any>();
    @Input() activeStep: any;
    @ViewChildren('stepElement') stepElements: QueryList<ElementRef>;
    isShowNavButtons = false;
    private scrollToElementByIndex = -1;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeStep?.currentValue) {
            this.setScrollToElementByIndex();
        }
    }

    ngAfterViewChecked(): void {
        this.scrollToActiveTab();
    }

    setActiveStep(step: any): void {
        if (step.Disabled) {
            return;
        }
        this.activeStep = step;
        this.stepClicked.emit(step);
        this.setScrollToElementByIndex();
    }

    private setScrollToElementByIndex(): void {
        this.scrollToElementByIndex = this.steps.findIndex((item) => item.ID === this.activeStep.ID);
    }

    private scrollToActiveTab(): void {
        if (this.scrollToElementByIndex > -1) {
            const stepElements = this.stepElements.toArray();
            const tabElement = stepElements[this.scrollToElementByIndex];

            if (!tabElement) {
                return;
            }

            tabElement.nativeElement.scrollIntoView();
            this.scrollToElementByIndex = -1;
        }
    }
}
