<div class="price">
    <div *ngIf="isShowDiscount"
         class="discounted-price">
        {{ finalPrice | customCurrency: true }}
    </div>

    <div [class.discounted]="isShowDiscount">
        <span class="value">
            {{ totalPrice | customCurrency: true }}
        </span>
    </div>
</div>
