import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class KeyboardService {
    private isSkipHandleIOSKeyboard = false;

    constructor() {}

    getIsSkipHandleIOSKeyboard(): boolean {
        return this.isSkipHandleIOSKeyboard;
    }

    setIsSkipHandleIOSKeyboard(value: boolean): void {
        this.isSkipHandleIOSKeyboard = value;
    }
}
