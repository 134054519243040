import { Component, Input } from '@angular/core';

@Component({
    selector: 'vendo-free-text-popover',
    templateUrl: './free-text-popover.component.html',
    styleUrls: ['./free-text-popover.component.scss']
})
export class FreeTextPopoverComponent {
    @Input() text = '';
}
