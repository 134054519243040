import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import round from 'lodash/round';

import { MenuService } from '@core/services/menu.service';
import { BaseModal } from '@shared/components/base-modal';
import { AbstractForm } from '@shared/helpers/abstract-form';

import { AppointmentsService } from '../../../main/appointments/services/appointments.service';

@Component({
    selector: 'vendo-change-custom-tax',
    templateUrl: './change-custom-tax.component.html',
    styleUrls: ['./change-custom-tax.component.scss']
})
export class ChangeCustomTaxComponent extends AbstractForm implements OnInit {
    @Input() appointmentId: number;
    @Input() actualTax: number;
    @Input() customTax: number;
    customTaxControl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(100),
        Validators.required
    ]);
    baseForm = new BaseModal(this.modalCtrl);

    constructor(
        private modalCtrl: ModalController,
        private appointmentService: AppointmentsService,
        private menuService: MenuService
    ) {
        super();
    }

    ngOnInit(): void {
        this.customTaxControl.setValue(this.actualTax);

        this.customTaxControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                filter((value: number) => value !== null),
                takeUntil(this.destroy$)
            )
            .subscribe((value: number) => {
                const rounded: number = round(value, 6);

                if (isNaN(rounded)) {
                    this.customTaxControl.setValue(null);

                    return;
                }

                if (rounded !== value) {
                    this.customTaxControl.setValue(rounded);
                }
            });
    }

    save(): void {
        this.appointmentService.changeCustomTax(this.appointmentId, this.customTaxControl.value).subscribe(() => {
            this.menuService.emitTaxWasChanged();
            this.baseForm.dismiss(true);
        });
    }
}
