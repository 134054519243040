<ion-grid>
    <ion-row class="header-row" (click)="isExpanded = !isExpanded">
        <ion-col [size]="isShowColumnTitles && isExpanded ? 7 : 12">
            <ion-button class="icon-only"
                        [fill]="isExpanded ? 'outline' : undefined"
                        color="primary">
                <ion-icon [name]="isExpanded ? 'remove-outline' : 'add-outline'"></ion-icon>
            </ion-button>
            <span>{{ title }}</span>
        </ion-col>
        <ng-container *ngIf="isShowColumnTitles && isExpanded">
            <ion-col *ngIf="isShowOldAnswerCol" size="2.5">
                Original
            </ion-col>
            <ion-col [size]="isShowOldAnswerCol ? 2.5 : 5">
                {{ isShowOldAnswerCol ? 'New' : 'Answers' }}
            </ion-col>
        </ng-container>
    </ion-row>

    <ng-container *ngIf="isExpanded">
        <ion-row *ngFor="let question of questions" class="data-row">
            <ng-container>
                <ion-col size="7">
                    {{ question.label }}
                </ion-col>
                <ion-col *ngIf="isShowOldAnswerCol" size="2.5">
                    {{ question.old }}
                </ion-col>
                <ion-col [size]="isShowOldAnswerCol ? 2.5 : 5">
                    {{ question.new || '-' }}
                </ion-col>
            </ng-container>
        </ion-row>
    </ng-container>
</ion-grid>
