import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { DictionaryService } from '@core/services/dictionary.service';
import { FormHelperService } from '@core/services/form-helper.service';
import { ToastService } from '@core/services/toast.service';
import { BaseModal } from '@shared/components/base-modal';
import { maxQuantity } from '@shared/constants/form-validation-constants';
import { DictionaryType } from '@shared/enums/dictionary-type';
import { Dictionary } from '@shared/interfaces/dictionary';
import { ValidateMatchPattern } from '@shared/validators/match-pattern.validator';
import { RequiredOneTrueValidator } from '@shared/validators/required-one-true.validator';

import { ConfigureService } from '../../../main/appointments/configure/services/configure.service';

@Component({
    selector: 'vendo-create-adder-modal',
    templateUrl: './create-adder-modal.component.html',
    styleUrls: ['./create-adder-modal.component.scss']
})
export class CreateAdderModalComponent extends BaseModal implements OnInit, OnDestroy {
    @Input() adder: any;
    @Input() openingId?: number;
    @Input() quoteId?: number;
    @Input() countOfPackages = 1;
    @Input() packageNames: string[] = [];
    @Input() isProjectAdder: boolean;
    form: UntypedFormGroup;
    adderNameSetting: Dictionary;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private authService: AuthService,
        private configureService: ConfigureService,
        private dictionaryService: DictionaryService,
        private formBuilder: UntypedFormBuilder,
        private formHelperService: FormHelperService,
        modalCtrl: ModalController,
        private toastService: ToastService
    ) {
        super(modalCtrl);
    }

    async ngOnInit(): Promise<void> {
        this.initForm();
        this.adderNameSetting = await this.dictionaryService.getDictionaryName(DictionaryType.Adder);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getMessages(errors): string[] {
        return this.formHelperService.getMessages(errors);
    }

    save(): void {
        if (this.form.invalid) {
            this.toastService.showMessage('Please fill in all the required fields!');

            return;
        }

        const adder = this.form.getRawValue();

        delete adder.packages;

        if (this.adder) {
            adder.id = this.adder.id;
            adder.adder_type_id = this.adder.adder_type_id || this.adder.adder_type.id;
        }

        (this.adder
            ? this.configureService.updateMiscAdder(adder, this.quoteId, this.openingId)
            : this.configureService.createMiscAdder(adder)
        ).subscribe((data) => this.dismiss(this.getResult(data.id)));
    }

    count(step: number): void {
        const control: AbstractControl = this.form.get('quantity');

        control.markAsTouched();

        const value: number = isNaN(control.value) ? 1 : control.value;

        if (value === 1 && step === -1) {
            return;
        }

        control.setValue(value + step);
    }

    get packagesFormArray(): UntypedFormArray {
        return this.form.get('packages') as UntypedFormArray;
    }

    private initForm(): void {
        const user = this.authService.getUser();

        const isDefaultCustomAddersTaxable: boolean =
            user.office.other_settings.default_custom_adders_as_taxable || false;

        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            short_description: '',
            amount: [null, Validators.required],
            quantity: [
                1,
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(maxQuantity),
                    ValidateMatchPattern('^[0-9]*', 'Value should include only numbers')
                ]
            ],
            taxable: { value: isDefaultCustomAddersTaxable, disabled: isDefaultCustomAddersTaxable },
            packages: this.formBuilder.array(
                new Array(this.countOfPackages + 1).fill(true),
                RequiredOneTrueValidator()
            ),
            amount_type: 'static',
            applies_to: this.isProjectAdder ? 'project' : 'line'
        });

        if (this.adder) {
            let isAllSelected: boolean;

            if (this.isProjectAdder) {
                isAllSelected = this.adder.packages.every(Boolean);
            }

            this.form.patchValue({
                ...this.adder,
                ...(this.isProjectAdder && { packages: [isAllSelected, ...this.adder.packages] })
            });
        }

        this.packagesFormArray.controls[0].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
            this.packagesFormArray.setValue(new Array(this.countOfPackages + 1).fill(value), { emitEvent: false });
            this.packagesFormArray.markAsTouched();
        });

        merge(...this.packagesFormArray.controls.slice(1).map((control: AbstractControl) => control.valueChanges))
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                const packages: boolean[] = this.packagesFormArray.getRawValue();
                const isAllSelected: boolean = packages.slice(1).every((value: boolean) => value);

                this.packagesFormArray.at(0).setValue(isAllSelected, { emitEvent: false });
                this.packagesFormArray.markAsTouched();
            });
    }

    private getResult(id: string): any[] {
        let result: any[] = new Array(this.countOfPackages + 1).fill(null);

        if (this.packagesFormArray.at(0).value) {
            result = result.map(() => [id]);
        } else {
            result = result.map(() => []);
            for (let i = 1; i < this.packagesFormArray.controls.length; i++) {
                if (this.packagesFormArray.at(i).value) {
                    result[i - 1].push(id);
                }
            }
        }

        return result;
    }
}
