import { Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APPLY_SERIES_CHANGES } from '../mutations/series-changes.mutations';
import { GET_SERIES_CHANGES } from '../queries/series-changes.queries';
import { SeriesChangeGroup, SeriesChangeInput, SeriesChangesResult } from '@shared/interfaces/series-change';

@Injectable({
    providedIn: 'root'
})
export class SeriesChangesService {
    constructor(private apollo: Apollo) {}

    applySeriesChange(quoteId: number, series_changes: SeriesChangeInput[]): Observable<SeriesChangesResult> {
        return this.apollo
            .mutate({
                mutation: APPLY_SERIES_CHANGES,
                variables: {
                    quoteId,
                    series_changes
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.applySeriesChanges));
    }

    getSeriesChanges(quoteId: string, openingIds: string[]): Observable<SeriesChangeGroup[]> {
        return this.apollo
            .query({
                query: GET_SERIES_CHANGES,
                variables: {
                    quoteId,
                    openingIds
                }
            })
            .pipe(map((resp: ApolloQueryResult<any>) => resp.data.seriesChanges));
    }
}
