import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

declare let moment: any;

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor() {}

    transform(value: any, pattern = 'MM/dd/yyyy'): any {
        if (!value) {
            return value;
        }

        const datePipe: DatePipe = new DatePipe('en-US');

        return datePipe.transform(moment.utc(value).local(), pattern);
    }
}
