import { SignatureTabs } from '@shared/components/signature-modal/signature-modal.constants';

export class SignatureTabsModel {
    tabs = [];

    constructor(data: { customerSignatures: number; customerInitials: number; sellerSignatures: number }) {
        if (data.customerInitials) {
            this.tabs.push({
                name: 'Customer Initials',
                hash: SignatureTabs.CUSTOMER_INITIALS,
                isInitials: true
            });
        }
        if (data.customerSignatures) {
            this.tabs.push({
                name: 'Customer Signature',
                hash: SignatureTabs.CUSTOMER_SIGNATURE
            });
        }
        if (data.sellerSignatures) {
            this.tabs.push({
                name: 'Seller Signature',
                hash: SignatureTabs.SELLER_SIGNATURE
            });
        }
    }
}
