import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vendo-package-selector',
    templateUrl: './package-selector.component.html',
    styleUrls: ['./package-selector.component.scss']
})
export class PackageSelectorComponent {
    @Input() packages: any[];
    @Input() activePackageId: number;

    @Output() packageChanged = new EventEmitter<number>();

    selectionChanged(): void {
        this.packageChanged.emit(this.activePackageId);
    }
}
